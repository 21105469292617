import { Button } from '@mui/material';
import Iconify from 'src/components/iconify';
import { useLocales } from 'src/locales';
import { DEFAULT_REQUEST_FILTERS, RequestFilters } from 'src/@types/request';
import { RootStyle, FilterChip, CustomFieldsFilterChip } from 'src/utils/SummaryUtils';

type Props = {
    filters: RequestFilters,
    isShowReset: boolean,
    onResetAll: VoidFunction,
    onResetFilter: (el: any, value?: any) => void
}

export default function RequestsFilterSummary({ filters, isShowReset, onResetAll, onResetFilter }: Props) {

    const { translate } = useLocales();

    const { externalId, requestType, customerId, customer, customFields } = filters;

    return (
        <RootStyle sx={{ mb: 2, ml: 2 }}>

            {customerId && customer && customerId !== DEFAULT_REQUEST_FILTERS.customerId &&
                < FilterChip
                    el={"orders.filter.customerId"}
                    value={customer!.name!}
                    onResetFilter={onResetFilter}
                />
            }

            {externalId && externalId !== DEFAULT_REQUEST_FILTERS.externalId &&
                <FilterChip
                    el={"returns.filter.partRef"}
                    value={externalId}
                    onResetFilter={onResetFilter}
                />
            }

            {requestType !== DEFAULT_REQUEST_FILTERS.requestType &&
                <FilterChip
                    el={"returns.tableHeaders.reason"}
                    value={`${translate(`request.${requestType!}`)}`}
                    onResetFilter={onResetFilter}
                    toDelete={"requestType"}
                />
            }

            {customFields && Object.entries(customFields).length > 0 &&
                <CustomFieldsFilterChip
                    context={"ReturnRequestApprover"}
                    customfieldValues={customFields}
                    onResetFilter={onResetFilter}
                />
            }

            {
                isShowReset && (
                    <Button
                        color="error"
                        size="small"
                        onClick={onResetAll}
                        startIcon={<Iconify icon={'ic:round-clear-all'} />}
                    >
                        {`${translate("commons.clear")}`}
                    </Button>
                )
            }

        </RootStyle >
    );
}