import axios, { AxiosResponse } from "axios";
import { ContainerFilters } from "src/@types/container";
import { Group } from "./paths";
import { PagedResponseType } from "src/@types/commons";
import { GroupMemberSearchResult, GroupSearchResult, GroupType, NewGroup } from "src/@types/group";

const createGroup = (params: NewGroup): Promise<AxiosResponse> => {
    const url = Group.admin.group.create();

    return axios.post(url, params);
};

const updateGroup = (params: NewGroup, id: string): Promise<AxiosResponse> => {
    const url = Group.admin.group.edit(id);

    return axios.put(url, params);
};

const detailGroup = (id: string): Promise<AxiosResponse<GroupType>> => {
    const url = Group.admin.group.detail(id);

    return axios.get(url);
};

const searchGroups = (options: ContainerFilters): Promise<AxiosResponse<PagedResponseType<GroupSearchResult>>> => {
    const url = Group.admin.group.list();

    return axios.get(url, { params: options, paramsSerializer: { indexes: null } });
};

const searchGroupMembers = (options: ContainerFilters, id: string): Promise<AxiosResponse<PagedResponseType<GroupMemberSearchResult>>> => {
    const url = Group.admin.group.members(id);

    return axios.get(url, { params: options, paramsSerializer: { indexes: null } });
};

const searchGroupLogs = (id: string, filters: any): Promise<AxiosResponse<PagedResponseType<any>>> => {
    const url = Group.admin.group.logs(id);

    return axios.get<any>(url, { params: filters });
};

const enableGroup = (id: string): Promise<AxiosResponse> => {
    const url = Group.admin.group.enable(id);

    return axios.patch(url);
};

const disableGroup = (id: string): Promise<AxiosResponse> => {
    const url = Group.admin.group.disable(id);

    return axios.patch(url);
};

export {
    createGroup,
    updateGroup,
    detailGroup,
    searchGroups,
    searchGroupMembers,
    searchGroupLogs,
    enableGroup,
    disableGroup
};