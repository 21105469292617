import { WarningRounded } from "@mui/icons-material";
import { Box, Typography } from "@mui/material";
import { RequestApproverNotes } from "src/@types/request";
import { useLocales } from "src/locales";
import { InfoIcon } from "src/theme/overrides/CustomIcons";

export const FeeStyle = {
    borderRadius: '8px',
    px: 1,
    py: 1.5,
    my: 2
};

export const FeeTypographyStyle = {
    color: '#7A0916',
    display: 'flex',
    justifyContent: 'flex-start',
    gap: 2,
    "& b": { marginLeft: -1.25, marginRight: -1.25 }
};

//---------------------------------------------------------------------------------------

interface RefusedNotesAlertProps {
    refusedNotes: {
        note: string,
        customFields: Record<string, string>
    }
}

export function RefusedNotesAlert({ refusedNotes }: RefusedNotesAlertProps) {

    const { translate } = useLocales();

    return (
        <Box sx={{ bgcolor: (theme) => theme.palette.error.lighter, ...FeeStyle }}>
            <Typography sx={{ ...FeeTypographyStyle }}>
                <InfoIcon color="error" />
                {`${translate(`request.refusal`)}: `}
                {refusedNotes.note}
            </Typography>
        </Box>
    );
}

//---------------------------------------------------------------------------------------

interface AmendNotesAlertProps {
    amendNotes: {
        note: string,
        customFields: Record<string, string>
    }
}

export function AmendNotesAlert({ amendNotes }: AmendNotesAlertProps) {

    const { translate } = useLocales();

    return (
        <Box sx={{ bgcolor: (theme) => theme.palette.error.lighter, ...FeeStyle }}>
            <Typography sx={{ ...FeeTypographyStyle }}>
                <InfoIcon color="error" />
                {`${translate(`request.amendReason`)}: `}
                {amendNotes.note}
            </Typography>
        </Box>
    );
}

//---------------------------------------------------------------------------------------

interface FrequencyFeeAlertProps {
    frequencyFee: number
}

export function FrequencyFeeAlert({ frequencyFee }: FrequencyFeeAlertProps) {

    const { translate } = useLocales();

    return (
        <Box sx={{ bgcolor: (theme) => theme.palette.warning.lighter, ...FeeStyle }}>
            <Typography sx={{ ...FeeTypographyStyle }}>
                <WarningRounded color="warning" />
                {`${translate('request.messages.feeFrequencyWarning')}`}
                <b>-{frequencyFee}%</b>
                {`${translate('request.messages.disc')}`}
            </Typography>
        </Box>
    );
}

//---------------------------------------------------------------------------------------

interface InvoiceFeeAlertProps {
    invoiceFee: number
}

export function InvoiceFeeAlert({ invoiceFee }: InvoiceFeeAlertProps) {

    const { translate } = useLocales();

    return (
        <Box sx={{ bgcolor: (theme) => theme.palette.warning.lighter, ...FeeStyle }}>
            <Typography sx={{ ...FeeTypographyStyle }}>
                <WarningRounded color="warning" />
                {`${translate('request.messages.feeInvoiceWarning')}`}
                <b>-{invoiceFee}%</b>
                {`${translate('request.messages.disc')}`}.
            </Typography>
        </Box>
    );
}

//---------------------------------------------------------------------------------------

export function HasSurveillanceAlert() {

    const { translate } = useLocales();

    return (
        <Box sx={{ bgcolor: (theme) => theme.palette.error.lighter, ...FeeStyle }}>
            <Typography sx={{ ...FeeTypographyStyle }}>
                <InfoIcon color="error" />
                {`${translate('request.messages.underSurvelliance')}`}.
            </Typography>
        </Box>
    );
}

//---------------------------------------------------------------------------------------

interface ApproverNotesAlertProps {
    approverNotes: RequestApproverNotes
}

export function ApproverNotesAlert({ approverNotes }: ApproverNotesAlertProps) {

    const { translate } = useLocales();

    return (
        <Box sx={{ bgcolor: (theme) => theme.palette.warning.lighter, ...FeeStyle }}>
            <Typography sx={{ ...FeeTypographyStyle }}>
                <WarningRounded color="warning" />
                {`${translate('request.messages.feeSurvellianceWarning')}`}
                <b>-{approverNotes.surveillanceFee}%</b>
                {`${translate('request.messages.disc')}`}.
            </Typography>
        </Box>
    );
}

//---------------------------------------------------------------------------------------

export function FortyDaysAlert() {

    const { translate } = useLocales();

    return (
        <Box sx={{ bgcolor: (theme) => theme.palette.info.lighter, ...FeeStyle, mt: 0, mb: 1 }}>
            <Typography sx={{ ...FeeTypographyStyle, color: (theme) => theme.palette.info.darker }}>
                <InfoIcon color="info" sx={{ ml: 1 }} />
                {`${translate('request.messages.fortyDaysMessage')}`}.
            </Typography>
        </Box>
    );
}