import { Box, Checkbox, Container, FormControl, FormControlLabel, InputLabel, Select, TextField, Button, List, ListItem, IconButton, OutlinedInput, InputAdornment, Tooltip, Switch, FormHelperText } from "@mui/material";
import { LocalizedString } from "src/@types/commons";
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import LocalizedInput from "src/appComponents/LocalizedInput";
import Page from "src/appComponents/Page";
import { useSettingsContext } from "src/components/settings";
import HeaderBreadcrumbs from '../../../components/custom-breadcrumbs';
import { useLocales } from "src/locales";
import { PATH_DASHBOARD } from "src/routes/paths";
import { ReactNode, useEffect, useState } from "react";
import { DEFAULT_PARAMS, EntityType, NewCustomField, core, rvMatrix, spareParts } from "src/@types/customField";
import { LoadingButton } from "@mui/lab";
import { create, section } from "src/services/customFieldServices";
import { useNavigate } from "react-router";
import InfoIcon from '@mui/icons-material/Info';
import useTenant from "src/appHooks/useTenant";
import StarIcon from '@mui/icons-material/Star';
import { IconButtonAnimate } from "src/components/animate";
import ErrorModal from "src/components/modals/ErrorModal";
import SuccessModal from "src/components/modals/SuccessModal";
import * as Yup from 'yup';
import { convert } from "src/utils/currency";
import { CurrencyTypes } from "src/@types/vehicle";
import useCurrency from "src/appHooks/useCurrency";
import { DatePicker } from "@mui/x-date-pickers";
import dayjs, { Dayjs } from "dayjs";
import { LicenseTypes, TenantType } from "src/@types/tenant";

export default function CustomFieldCreation() {

    const { translate } = useLocales();

    const { themeStretch, currency } = useSettingsContext();

    const navigate = useNavigate();

    const tenant = useTenant();

    const { rates } = useCurrency();

    const [param, setParam] = useState<NewCustomField>(DEFAULT_PARAMS);

    const [check, setCheck] = useState(false);

    const [searchable, setSearchable] = useState(false);

    const [autoComplete, setAutoComplete] = useState(false);

    const [autoId, setAutoId] = useState(true);

    const [selectOptions, setSelectOptions] = useState<Record<string, string[]>>({ 'En': [''] });

    const [sections, setSections] = useState<string[]>([]);

    const [lang, setLang] = useState('En');

    const [isOpenErr, setIsOpenErr] = useState(false);

    const [isOpenSucc, setIsOpenSucc] = useState(false);

    const [favourite, setFavourite] = useState<Record<string, number>>({ 'En': -1 });

    const [isFilter, setIsFilter] = useState(false);

    const [errors, setErrors] = useState<any>({});

    const [locError, setLocError] = useState<Record<number, string>>({});

    const [isDisabled, setIsDisabled] = useState(true);

    const [newSection, setNewSection] = useState("");

    const [update, setUpdate] = useState(false);

    const LocalizedStringSchema = Yup.object().shape({
        text: Yup.string().trim().required(),
        culture: Yup.string().trim().required()
    });

    const sectionFactory: Record<string, ReactNode> = {
        User: <></>,
        Vehicle: <></>,
        Organization:
            <>
                <option value={'General'}>{`${translate('organization.detail.general')}`}</option>
                <option value={'Address'}>{`${translate('commons.address')}`}</option>
                <option value={'Contact'}>{`${translate('organization.form.contacts')}`}</option>
                <option value={'Fiscal'}>{`${translate('organization.detail.fiscal')}`}</option>
            </>,
        RvMatrix: <></>,
        Brand: <></>,
        "": <></>
    };

    const schema = Yup.object().shape({
        name: Yup.array().of(LocalizedStringSchema),
        entityType: Yup.string().trim().nonNullable().required(`${translate('customField.form.entity')} ${translate('customField.validation.required')}`).oneOf(['User', 'Vehicle', 'RvMatrix', 'Brand', 'Organization', 'SpareParts', 'Invoice', 'Order', 'DeliveryNote', 'CreditNote', 'OrderItem', 'ReturnRequestApprover', 'Containers'], `${translate('customField.form.entity')} ${translate('customField.validation.required')}`),
        type: Yup.string().trim().nonNullable().required(`${translate('commons.type')} ${translate('customField.validation.required')}`).oneOf(['string', 'number', 'select', 'checkbox', 'currency', 'date'], `${translate('commons.type')} ${translate('customField.validation.required')}`),
        id: Yup.string().trim().required(`${translate('customField.form.id')} ${translate('customField.validation.required')}`)
            .matches(/^[a-zA-Z0-9-]+$/, `${translate('customField.validation.noSpecial')}`)
            .matches(/^[^0-9\s]+$/, `${translate('customField.validation.noSpaces')}`)
            .matches(/^[a-z-]+$/, `${translate('customField.validation.lowercase')}`),
        select: Yup.string().trim().test('req', `${translate('customField.validation.required')}`, function (value) {
            const field = this.parent.type;

            if (field && field === 'select')
                return value !== '';

            return true;
        }),
        defaultValue: Yup.string().trim().test('wrongFormat', `${translate('customField.validation.wrongFormat')}`, function (value) {
            const { format, type } = this.parent;

            let regex: RegExp | null = null;

            if (type === 'currency') {
                regex = /^-?\d+(\.\d{1,2})?(,\d{1,2})?$/;
                if (value) {
                    return regex.test(value);
                }

                return true;
            }
            if (type === 'date') {

                regex = /^(?!3000)(?!00)(?:(?:[1-2]\d{3})|(?:[1-9]\d{2})|0[1-9]\d|00[1-9])-(?:(?:0[1-9])|(?:1[0-2]))-(?:(?:0[1-9])|(?:[1-2][0-9])|(?:3[0-1]))$/;
                let regex2 = /^(?:(?:0[1-9]|[12][0-9]|3[01])\/(?:0[1-9]|1[0-2])\/(?:(?!3000)(?!00)(?:(?:[1-2]\d{3})|(?:[1-9]\d{2})|0[1-9]\d|00[1-9])))$/;

                if (value) {
                    return (regex.test(value) || regex2.test(value)) && value !== 'Invalid Date';
                }

                return true;
            }

            switch (format) {
                case 'alphanumeric':
                    regex = /^[a-zA-Z0-9]+$/;
                    break;
                case 'address':
                    regex = /^[a-zA-Z0-9\s.,'-]+$/;
                    break;
                case 'email':
                    regex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
                    break;
                case 'number':
                    regex = /^-?\d+$/;
                    break;
                case 'float':
                    regex = /^-?\d+(\.\d+)?(,\d+)?$/;
                    break;
            }
            if (regex && value) {
                return regex.test(value);
            }

            return true;
        })
    });

    const changeLang = (l: string) => {
        setLang(l);
    };

    const getSections = async () => {
        try {
            const sect = await section();

            setSections(sect.data);
        }
        catch (e) {
            console.error(e);
        }
    };

    useEffect(() => {
        getSections();
    }, []);

    useEffect(() => {

        try {
            schema.validateSync(param, { abortEarly: true });
            if (Object.keys(locError).length < param.name.length || Object.values(locError).some(l => l !== '')) {
                setIsDisabled(true);

                return;
            }
            if (param.type === 'select' && selectOptions[lang][0] === '') {
                setIsDisabled(true);

                return;
            }
            setIsDisabled(false);
        } catch (e) {
            setIsDisabled(true);
        }
    }, [update, locError, param]);

    const onChangeTabs = (tabs: LocalizedString[], name: string) => {

        const formatString = (input: string): string => {

            // remove special characters but -
            let formattedString = input.replace(/[^a-zA-Z0-9 -]/g, '');
            // change space with -
            formattedString = formattedString.replace(/\s/g, '-');
            // remove numbers
            formattedString = formattedString.replace(/[0-9]/g, '');

            return formattedString;
        };

        if (tabs[0].text && autoId && name === 'name') {
            setParam({ ...param, [name]: tabs, id: formatString(tabs[0].text).toLowerCase() });
        }
        else
            setParam({ ...param, [name]: tabs });

    };

    const handleVisibility = (e: any) => {
        setParam({ ...param, visibility: e.target.value });
    };

    const handleChange = (e: any) => {
        if (e.target.name === 'id' && e.target.value) {
            setAutoId(false);
        }
        else {
            setAutoId(true);
        }
        if (e.target.name === 'section' && e.target.value !== '-1')
            setNewSection('');
        setParam({ ...param, [e.target.name]: e.target.value });
    };

    useEffect(() => {
        setParam({ ...param, defaultValue: '' });
    }, [param.type]);

    const handleDeleteOption = (index: number) => {
        let list = selectOptions[lang] && selectOptions[lang].filter((_, ind) => ind !== index);
        setSelectOptions({ ...selectOptions, [lang]: list });
        if (favourite[lang] && favourite[lang] === index)
            setFavourite({ ...favourite, [lang]: -1 });
        else if (favourite[lang] && favourite[lang] > index)
            setFavourite({ ...favourite, [lang]: favourite[lang] - 1 });
    };

    const handleFavourite = (elem: string) => {
        let val = selectOptions[lang] && selectOptions[lang].indexOf(elem);
        if (favourite[lang] && val === favourite[lang]) {
            setFavourite({ ...favourite, [lang]: -1 });

            return;
        }
        setFavourite({ ...favourite, [lang]: val });
    };

    const convertOption = (): { values: LocalizedString[], isDefault: boolean }[] => {
        let m: { values: LocalizedString[], isDefault: boolean }[] = [];
        let langs = param.name.map(x => x.culture);
        Object.keys(selectOptions).filter(l => langs.includes(l)).forEach((la) => {
            selectOptions[la].forEach(
                (val, index) => {
                    let def = favourite[la] ? favourite[la] === index : false;
                    m.push({ values: [{ culture: la, text: val }], isDefault: def });
                }
            );
        });

        return m;
    };

    const handleSubmit = async (e: any) => {
        e.preventDefault();
        setErrors({
            nameError: false,
            idError: false,
            typeError: false,
            entityError: false,
            defaultError: false
        });
        let par = param;
        if (!check) {
            par.validationMessage = null;
        }
        if (par.type.toLowerCase() !== 'select') {
            par.selectOptions = null;
        }
        else {
            par.selectOptions = convertOption();
        }
        if (par.type !== 'string' && par.type !== 'number') {
            par.format = "";
            par.regEx = null;
        }

        if (Object.values(errors).some(x => x)) {
            toggleErr();

            return;
        }
        if (newSection) {
            par.section = newSection;
        }
        if (par.defaultValue === "")
            par.defaultValue = null;
        if (!par.required)
            par.regEx = null;

        if (par.type === 'currency' && par.defaultValue) {

            let val = par.defaultValue.replace(',', '.');
            val = val.replace(/[^\d.-]/g, '');
            par.defaultValue = convert(+val, currency.label, CurrencyTypes.EUR, rates).toString();
        }

        try {
            await create(par);
            tenant.refreshCustomFields();
            toggleSucc();
        }
        catch (e) {
            console.error(e);
            toggleErr();
        }
    };

    const handleFormat = (e: any) => {
        let regEx: RegExp | null = null;
        if (e.target.name === 'formatText') {

            switch (e.target.value) {
                case ('alphanumeric'):
                    regEx = /^[a-zA-Z0-9]+$/;
                    break;
                case 'address':
                    regEx = /^[a-zA-Z0-9\s.,'-]+$/;
                    break;
                case 'email':
                    regEx = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
                    break;
                default:
                    regEx = null;
                    break;
            }
        }
        else {
            switch (e.target.value) {
                case 'number':
                    regEx = /^-?\d+$/;
                    break;
                case 'float':
                    regEx = /^-?\d+(\.\d+)?(,\d+)?$/;
                    break;
                default:
                    regEx = null;
                    break;
            }
        }
        setParam({ ...param, format: e.target.value, regEx: regEx ? regEx.toString() : null });
    };

    const handleDate = (date: Dayjs) => {

        if (date.toString() !== 'Invalid Date') {
            setParam({ ...param, defaultValue: dayjs(date).format('YYYY-MM-DD') });
        }
        else {
            setParam({ ...param, defaultValue: 'Invalid Date' });
        }
    };

    const handleSearchable = (e: any) => {
        setSearchable(e.target.checked);
        setParam({ ...param, searchable: e.target.checked });
        if (!e.target.checked)
            setAutoComplete(false);
    };

    const handleAutocomplete = (e: any) => {
        setAutoComplete(e.target.checked);
        setParam({ ...param, autoComplete: e.target.checked });
    };

    const handleCheck = (e: any) => {
        setCheck(e.target.checked);
        setParam({ ...param, required: e.target.checked });
    };

    const handleIsFilter = (e: any) => {
        setIsFilter(e.target.checked);
    };

    const handleSelectOptions = (e: any, index: number) => {
        if (Object.keys(selectOptions).includes(lang)) {
            let opt = selectOptions[lang] && selectOptions[lang];
            opt[index] = e.target.value;
            if (selectOptions[lang] && index === selectOptions[lang].length - 1) {
                opt.push('');
            }
            setSelectOptions({ ...selectOptions, [lang]: opt });
        }
        else {
            setSelectOptions({ ...selectOptions, [lang]: [e.target.value, ''] });
        }
    };

    const toggleSucc = () => {
        isOpenSucc ? setIsOpenSucc(false) : setIsOpenSucc(true);
    };

    const toggleErr = () => {
        isOpenErr ? setIsOpenErr(false) : setIsOpenErr(true);
    };

    const handleBlur = (e: any) => {
        setLocError(e);
    };

    const handleB = (e: any) => {

        setUpdate(!update);
        if (e.target.name === 'select') {
            schema.validateAt(e.target.name, { ...param, select: selectOptions[lang][0] })
                .then(() => setErrors((prev: any) => ({ ...prev, [e.target.name]: '' })))
                .catch(err => setErrors((prev: any) => ({ ...prev, [e.target.name]: err.message })));
        }
        else {
            schema.validateAt(e.target.name, param).then(
                () => setErrors((prev: any) => ({ ...prev, [e.target.name]: '' }))
            ).catch(
                err => setErrors((prev: any) => ({ ...prev, [e.target.name]: err.message }))
            );
        }
    };

    return (
        <Page title={`${translate('customField.form.title.new')}`}>
            <ErrorModal toggle={toggleErr} isOpen={isOpenErr} errorText={`${translate('commons.error')}`} />
            <SuccessModal toggle={toggleSucc} isOpen={isOpenSucc} returnPage={PATH_DASHBOARD.tenant.CustomFieldList} successText={`${translate('customField.messages.success')}`} />
            <Container maxWidth={themeStretch ? false : 'lg'} >
                <HeaderBreadcrumbs
                    heading={`${translate('customField.form.title.new')}`}
                    links={[
                        { name: `${translate('commons.home')}`, href: PATH_DASHBOARD.root },
                        { name: `${translate('commons.customField')}`, href: PATH_DASHBOARD.tenant.CustomFieldList },
                        { name: `${translate('customField.form.title.new')}` }
                    ]}
                />
                <Box sx={{ p: 3 }} >

                    <LocalizedInput
                        onBlur={handleBlur}
                        onChangeLang={(l) => changeLang(l)}
                        error={!!errors.nameError}
                        label={`${translate('commons.name')}`}
                        onChangeTabs={(e) => onChangeTabs(e, 'name')}
                    >

                        <Box sx={{
                            mt: 2,
                            mb: 2,
                            display: 'grid',
                            columnGap: 2,
                            rowGap: 3,
                            pt: 3,
                            gridTemplateColumns: 'repeat(2, 2fr)',
                        }}>
                            <FormControl>
                                <InputLabel
                                    error={!!errors.id}
                                    required
                                    id='idlabel'
                                    sx={{ '& .MuiInputLabel-asterisk': { color: 'error.main' } }}
                                >
                                    {`${translate('customField.form.id')}`}
                                </InputLabel>
                                <OutlinedInput
                                    required
                                    error={!!errors.id}
                                    label={`${translate('customField.form.id')}`}
                                    onFocus={() => setUpdate(!update)}
                                    onBlur={handleB}
                                    name='id'
                                    endAdornment={
                                        <InputAdornment position="start">
                                            <Tooltip title={<pre>{`${translate('customField.messages.idHelperText')}`}</pre>}>
                                                <InfoIcon />
                                            </Tooltip>
                                        </InputAdornment>
                                    }
                                    value={param.id}
                                    onChange={handleChange} />
                                {errors.id && <FormHelperText error>{errors.id}</FormHelperText>}
                            </FormControl>

                            <FormControl>
                                <InputLabel
                                    error={!!errors.entityType}
                                    required
                                    id='entityType'
                                    sx={{ '& .MuiInputLabel-asterisk': { color: 'error.main' } }}
                                >
                                    {`${translate('customField.form.entity')}`}
                                </InputLabel>
                                <Select label={`${translate('customField.form.entity')}`} error={!!errors.entityType} onFocus={() => setUpdate(!update)} onBlur={handleB} onChange={handleChange} value={param.entityType} native name='entityType' placeholder={`${translate('customField.form.entity')}`}>
                                    <option value='' hidden />
                                    {getEntities(tenant).map(c => (
                                        <option key={c} value={c}>{`${translate(`customField.entity.${c[0].toLowerCase() + c.slice(1)}`)}`}</option>
                                    ))}
                                </Select>
                                {errors.entityType && <FormHelperText error>{errors.entityType}</FormHelperText>}
                            </FormControl>

                            <FormControl>
                                <InputLabel
                                    error={!!errors.type}
                                    required
                                    id='select'
                                    sx={{ '& .MuiInputLabel-asterisk': { color: 'error.main' } }}
                                >
                                    {`${translate('commons.type')}`}
                                </InputLabel>
                                <Select
                                    error={!!errors.type}
                                    label={`${translate('commons.type')}`}
                                    name='type'
                                    onFocus={() => setUpdate(!update)}
                                    onBlur={handleB}
                                    value={param.type}
                                    native
                                    onChange={handleChange}
                                    placeholder="type" >
                                    <option value='' hidden />
                                    <option value={'string'}>{`${translate('commons.text')}`}</option>
                                    <option value='number'>{`${translate('commons.number')}`}</option>
                                    <option value='currency'>{`${translate('commons.currency')}`}</option>
                                    <option value='date'>{`${translate('commons.date')}`}</option>
                                    <option value='select'>{`${translate('commons.select')}`}</option>
                                    <option value={'checkbox'}>{`${translate('commons.checkbox')}`}</option>
                                </Select>
                                {errors.type && <FormHelperText error>{errors.type}</FormHelperText>}
                            </FormControl>

                            <FormControl>
                                <InputLabel  >{`${translate('customField.form.section')}`}</InputLabel>
                                <Select
                                    id="section"
                                    label={`${translate('customField.form.section')}`}
                                    onChange={handleChange}
                                    value={param.section}
                                    native
                                    name='section' >
                                    <option value='OtherInfo' >{`${translate('customField.section.otherInfo')}`}</option>
                                    {
                                        sectionFactory[param.entityType]
                                    }
                                    {sections.map((sec, index) => (
                                        <option value={sec} key={index}>{sec}</option>
                                    ))}
                                    <option value='-1'>{`${translate('customField.section.custom')}`}</option>
                                </Select>
                            </FormControl>

                            <Box hidden={param.section !== '-1'}>
                                <TextField value={newSection} fullWidth id="newSection" name="newSection" onChange={(e) => setNewSection(e.target.value)} placeholder="Extra" label='section name' />
                                <FormControlLabel
                                    label={<b>{`${translate("commons.addFilter")}`}</b>}
                                    onChange={handleIsFilter}
                                    control={<Switch name='filter' checked={isFilter} />} />
                            </Box>

                            <div hidden={param.type !== 'currency'}>
                                <TextField
                                    sx={{ width: '100%' }}
                                    label={`${translate('customField.form.defaultValue')}`}
                                    onFocus={() => setUpdate(!update)}
                                    onBlur={handleB}
                                    InputProps={{ startAdornment: <InputAdornment position="start">{currency.symbol}</InputAdornment> }}
                                    error={!!errors.defaultValue}
                                    helperText={errors.defaultValue}
                                    value={param.defaultValue}
                                    onChange={handleChange}
                                    name="defaultValue"
                                />
                            </div>

                            <div hidden={param.type !== 'number' && param.type !== 'string'}>
                                <TextField
                                    sx={{ width: '100%' }}
                                    label={`${translate('customField.form.defaultValue')}`}
                                    onFocus={() => setUpdate(!update)}
                                    onBlur={handleB}
                                    error={!!errors.defaultValue}
                                    helperText={errors.defaultValue}
                                    value={param.defaultValue} onChange={handleChange}
                                    name="defaultValue"
                                />
                            </div>

                            <div hidden={param.type !== 'checkbox'}>
                                <FormControl fullWidth>
                                    <InputLabel required id='select' >{`${translate('customField.form.defaultValue')}`}</InputLabel>
                                    < Select label={`${translate('customField.form.defaultValue')}`}
                                        value={param.defaultValue ? param.defaultValue : 'disabled'}
                                        onChange={handleChange}
                                        name="defaultValue"
                                        native >
                                        <option value={'disabled'}>{`${translate('commons.disabled')}`}</option>
                                        <option value={'enabled'}>{`${translate('commons.enabled')}`}</option>
                                    </Select>
                                </FormControl>
                            </div>

                            <div hidden={param.type !== 'date'}>
                                <DatePicker
                                    views={['year', 'month', 'day']}
                                    format={'dd/MM/yyyy'}
                                    value={param.defaultValue ? dayjs(param.defaultValue).toDate() : null}
                                    onAccept={(val) => setParam({ ...param, defaultValue: val ? dayjs(val).format('YYYY-MM-DD') : "" })}
                                    onChange={(fromDateValue, inputval) => {
                                        if (!inputval.validationError && fromDateValue) {
                                            handleDate(dayjs(fromDateValue));
                                        }
                                    }}
                                    slotProps={{
                                        textField: {
                                            error: !!errors.defaultValue || false,
                                            fullWidth: true,
                                            helperText: `${translate(errors.defaultValue)}`,
                                            sx: { '& .MuiInputLabel-asterisk': { color: 'error.main' }, width: '100%' },
                                            onFocus: () => setUpdate(!update),
                                            onBlur: handleB,
                                            name: "defaultValue"
                                        }
                                    }}
                                />
                            </div>

                            <Box hidden={param.type !== 'string'}>
                                <FormControl fullWidth>
                                    <InputLabel >{`${translate('commons.format')}`}</InputLabel>
                                    <Select
                                        label={`${translate('commons.format')}`}
                                        sx={{ width: '100%' }}
                                        onChange={handleFormat}
                                        name="formatText"
                                        native
                                        id='formatText'>
                                        <option value='' hidden />
                                        <option value='alphanumeric'>{`${translate('commons.alphanumeric')}`}</option>
                                        <option value='address'>{`${translate('commons.address')}`}</option>
                                        <option value='email'>{`${translate('commons.email')}`}</option>
                                    </Select>
                                </FormControl>
                            </Box>

                            <Box hidden={param.type !== 'number'}>
                                <FormControl fullWidth>
                                    <InputLabel >{`${translate('commons.format')}`}</InputLabel>
                                    <Select
                                        label={`${translate('commons.format')}`}
                                        onChange={handleFormat}
                                        name="format"
                                        sx={{ width: '100%' }}
                                        native
                                        id='formatNumber'>
                                        <option value='' hidden />
                                        <option value='number'>0000</option>
                                        <option value='float'>0,1</option>
                                    </Select>
                                </FormControl>
                            </Box>

                            <Box sx={{ width: '100%' }}>
                                <FormControl sx={{ display: 'flex' }}>
                                    <InputLabel >{`${translate('commons.visibility')}`}</InputLabel>
                                    <Select
                                        label={`${translate('commons.visibility')}`}
                                        onChange={handleVisibility}
                                        name="visibility"
                                        sx={{ width: '100%' }}
                                        native
                                        id='visibility'>
                                        <option value='Always' >{`${translate('commons.always')}`}</option>
                                        <option value='OnlyIfNotEmpty'>{`${translate('commons.onlyIfNotEmpty')}`}</option>
                                        <option value='Never'>{`${translate('commons.never')}`}</option>
                                    </Select>
                                </FormControl>
                            </Box>
                        </Box>

                        <Box hidden={param.type !== 'select'}>
                            <List>
                                <ListItem>
                                    <TextField
                                        name="select"
                                        onFocus={() => setUpdate(!update)}
                                        onBlur={handleB}
                                        error={!!errors.select}
                                        helperText={errors.select}
                                        label={`${translate('commons.option')} 1`}
                                        value={selectOptions[lang] ? selectOptions[lang][0] : ''}
                                        onChange={(e) => handleSelectOptions(e, 0)}
                                    />
                                    <IconButtonAnimate onClick={() => handleFavourite(selectOptions[lang][0])}>
                                        <StarIcon sx={{ color: favourite[lang] === 0 ? 'primary.dark' : 'primary.lighter' }} />
                                    </IconButtonAnimate>
                                </ListItem>
                                {selectOptions[lang] && selectOptions[lang].slice(1).map((elem, index) => (
                                    <ListItem key={index}>
                                        <TextField
                                            key={index}
                                            label={`${translate('commons.option')} ${index + 2}`}
                                            value={elem}
                                            onChange={(e) => handleSelectOptions(e, index + 1)} />
                                        <IconButtonAnimate onClick={() => handleFavourite(elem)} >
                                            <StarIcon sx={{ color: favourite[lang] === index + 1 ? 'primary.dark' : 'primary.lighter' }} />
                                        </IconButtonAnimate>
                                        <IconButton onClick={() => handleDeleteOption(index + 1)}>
                                            <DeleteForeverIcon />
                                        </IconButton>
                                    </ListItem>
                                ))}
                            </List>
                        </Box>

                        <FormControlLabel
                            label={<b>{`${translate("commons.required")}`}</b>}
                            onChange={handleCheck}
                            control={<Checkbox name='required' checked={check} />}
                        />

                        <FormControlLabel
                            label={<b>{`${translate("commons.searchable")}`}</b>}
                            onChange={handleSearchable}
                            control={<Checkbox name='searchable' checked={searchable} />}
                        />

                        <FormControlLabel
                            label={<b>{`${translate("commons.autocomplete")}`}</b>}
                            onChange={handleAutocomplete}
                            disabled={!searchable}
                            control={<Checkbox name='autocomplete' checked={autoComplete} />}
                        />

                        <div hidden={!check}>
                            <LocalizedInput label={`${translate('customField.form.validationMessage')}`} onChangeTabs={(e) => onChangeTabs(e, 'validationMessage')} reset={!check} />
                        </div>

                        <Box sx={{ mt: 3, display: 'flex', justifyContent: 'flex-end', gap: 2 }}>
                            <Button variant="soft" color="inherit" onClick={() => navigate(PATH_DASHBOARD.tenant.CustomFieldList)}>
                                {`${translate("commons.cancel")}`}
                            </Button>
                            <LoadingButton disabled={isDisabled} type="submit" variant="contained" onClick={(e) => handleSubmit(e)}>
                                {`${translate("role.form.save")}`}
                            </LoadingButton>
                        </Box>

                    </LocalizedInput>
                </Box>
            </Container >
        </Page >
    );
}

export const getEntities = (tenant: TenantType): EntityType[] => {

    let entity: EntityType[] = [];

    if (tenant.licenses.includes(LicenseTypes.Core))
        entity = entity.concat(core);
    if (tenant.licenses.includes(LicenseTypes.Rv))
        entity = entity.concat(rvMatrix);
    if (tenant.licenses.includes(LicenseTypes.WebShop))
        entity = entity.concat(spareParts);

    return entity;
};