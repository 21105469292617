import { List, Stack } from '@mui/material';
import { useLocales } from '../../../locales';
import { NavSectionProps } from '../types';
import { StyledSubheader } from './styles';
import NavList from './NavList';
import { hasPermissions } from 'src/utils/user';
import { useAuth } from 'src/appHooks/useAuth';
import useTenant from 'src/appHooks/useTenant';
import { hasLicense } from 'src/utils/tenant';
import { useUserOrganizationContext } from 'src/contexts/UserOrganizationContext';
import LicenseGuard from 'src/guards/LicenseGuard';

// ----------------------------------------------------------------------

export default function NavSectionVertical({ data, sx, ...other }: NavSectionProps) {
  const { translate } = useLocales();

  const { user } = useAuth();

  const tenant = useTenant();

  const { organization } = useUserOrganizationContext();

  return (
    <Stack sx={sx} {...other}>
      {data.map((group) => {

        if (group.forGod && !user.isGod) {
          return null;
        }

        if (tenant.licenses && group.license && !hasLicense(tenant, group.license)) {
          return null;
        }

        if (!group.permissions || hasPermissions(group.permissions, user.roles, false) || hasPermissions(group.permissions, organization.roles, false)) {
          const key = group.subheader || group.items[0].title;

          return (
            <List key={key} disablePadding sx={{ px: 2 }}>

              {group.subheader && (
                <StyledSubheader key={"StyledSubheader." + key} disableSticky>{`${translate(group.subheader)}`}</StyledSubheader>
              )}

              {group.items.map((list, index) => (
                <LicenseGuard key={index} license={list.license}>
                  <NavList
                    data={list}
                    depth={1}
                    hasChild={!!list.children}
                  />
                </LicenseGuard>
              ))}
            </List>
          );
        }

        return <></>;

      })}
    </Stack>
  );
}
