import { Container } from '@mui/system';
import { useLocales } from 'src/locales';
import { useSettingsContext } from 'src/components/settings';
import { useNavigate, useParams } from 'react-router';
import { Box, Button, Card, Divider, FormControlLabel, Grid, InputAdornment, Modal, Stack, Switch, TablePagination, TextField, Typography } from '@mui/material';
import { DataGrid, GridColDef, GridSortModel } from '@mui/x-data-grid';
import { Add, DeleteForever, Search } from '@mui/icons-material';
import useResponsive from 'src/hooks/useResponsive';
import { useSelector } from 'react-redux';
import { RootState, dispatch } from 'src/redux/store';
import { ChangeEvent, useEffect, useMemo, useRef, useState } from 'react';
import { sparePartsOperations } from 'src/redux/spareParts';
import useTable from 'src/appHooks/useTable';
import { DataGridStyle } from "src/utils/DataGridStyle";
import { noData } from 'src/components/empty-content/EmptyContent';
import { SparePartsFilters, SparePart, SparePartSearchResult } from 'src/@types/spareParts';
import { debounce } from 'lodash';
import { rulesOperations } from 'src/redux/rules';
import { convert } from 'src/utils/currency';
import useCurrency from 'src/appHooks/useCurrency';
import { CurrencyTypes } from 'src/@types/vehicle';
import AddIcon from '@mui/icons-material/Add';
import { PATH_DASHBOARD } from 'src/routes/paths';
import { RuleTabs } from 'src/@types/rules';
import SaveModal from 'src/components/modals/SaveModal';

interface PartBlacklistProps {
    setIsOpenSuccess: (value: boolean) => void;
    setIsOpenError: (value: boolean) => void;
}

export default function PartBlacklist({ setIsOpenSuccess, setIsOpenError }: PartBlacklistProps) {

    const isDesktop = useResponsive('up', 'md');

    const { rule } = useSelector((state: RootState) => state.rules);

    const navigate = useNavigate();

    const { translate } = useLocales();

    const [selected, setSelected] = useState<string[]>(rule ? rule.part.products : []);

    const [displayList, setDisplayList] = useState<SparePart[]>([]);

    const [openClear, setOpenClear] = useState(false);

    const [isOpenSave, setIsOpenSave] = useState(false);

    const [searchModal, setSearchModal] = useState(false);

    const { rates } = useCurrency();

    const { rulesTab } = useParams();

    const { currency: currentCurrency } = useSettingsContext();

    useEffect(() => {

        const get = async (id: string) => {
            const spare = await dispatch(sparePartsOperations.getSparePart(id)).unwrap();

            return spare;
        };

        let idList = displayList.map(v => v.id);
        setDisplayList(prev => prev.filter(v => selected.includes(v.id)));

        selected.forEach(v => {
            if (idList.includes(v))
                return;

            get(v).then(val => {
                setDisplayList(prev => prev.concat([val]));
            });
        });

    }, [selected]);

    const handleSubmit = async () => {
        try {
            await dispatch(rulesOperations.updateRuleParts({ products: selected })).unwrap();
            setIsOpenSuccess(true);
        }
        catch {
            setIsOpenError(true);
        }
    };

    const handleClear = () => {
        setOpenClear(false);

        setSelected([]);
        setDisplayList([]);
    };

    const handleDelete = (id: string) => {
        setSelected(prev => prev.filter(v => v !== id));
        setDisplayList(prev => prev.filter(v => v.id !== id));
    };

    const lastItem = useMemo(() => displayList[displayList.length - 1], [displayList]);

    const onQuit = () => {
        navigate(PATH_DASHBOARD.rules.listTab(rulesTab as RuleTabs));
    };

    return (
        <>
            <ClearModal isOpen={openClear} toggle={() => setOpenClear(p => !p)} submit={handleClear} />
            <PartBlacklistModal
                handleSearchModal={(v) => setSearchModal(v)}
                searchModal={searchModal}
                selected={selected}
                setSelected={(v) => setSelected(v)}
            />

            <SaveModal
                toggle={() => setIsOpenSave(prev => !prev)}
                isOpen={isOpenSave}
                handleSave={handleSubmit as any}
                saveText={`${translate('rules.messages.saveConfirmPartRef')}`} />

            <Card sx={{ mt: 2 }}>

                {displayList.length === 0 &&
                    <Box
                        sx={{
                            display: 'flex', gap: 1,
                            flexDirection: 'column'
                        }}
                    >

                        <Typography
                            variant="h5"
                            sx={{ pt: 3, px: 2.5 }}
                        >
                            {`${translate(`rules.list.tableContent.name.partBlacklist`)}`}
                        </Typography>

                        <Grid container sx={{ px: 3, pt: 2.5, pb: 7.5 }}>

                            <Grid item xs={12} >
                                <Button
                                    variant="soft"
                                    size={"large"}
                                    fullWidth
                                    sx={{ height: "200%" }}
                                    startIcon={<AddIcon />}
                                    onClick={() => setSearchModal(true)}
                                >
                                    {`${translate("rules.form.edit.addPartRef")}`}
                                </Button>
                            </Grid>

                        </Grid>

                    </Box>
                }

                {displayList.length > 0 &&
                    <Box>

                        <Box
                            sx={{
                                display: 'flex',
                                pt: 3, pb: 1.5, px: 2.5, mb: 2,
                                flexDirection: isDesktop ? 'row' : 'column'
                            }}
                        >

                            <Typography variant="h5">
                                {`${translate(`rules.list.tableContent.name.partBlacklist`)}`}
                            </Typography>

                            <Button
                                variant="text"
                                size={"medium"}
                                onClick={() => setSearchModal(true)}
                                startIcon={<Add />}
                                sx={{ ml: "auto", height: "auto", mt: (isDesktop ? 0 : 1.5) }}
                            >
                                {`${translate("rules.form.edit.addPartRef")}`}
                            </Button>

                        </Box>

                        <Box sx={{ display: 'flex', flexDirection: 'column', gap: isDesktop ? 0 : 2, overflow: 'auto', maxHeight: 500 }}>

                            {displayList.map((item, index) =>
                                <Box key={index + ".summary"}>
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                            width: '100%',
                                            mb: isDesktop ? 0 : 2,
                                            position: "relative"
                                        }}
                                    >
                                        <Grid
                                            container
                                            columnGap={0.5}
                                            rowGap={isDesktop ? 0.5 : 1.5}
                                            sx={{ mx: 2, width: '100%' }}
                                        >

                                            <Grid item xs={5} sm={5} md={2}>
                                                <Stack sx={{ m: isDesktop ? 2 : 0 }}>
                                                    <Typography variant="overline">
                                                        {`${translate('spareParts.list.tableHeaders.partNr')}`}
                                                    </Typography>
                                                    <Typography variant="body2" sx={{ mt: 0.5 }}>
                                                        {item.externalId || '—'}
                                                    </Typography>
                                                </Stack>
                                            </Grid>

                                            <Grid item xs={5} sm={5} md={2}>
                                                <Stack sx={{ m: isDesktop ? 2 : 0 }}>
                                                    <Typography variant="overline">
                                                        {`${translate('spareParts.list.tableHeaders.partDesc')}`}
                                                    </Typography>
                                                    <Typography variant="body2" sx={{ mt: 0.5 }}>
                                                        {item.name || '—'}
                                                    </Typography>
                                                </Stack>
                                            </Grid>

                                            <Grid item xs={11} sm={5} md={4}>
                                                <Stack sx={{ m: isDesktop ? 2 : 0 }}>
                                                    <Typography variant="overline">{`${translate('spareParts.detail.family')}`}</Typography>
                                                    {
                                                        isDesktop ?
                                                            <>
                                                                <Typography variant="body2">
                                                                    <b>{item.family.internal.code}</b>
                                                                </Typography>
                                                                <Typography variant="body2" sx={{ mt: 0.5 }}>
                                                                    {item.family.internal.description || '—'}
                                                                </Typography>
                                                            </>
                                                            :
                                                            <Typography variant="body2">
                                                                <b>{item.family.internal.code}</b>{" - " + (item.family.internal.description || '—')}
                                                            </Typography>
                                                    }

                                                </Stack>
                                            </Grid>

                                            <Grid item xs={5} sm={3} md={1.5}>
                                                <Stack sx={{ m: isDesktop ? 2 : 0 }}>
                                                    <Typography variant="overline">
                                                        {`${translate('vehicle.commercialData.form.sellingPrice')}`}
                                                    </Typography>
                                                    <Typography variant="body2" sx={{ mt: 0.5 }}>
                                                        {item.price.selling.withoutVat ?
                                                            Math.floor(
                                                                convert(
                                                                    item.price.selling.withoutVat,
                                                                    item.price.selling.currentCurrency as CurrencyTypes,
                                                                    currentCurrency.label,
                                                                    rates
                                                                )) + " " + currentCurrency.symbol
                                                            : "—"}
                                                    </Typography>
                                                </Stack>
                                            </Grid>

                                            <Grid item xs={5} sm={3} md={1.5}>
                                                <Stack sx={{ m: isDesktop ? 2 : 0 }}>
                                                    <Typography variant="overline">
                                                        {`${translate('spareParts.list.tableHeaders.stockQuantity')}`}
                                                    </Typography>
                                                    <Typography variant="body2" sx={{ mt: 0.5 }}>
                                                        {item.quantity ? item.quantity.stockQuantity : '—'}
                                                    </Typography>
                                                </Stack>
                                            </Grid>

                                        </Grid>

                                        <Button
                                            size={"medium"}
                                            startIcon={<DeleteForever sx={{ ml: 1.5 }} />}
                                            onClick={() => handleDelete(item.id)}
                                            sx={{
                                                position: "absolute",
                                                right: isDesktop ? "3%" : "2%",
                                                top: isDesktop ? "25%" : "2%"
                                            }}
                                        />

                                    </Box>

                                    {(lastItem.id !== item.id && !isDesktop) && <Divider sx={{ mx: 2 }} />}
                                </Box>
                            )}
                        </Box>

                    </Box>
                }

                <Divider />

                {
                    isDesktop ?
                        <Box
                            sx={{
                                display: 'flex',
                                px: 3, py: 3, gap: 1,
                                flexDirection: isDesktop ? 'row' : 'column-reverse'
                            }}
                        >
                            <Button
                                variant="soft"
                                size={"medium"}
                                sx={{ borderRadius: 100 }}
                                onClick={onQuit}
                            >
                                {`${translate("commons.cancel")}`}
                            </Button>

                            <Box
                                sx={{
                                    ml: 'auto',
                                    display: 'flex',
                                    gap: 1,
                                    flexDirection: 'default'
                                }}
                            >
                                {displayList.length > 0 &&
                                    <Button
                                        startIcon={<DeleteForever />}
                                        variant='contained'
                                        color='error'
                                        size={"medium"}
                                        onClick={() => setOpenClear(true)}
                                        sx={{ borderRadius: 100 }}
                                    >
                                        {`${translate('commons.empty')}`}
                                    </Button>
                                }

                                <Button
                                    variant="contained"
                                    size={"medium"}
                                    onClick={() => setIsOpenSave(prev => !prev)}
                                    sx={{ ml: 1, borderRadius: 100 }}
                                >
                                    {`${translate("commons.justSave")}`}
                                </Button>

                            </Box>

                        </Box>
                        :
                        <Box
                            sx={{
                                display: 'flex',
                                px: 2, py: 1, gap: 1,
                                flexDirection: 'column'
                            }}
                        >

                            <Box
                                sx={{
                                    mx: 2, mt: 1.5,
                                    display: 'flex', gap: 3,
                                }}
                            >
                                <Button
                                    variant="soft"
                                    size={"medium"}
                                    sx={{ borderRadius: 100 }}
                                    onClick={onQuit}
                                    fullWidth
                                >
                                    {`${translate("commons.cancel")}`}
                                </Button>

                                {displayList.length > 0 &&
                                    <Button
                                        startIcon={<DeleteForever />}
                                        variant='contained'
                                        color='error'
                                        size={"medium"}
                                        onClick={() => setOpenClear(true)}
                                        sx={{ borderRadius: 100 }}
                                        fullWidth
                                    >
                                        {`${translate('commons.empty')}`}
                                    </Button>
                                }

                            </Box>

                            <Button
                                variant="contained"
                                size={"medium"}
                                onClick={() => setIsOpenSave(prev => !prev)}
                                sx={{ my: 1.5, mx: 2, borderRadius: 100 }}

                            >
                                {`${translate("commons.justSave")}`}
                            </Button>

                        </Box>
                }

            </Card>
        </>
    );
}

//----------CLEAR MODAL---------------//

interface ClearModalProps {
    isOpen: boolean,
    toggle: VoidFunction,
    submit: VoidFunction,
    clearText?: string
}

export function ClearModal({ isOpen, toggle, submit, clearText }: ClearModalProps) {

    const { translate } = useLocales();

    const isDesktop = useResponsive('up', 'sm');

    return (
        <Modal
            open={isOpen}
            onClose={toggle}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Card sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: isDesktop ? '23vw' : '63vw',
                p: isDesktop ? 5 : 2,
            }}>
                <Box sx={{ textAlign: "center", mb: 2 }}>

                    <Typography variant="h4" sx={{ mt: 3 }}>
                        {`${translate(`rules.messages.saveConfirm`)}`}
                    </Typography>
                </Box>

                <Box sx={{ textAlign: "center", mb: 1 }}>

                    <Typography variant='body1'>
                        {`${translate(`rules.messages.${clearText ? clearText : "ruleClearQst"}`)}`}
                    </Typography>

                    <Button
                        variant="contained"
                        size={isDesktop ? "small" : 'large'}
                        onClick={() => {
                            toggle();
                        }}
                        sx={{ mt: 3, mr: 1.5, borderRadius: "50px !important" }}
                    >
                        {`${translate('commons.close')}`}
                    </Button>
                    <Button
                        variant="contained"
                        color="error"
                        startIcon={<DeleteForever />}
                        size={isDesktop ? "small" : 'large'}
                        onClick={submit}
                        sx={{ mt: 3, ml: 2, borderRadius: "50px !important" }}
                    >
                        {`${translate('commons.empty')}`}
                    </Button>

                </Box>
            </Card>
        </Modal>
    );
}

//----------PART BLACKLIST MODAL---------------//

interface PartBlacklistModalProps {
    searchModal: boolean;
    handleSearchModal: (value: boolean) => void,
    setSelected: (val: string[]) => void,
    selected: string[]
}

export function PartBlacklistModal({ searchModal, handleSearchModal, setSelected, selected }: PartBlacklistModalProps) {

    const { translate } = useLocales();

    const { isLoading, sparePartsList, totalCount } = useSelector((state: RootState) => state.spareParts);

    const [searchVal, setSearchVal] = useState("");

    const [rowSelected, setRowSelected] = useState(selected);

    const isDesktop = useResponsive("up", "sm");

    const {
        rowsPerPage,
        page,
        onChangeDense,
        onChangePage,
        onChangeRowsPerPage,
        dense,
        order,
        orderBy,
        setOrder,
        setOrderBy } = useTable();

    const [options, setOptions] = useState<SparePartsFilters>({
        pageIndex: page,
        pageSize: rowsPerPage,
        sortAscending: order === 'desc' ? true : false,
        sortField: orderBy || undefined,
    });

    useEffect(() => {
        setRowSelected(selected);
    }, [selected]);

    useEffect(() => {
        if (searchModal)
            dispatch(sparePartsOperations.searchVendorSpareParts(options));
    }, [options, searchModal]);

    useEffect(() => {
        setOptions({
            pageIndex: page,
            pageSize: rowsPerPage,
            sortAscending: order === 'desc' ? true : false,
            sortField: orderBy || undefined,
            all: searchVal
        });
    }, [order, orderBy, page, rowsPerPage]);

    useEffect(() => {
        if (totalCount <= rowsPerPage * page)
            onChangePage(null, 0);
    }, [onChangePage, page, rowsPerPage, totalCount]);

    const handleSort = (sortModel: GridSortModel) => {
        if (sortModel.length > 0) {
            setOrderBy(sortModel[0].field);
            setOrder(sortModel[0].sort!);
        } else {
            setOrderBy("");
            setOrder("asc");
        }
    };

    const handleClear = () => {
        setRowSelected([]);
    };

    const columns: GridColDef<SparePartSearchResult>[] = [
        {
            field: 'externalId',
            headerName: `${translate('spareParts.list.tableHeaders.partNr')}`,
            flex: isDesktop ? 1 : undefined,
            minWidth: !isDesktop ? 150 : undefined,
            valueGetter: (value: string) => {
                if (!value) {
                    return '—';
                }

                return value;
            },
        },
        {
            field: 'name',
            headerName: `${translate('spareParts.list.tableHeaders.partDesc')}`,
            flex: isDesktop ? 1 : undefined,
            minWidth: !isDesktop ? 175 : undefined,
            valueGetter: (value: string) => {
                if (!value) {
                    return '—';
                }

                return value;
            },
        },
        {
            field: 'family',
            headerName: `${translate('spareParts.detail.family')}`,
            flex: isDesktop ? 1.5 : undefined,
            minWidth: !isDesktop ? 350 : undefined,
            renderCell: (params) =>
                <Box>
                    <Typography variant="subtitle2"><b>{params.row.family.code}</b></Typography>
                    <Typography variant="body2">{params.row.family.name}</Typography>
                </Box>
        },
        {
            field: 'stockQuantity',
            headerName: `${translate('spareParts.list.tableHeaders.stockQuantity')}`,
            flex: isDesktop ? 0.8 : undefined,
            minWidth: !isDesktop ? 125 : undefined
        },
    ];

    //calls search only after the user has stopped typing and 500 milliseconds have passed
    const debounced = useRef(
        debounce((options: SparePartsFilters) => {
            dispatch(sparePartsOperations.searchVendorSpareParts(options));
        }, 500));

    const handleSearch = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setSearchVal(e.target.value);
        if (e.target.value.length >= 3 || e.target.value.length === 0)
            debounced.current({ ...options, all: e.target.value });
    };

    return (
        <Modal
            open={searchModal}
            onClose={() => {
                handleSearchModal(false);
                onChangePage(null, 0);
            }}
            aria-labelledby="modal-title"
            aria-describedby="modal-description"
            sx={{ m: 'auto' }}
        >
            <Container
                sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)'
                }}
            >

                <Card
                    sx={{
                        p: 2,
                        pb: 0,
                        maxHeight: '95vh',
                        overflowY: "auto"
                    }}
                >
                    <Typography variant='h5'>
                        {`${translate("rules.list.tableContent.name.partBlacklist")}`}
                    </Typography>

                    <Box sx={{ mt: 2, display: 'flex', flexDirection: 'column', gap: 3 }}>
                        <TextField
                            value={searchVal}
                            onChange={(e) => handleSearch(e)}
                            label={`${translate("rules.form.searchPart")}`}
                            placeholder={`${translate("rules.form.searchPart")}...`}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <Search />
                                    </InputAdornment>
                                ),
                            }}
                        />

                        <DataGrid
                            columns={columns}
                            rows={sparePartsList}
                            disableColumnMenu
                            disableRowSelectionOnClick
                            keepNonExistentRowsSelected
                            checkboxSelection
                            disableColumnResize
                            rowSelectionModel={rowSelected}
                            onRowSelectionModelChange={(v) => setRowSelected(v as string[])}
                            pagination
                            paginationModel={{
                                page: page,
                                pageSize: rowsPerPage
                            }}
                            density={dense ? 'compact' : 'standard'}
                            sortingMode={"server"}
                            onSortModelChange={handleSort}
                            loading={isLoading}
                            sx={{ ...DataGridStyle, maxHeight: 640 }}
                            pageSizeOptions={[5, 10, 15, 30]}
                            slots={{
                                noRowsOverlay: noData,
                                footer: () => (
                                    <Box sx={{
                                        position: 'relative',
                                        width: { xs: "85vw", sm: "90vw", md: "auto" },
                                    }}>
                                        <TablePagination
                                            rowsPerPageOptions={[5, 10, 15, 30]}
                                            component="div"
                                            count={totalCount}
                                            rowsPerPage={rowsPerPage}
                                            page={page}
                                            onPageChange={onChangePage}
                                            onRowsPerPageChange={onChangeRowsPerPage}
                                            labelRowsPerPage={`${translate('commons.rowsPerPage')}`}
                                            sx={{
                                                overflow: "hidden",
                                                "& .MuiTablePagination-toolbar": { px: 0 },
                                                "& .MuiTablePagination-actions": { m: isDesktop ? "inherit" : 0 }
                                            }}
                                        />

                                        <FormControlLabel
                                            control={<Switch checked={dense} onChange={onChangeDense} />}
                                            label={`${translate('commons.dense')}`}
                                            sx={{
                                                px: { xs: 0, sm: 2 },
                                                py: { xs: 0, sm: 1.5 },
                                                pb: { xs: 1.5, sm: 0 },
                                                mx: 0, top: 0,
                                                justifyContent: "center",
                                                width: { xs: "80vw", sm: "auto" },
                                                position: { sm: 'absolute' }
                                            }}
                                        />
                                    </Box>
                                )
                            }}
                        />
                    </Box>

                    <Divider />

                    {
                        isDesktop ?
                            <Box
                                sx={{
                                    justifyContent: 'space-between',
                                    ml: 'auto', display: 'flex',
                                    gap: 1, p: 3
                                }}
                            >

                                <Button
                                    variant="soft"
                                    color="inherit"
                                    onClick={() => {
                                        handleSearchModal(false);
                                        onChangePage(null, 0);
                                    }}
                                >
                                    {`${translate("commons.cancel")}`}
                                </Button>

                                <Box sx={{ display: 'flex', gap: 2 }}>

                                    <Button startIcon={<DeleteForever />} variant='contained' color='error' onClick={handleClear}>
                                        {`${translate('rules.form.clear')}`}
                                    </Button>

                                    <Button
                                        type="submit"
                                        variant="contained"
                                        onClick={() => {
                                            setSelected(rowSelected);
                                            handleSearchModal(false);
                                            onChangePage(null, 0);
                                        }}
                                    >
                                        {`${translate("commons.add")}`}
                                    </Button>

                                </Box>

                            </Box>
                            :
                            <Box
                                sx={{
                                    display: 'flex',
                                    px: 2, py: 1, gap: 1,
                                    flexDirection: 'column'
                                }}
                            >

                                <Box
                                    sx={{
                                        mx: 2, mt: 1.5,
                                        display: 'flex', gap: 3,
                                    }}
                                >
                                    <Button
                                        variant="soft"
                                        color="inherit"
                                        onClick={() => {
                                            handleSearchModal(false);
                                            onChangePage(null, 0);
                                        }}
                                        fullWidth
                                    >
                                        {`${translate("commons.cancel")}`}
                                    </Button>

                                    <Button
                                        startIcon={<DeleteForever />}
                                        variant='contained'
                                        color='error'
                                        onClick={handleClear}
                                        fullWidth
                                    >
                                        {`${translate('commons.empty')}`} {/* clear */}
                                    </Button>

                                </Box>

                                <Button
                                    type="submit"
                                    variant="contained"
                                    onClick={() => {
                                        setSelected(rowSelected);
                                        handleSearchModal(false);
                                        onChangePage(null, 0);
                                    }}
                                    sx={{ my: 1.5, mx: 2 }}

                                >
                                    {`${translate("commons.add")}`}
                                </Button>

                            </Box>
                    }

                </Card>
            </Container>
        </Modal>
    );

}