import {
    Box,
    Button,
    FormControl,
    IconButton,
    InputAdornment,
    InputLabel,
    MenuItem,
    OutlinedInput,
    Select,
    Stack,
    Tooltip
} from '@mui/material';
import useLocales from 'src/appHooks/useLocales';
import { useFormContext } from 'react-hook-form';
import { useEffect, useState } from 'react';
import Iconify from 'src/components/iconify';
import DeleteIcon from '@mui/icons-material/Delete';
import ClearIcon from '@mui/icons-material/Clear';
import dayjs from 'dayjs';
import { DatePicker } from '@mui/x-date-pickers';
import { Filter } from 'src/@types/logs';
import { DEFAULT_FILTERS_VALUE } from './Logs';
import { ToolbarSearchFilters } from 'src/@types/commons';

type FilterProps = {
    filters: Filter;
    isDefault: (valuesToCheck: any) => boolean;
    onFilter: VoidFunction;
    resetAll: VoidFunction;
    optionsFields: ToolbarSearchFilters[];
}

const DEFAULT_DATE_ERRORS = {
    fromFormat: false,
    fromMissing: false,
    toFormat: false,
    toMissing: false,
    toInvalid: false
};

export default function LogsToolbar({ filters, isDefault, onFilter, resetAll, optionsFields }: FilterProps) {

    const { translate } = useLocales();

    const { setValue } = useFormContext();

    const [resetFilter, setResetFilter] = useState(false);

    const [openTooltip, setOpenTooltip] = useState(false);

    const [showDelete, setShowDelete] = useState(false);

    const [datesErrors, setDatesErrors] = useState(DEFAULT_DATE_ERRORS);

    const [filterValues, setFilterValues] = useState(filters);

    useEffect(() => {
        const newValues = {
            from: filters.from || DEFAULT_FILTERS_VALUE.from,
            to: filters.to || DEFAULT_FILTERS_VALUE.to,
            eventType: filters.eventType || DEFAULT_FILTERS_VALUE.eventType,
            pageIndex: filters.pageIndex || DEFAULT_FILTERS_VALUE.pageIndex,
            pageSize: filters.pageSize || DEFAULT_FILTERS_VALUE.pageSize
        };

        setFilterValues(newValues);

        if (isDefault(newValues)) {
            setShowDelete(false);
        } else {
            setShowDelete(true);
        }

    }, [filters]);

    useEffect(() => {
        if (resetFilter) {
            setFilterValues(DEFAULT_FILTERS_VALUE);
            setResetFilter(false);
            resetAll();
        }
    }, [resetFilter]);

    const handleChangeValues = (newVal: any, type: string) => {
        const valuesCopy = JSON.parse(JSON.stringify(filterValues));

        valuesCopy[type] = newVal;

        setFilterValues(valuesCopy);
    };

    const setAllValues = () => {
        for (const [key, value] of Object.entries(filterValues)) {
            setValue(`${key}`, value);
        }
    };

    const handleSearch = () => {

        if (filterValues.from && !filterValues.to) {
            setDatesErrors({ ...datesErrors, toMissing: true });
        } else if (!filterValues.from && filterValues.to) {
            setDatesErrors({ ...datesErrors, fromMissing: true });
        } else {
            setAllValues();
            onFilter();
            setShowDelete(true);
        }
    };

    const handleDelete = () => {
        setResetFilter(true);
        setDatesErrors(DEFAULT_DATE_ERRORS);
        setShowDelete(false);
    };

    const handleChangeFrom = (date: dayjs.Dayjs) => {

        if (date.toString() !== 'Invalid Date') {

            handleChangeValues(date.toISOString(), "from");

            let toInvalidCheck = false;

            if (filterValues.to && dayjs(filterValues.to).diff(date, "d") < 1) {
                toInvalidCheck = true;
            }

            setDatesErrors({ ...datesErrors, fromFormat: false, fromMissing: false, toInvalid: toInvalidCheck });
        }
        else {
            setDatesErrors({ ...datesErrors, fromFormat: true });
        }
    };

    const handleChangeTo = (date: dayjs.Dayjs) => {

        if (date.toString() !== 'Invalid Date') {

            if (date.diff(filterValues.from, "d") < 1) {
                setDatesErrors({ ...datesErrors, toInvalid: true, toFormat: false, toMissing: false });
            } else {
                handleChangeValues(date.toISOString(), "to");
                setDatesErrors({ ...datesErrors, toInvalid: false, toFormat: false, toMissing: false });
            }
        }
        else {
            setDatesErrors({ ...datesErrors, toFormat: true });
        }
    };

    const handleDeleteDate = (type: string) => {

        handleChangeValues("", type);

        if (type === "from") {
            setDatesErrors({ ...datesErrors, fromFormat: false, fromMissing: true, toInvalid: false });
        } else {
            setDatesErrors({ ...datesErrors, toFormat: false, toMissing: true, toInvalid: false });
        }
    };

    const errorChecker = () => {
        if (datesErrors.fromFormat) return true;
        if (datesErrors.fromMissing) return true;
        if (datesErrors.toFormat) return true;
        if (datesErrors.toMissing) return true;
        if (datesErrors.toInvalid) return true;

        return false;
    };

    const getDateErrorMessage = (type: string) => {

        if (datesErrors.fromFormat && type === "from") return `${translate("commons.validation.wrongDate")}`;
        if (datesErrors.toFormat && type === "to") return `${translate("commons.validation.wrongDate")}`;
        if (datesErrors.fromMissing && type === "from") return `${translate("commons.validation.missingDate")}`;
        if (datesErrors.toMissing && type === "to") return `${translate("commons.validation.missingDate")}`;
        if (datesErrors.toInvalid && type === "to") return `${translate("commons.validation.invalidDate")}`;

        return "";
    };

    return (
        <Stack
            direction={{ xs: 'column', sm: 'column', md: 'row' }}
            sx={{ py: 2.5, px: 3 }}
        >

            <Box
                sx={{ minWidth: 530, mr: 2 }}
            >
                <Stack
                    spacing={2}
                    direction={{ xs: 'column', sm: 'row' }}
                >
                    <DatePicker
                        label={`${translate('commons.startDate')}`}

                        value={filterValues.from ? dayjs(filterValues.from).toDate() : null}

                        onAccept={(newValue) => {
                            if (newValue)
                                handleChangeValues(dayjs(newValue).toISOString(), "from");
                        }}

                        onChange={(fromDateValue, inputval) => {

                            if (!inputval.validationError && fromDateValue) {

                                const date = dayjs(fromDateValue);

                                handleChangeFrom(date);
                            } else {
                                setDatesErrors({ ...datesErrors, fromFormat: true });
                            }
                        }}

                        slotProps={{
                            textField: {
                                name: 'from',
                                fullWidth: true,
                                error: datesErrors.fromFormat || datesErrors.fromMissing,
                                helperText: getDateErrorMessage("from"),
                                InputProps: {
                                    startAdornment: (filterValues.from &&
                                        <InputAdornment position="end">
                                            <IconButton
                                                sx={{ ml: -2, mr: 1 }}
                                                onClick={() => handleDeleteDate("from")}
                                            >
                                                <ClearIcon />
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }
                            }
                        }}
                    />

                    <DatePicker
                        label={`${translate('commons.endDate')}`}

                        value={filterValues.to ? dayjs(filterValues.to).toDate() : null}

                        onAccept={(newValue) => {
                            if (newValue)
                                handleChangeValues(dayjs(newValue).toISOString(), "to");
                        }}

                        onChange={(fromDateValue, inputval) => {
                            if (!inputval.validationError && fromDateValue) {

                                const date = dayjs(fromDateValue);

                                handleChangeTo(date);
                            } else {
                                setDatesErrors({ ...datesErrors, toFormat: true });
                            }
                        }}

                        slotProps={{
                            textField: {
                                name: 'to',
                                fullWidth: true,
                                error: datesErrors.toFormat || datesErrors.toMissing || datesErrors.toInvalid,
                                helperText: getDateErrorMessage("to"),
                                InputProps: {
                                    startAdornment: (filterValues.to &&
                                        <InputAdornment position="end">
                                            <IconButton
                                                sx={{ ml: -2, mr: 1 }}
                                                onClick={() => handleDeleteDate("to")}
                                            >
                                                <ClearIcon />
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }
                            }
                        }}
                    />

                </Stack>
            </Box>

            <FormControl
                sx={{ mr: 2 }}
                fullWidth
                variant="outlined"
            >
                <InputLabel
                    id="label"
                    sx={{ '&.Mui-focused': { color: 'grey.500' } }}
                >
                    {`${translate('spareParts.logs.eventType')}`}
                </InputLabel>
                <Select
                    labelId="label"
                    id="eventType"
                    value={filterValues.eventType}
                    input={
                        <OutlinedInput label={`${translate('spareParts.logs.eventType')}`} />
                    }
                    onChange={(event) => handleChangeValues(event.target.value, "eventType")}
                >
                    {optionsFields.map((option) => (
                        <MenuItem
                            key={option.key}
                            value={option.key}
                            sx={{
                                mx: 1,
                                my: 0.5,
                                borderRadius: 0.75,
                                typography: 'body2'
                            }}
                        >
                            {option.label}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>

            <Tooltip
                title={`${translate("commons.validation.invalidData")}`}
                disableFocusListener
                disableTouchListener
                disableInteractive
                open={openTooltip}
                sx={{ mb: () => errorChecker() ? 4 : 0 }}
            >
                <Box
                    onMouseEnter={() => { if (errorChecker()) setOpenTooltip(true); }}
                    onMouseLeave={() => { if (errorChecker()) setOpenTooltip(false); }}
                >
                    <Button
                        startIcon={<Iconify icon={'bx:search-alt'} sx={{ ml: 1.5, mt: 0.5 }} />}
                        onClick={handleSearch}
                        disabled={errorChecker()}
                        size="large"
                        sx={{ mt: 0.5 }}
                        variant="outlined"
                    />
                </Box>
            </Tooltip>

            <Box sx={{ ml: 1.5 }}>
                <Button
                    startIcon={<DeleteIcon sx={{ ml: 1.5, mt: 0.75 }} />}
                    onClick={handleDelete}
                    sx={{ display: `${(showDelete && !isDefault(filterValues)) ? "inline" : "none"}`, mt: 0.5 }}
                    size="large"
                    variant="outlined"
                />
            </Box>

        </Stack>
    );
}