import { Box, Button, Card, Modal, Typography } from "@mui/material";
import { To, useNavigate } from "react-router";
import useResponsive from "src/hooks/useResponsive";
import { useLocales } from "src/locales";

interface ModalType {
    isOpen: boolean;
    successText: string;
    toggle: () => void;
    toggleSave?: () => void;
    returnPage?: string | number;
    goToPage?: string | number
}

export default function SuccessModal({ isOpen, returnPage, successText, toggle, toggleSave, goToPage }: ModalType) {

    const { translate } = useLocales();

    const navigate = useNavigate();

    const isDesktop = useResponsive('up', 'lg');

    const goBack = () => {
        toggle();
        if (returnPage || goToPage)
            navigate((returnPage || goToPage) as To);

    };

    const getLabel = () => {
        if (returnPage)
            return `${translate('commons.toList')}`;

        else if (goToPage)
            return `${translate('commons.goToList')}`;

        else
            return "";
    };

    return (
        <Modal
            open={isOpen}
            onClose={() => {
                if (toggleSave) {
                    toggleSave();
                }
                toggle();
            }}
        >
            <Card sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: isDesktop ? '25vw' : '70vw',
                p: isDesktop ? 4 : 2,
            }}>
                <Box sx={{ textAlign: "center" }}>

                    <Box component="img" src="/assets/illustrations/illustration_docs.svg" sx={{ width: "45%", m: "auto" }} />

                    <Typography variant="h6" sx={{ mt: 3 }}>
                        {`${translate(`commons.success`)}`}
                    </Typography>

                    <Typography variant='body1'>
                        {successText}
                    </Typography>
                    <Box sx={{ display: 'flex', justifyContent: 'center', gap: 4 }}>
                        <Button
                            variant="contained"
                            size={isDesktop ? "small" : "medium"}
                            onClick={() => {
                                if (toggleSave) {
                                    toggleSave();
                                }
                                toggle();
                            }}
                            sx={{ mt: 3, borderRadius: "100px" }}
                        >
                            {`${translate('commons.close')}`}
                        </Button>
                        {(!!returnPage || !!goToPage) && <Button
                            variant="contained"
                            size={isDesktop ? "small" : "medium"}
                            onClick={goBack}
                            sx={{ mt: 3, borderRadius: "100px" }}
                        >
                            {getLabel()}
                        </Button>}
                    </Box>
                </Box>
            </Card>
        </Modal>
    );
}
