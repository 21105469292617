import { LocalizedString, PriorityEnum } from './commons';

export const InboxLabelIdArr = ['all', 'sent', 'trash', 'important', 'starred', 'id_social', 'id_promotions', 'id_forums'] as const;

export type InboxLabelId = typeof InboxLabelIdArr[number];

export const LabelTypeArr = ['System', 'Custom'] as const;

export type LabelType = typeof LabelTypeArr[number];

export const NotificationTypeArr = ['Generic', 'Report', 'Account', 'ImportFile', 'NewOrder', 'ReturnRequest'] as const;

export type NotificationType = typeof NotificationTypeArr[number]

export type InboxSearchResult = {
    [key: string]: any
    id: string,
    createdOn: Date,
    title: string,
    message: string,
    read: boolean,
    starred: boolean,
    important: boolean,
    priority: PriorityEnum,
    labeles: InboxLabelId[],
    attaches: string[],
    from: {
        name: string,
        email: string,
        avatar: string
    },
    to: {
        name: string,
        email: string,
        avatar: string
    }[]
}

export type InboxStatisticsType = {
    totalCount: number,
    totalUnreadCount: number,
    labels: InboxLabelStatisticsType[],
}

export type InboxFilters = {
    [key: string]: any
    pageIndex: number,
    pageSize: number,
    isRead?: boolean,
    isStarred?: boolean,
    isImportant?: boolean,
    isSent: boolean
}

export type InboxLabelStatisticsType = {
    labelId: InboxLabelId,
    unreadCount: number,
    name: string,
    type: LabelType,
    color?: string
}

export type PagedInboxResponseType = {
    items: InboxSearchResult[],
    totalCount: number,
    totalPages: number,
    pageIndex: number,
    pageSize: number,
    hasNextPage: boolean,
    hasPreviousPage: boolean,
    unreadMessageCount: number
}

export type NotificationSettings = {
    items: {
        settingId: string
        notificationType: NotificationType,
        allowEmail: boolean,
        allowInbox: boolean,
        allowPush: boolean
    }[]

}

export type NewNotificationTemplate = {
    notificationType: NotificationType,
    permissionDependency: {
        permissions: string[],
        atLeastOne: boolean
    },
    displayName: LocalizedString[],
    description: LocalizedString[]
}

export type NotificationTemplate = NewNotificationTemplate & {
    notificationId: string,
    createdOn: string,
    status: string
};

export type NotificationTemplateSearchResult = {
    items: NotificationTemplate[]
}
//-----------------------------------------

export const DEFAULT_INBOX_SEARCH: PagedInboxResponseType = {
    items: [],
    totalCount: 0,
    totalPages: 0,
    pageIndex: 0,
    pageSize: 10,
    hasNextPage: false,
    hasPreviousPage: false,
    unreadMessageCount: 0
};

export const DEFAULT_INBOX_FILTERS: InboxFilters = {
    pageIndex: 0,
    pageSize: 10,
    isRead: false,
    isStarred: false,
    isImportant: false,
    isSent: false
};

export const DEFAULT_NEW_NOTIFICATION_TEMPLATE: NewNotificationTemplate = {
    description: [{
        text: "",
        culture: 'en'
    }],
    displayName: [{
        text: "",
        culture: 'en'
    }],
    notificationType: 'Generic',
    permissionDependency: {
        atLeastOne: false,
        permissions: []
    }
};