import { ClusterPermissionTypes, OrganizationPermissionTypes } from './permissions';
import { OganizationType } from './organizations';

const UserStatusesArr = ["", "enabled", "disabled"] as const;

export type UserStatusType = typeof UserStatusesArr[number];

export type OrganizationIdItem = {
  organizationId: string,
  roles: string[]
}

export type UserOrganization = {
  organizationId: string,
  name: string,
  type: OganizationType,
  roles: UserRole[],
  externalId: string
}

export type UserManager = {
  [key: string]: any
  /* FROM SERVICE */
  id: string,
  externalId: string | null,
  createdBy: string | null,
  modifiedBy: string | null,
  accountId: string,
  username: string,
  email: string,
  country: string,
  culture: string,
  timeZone: string,
  enabled: boolean,
  lastIpAddress: string,
  emailVerified: boolean,
  lastLogin: string | null,
  loginsCount: number,
  measurementSystem: string,
  currency: string,
  pictureUrl: string,
  roles: UserRole[],
  organizations: UserOrganization[],
  customFields: Record<string, string> | null,
  tags: string[],
  firstName: string,
  lastName: string,
  /* OTHERS */
  displayName: string,
  isGod: boolean,
  isOrganizationUser: boolean,
  isAdminUser: boolean,
  authenticationProvider: string
}

export type UserToCreateEdit = {
  [key: string]: any
  currency: string,
  measurementSystem: string,
  username: string,
  firstName: string,
  lastName: string,
  email: string,
  tags: string[],
  externalId: string | null,
  country: string,
  culture: string,
  timeZone: string,
  enabled: boolean,
  customFields: Record<string, string> | null,
  authenticationProvider: string
}

export interface UserRole {
  id: string,
  name?: string,
  permissions: (ClusterPermissionTypes | OrganizationPermissionTypes)[]
}

export type UserStatistics = {
  all: number,
  disabled: number,
  enabled: number
}

export type EnableDisableSliceProps = {
  id: string,
  tab: UserStatusType,
  action: "enable" | "disable",
  totalCount: number,
  listLen: number,
  filters: UserFilters,
  pageSize: number,
  newItem?: UserSearchResult
}

export type UserSearchResult = {
  [key: string]: any
  id: string,
  externalId: string,
  enabled: boolean,
  pictureUrl: string,
  firstName: string,
  lastName: string,
  username: string,
  email: string,
  lastLogin: Date,
  country: string
}

export type UserFilters = {
  [key: string]: any
  pageIndex: number,
  pageSize: number,
  sortField?: string,
  sortAscending?: boolean,
  all?: string,
  firstname?: string,
  lastname?: string,
  username?: string,
  email?: string,
  country?: string,
  onlyEnabled?: boolean,
  role?: string,
  tags?: string[],
  organizations?: string[],
  customFields?: Record<string, string>
}

//-------------------------------------------------------------------

export const DEFAULT_USER_ORGANIZATION: UserOrganization = {
  organizationId: "",
  name: "",
  externalId: "",
  roles: [],
  type: "Hub"
};

export const DEFAULT_USER: UserManager = {
  id: "",
  externalId: "",
  createdBy: "",
  modifiedBy: "",
  accountId: "",
  username: "",
  email: "",
  country: "",
  culture: "",
  timeZone: "",
  enabled: true,
  lastIpAddress: "",
  emailVerified: false,
  lastLogin: "",
  loginsCount: 0,
  measurementSystem: "",
  currency: "",
  pictureUrl: "",
  roles: [],
  organizations: [],
  customFields: {},
  tags: [],
  firstName: "",
  lastName: "",
  displayName: "",
  isGod: false,
  isOrganizationUser: false,
  isAdminUser: false,
  authenticationProvider: ""
};

export const DEFAULT_USER_FILTERS: UserFilters = {
  pageIndex: 0,
  pageSize: 10,
  sortField: "",
  sortAscending: false,
  all: "",
  firstname: "",
  lastname: "",
  username: "",
  email: "",
  country: "all",
  onlyEnabled: undefined,
  role: "all",
  tags: [],
  organizations: [],
  customFields: {}
};

export const DEFAULT_USER_STATISTICS: UserStatistics = {
  all: 0,
  disabled: 0,
  enabled: 0
};