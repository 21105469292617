import { PATH_DASHBOARD } from '../../../routes/paths';
import PeopleIcon from '@mui/icons-material/People';
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import AddIcon from '@mui/icons-material/Add';
import InventoryIcon from '@mui/icons-material/Inventory';
import PublishIcon from '@mui/icons-material/Publish';
import ViewModuleRoundedIcon from '@mui/icons-material/ViewModuleRounded';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import { LicenseTypes } from 'src/@types/tenant';
import BusinessIcon from '@mui/icons-material/Business';
import EmailIcon from '@mui/icons-material/Email';
import DashboardCustomizeIcon from '@mui/icons-material/DashboardCustomize';
import SvgColor from '../../../components/svg-color';
import ListAltIcon from '@mui/icons-material/ListAlt';
import { ClusterPermissionTypes, OrganizationPermissionTypes, ReportPermissionsList } from 'src/@types/permissions';
import { Gavel, Inbox, Inventory, LocalShipping } from '@mui/icons-material';
import WalletIcon from '@mui/icons-material/Wallet';
import SummarizeIcon from '@mui/icons-material/Summarize';
import EditNotificationsIcon from '@mui/icons-material/EditNotifications';
import GroupsIcon from '@mui/icons-material/Groups';

export const icon = (name: string) => (
  <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />
);

const ICONS = {
  file: icon('ic_file'),
  disabled: icon('ic_disabled'),
  analytics: icon('ic_analytics'),
  forklift: icon('ic_forklift')
};

const navConfig = [
  // GENERAL
  // ----------------------------------------------------------------------
  {
    subheader: '',
    items: [
      {
        title: 'menu.management.matrix.dashboard',
        path: PATH_DASHBOARD.matrix.dashboard,
        icon: <AccessTimeIcon />,
      },
    ],
  },
  {
    subheader: 'menu.management.tenant.title',
    title: 'menu.management.tenant.title',
    path: PATH_DASHBOARD.tenant.tenant,
    license: LicenseTypes.Core,
    forGod: true,
    items: [
      {
        title: 'menu.management.tenant.customField',
        forGod: true,
        path: PATH_DASHBOARD.tenant.CustomFieldList,
        icon: <DashboardCustomizeIcon />,
        children: [
          { title: 'commons.new', path: PATH_DASHBOARD.tenant.newCustomField, forGod: true },
          { title: 'commons.list', path: PATH_DASHBOARD.tenant.CustomFieldList, forGod: true }
        ]
      },
      {
        title: 'menu.management.brands.title',
        icon: <WalletIcon />,
        path: PATH_DASHBOARD.brands.list,
        forGod: true,
        children: [
          { title: 'menu.management.brands.new', path: PATH_DASHBOARD.brands.new, forGod: true },
          { title: 'commons.list', path: PATH_DASHBOARD.brands.list, forGod: true }
        ]
      },
      {
        title: 'menu.management.email.title',
        icon: <EmailIcon />,
        path: PATH_DASHBOARD.tenant.systemEmail,
        forGod: true
      }
    ]
  },
  // PERSONAL AREA
  // ----------------------------------------------------------------------
  {
    subheader: 'menu.management.personalArea.title',
    title: 'menu.management.personalArea.title',
    path: PATH_DASHBOARD.personalArea.personalArea,
    items: [
      {
        title: 'menu.management.reports.title',
        path: PATH_DASHBOARD.reports.reports,
        icon: <SummarizeIcon />,
        permissions: ReportPermissionsList,
        license: LicenseTypes.Reports,
        children: [
          { title: 'menu.management.reports.recurring.short', path: PATH_DASHBOARD.reports.recurring.list, permissions: ReportPermissionsList },
          { title: 'menu.management.reports.scheduled.short', path: PATH_DASHBOARD.reports.scheduled.list, permissions: ReportPermissionsList },
        ]
      },
      {
        title: 'menu.management.inbox.title',
        path: PATH_DASHBOARD.inbox.list,
        icon: <Inbox />
      }
    ]
  },
  // MANAGEMENT
  // ----------------------------------------------------------------------
  {
    subheader: 'menu.management.title',
    license: LicenseTypes.Core,
    permissions: [ClusterPermissionTypes.UserView, OrganizationPermissionTypes.Product_VehiclesView, OrganizationPermissionTypes.OrganizationView],
    items: [
      {
        title: 'menu.management.user.title',
        path: PATH_DASHBOARD.user.list,
        icon: <PeopleIcon />,
        permissions: [ClusterPermissionTypes.UserView],
        children: [
          { title: 'menu.management.user.new', path: PATH_DASHBOARD.user.new, permissions: [ClusterPermissionTypes.UserCreate] },
          { title: 'menu.management.user.list', path: PATH_DASHBOARD.user.list, permissions: [ClusterPermissionTypes.UserView] },
        ],
      },
      {
        title: 'menu.management.role.admin.title',
        path: PATH_DASHBOARD.tenant.role.admin.list,
        icon: <AdminPanelSettingsIcon />,
        permissions: [ClusterPermissionTypes.RoleView],
        children: [
          { title: 'menu.management.role.new', path: PATH_DASHBOARD.tenant.role.admin.new, permissions: [ClusterPermissionTypes.RoleCreate] },
          { title: 'commons.list', path: PATH_DASHBOARD.tenant.role.admin.list, permissions: [ClusterPermissionTypes.RoleView] },
        ]
      },
      {
        title: 'menu.management.role.organization.title',
        path: PATH_DASHBOARD.tenant.role.organization.list,
        icon: <AdminPanelSettingsIcon />,
        permissions: [ClusterPermissionTypes.RoleView],
        children: [
          { title: 'menu.management.role.new', path: PATH_DASHBOARD.tenant.role.organization.new, permissions: [ClusterPermissionTypes.RoleCreate] },
          { title: 'commons.list', path: PATH_DASHBOARD.tenant.role.organization.list, permissions: [ClusterPermissionTypes.RoleView] },
        ]
      },
      {
        title: 'menu.management.notification.title',
        path: PATH_DASHBOARD.tenant.notificationTemplate.list,
        icon: <EditNotificationsIcon />,
        permissions: [ClusterPermissionTypes.Notification_View],
        children: [
          { title: 'commons.new', path: PATH_DASHBOARD.tenant.notificationTemplate.new, permission: [ClusterPermissionTypes.Notification_Create] },
          { title: 'commons.list', path: PATH_DASHBOARD.tenant.notificationTemplate.list, permissions: [ClusterPermissionTypes.Notification_View] },
        ]
      },
      {
        title: 'menu.management.groups.title',
        path: PATH_DASHBOARD.groups.list,
        license: LicenseTypes.Group,
        permissions: [OrganizationPermissionTypes.Group_View],
        icon: <GroupsIcon />,
        children: [
          { title: 'menu.management.groups.new', path: PATH_DASHBOARD.groups.new, permissions: [OrganizationPermissionTypes.Group_Create] },
          { title: 'commons.list', path: PATH_DASHBOARD.groups.list, permissions: [OrganizationPermissionTypes.Group_View] },
        ]
      },
      {
        title: 'menu.management.vehicle.title',
        path: PATH_DASHBOARD.vehicle.list,
        icon: <DirectionsCarIcon />,
        license: LicenseTypes.ProductVehicle,
        permissions: [OrganizationPermissionTypes.Product_VehiclesView],
        children: [
          { title: 'menu.management.vehicle.new', path: PATH_DASHBOARD.vehicle.new, permissions: [OrganizationPermissionTypes.Product_VehiclesCreate] },
          { title: 'menu.management.vehicle.list', path: PATH_DASHBOARD.vehicle.list, permissions: [OrganizationPermissionTypes.Product_VehiclesView] },
        ],
      },
      {
        title: 'menu.management.spareParts.list',
        license: LicenseTypes.ProductSparePart,
        icon: <Inventory />,
        path: PATH_DASHBOARD.spareParts.list,
        permissions: [OrganizationPermissionTypes.Product_SparePartsView]
      },
      {
        title: 'menu.management.organizations.myTitle',
        path: PATH_DASHBOARD.organizations.vendor.list,
        icon: <BusinessIcon />,
        permissions: [OrganizationPermissionTypes.OrganizationView],
        children: [
          { title: 'menu.management.organizations.new', path: PATH_DASHBOARD.organizations.vendor.new, permissions: [OrganizationPermissionTypes.OrganizationCreate] },
          { title: 'menu.management.organizations.list', path: PATH_DASHBOARD.organizations.vendor.list, permissions: [OrganizationPermissionTypes.OrganizationView] },
        ],
      },
      {
        title: 'menu.management.organizations.cusTitle',
        path: PATH_DASHBOARD.organizations.customer.list,
        icon: <BusinessIcon />,
        permissions: [OrganizationPermissionTypes.Customer_View],
        children: [
          { title: 'menu.management.organizations.new', path: PATH_DASHBOARD.organizations.customer.new, permissions: [OrganizationPermissionTypes.Customer_Create] },
          { title: 'menu.management.organizations.list', path: PATH_DASHBOARD.organizations.customer.list, permissions: [OrganizationPermissionTypes.Customer_View] },
        ],
      }
    ]
  },
  // ----------------------------------------------------------------------
  {
    subheader: 'menu.management.spareParts.title',
    license: LicenseTypes.WebShop,
    permissions: [
      OrganizationPermissionTypes.WebShop_Orders_CustomerView,
      OrganizationPermissionTypes.WebShop_Orders_VendorView,
      OrganizationPermissionTypes.WebShop_ReturnRequests_Rules_VendorView,
      OrganizationPermissionTypes.WebShop_ReturnRequests_Requests_VendorView,
      OrganizationPermissionTypes.WebShop_ReturnRequests_Requests_CustomerView,
      OrganizationPermissionTypes.WebShop_ReturnRequests_Requests_CarrierView
    ],
    items: [
      {
        title: 'menu.management.orders.myTitle',
        icon: <ListAltIcon />,
        path: PATH_DASHBOARD.orders.vendor.list,
        permissions: [OrganizationPermissionTypes.WebShop_Orders_VendorView],
        children: [
          { title: 'menu.management.orders.list', path: PATH_DASHBOARD.orders.vendor.list, permissions: [OrganizationPermissionTypes.WebShop_Orders_VendorView] },
          { title: 'menu.management.documents.list', path: PATH_DASHBOARD.orders.vendor.documents.list, permissions: [OrganizationPermissionTypes.WebShop_Orders_VendorView] }
        ]
      },
      {
        title: 'menu.management.orders.custTitle',
        icon: <ListAltIcon />,
        path: PATH_DASHBOARD.orders.customer.list,
        permissions: [OrganizationPermissionTypes.WebShop_Orders_CustomerView],
        children: [
          { title: 'menu.management.orders.list', path: PATH_DASHBOARD.orders.customer.list, permissions: [OrganizationPermissionTypes.WebShop_Orders_CustomerView] },
          { title: 'menu.management.documents.list', path: PATH_DASHBOARD.orders.customer.documents.list, permissions: [OrganizationPermissionTypes.WebShop_Orders_CustomerView] }
        ]
      },
      {
        title: 'menu.management.request.myRequest',
        icon: ICONS.analytics,
        path: PATH_DASHBOARD.request.vendor.list,
        permissions: [OrganizationPermissionTypes.WebShop_ReturnRequests_Requests_VendorView, OrganizationPermissionTypes.WebShop_ReturnRequests_Requests_VendorAnomaly],
        children: [
          { title: 'menu.management.request.new', path: PATH_DASHBOARD.request.vendor.new, permissions: [OrganizationPermissionTypes.WebShop_ReturnRequests_Requests_VendorCreate] },
          { title: 'menu.management.request.list', path: PATH_DASHBOARD.request.vendor.list, permissions: [OrganizationPermissionTypes.WebShop_ReturnRequests_Requests_VendorView] },
          { title: 'menu.management.anomalies.title', path: PATH_DASHBOARD.request.vendor.anomalies.list, permissions: [OrganizationPermissionTypes.WebShop_ReturnRequests_Requests_VendorAnomaly] }
        ]
      },
      {
        title: 'menu.management.request.customerTitle',
        icon: ICONS.analytics,
        path: PATH_DASHBOARD.request.customer.list,
        permissions: [OrganizationPermissionTypes.WebShop_ReturnRequests_Requests_CustomerView, OrganizationPermissionTypes.WebShop_ReturnRequests_Requests_CustomerCreate],
        children: [
          { title: 'menu.management.request.new', path: PATH_DASHBOARD.request.customer.new, permissions: [OrganizationPermissionTypes.WebShop_ReturnRequests_Requests_CustomerCreate] },
          { title: 'menu.management.request.list', path: PATH_DASHBOARD.request.customer.list, permissions: [OrganizationPermissionTypes.WebShop_ReturnRequests_Requests_CustomerView] }
        ]
      },
      {
        title: 'menu.management.request.carrierTitle',
        icon: ICONS.analytics,
        path: PATH_DASHBOARD.request.carrier.list,
        permissions: [OrganizationPermissionTypes.WebShop_ReturnRequests_Requests_CarrierView, OrganizationPermissionTypes.WebShop_ReturnRequests_Requests_CarrierManagePickUp],
        children: [
          { title: 'menu.management.request.list', path: PATH_DASHBOARD.request.carrier.list, permissions: [OrganizationPermissionTypes.WebShop_ReturnRequests_Requests_CarrierView] },
        ]
      },
      {
        title: 'menu.management.rules.title',
        icon: <Gavel />,
        permissions: [OrganizationPermissionTypes.WebShop_ReturnRequests_Rules_VendorView],
        path: PATH_DASHBOARD.rules.listTab("request")
      }
    ]
  },
  {
    subheader: 'menu.management.shipping.title',
    license: LicenseTypes.Shipping,
    permissions: [OrganizationPermissionTypes.Shipping_Container_View, OrganizationPermissionTypes.Shipping_Container_Template_View],
    items: [
      {
        title: 'menu.management.containersTemplate.title',
        icon: ICONS.forklift,
        path: PATH_DASHBOARD.containers.templates.list,
        permissions: [OrganizationPermissionTypes.Shipping_Container_Template_Create, OrganizationPermissionTypes.Shipping_Container_Template_View],
      },
      {
        title: 'menu.management.containers.title',
        icon: <LocalShipping />,
        path: PATH_DASHBOARD.containers.list,
        permissions: [OrganizationPermissionTypes.Shipping_Container_View]
      }]
  },
  {
    subheader: 'menu.management.matrix.title',
    license: LicenseTypes.Rv,
    permissions: [OrganizationPermissionTypes.RvTemplateCreate, OrganizationPermissionTypes.RvTemplateView, OrganizationPermissionTypes.RvMatrixView, OrganizationPermissionTypes.RvMatrixApproveReject],
    items: [
      {
        title: 'menu.management.matrix.createMatrixTemplate',
        path: PATH_DASHBOARD.matrix.create,
        icon: <AddIcon />,
        permissions: [OrganizationPermissionTypes.RvTemplateCreate],
      },
      {
        title: 'menu.management.matrix.templates',
        path: PATH_DASHBOARD.matrix.templates,
        icon: <InventoryIcon />,
        permissions: [OrganizationPermissionTypes.RvTemplateView],
      },
      {
        title: 'menu.management.matrix.uploadMatrix',
        path: PATH_DASHBOARD.matrix.upload,
        icon: <PublishIcon />,
        permissions: [OrganizationPermissionTypes.RvTemplateCreate],
      },
      {
        title: 'menu.management.matrix.matrices',
        path: PATH_DASHBOARD.matrix.managment,
        icon: <ViewModuleRoundedIcon />,
        permissions: [OrganizationPermissionTypes.RvMatrixView],
      },
      {
        title: 'menu.management.matrix.requests',
        path: PATH_DASHBOARD.matrix.requests,
        icon: <ArrowRightAltIcon />,
        permissions: [OrganizationPermissionTypes.RvMatrixApproveReject],
      },
      {
        title: 'menu.management.matrix.list',
        path: PATH_DASHBOARD.matrix.MatrixListMain,
        icon: <FormatListBulletedIcon />,
        permissions: [OrganizationPermissionTypes.RvMatrixView, OrganizationPermissionTypes.RvTemplateView]
      }
    ]
  }
];

export default navConfig;
