import { Container, Step, StepLabel, Stepper } from "@mui/material";
import Page from "src/appComponents/Page";
import { useSettingsContext } from "src/components/settings";
import { useLocales } from "src/locales";
import HeaderBreadcrumbs from '../../../../components/custom-breadcrumbs';
import { PATH_DASHBOARD } from "src/routes/paths";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useLocation, useParams } from "react-router";
import SaveModal from "src/components/modals/SaveModal";
import SuccessModal from "src/components/modals/SuccessModal";
import ErrorModal from "src/components/modals/ErrorModal";
import { useSelector } from "react-redux";
import { RootState, dispatch } from "src/redux/store";
import LoadingScreen from "src/appComponents/loading-screen";
import { useForm } from "react-hook-form";
import { OrganizationIdItem, UserManager, UserToCreateEdit } from "src/@types/user";
import { usersOperations } from "src/redux/users";
import { defaultNewUserData } from "src/sections/@dashboard/user/costants";
import AddForm from "./AddForm";

export default function AddFromEdit() {

    const { translate } = useLocales();

    const { themeStretch } = useSettingsContext();

    const { userId = '' } = useParams();

    const { pathname } = useLocation();

    const isOrganizationData = pathname.includes('organization');

    const { isLoading, user } = useSelector((state: RootState) => state.user);

    const userToEdit = useMemo(() => {
        return { ...user } as UserManager;
    }, [user]);

    /* HANDLING MODALS START */
    const [isOpenSave, setIsOpenSave] = useState(false);

    const [isOpenSuccess, setIsOpenSuccess] = useState(false);

    const [isOpenError, setIsOpenError] = useState(false);

    const toggleSave = () => {
        isOpenSave ? setIsOpenSave(false) : setIsOpenSave(true);
    };

    const toggleSuccess = () => {
        isOpenSuccess ? setIsOpenSuccess(false) : setIsOpenSuccess(true);
    };

    const toggleError = () => {
        isOpenError ? setIsOpenError(false) : setIsOpenError(true);
    };
    /* HANDLING MODALS END */

    /* HANDLING FORM START */
    const formContainerRef = useRef<{
        getValues: () => UserManager,
        onSave: () => UserManager
    }>();

    const defaultValues = useMemo(() => defaultNewUserData(user, null, false), [user]);

    const methods = useForm<UserManager>({
        mode: "onChange",
        defaultValues
    });

    const { reset, watch, getValues } = methods;

    useEffect(() => {
        reset(defaultValues);
    }, [defaultValues, reset]);
    /* HANDLING FORM END */

    /* HANDLING SAVE START */
    const userCleaner = (values: UserManager) => {

        let organizationsAndRoles: OrganizationIdItem[] = [];

        if (isOrganizationData) {
            values.organizations.forEach((org) => {
                const onlyRoleIds: string[] = [];

                org.roles.forEach((role) => onlyRoleIds.push(role.id));

                organizationsAndRoles.push({ organizationId: org.organizationId, roles: onlyRoleIds });
            });
        }

        let adminRoles: string[] = [];

        if (!isOrganizationData) {
            values.roles.forEach((role) => {
                adminRoles.push(role.id);
            });
        }

        let finalUser: UserToCreateEdit = {
            firstName: values.firstName,
            lastName: values.lastName,
            country: values.country,
            culture: values.culture,
            timeZone: values.timeZone,
            externalId: values.externalId,
            enabled: values.enabled,
            measurementSystem: values.measurementSystem,
            currency: values.currency,
            customFields: values.customFields,
            tags: values.tags,
            username: values.username,
            email: values.email,
            authenticationProvider: values.authenticationProvider
        };

        return {
            organizationsAndRoles: organizationsAndRoles,
            adminRoles: adminRoles,
            finalUser: finalUser
        };
    };

    const handleSaveUser = async () => {
        try {
            const results = getValues();

            const cleanedValues = userCleaner(results!);

            if (results) {

                if (isOrganizationData) {
                    await dispatch(usersOperations.associateUser({ userId: userId, organizations: cleanedValues.organizationsAndRoles })).unwrap();
                }
                else {
                    await dispatch(usersOperations.userSetAdminRoles({ userId: userId, roles: cleanedValues.adminRoles })).unwrap();
                }
            }
            toggleSuccess();
        } catch (e) {
            toggleError();
        }
    };
    /* HANDLING SAVE END */

    const getUserFormValues = async (data: UserManager) => {
    };

    /* STEPS DECLARATION START */
    const [step, setStep] = useState<number>(isOrganizationData ? 0 : 2);

    const STEPS_ORGANIZATION_USER = useMemo(() => ['entity', 'roles', 'complete'], []);

    const STEPS_ADMIN_USER = useMemo(() => ['adminRoles', 'complete'], []);

    const organizations = watch("organizations");

    const roles = watch("roles");

    const getSteps = useCallback(() => {
        if (isOrganizationData) {
            return STEPS_ORGANIZATION_USER;
        }

        return STEPS_ADMIN_USER;
    }, [STEPS_ADMIN_USER, STEPS_ORGANIZATION_USER, isOrganizationData]);

    const STEPS = useMemo(() => getSteps(), [getSteps]);

    const handleNextStep = useCallback(async () => {
        setStep((prevActiveStep) => prevActiveStep + 1);
    }, []);

    const handlePreviousStep = useCallback(() => {
        setStep((prevActiveStep) => prevActiveStep - 1);
    }, []);

    const showSubmit = useCallback(() => {
        return isOrganizationData ? step === 1 : true;
    }, [step, isOrganizationData]);

    const anyRolesInOrg = useCallback(() => {
        return !!organizations.find((org) => org.roles.length > 0);
    }, [organizations]);

    const disableSaveButton = useCallback(() => {

        if (step === 1) {
            return !anyRolesInOrg();
        }

        if (step === 2) {
            return roles.length <= 0;
        }

        return true;
    }, [anyRolesInOrg, step, roles]);
    /* STEPS DECLARATION END */

    return (
        <Page title={`${translate(`user.form.title.edit`)}`}>

            <Container maxWidth={themeStretch ? false : 'lg'}>
                {(!user || isLoading) ? (
                    <LoadingScreen />
                ) : (
                    <>
                        <HeaderBreadcrumbs
                            heading={`${translate(`user.form.title.edit`)}`}
                            links={[
                                { name: `${translate("commons.home")}`, href: PATH_DASHBOARD.root },
                                { name: `${translate("user.form.management")}`, href: PATH_DASHBOARD.user.list },
                                { name: `${userToEdit?.firstName} ${userToEdit?.lastName}`, href: PATH_DASHBOARD.user.detailsTab(userId, "profile") },
                                { name: `${translate("user.form.title.edit")}` }
                            ]}
                        />

                        <ErrorModal
                            toggle={toggleError}
                            toggleSave={toggleSave}
                            isOpen={isOpenError}
                            errorText={`${translate('commons.error')}`}
                        />
                        <SuccessModal
                            toggle={toggleSuccess}
                            toggleSave={toggleSave}
                            isOpen={isOpenSuccess}
                            returnPage={PATH_DASHBOARD.user.list}
                            successText={`${translate('user.messages.save')}`}
                        />
                        <SaveModal
                            toggle={toggleSave}
                            isOpen={isOpenSave}
                            handleSave={handleSaveUser}
                            saveText={`${translate('user.messages.saveConfirm')}`}
                        />

                        <Stepper sx={{ mb: 2, px: 5 }} activeStep={step} alternativeLabel>
                            {
                                STEPS!.map((step, ind) =>
                                    <Step key={ind}>
                                        <StepLabel>{`${translate(`user.form.steps.${step}`)}`}</StepLabel>
                                    </Step>)
                            }
                        </Stepper>

                        <AddForm
                            outerRef={formContainerRef}
                            step={step + 1}
                            onSubmitFn={getUserFormValues}
                            formMethods={methods}
                            handleNextStep={handleNextStep}
                            handlePreviousStep={handlePreviousStep}
                            showSubmit={showSubmit()}
                            toggleSave={toggleSave}
                            isSaveDisabled={disableSaveButton()}
                        />

                    </>
                )}

            </Container>
        </Page>
    );
}