import { useAuth } from "src/appHooks/useAuth";
import connection from 'src/signalr-connection';
import { Notification } from 'src/services/paths';
import { enqueueSnackbar } from "notistack";
import { ReportPayload } from "src/@types/notification";
import { PATH_DASHBOARD } from "src/routes/paths";
import { useCallback, useEffect, useState } from "react";
import { Typography } from "@mui/material";
import { useLocales } from "src/locales";
import { ReportTypeData } from "src/@types/report";
import { dispatch } from "src/redux/store";
import { incrementUnread } from "src/redux/inbox/inbox-slices";

type ConnectionStorage = {
    inbox: connection,
    report: connection
}

enum targets {
    NEW_REPORT = "newReportGenerationCompleted",
    NEW_MESSAGE = "newInboxMessage"
}

export default function NotificationHandler() {
    const { getAccessToken, isAuthenticated } = useAuth();

    const { translate } = useLocales();

    const [connections, setConnections] = useState<ConnectionStorage>();

    const initialize = useCallback(() => {
        if (isAuthenticated)
            setConnections({

                inbox: new connection(Notification.endUser.sockets.inbox(), getAccessToken),
                report: new connection(Notification.endUser.sockets.report(), getAccessToken)

            });

    }, [getAccessToken, isAuthenticated]);

    const reportEvent = useCallback((payload: ReportPayload) => {

        enqueueSnackbar(

            <Typography style={{ whiteSpace: 'pre-line' }}>
                {`${translate('report.detail.title')}`}: {payload.reportName} <span><br /></span>
                {`${translate('commons.status')}`}: {`${translate(`commons.${payload.status}`)}`}
            </Typography>,

            {
                autoHideDuration: null,
                anchorOrigin: { vertical: 'bottom', horizontal: 'right' },
                variant: 'NotificationSnackbar',
                link: payload.reportCategory === "Scheduled" ?
                    PATH_DASHBOARD.reports.scheduled.detail(payload.reportId, "general", payload.reportType as ReportTypeData) :
                    PATH_DASHBOARD.reports.recurring.detail(payload.reportId, "general", payload.reportType as ReportTypeData),
            });

    }, [translate]);

    useEffect(() => {

        if (!connections)
            initialize();

        else {
            connections.report.setEvents(targets.NEW_REPORT, reportEvent);
            connections.inbox.setEvents(targets.NEW_MESSAGE, inboxEvent);
        }
    }, [connections, initialize, reportEvent]);

    const inboxEvent = () => {
        dispatch(incrementUnread());
    };

    return null;
}