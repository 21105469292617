import { Box } from "@mui/material";
import { useAuth } from "./appHooks/useAuth";

const environment = process.env.REACT_APP_ENV as string;

export default function EnvironmentVisualizer() {
    const { user } = useAuth();

    return <>
        {
            user && user.isGod &&
            <Box sx={{
                height: '3px', width: '100%', position: 'absolute', zIndex: '9999', bgcolor: (theme) => {
                    //environment visualizer
                    if (environment === "development" || environment === "develop" || environment === "dev")
                        return theme.palette.success.main;
                    if (environment === "staging")
                        return theme.palette.warning.main;

                    return theme.palette.error.main;
                }
            }} />
        }
    </>;
}