import { Box, Button, FormControl, IconButton, InputAdornment, InputLabel, MenuItem, OutlinedInput, Select, Stack, TextField, Tooltip } from '@mui/material';
import useLocales from 'src/appHooks/useLocales';
import { useFormContext } from 'react-hook-form';
import { useCallback, useEffect, useMemo, useState } from 'react';
import Iconify from 'src/components/iconify';
import DeleteIcon from '@mui/icons-material/Delete';
import ClearIcon from '@mui/icons-material/Clear';
import dayjs from 'dayjs';
import { DatePicker } from '@mui/x-date-pickers';
import { remove } from 'lodash';
import { ContainerFilters, DEFAULT_CONTAINER_FILTERS } from 'src/@types/container';
import { ToolbarSearchFilters } from 'src/@types/commons';

type FilterProps = {
    filters: ContainerFilters,
    onFilter: VoidFunction,
    resetAll: VoidFunction,
    showSummary: boolean,
    setShowSummary: (value: boolean) => void,
    isTemplate: boolean
}

const DEFAULT_DATE_ERRORS = {
    fromFormat: false,
    fromMissing: false,
    toFormat: false,
    toMissing: false,
    toInvalid: false
};

export default function ContainerGeneralFilters({ filters, onFilter, resetAll, showSummary, setShowSummary, isTemplate }: FilterProps) {

    const { translate } = useLocales();

    const { setValue } = useFormContext();

    const [resetFilter, setResetFilter] = useState(false);

    const [openTooltip, setOpenTooltip] = useState(false);

    const [showDelete, setShowDelete] = useState(false);

    const [datesErrors, setDatesErrors] = useState(DEFAULT_DATE_ERRORS);

    const [filterValues, setFilterValues] = useState(filters);

    //-----------------------------------
    const getFromTo = useCallback((item: "from" | "to") => {
        if (!isTemplate) {
            switch (filterValues.dateField) {
                case "Opened": return item === "from" ? "fromCreatedOn" : "toCreatedOn";
                case "Closed": return item === "from" ? "fromClosedOn" : "toClosedOn";
                case "Shipped": return item === "from" ? "fromShippedOn" : "toShippedOn";
            }
        }

        return item === "from" ? "from" : "to";
    }, [filterValues, isTemplate]);

    const from: string = useMemo(() => getFromTo("from"), [getFromTo]);

    const to: string = useMemo(() => getFromTo("to"), [getFromTo]);
    //-----------------------------------

    const toolbarFiltersList: ToolbarSearchFilters[] = useMemo(() =>
        [
            { key: 'Opened', label: `${translate('containers.dateOptions.opened')}` },
            { key: 'Closed', label: `${translate('containers.dateOptions.closed')}` },
            { key: 'Shipped', label: `${translate('containers.dateOptions.shipped')}` }
        ], [translate]);

    //---- IS DEFAULT - START ----//
    const fullKeysToRemove: string[] = useMemo(() => ["pageIndex", "pageSize", "sortField", "sortAscending", "onlyEnabled", "status"], []);

    const fullKeyRemover = useCallback((key: string) => {
        return !(fullKeysToRemove.includes(key));
    }, [fullKeysToRemove]);

    const isDefaultx = useCallback((filter: ContainerFilters, controller?: ContainerFilters) => {

        const check = controller ? controller : DEFAULT_CONTAINER_FILTERS;

        const found = remove(Object.keys(filter), fullKeyRemover)
            .find((element) => (filter[element] && filter[element] !== check[element]));

        return (!found);
    }, [fullKeyRemover]);
    //---- IS DEFAULT - END ----//

    useEffect(() => {

        if (!isDefaultx(filters) && !showSummary) {
            setFilterValues({
                ...filters,
                name: filters.name || DEFAULT_CONTAINER_FILTERS.name,
                from: filters.from || DEFAULT_CONTAINER_FILTERS.from,
                to: filters.to || DEFAULT_CONTAINER_FILTERS.to,
                fromClosedOn: filters.fromClosedOn || DEFAULT_CONTAINER_FILTERS.fromClosedOn,
                toClosedOn: filters.toClosedOn || DEFAULT_CONTAINER_FILTERS.toClosedOn,
                fromShippedOn: filters.fromShippedOn || DEFAULT_CONTAINER_FILTERS.fromShippedOn,
                toShippedOn: filters.toShippedOn || DEFAULT_CONTAINER_FILTERS.toShippedOn,
                fromCreatedOn: filters.fromCreatedOn || DEFAULT_CONTAINER_FILTERS.fromCreatedOn,
                toCreatedOn: filters.toCreatedOn || DEFAULT_CONTAINER_FILTERS.toCreatedOn
            });
            if (!filters[from]) {
                setDatesErrors(DEFAULT_DATE_ERRORS);
            }
            if (filters[from] || filters.name) {
                setShowDelete(true);
            } else {
                setShowDelete(false);
            }
        } else {
            setFilterValues({
                ...filters,
                name: DEFAULT_CONTAINER_FILTERS.name,
                from: DEFAULT_CONTAINER_FILTERS.from,
                to: DEFAULT_CONTAINER_FILTERS.to,
                fromClosedOn: DEFAULT_CONTAINER_FILTERS.fromClosedOn,
                toClosedOn: DEFAULT_CONTAINER_FILTERS.toClosedOn,
                fromShippedOn: DEFAULT_CONTAINER_FILTERS.fromShippedOn,
                toShippedOn: DEFAULT_CONTAINER_FILTERS.toShippedOn,
                fromCreatedOn: DEFAULT_CONTAINER_FILTERS.fromCreatedOn,
                toCreatedOn: DEFAULT_CONTAINER_FILTERS.toCreatedOn
            });
            setDatesErrors(DEFAULT_DATE_ERRORS);
            setShowDelete(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filters, isDefaultx]);

    useEffect(() => {
        if (!filterValues[from] && !filterValues[to]) {
            setDatesErrors(DEFAULT_DATE_ERRORS);
        }
    }, [filterValues, from, to]);

    useEffect(() => {
        if (resetFilter) {
            setFilterValues({ ...DEFAULT_CONTAINER_FILTERS });
            setResetFilter(false);
            resetAll();
        }
    }, [resetFilter, resetAll]);

    const handleChangeValues = (newVal: any, type: string) => {
        const valuesCopy = JSON.parse(JSON.stringify(filterValues));

        valuesCopy[type] = newVal;

        setFilterValues(valuesCopy);
    };

    const setAllValues = () => {
        for (const [key, value] of Object.entries(filterValues)) {
            if (key === "name" || key === to || key === from || key === "dateField") {
                setValue(`${key}`, value);
            } else {
                setValue(`${key}`, DEFAULT_CONTAINER_FILTERS[key]);
            }
        }
    };

    const handleSearch = () => {
        if (filterValues[from] && !filterValues[to]) {
            setDatesErrors({ ...datesErrors, toMissing: true });
        } else if (!filterValues[from] && filterValues[to]) {
            setDatesErrors({ ...datesErrors, fromMissing: true });
        } else if (filterValues[from] || filterValues.name) {
            setAllValues();
            onFilter();
            setShowDelete(true);
            setShowSummary(false);
        }
    };

    const handleDelete = () => {
        setResetFilter(true);
        setDatesErrors(DEFAULT_DATE_ERRORS);
        setShowDelete(false);
    };

    const handleChangeFrom = (date: dayjs.Dayjs) => {

        if (date.toString() !== 'Invalid Date') {

            handleChangeValues(date.format('YYYY-MM-DD'), from);

            let toInvalidCheck = false;

            if (filterValues[to] && dayjs(filterValues[to]).diff(date, "d") < 0) {
                toInvalidCheck = true;
            }

            setDatesErrors({ ...datesErrors, fromFormat: false, fromMissing: false, toInvalid: toInvalidCheck });
        }
        else {
            setDatesErrors({ ...datesErrors, fromFormat: true });
        }
    };

    const handleChangeTo = (date: dayjs.Dayjs) => {

        if (date.toString() !== 'Invalid Date') {

            if (date.diff(filterValues[from], "d") < 0) {
                setDatesErrors({ ...datesErrors, toInvalid: true, toFormat: false, toMissing: false });
            } else {
                handleChangeValues(date.format('YYYY-MM-DD'), to);
                setDatesErrors({ ...datesErrors, toInvalid: false, toFormat: false, toMissing: false });
            }
        }
        else {
            setDatesErrors({ ...datesErrors, toFormat: true });
        }
    };

    const handleDeleteDate = (type: string) => {

        handleChangeValues("", type);

        if (type === from) {
            setDatesErrors({ ...datesErrors, fromFormat: false, fromMissing: false, toInvalid: false });
        } else {
            setDatesErrors({ ...datesErrors, toFormat: false, toMissing: false, toInvalid: false });
        }
    };

    const errorChecker = () => {
        if (datesErrors.fromFormat) return true;
        if (datesErrors.fromMissing) return true;
        if (datesErrors.toFormat) return true;
        if (datesErrors.toMissing) return true;
        if (datesErrors.toInvalid) return true;

        return false;
    };

    const getDateErrorMessage = (type: string) => {

        if (datesErrors.fromFormat && type === from) return `${translate("commons.validation.wrongDate")}`;
        if (datesErrors.toFormat && type === to) return `${translate("commons.validation.wrongDate")}`;
        if (datesErrors.fromMissing && type === from) return `${translate("commons.validation.missingDate")}`;
        if (datesErrors.toMissing && type === to) return `${translate("commons.validation.missingDate")}`;
        if (datesErrors.toInvalid && type === to) return `${translate("commons.validation.invalidDate")}`;

        return "";
    };

    return (
        <Stack
            direction={{ xs: 'column', sm: 'column', md: 'row' }}
            sx={{
                px: 3, pt: 2.5,
                pb: { xs: 0, md: 2.5 }
            }}
        >

            {!isTemplate &&
                <FormControl
                    sx={{
                        minWidth: { xs: "100%", md: 160 },
                        mr: 2,
                        mb: { xs: 2, md: 0 }
                    }}
                    variant="outlined"
                >
                    <InputLabel
                        id="label"
                        sx={{ '&.Mui-focused': { color: 'grey.500' } }}
                    >
                        {`${translate('returns.searchType')}`}
                    </InputLabel>
                    <Select
                        labelId="label"
                        id="dateField"
                        value={filterValues.dateField}
                        input={
                            <OutlinedInput label={`${translate('returns.searchType')}`} />
                        }
                        onChange={(event) => handleChangeValues(event.target.value, "dateField")}
                    >
                        {toolbarFiltersList.map((option) => (
                            <MenuItem
                                key={option.key}
                                value={option.key}
                                sx={{
                                    mx: 1, my: 0.5,
                                    borderRadius: 0.75,
                                    typography: 'body2',
                                    textTransform: 'capitalize',
                                }}
                            >
                                {option.label}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            }

            <Box
                sx={{
                    minWidth: { xs: "100%", md: 410 },
                    mr: 2,
                    mb: { xs: 2, md: 0 }
                }}
            >
                <Stack
                    spacing={2}
                    direction={{ xs: 'column', sm: 'row' }}
                >
                    <DatePicker
                        label={`${translate('commons.from')}`}
                        views={['year', 'month', 'day']}
                        format={'dd/MM/yyyy'}

                        value={filterValues[from] ? dayjs(filterValues[from]).toDate() : null}

                        onAccept={(newValue) => {
                            if (newValue)
                                handleChangeValues(dayjs(newValue).format('YYYY-MM-DD'), from);
                        }}

                        onChange={(fromDateValue, inputval) => {

                            if (!inputval.validationError && fromDateValue) {

                                const date = dayjs(fromDateValue);

                                handleChangeFrom(date);
                            } else {
                                setDatesErrors({ ...datesErrors, fromFormat: true });
                            }
                        }}

                        slotProps={{
                            textField: {
                                name: from,
                                fullWidth: true,
                                error: datesErrors.fromFormat || datesErrors.fromMissing,
                                helperText: getDateErrorMessage(from),
                                InputProps: {
                                    startAdornment: (filterValues[from] &&
                                        <InputAdornment position="end">
                                            <IconButton
                                                sx={{ ml: -2, mr: 1 }}
                                                onClick={(event) => {
                                                    event.stopPropagation();
                                                    handleDeleteDate(from);
                                                }}
                                            >
                                                <ClearIcon />
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }
                            }
                        }}
                    />

                    <DatePicker
                        label={`${translate('commons.to')}`}
                        views={['year', 'month', 'day']}
                        format={'dd/MM/yyyy'}
                        value={filterValues[to] ? dayjs(filterValues[to]).toDate() : null}

                        onAccept={(newValue) => {
                            if (newValue)
                                handleChangeValues(dayjs(newValue).format('YYYY-MM-DD'), to);
                        }}

                        onChange={(fromDateValue, inputval) => {
                            if (!inputval.validationError && fromDateValue) {

                                const date = dayjs(fromDateValue);

                                handleChangeTo(date);
                            } else {
                                setDatesErrors({ ...datesErrors, toFormat: true });
                            }
                        }}

                        slotProps={{
                            textField: {
                                name: to,
                                fullWidth: true,
                                error: datesErrors.toFormat || datesErrors.toMissing || datesErrors.toInvalid,
                                helperText: getDateErrorMessage(to),
                                InputProps: {
                                    startAdornment: (filterValues[to] &&
                                        <InputAdornment position="end">
                                            <IconButton
                                                sx={{ ml: -2, mr: 1 }}
                                                onClick={(event) => {
                                                    event.stopPropagation();
                                                    handleDeleteDate(to);
                                                }}
                                            >
                                                <ClearIcon />
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }
                            }
                        }}
                    />

                </Stack>
            </Box>

            <TextField
                type="search"
                value={filterValues.name}
                onChange={(event) => handleChangeValues(event.target.value, "name")}
                onKeyDown={(event) => {
                    if (event.key === 'Enter') {
                        handleSearch();
                    }
                }}
                placeholder={`${translate('commons.search')}`}
                sx={{ width: "100%", mr: 2 }}
                InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                            <Iconify
                                icon={'eva:search-fill'}
                                sx={{ color: 'text.disabled', width: 20, height: 20 }}
                            />
                        </InputAdornment>
                    ),
                }}
            />

            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center'
                }}
            >
                <Tooltip
                    title={`${translate("commons.validation.invalidData")}`}
                    disableFocusListener
                    disableTouchListener
                    disableInteractive
                    open={openTooltip}
                    sx={{
                        mb: () => errorChecker() ? 4 : 0,
                        width: { xs: "100%" }
                    }}
                >
                    <Box
                        onMouseEnter={() => { if (errorChecker()) setOpenTooltip(true); }}
                        onMouseLeave={() => { if (errorChecker()) setOpenTooltip(false); }}
                    >
                        <Button
                            startIcon={
                                <Iconify
                                    icon={'bx:search-alt'}
                                    sx={{ ml: 1.5, mt: 0.5 }}
                                />
                            }
                            onClick={handleSearch}
                            disabled={errorChecker()}
                            size="large"
                            sx={{ mt: 0.5, width: { xs: "100%" } }}
                        />
                    </Box>
                </Tooltip>

                <Button
                    startIcon={<DeleteIcon sx={{ ml: 1.5, mt: 0.75 }} />}
                    onClick={handleDelete}
                    sx={{
                        display: `${(showDelete) ? "inline" : "none"}`,
                        mt: 0.5,
                        width: { xs: "100%" }
                    }}
                    size="large"
                />
            </Box>

        </Stack>
    );
}