import { Box, Button, Card, Modal, Typography } from "@mui/material";
import useResponsive from "src/hooks/useResponsive";
import { useLocales } from "src/locales";

interface ModalType {
    isOpen: boolean;
    errorText: string;
    toggle: () => void;
    toggleSave?: () => void;
}

export default function ErrorModal({ isOpen, errorText, toggle, toggleSave }: ModalType) {

    const { translate } = useLocales();

    const isDesktop = useResponsive('up', 'lg');

    return (
        <Modal
            open={isOpen}
            onClose={() => {
                if (toggleSave) {
                    toggleSave();
                }
                toggle();
            }}
        >
            <Card sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: isDesktop ? '25vw' : '70vw',
                p: isDesktop ? 4 : 2,
            }}>
                <Box sx={{ textAlign: "center" }}>

                    <Box component="img" src="/assets/illustrations/illustration_empty_mail.svg" sx={{ width: "45%", m: "auto" }} />

                    <Typography variant="h6" sx={{ mt: 3 }}>
                        {`${translate(`commons.wrongError`)}`}
                    </Typography>

                    <Typography variant='body1'>
                        {errorText}
                    </Typography>

                    <Button
                        variant="contained"
                        size={isDesktop ? "small" : "medium"}
                        onClick={() => {
                            if (toggleSave) {
                                toggleSave();
                            }
                            toggle();
                        }}
                        sx={{ mt: 3, borderRadius: "100px" }}
                    >
                        {`${translate('commons.close')}`}
                    </Button>

                </Box>
            </Card>
        </Modal>
    );
}
