import React, { useMemo, useState } from 'react';
import { TableRow, TableCell, MenuItem } from '@mui/material';
import useLocales from 'src/appHooks/useLocales';
import Label from 'src/components/label';
import TableMoreMenu from 'src/appComponents/TableMoreMenu';
import DateZone from 'src/appComponents/DateZone';
import { CustomField, EntityType } from 'src/@types/customField';

type CustomFieldTableRowProps = {
  row: CustomField;
  onEditRow: VoidFunction;
  onDetailsRow: VoidFunction;
  onEnableRow: VoidFunction;
  onDisableRow: VoidFunction;
};

export default function CustomFieldTableRow({
  row,
  onEditRow,
  onDetailsRow,
  onEnableRow,
  onDisableRow
}: CustomFieldTableRowProps) {

  const { id, name, createdOn, enabled, entityType, type } = row;

  const { translate } = useLocales();

  const [openMenu, setOpenMenuActions] = useState<HTMLElement | null>(null);

  const handleOpenMenu = (event: React.MouseEvent<HTMLElement>) => {
    setOpenMenuActions(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setOpenMenuActions(null);
  };

  const customFieldType = useMemo(() => EntityType[entityType as keyof typeof EntityType], [entityType]);

  return (
    <TableRow hover sx={{ cursor: 'pointer' }}>

      <TableCell align='left' onClick={onDetailsRow}>{name[0].text}</TableCell>

      <TableCell align='left' onClick={onDetailsRow}>{id}</TableCell>

      <TableCell align='left' onClick={onDetailsRow}><DateZone date={new Date(createdOn)} /></TableCell>

      <TableCell align='left' onClick={onDetailsRow}>{`${translate(`commons.${type.toLowerCase()}`)}`}</TableCell>

      <TableCell align='left' onClick={onDetailsRow}>{`${translate(`customField.entity.${customFieldType[0].toLowerCase() + customFieldType.slice(1)}`)}`}</TableCell>

      <TableCell align='center' onClick={onDetailsRow}>
        <Label
          color={!enabled ? 'error' : 'success'}
          sx={{ textTransform: 'uppercase' }}
        >
          {enabled ? `${translate("commons.enabled")}` : `${translate("commons.disabled")}`}
        </Label>
      </TableCell>

      <TableCell>
        <TableMoreMenu
          open={openMenu}
          onOpen={handleOpenMenu}
          onClose={handleCloseMenu}
          actions={
            <>
              <MenuItem
                onClick={() => {
                  if (enabled) {
                    onDisableRow();
                  }
                  else {
                    onEnableRow();
                  }
                  handleCloseMenu();
                }}
                sx={!enabled ? { color: 'success.main' } : { color: 'error.main' }}
              >
                {enabled ? `${translate("commons.disable")}` : `${translate("commons.enabled")}`}
              </MenuItem>

              <MenuItem
                onClick={() => {
                  onEditRow();
                  handleCloseMenu();
                }}>
                {`${translate("commons.edit")}`}
              </MenuItem>

            </>
          }
        />
      </TableCell>

    </TableRow>
  );
}
