import { Box, FormControlLabel, Switch, TablePagination, Typography } from '@mui/material';
import { useNavigate, useParams, } from 'react-router-dom';
import { useLocales } from 'src/locales';
import { PATH_DASHBOARD } from 'src/routes/paths';
import { DataGrid, GridCellParams, GridColDef } from '@mui/x-data-grid';
import { DataGridStyle } from 'src/utils/DataGridStyle';
import { noData } from 'src/components/empty-content/EmptyContent';
import useTable from 'src/appHooks/useTable';
import useResponsive from 'src/hooks/useResponsive';
import { useSelector } from 'react-redux';
import { RootState, dispatch } from 'src/redux/store';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { DEFAULT_GROUP_MEMBER_FILTERS, GroupMemberFilters, GroupMemberSearchResult } from 'src/@types/group';
import { groupOperations } from 'src/redux/group';

export default function MembersList() {

    const {
        page,
        rowsPerPage,
        dense,
        onChangePage,
        onChangeDense,
        onChangeRowsPerPage,
    } = useTable();

    const { translate, currentLang } = useLocales();

    const { id: groupId } = useParams();

    const navigate = useNavigate();

    const isDesktop = useResponsive('up', 'md');

    const { isMembersLoading, membersList, memberTotalCount } = useSelector((state: RootState) => state.group);

    const [lastUsedLang, setLastUsedLang] = useState(currentLang.label);

    //---- SEARCH FOR ITEMS AND STATISTICS - START ----//
    // This function is used to call APIs and get Requests list and statistics using filters
    const [firstRender, setFirstRender] = useState(true);

    const onSearch = useCallback(async (filters: GroupMemberFilters) => {
        dispatch(groupOperations.searchGroupMembers({ filters: filters, id: groupId! }));

        if (firstRender) setFirstRender(false);
    }, [groupId, firstRender]);
    //---- SEARCH FOR ITEMS AND STATISTICS - END ----//

    //---- SEARCH FOR ITEMS AND STATISTICS HOOK - START ----//
    // This hook is used to call onSearch when filters or language are changed
    useEffect(() => {

        const searchFilters = { pageIndex: page, pageSize: rowsPerPage };

        const strSearchFilters = JSON.stringify(searchFilters, Object.keys(searchFilters).sort());

        const strFilters = JSON.stringify(DEFAULT_GROUP_MEMBER_FILTERS, Object.keys(DEFAULT_GROUP_MEMBER_FILTERS).sort());

        if (strSearchFilters !== strFilters || lastUsedLang !== currentLang.label || firstRender) {
            onSearch({ pageIndex: page, pageSize: rowsPerPage } as GroupMemberFilters);
            setLastUsedLang(currentLang.label);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentLang, lastUsedLang, onSearch, page, rowsPerPage]);
    //---- SEARCH FOR ITEMS AND STATISTICS HOOK - END ----//

    //---- HANDLE TABLE START ----//
    const handleCellClick = (params: GridCellParams<any>) => {
        navigate(PATH_DASHBOARD.groups.detail(params.row.id.toString()));
    };

    const getHeight = () => {
        let height: string | number = "auto";

        if (!dense || membersList.length === 0) {
            if (isDesktop) height = rowsPerPage === 5 ? 380 : 650;
            else height = rowsPerPage === 5 ? 440 : 700;
        }

        return height;
    };

    const getMaxHeight = () => {
        return isDesktop ? 650 : 700;
    };

    const COLUMNS: GridColDef<GroupMemberSearchResult>[] = useMemo(() => [
        {
            field: 'name',
            headerName: `${translate('commons.name')}`,
            flex: isDesktop ? 0.8 : undefined,
            minWidth: !isDesktop ? 155 : undefined,
            sortable: false,
            renderCell: (obj) => {
                return (
                    <Typography noWrap variant='body2'>
                        {obj.row.firstName + " " + obj.row.lastName}
                    </Typography>
                );
            }
        },
        {
            field: 'email',
            headerName: `${translate('commons.email')}`,
            flex: isDesktop ? 0.8 : undefined,
            minWidth: !isDesktop ? 155 : undefined,
            sortable: false,
            renderCell: (obj) => {
                return (
                    <Typography noWrap variant='body2'>
                        {obj.row.email}
                    </Typography>
                );
            }
        }
    ], [translate, isDesktop]);
    //---- HANDLE TABLE END ----//

    return (
        <Box>
            <DataGrid
                rows={membersList}
                columns={COLUMNS}
                getRowId={(row) => row.userId}
                pagination
                paginationModel={{
                    page: page,
                    pageSize: rowsPerPage
                }}
                density={(dense && membersList.length > 0) ? 'compact' : 'standard'}
                sortingMode={"server"}
                loading={isMembersLoading}
                disableColumnResize
                slots={{
                    noRowsOverlay: noData,
                    footer: () => (
                        <Box sx={{
                            position: 'relative',
                            width: { xs: "90vw", md: "auto" },
                        }}>
                            <TablePagination
                                rowsPerPageOptions={[5, 10, 15, 30]}
                                component="div"
                                count={memberTotalCount}
                                rowsPerPage={rowsPerPage}
                                page={!memberTotalCount || memberTotalCount <= 0 ? 0 : page}
                                onPageChange={onChangePage}
                                onRowsPerPageChange={onChangeRowsPerPage}
                                labelRowsPerPage={`${translate('commons.rowsPerPage')}`}
                                sx={{
                                    overflow: "hidden",
                                    "& .MuiTablePagination-input": {
                                        ml: { xs: 0.5, md: "default" },
                                        mr: { xs: 3.5, md: "default" }
                                    }
                                }}
                            />
                            <FormControlLabel
                                control={<Switch checked={dense} onChange={onChangeDense} />}
                                label={`${translate('commons.dense')}`}
                                sx={{
                                    px: { xs: 0, sm: 3 },
                                    py: { xs: 0, sm: 1.5 },
                                    pb: { xs: 1.5, sm: 0 },
                                    mx: 0,
                                    top: 0,
                                    justifyContent: "center",
                                    width: { xs: "90vw", sm: "auto" },
                                    position: { sm: 'absolute' }
                                }}
                            />
                        </Box>
                    )
                }}
                disableColumnMenu
                pageSizeOptions={[5, 10, 15, 30]}
                disableRowSelectionOnClick
                onCellClick={(param) => {
                    if (!window.getSelection()?.toString())
                        handleCellClick(param);
                }}
                sx={{
                    ...DataGridStyle,
                    cursor: 'pointer',
                    height: getHeight(),
                    maxHeight: getMaxHeight(),
                }}
            />
        </Box>
    );
}