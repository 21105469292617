import axios, { AxiosResponse } from 'axios';
import { InboxFilters, InboxSearchResult, InboxStatisticsType, NewNotificationTemplate, NotificationSettings, NotificationTemplate, NotificationTemplateSearchResult, PagedInboxResponseType } from '../@types/inbox';
import { Notification } from './paths';

const getMessages = (options: InboxFilters): Promise<AxiosResponse<PagedInboxResponseType>> => {

    const url = Notification.endUser.inbox.messages();

    return axios.get<PagedInboxResponseType>(url, { params: options });
};

const getMessagesDetail = (id: string): Promise<AxiosResponse<InboxSearchResult>> => {

    const url = Notification.endUser.inbox.messagesDetail(id);

    return axios.get<InboxSearchResult>(url);
};

const markAsRead = (messageIds: string[]): Promise<AxiosResponse> => {

    const url = Notification.endUser.inbox.markAsRead();

    return axios.patch(url, {
        ids: messageIds
    });
};

const markAsUnRead = (messageIds: string[]): Promise<AxiosResponse> => {

    const url = Notification.endUser.inbox.markAsUnRead();

    return axios.patch(url, {
        ids: messageIds
    });
};

const markAsImportant = (messageIds: string[]): Promise<AxiosResponse> => {

    const url = Notification.endUser.inbox.markAsImportant();

    return axios.patch(url, {
        ids: messageIds
    });
};

const markAsUnImportant = (messageIds: string[]): Promise<AxiosResponse> => {

    const url = Notification.endUser.inbox.markAsUnImportant();

    return axios.patch(url, {
        ids: messageIds
    });
};

const markAsStarred = (messageIds: string[]): Promise<AxiosResponse> => {

    const url = Notification.endUser.inbox.markAsStarred();

    return axios.patch(url, {
        ids: messageIds
    });
};

const markAsUnStarred = (messageIds: string[]): Promise<AxiosResponse> => {

    const url = Notification.endUser.inbox.markAsUnStarred();

    return axios.patch(url, {
        ids: messageIds
    });
};

const deleteMessages = (messageIds: string[]): Promise<AxiosResponse> => {

    const url = Notification.endUser.inbox.delete();

    return axios.delete(url, { data: { ids: messageIds } });
};

const labelsAndStatistics = (): Promise<AxiosResponse<InboxStatisticsType>> => {

    const url = Notification.endUser.inbox.labelsAndStatistics();

    return axios.get<InboxStatisticsType>(url);
};

const getSettings = (): Promise<AxiosResponse<NotificationSettings>> => {
    const url = Notification.endUser.settings.getSettings();

    return axios.get(url);
};

const updateSettings = (options: NotificationSettings): Promise<AxiosResponse> => {
    const url = Notification.endUser.settings.updateSettings();

    return axios.put(url, options);
};

const createNotificationTemplate = (params: NewNotificationTemplate): Promise<AxiosResponse<NotificationTemplate>> => {
    const url = Notification.admin.notification.createNotification();

    return axios.post(url, params);
};

const searchNotificationTemplate = (): Promise<AxiosResponse<NotificationTemplateSearchResult>> => {
    const url = Notification.admin.notification.search();

    return axios.get(url);
};

const notificationTemplateDetail = (id: string): Promise<AxiosResponse<NotificationTemplate>> => {
    const url = Notification.admin.notification.detail(id);

    return axios.get(url);
};

const updateNotificationType = (id: string, params: NotificationTemplate): Promise<AxiosResponse<NotificationTemplate>> => {
    const url = Notification.admin.notification.editNotification(id);

    return axios.put(url, params);
};

export {
    getMessages,
    getMessagesDetail,
    markAsRead,
    markAsUnRead,
    markAsImportant,
    markAsUnImportant,
    markAsStarred,
    markAsUnStarred,
    labelsAndStatistics,
    deleteMessages,
    getSettings,
    updateSettings,
    updateNotificationType,
    notificationTemplateDetail,
    createNotificationTemplate,
    searchNotificationTemplate
};
