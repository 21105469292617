import { Box, Button, Card, Chip, Container, Grid, Typography } from "@mui/material";
import Page from "src/appComponents/Page";
import useLocales from "src/appHooks/useLocales";
import { useSettingsContext } from "src/components/settings/SettingsContext";
import { PATH_DASHBOARD } from "src/routes/paths";
import HeaderBreadcrumbs from '../../../../components/custom-breadcrumbs';
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { organizationRoleDetail } from "src/services/roleServices";
import LoadingScreen from "src/appComponents/loading-screen/LoadingScreen";
import useResponsive from "src/hooks/useResponsive";
import { DEFAULT_ROLE, Role } from "src/@types/role";
import DateZone from "src/appComponents/DateZone";
import useTenant from "src/appHooks/useTenant";
import { GridLabeledText } from "src/appComponents/GridLabeledText";
import { StyledInfo, StyledRoot } from "src/utils/Detail";
import { lokalizableRoleStr } from "../utils";

export default function OrganizationRoleDetail() {

    const [role, setRole] = useState<Role>(DEFAULT_ROLE);

    const [isLoading, setIsLoading] = useState<boolean>(true);

    const tenant = useTenant();

    const navigate = useNavigate();

    const isDesktop = useResponsive('up', 'lg');

    const { translate, currentLang } = useLocales();

    const { themeStretch } = useSettingsContext();

    const { id } = useParams();

    useEffect(() => {
        async function getRole(id: string) {
            try {
                const response = await organizationRoleDetail(id);

                setRole(response.data);
            }
            catch (e) {
                console.error(e);
            }
        }

        if (id)
            getRole(id);
    }, [id, currentLang]);

    useEffect(() => {
        if (role.id) {
            setIsLoading(false);
        }
    }, [role.id, tenant, currentLang]);

    const handleEdit = () => {
        navigate(PATH_DASHBOARD.tenant.role.organization.edit(role.id));
    };

    return (
        <Page title={`${translate('role.detail.operation')}`}>
            {isLoading ? (
                <LoadingScreen />
            ) : (
                <Container maxWidth={themeStretch ? false : 'lg'}>
                    <HeaderBreadcrumbs
                        heading={`${translate('role.detail.operation')}`}
                        links={[
                            { name: `${translate('commons.home')}`, href: PATH_DASHBOARD.root },
                            { name: `${translate('role.title_plural')}`, href: PATH_DASHBOARD.tenant.role.organization.list },
                            { name: `${translate('commons.details')}` },
                            { name: `${role.name}` },
                        ]}
                    />

                    <Card sx={{ p: 3 }}>

                        <Card
                            sx={{
                                mb: 3,
                                height: 280,
                                position: 'relative',
                            }}>
                            <StyledRoot>
                                <StyledInfo>
                                    <Box
                                        sx={{
                                            ml: { md: 3 },
                                            mt: { xs: 1, md: 0 },
                                            color: 'common.white',
                                            textAlign: { xs: 'center', md: 'left' },
                                        }}>

                                        <Typography variant="h4">{`${role?.name} `}</Typography>
                                        <Button variant="contained" size="small" sx={{ mt: isDesktop ? 2 : 1 }} onClick={handleEdit}>
                                            {`${translate("commons.edit")}`}
                                        </Button>
                                    </Box>

                                </StyledInfo>
                            </StyledRoot>

                        </Card>
                        <Box
                            sx={{
                                px: 3,
                                pb: 3,
                                display: 'grid',
                                gap: 2,
                                gridTemplateColumns: { xs: 'repeat(1, 1fr)', sm: 'repeat(1, 1fr)' },
                            }}
                        >
                            <GridLabeledText
                                align={"left"}
                                label={`${translate('role.detail.id')}`}
                                value={role?.id || '—'}
                                left={3}
                                right={9}
                            />

                            <Grid container>
                                <Grid item xs={3}>
                                    <Typography display="inline" sx={{ color: 'text.secondary', fontWeight: '400' }}>
                                        {`${translate('role.detail.roledate')} :`}
                                    </Typography>
                                </Grid>
                                <Grid item xs={9}>
                                    <DateZone date={role.createdOn} display="inline" sx={{ color: 'text.primary', fontWeight: '600', textAlign: 'left', pl: isDesktop ? 2 : 0 }} />
                                </Grid>
                            </Grid>

                            <GridLabeledText
                                align={"left"}
                                label={`${translate('commons.user')}`}
                                value={role?.userInRole.toString() || '0'}
                                left={3}
                                right={9}
                            />

                            <Box>
                                <Typography display="inline" sx={{ color: 'text.secondary', fontWeight: '400' }}>
                                    {`${translate('role.title')} : `}
                                </Typography>

                                {role.permissions.map((role) =>

                                (
                                    <Chip
                                        sx={{ height: '20px', m: '3px' }}
                                        key={role}
                                        label={`${translate(`role.permission.${lokalizableRoleStr(role)}.title`)}` || ''}
                                    />
                                )
                                )}
                            </Box>

                        </Box>
                    </Card>
                </Container>
            )
            }
        </Page >
    );

}

