import { Box, Card, Container, Divider, FormControlLabel, MenuItem, Switch, TablePagination, Typography } from '@mui/material';
import Page from 'src/appComponents/Page';
import { useLocation, useNavigate, } from 'react-router-dom';
import HeaderBreadcrumbs from 'src/components/custom-breadcrumbs';
import { useSettingsContext } from 'src/components/settings';
import { useLocales } from 'src/locales';
import { PATH_DASHBOARD } from 'src/routes/paths';
import { DataGrid, GridCellParams, GridColDef } from '@mui/x-data-grid';
import { DataGridStyle } from 'src/utils/DataGridStyle';
import { noData } from 'src/components/empty-content/EmptyContent';
import useTable from 'src/appHooks/useTable';
import useResponsive from 'src/hooks/useResponsive';
import { useSelector } from 'react-redux';
import { RootState, dispatch } from 'src/redux/store';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { setFiltersInUrl } from 'src/redux/container/container-slices';
import Label from 'src/components/label';
import TableMoreMenu from 'src/appComponents/TableMoreMenu';
import PermissionBasedGuard from 'src/guards/PermissionBasedGuard';
import { OrganizationPermissionTypes } from 'src/@types/permissions';
import { useSnackbar } from 'notistack';
import DateZone from 'src/appComponents/DateZone';
import { DEFAULT_GROUP_FILTERS, EnableDisableGroupSliceProps, GroupFilters, GroupSearchResult } from 'src/@types/group';
import { groupOperations } from 'src/redux/group';

export default function GroupList() {

    const {
        page,
        setPage,
        rowsPerPage,
        setRowsPerPage,
        dense,
        onChangePage,
        onChangeDense,
        onChangeRowsPerPage,
    } = useTable();

    const { translate, currentLang } = useLocales();

    const { themeStretch } = useSettingsContext();

    const { enqueueSnackbar } = useSnackbar();

    const navigate = useNavigate();

    const isDesktop = useResponsive('up', 'md');

    const { isLoading, groupsList, totalCount, pageSize, pageIndex, filtersInUrl } = useSelector((state: RootState) => state.group);

    const [filters, setFilters] = useState<GroupFilters>(DEFAULT_GROUP_FILTERS);

    const [lastUsedLang, setLastUsedLang] = useState(currentLang.label);

    //---- FILTERS IN URL GET/SET START ----//
    const location = useLocation();

    const [firstRender, setFirstRender] = useState(true);

    const updateFiltersInUrl = useCallback((filters: any) => {

        let queryString = Object.keys(filters)
            .filter(x => (filters[x] && filters[x] !== DEFAULT_GROUP_FILTERS[x]))
            .map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(filters[key])}`)
            .join('&');

        if (queryString) queryString = "#" + queryString;

        dispatch(setFiltersInUrl(queryString));

        navigate(location.pathname + queryString, { replace: true });

    }, [location, navigate]);

    const getPageAndSize = useCallback((filtersInUrl?: string) => {
        if (firstRender) {
            let returnValues = [0, 0];

            if (filtersInUrl) {
                const cleanedHash = filtersInUrl.slice(1);

                const decodedQuery = decodeURIComponent(cleanedHash);

                const searchParams = new URLSearchParams(decodedQuery);

                returnValues = [
                    searchParams.get('pageSize') ? Number(searchParams.get('pageSize')) : pageSize,
                    searchParams.get('pageIndex') ? Number(searchParams.get('pageIndex')) : pageIndex
                ];
            } else {
                returnValues = [pageSize, pageIndex];
            }

            return returnValues;
        }
        else return [rowsPerPage, page];
    }, [firstRender, page, pageIndex, pageSize, rowsPerPage]);

    const getFiltersFromUrl = useCallback(() => {

        let searchFilters = {};

        const [pageSizeFunc, pageIndexFunc] = getPageAndSize(filtersInUrl);

        searchFilters = {
            pageIndex: pageIndexFunc,
            pageSize: pageSizeFunc
        };

        searchFilters = Object.fromEntries(Object.entries(searchFilters).filter(([_, value]) => value !== null && value !== 'null'));

        return searchFilters as GroupFilters;

    }, [getPageAndSize, filtersInUrl]);
    //---- FILTERS IN URL GET/SET END ----//

    //---- SEARCH FOR ITEMS AND STATISTICS - START ----//
    // This function is used to call APIs and get Requests list and statistics using filters
    const onSearch = useCallback(async (filtersFromUrl: GroupFilters) => {

        updateFiltersInUrl(filtersFromUrl);

        dispatch(groupOperations.searchGroups(filtersFromUrl));

        if (firstRender) setFirstRender(false);

    }, [updateFiltersInUrl, firstRender]);
    //---- SEARCH FOR ITEMS AND STATISTICS - END ----//

    //---- SEARCH FOR ITEMS AND STATISTICS HOOK - START ----//
    // This hook is used to call onSearch when filters or language are changed
    useEffect(() => {

        let searchFilters = getFiltersFromUrl();

        const strSearchFilters = JSON.stringify(searchFilters, Object.keys(searchFilters).sort());

        const strFilters = JSON.stringify(filters, Object.keys(filters).sort());

        if (strSearchFilters !== strFilters || lastUsedLang !== currentLang.label || firstRender) {
            setFilters(searchFilters as GroupFilters);
            onSearch(searchFilters as GroupFilters);
            setLastUsedLang(currentLang.label);
            if (searchFilters.pageIndex !== page) setPage(searchFilters.pageIndex);
            if (searchFilters.pageSize !== rowsPerPage) setRowsPerPage(searchFilters.pageSize);
        }

    }, [currentLang, filters, getFiltersFromUrl, lastUsedLang, onSearch, page, rowsPerPage, setPage, setRowsPerPage, firstRender]);
    //---- SEARCH FOR ITEMS AND STATISTICS HOOK - END ----//

    //---- HANDLE TABLE START ----//
    const [openMenu, setOpenMenuActions] = useState<HTMLElement | null>(null);

    const [actualRow, setActualRow] = useState<any>(null);

    const handleOpenMenu = (event: React.MouseEvent<HTMLElement>) => {
        setOpenMenuActions(event.currentTarget);
    };

    const handleCloseMenu = () => {
        setOpenMenuActions(null);
    };

    const handleCellClick = (params: GridCellParams<any>) => {

        setActualRow(params);

        if (params.field !== "options") {
            navigate(PATH_DASHBOARD.groups.detail(params.row.groupId.toString()));
        }
    };

    const getHeight = () => {
        let height: string | number = "auto";

        if (!dense || groupsList.length === 0) {
            if (isDesktop) height = rowsPerPage === 5 ? 380 : 650;
            else height = rowsPerPage === 5 ? 440 : 700;
        }

        return height;
    };

    const getMaxHeight = () => {
        return isDesktop ? 650 : 700;
    };

    const handleLogs = useCallback((id: string) => {
        navigate(PATH_DASHBOARD.groups.logs(id));
    }, [navigate]);

    const handleEdit = useCallback((id: string) => {
        navigate(PATH_DASHBOARD.groups.edit(id));
    }, [navigate]);

    const handleEnableDisableGroup = useCallback(async (id: string, action: "Enable" | "Disable") => {

        const options: EnableDisableGroupSliceProps = {
            id: id,
            action: action
        };

        let x = await dispatch(groupOperations.enableDisableGroup(options));

        if (x.meta.requestStatus !== 'fulfilled') {
            enqueueSnackbar(`${translate('commons.error')}`, {
                variant: "error",
                autoHideDuration: 3000,
                anchorOrigin: { vertical: "top", horizontal: "right" }
            });
        }
        else {
            enqueueSnackbar(`${translate(action === "Enable" ? 'commons.enabled' : 'commons.disabled')}`, {
                variant: "success",
                autoHideDuration: 3000,
                anchorOrigin: { vertical: "top", horizontal: "right" }
            });
        }
    }, [enqueueSnackbar, translate]);

    const COLUMNS: GridColDef<GroupSearchResult>[] = useMemo(() => [
        {
            field: 'displayName',
            headerName: `${translate('commons.name')}`,
            flex: isDesktop ? 0.8 : undefined,
            minWidth: !isDesktop ? 155 : undefined,
            sortable: false,
            renderCell: (obj) => {
                return (
                    <Typography noWrap variant='body2'>
                        {obj.row.displayName}
                    </Typography>
                );
            }
        },
        {
            field: 'createdOn',
            headerName: `${translate('orders.tableHeaders.createdOn')}`,
            flex: isDesktop ? 0.9 : undefined,
            minWidth: !isDesktop ? 180 : undefined,
            sortable: false,
            renderCell: (obj) => {
                return (
                    <DateZone
                        date={new Date(obj.row.createdOn)}
                        shortMonth
                        noSeconds
                        variant={"body2"}
                    />
                );
            }
        },
        {
            field: 'enabled',
            headerName: `${translate('commons.enabled')}`,
            flex: isDesktop ? 1 : undefined,
            minWidth: !isDesktop ? 175 : undefined,
            headerAlign: 'center',
            align: "center",
            sortable: false,
            renderCell: (obj) => {
                return (
                    <Label
                        color={obj.row.enabled ? 'success' : 'error'}
                        sx={{ textTransform: 'uppercase' }}
                    >
                        {obj.row.enabled ? `${translate("commons.enabled")}` : `${translate("commons.disabled")}`}
                    </Label>
                );
            }
        },
        {
            field: 'options',
            headerName: ``,
            flex: isDesktop ? 0.2 : undefined,
            maxWidth: !isDesktop ? 70 : undefined,
            headerAlign: 'center',
            align: "center",
            sortable: false,
            renderCell: (obj: any) => {
                return (
                    <OptionsComponent
                        openMenu={openMenu}
                        handleOpenMenu={handleOpenMenu}
                        handleCloseMenu={handleCloseMenu}
                        object={obj}
                        currentRow={actualRow}
                        handleEnableDisable={handleEnableDisableGroup}
                        handleLogs={handleLogs}
                        handleEdit={handleEdit}
                    />
                );
            }
        }
    ], [translate, isDesktop, openMenu, actualRow, handleLogs, handleEdit, handleEnableDisableGroup]);

    useEffect(() => {
        const [rowsFromFunc, pageFromFunc] = getPageAndSize();

        if (totalCount <= rowsFromFunc * pageFromFunc) onChangePage(null, 0);

    }, [onChangePage, totalCount, getPageAndSize]);

    useEffect(() => {
        if (pageIndex !== page) setPage(pageIndex);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pageIndex, setPage]);
    //---- HANDLE TABLE END ----//

    return (
        <Page title={`${translate(`menu.management.groups.title`)}`}>

            <Container maxWidth={themeStretch ? false : 'lg'}>
                <HeaderBreadcrumbs
                    heading={`${translate(`menu.management.groups.title`)}`}
                    links={[
                        { name: `${translate('commons.home')}`, href: PATH_DASHBOARD.root },
                        { name: `${translate(`role.type.groups`)}` }
                    ]}
                />

                <Card>

                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', px: { xs: 2, md: 3.5 }, pb: 2 }}>
                        <Typography variant="body2" sx={{ mt: 2 }}>
                            {`${translate(`groups.subtitle`)}`}
                        </Typography>
                    </Box>

                    <Divider />

                    <Box>
                        <DataGrid
                            rows={groupsList}
                            columns={COLUMNS}
                            getRowId={(row) => row.groupId}
                            pagination
                            paginationModel={{
                                page: page,
                                pageSize: rowsPerPage
                            }}
                            density={(dense && groupsList.length > 0) ? 'compact' : 'standard'}
                            sortingMode={"server"}
                            loading={isLoading}
                            disableColumnResize
                            slots={{
                                noRowsOverlay: noData,
                                footer: () => (
                                    <Box sx={{
                                        position: 'relative',
                                        width: { xs: "90vw", md: "auto" },
                                    }}>
                                        <TablePagination
                                            rowsPerPageOptions={[5, 10, 15, 30]}
                                            component="div"
                                            count={totalCount}
                                            rowsPerPage={rowsPerPage}
                                            page={!totalCount || totalCount <= 0 ? 0 : page}
                                            onPageChange={onChangePage}
                                            onRowsPerPageChange={onChangeRowsPerPage}
                                            labelRowsPerPage={`${translate('commons.rowsPerPage')}`}
                                            sx={{
                                                overflow: "hidden",
                                                "& .MuiTablePagination-input": {
                                                    ml: { xs: 0.5, md: "default" },
                                                    mr: { xs: 3.5, md: "default" }
                                                }
                                            }}
                                        />
                                        <FormControlLabel
                                            control={<Switch checked={dense} onChange={onChangeDense} />}
                                            label={`${translate('commons.dense')}`}
                                            sx={{
                                                px: { xs: 0, sm: 3 },
                                                py: { xs: 0, sm: 1.5 },
                                                pb: { xs: 1.5, sm: 0 },
                                                mx: 0,
                                                top: 0,
                                                justifyContent: "center",
                                                width: { xs: "90vw", sm: "auto" },
                                                position: { sm: 'absolute' }
                                            }}
                                        />
                                    </Box>
                                )
                            }}
                            disableColumnMenu
                            pageSizeOptions={[5, 10, 15, 30]}
                            disableRowSelectionOnClick
                            onCellClick={(param) => {
                                if (!window.getSelection()?.toString())
                                    handleCellClick(param);
                            }}
                            sx={{
                                ...DataGridStyle,
                                cursor: 'pointer',
                                height: getHeight(),
                                maxHeight: getMaxHeight(),
                            }}
                        />
                    </Box>

                </Card>

            </Container>
        </Page>
    );
}

type OptionsComponentProps = {
    openMenu: HTMLElement | null,
    handleOpenMenu: (event: React.MouseEvent<HTMLElement>) => void,
    handleCloseMenu: () => void,
    object: any,
    currentRow: any,
    handleEnableDisable: (id: string, action: "Enable" | "Disable") => Promise<void>,
    handleLogs: (id: string) => void,
    handleEdit: (id: string) => void,
};

function OptionsComponent({ openMenu, handleOpenMenu, handleCloseMenu, object, currentRow, handleEnableDisable, handleLogs, handleEdit }: OptionsComponentProps) {

    const { translate } = useLocales();

    return (
        <TableMoreMenu
            showMenu={currentRow && object.id === currentRow.id}
            open={openMenu}
            onOpen={(event) => handleOpenMenu(event)}
            onClose={() => handleCloseMenu()}
            actions={
                <>
                    <PermissionBasedGuard permissions={[OrganizationPermissionTypes.Group_EnableDisable]}>
                        <MenuItem
                            onClick={() => {
                                if (handleEnableDisable) {
                                    if (object.row.enabled) handleEnableDisable(object.id, "Disable");
                                    else handleEnableDisable(object.id, "Enable");
                                }
                                handleCloseMenu();
                            }}
                            sx={{ color: object.row.enabled ? 'error.main' : 'success.main' }}
                        >
                            {!object.row.enabled ? `${translate("commons.enable")}` : `${translate("commons.disable")}`}
                        </MenuItem>
                    </PermissionBasedGuard>

                    <PermissionBasedGuard permissions={[OrganizationPermissionTypes.Group_Edit]}>
                        <MenuItem
                            onClick={() => {
                                handleEdit(object.id);
                                handleCloseMenu();
                            }}
                        >
                            {`${translate("commons.edit")}`}
                        </MenuItem>
                    </PermissionBasedGuard>

                    {/*                     
                    <PermissionBasedGuard permissions={[OrganizationPermissionTypes.Group_View]} >
                        <MenuItem
                            onClick={() => {
                                handleLogs(object.id);
                                handleCloseMenu();
                            }}
                        >
                            {`${translate("commons.logs")}`}
                        </MenuItem>
                    </PermissionBasedGuard> 
                    */}
                </>
            }
        />
    );
}