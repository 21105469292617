import { Delete } from "@mui/icons-material";
import { Box, Button, FormControlLabel, MenuItem, Radio, Stack, Switch, TablePagination, TextField, Typography } from "@mui/material";
import { DataGrid, GridColDef, GridRenderCellParams, GridRowParams, GridSortModel } from "@mui/x-data-grid";
import { debounce } from "lodash";
import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { OrganizationFilters, OrganizationSearchResult } from "src/@types/organizations";
import { useAuth } from "src/appHooks/useAuth";
import useTable from "src/appHooks/useTable";
import { noData } from "src/components/empty-content/EmptyContent";
import Iconify from "src/components/iconify";
import { useLocales } from "src/locales";
import { organizationsOperations } from "src/redux/organizations";
import { resetOrganizationList } from "src/redux/organizations/organizations-slices";
import { RootState, dispatch } from "src/redux/store";
import { DataGridStyle } from "src/utils/DataGridStyle";

interface SearchCustomerProps {
    organization: string,
    onChange: (org: string) => void,
}

export default function SearchCustomer({ organization, onChange }: SearchCustomerProps) {
    const { organizationList, isSearchLoading, totalCount } = useSelector((state: RootState) => state.organizations);

    const [searchBy, setSearchBy] = useState('all');

    const [search, setSearch] = useState('');

    const [isFiltered, setIsFiltered] = useState(false);

    const [selectionModel, setSelectionModel] = useState<string>(organization);

    const { user } = useAuth();

    const debounced = useRef(debounce((value: string) => handleSearch(false, value), 500));

    const {
        page,
        rowsPerPage,
        onChangeDense,
        dense,
        onChangePage,
        onChangeRowsPerPage,
        order,
        setOrder,
        setOrderBy,
        orderBy,
        setPage
    } = useTable();

    const { translate } = useLocales();

    const handleSearch = (clear?: boolean, value?: string) => {
        let options: OrganizationFilters = {
            pageIndex: page,
            pageSize: rowsPerPage,
            status: "Enabled",
            sortAscending: order === "asc" ? true : false,
        };

        if (clear) {
            dispatch(organizationsOperations.searchCustomerOrganizations(options));
            setSearch("");

            return;
        }

        if (orderBy) {
            options = {
                ...options,
                sortField: orderBy
            };
        }

        options = { ...options, [searchBy]: value ? value : search };

        dispatch(organizationsOperations.searchCustomerOrganizations(options));

        if (search || value)
            setIsFiltered(true);
        else
            setIsFiltered(false);
    };

    const searchOrg = (val: string) => {
        if (val.length >= 3 || val.length === 0)
            debounced.current(val);

        setSearch(val);
    };

    const handleChange = (value: GridRenderCellParams<OrganizationSearchResult> | GridRowParams<OrganizationSearchResult>) => {
        setSelectionModel(value.id.toString());
        onChange(value.id.toString());
    };

    useEffect(() => {
        if (totalCount <= rowsPerPage * page) {
            setPage(0);
        }
    }, [page, rowsPerPage, setPage, totalCount]);

    useEffect(() => {

        handleSearch();

        return () => { dispatch(resetOrganizationList()); };

    }, [page, rowsPerPage, user, order, orderBy]);

    const handleSort = (sortModel: GridSortModel) => {
        if (sortModel.length > 0) {
            setOrderBy(sortModel[0].field);
            setOrder(sortModel[0].sort!);
        } else {
            setOrderBy("");
            setOrder("asc");
        }
    };

    useEffect(() => {

        debounced.current = debounce((value: string) => handleSearch(false, value), 500);

    }, [searchBy]);

    const SELECT_ITEMS = [
        { key: 'all', label: `${translate('commons.all')}` },
        { key: 'name', label: `${translate('commons.name')}` },
        { key: 'externalId', label: `${translate('commons.code')}` }
    ];

    const columns: GridColDef<OrganizationSearchResult>[] = [
        {
            field: '',
            headerName: ``,
            flex: 0.25,
            sortable: false,
            align: 'left',
            renderCell: (v) =>
                <Radio
                    checked={selectionModel === v.id.toString()}
                    onClick={(e) => {
                        e.stopPropagation();
                        handleChange(v);
                    }}
                />
        },
        {
            field: 'externalId',

            headerName: `${translate('commons.code')}`,
            flex: 0.5,
            valueGetter: (value: string) => {
                if (!value) {
                    return '—';
                }

                return value;
            },
        },
        {
            field: 'name',
            headerName: `${translate('commons.name')}`,
            flex: 0.75,
            valueGetter: (value: string) => {
                if (!value) {
                    return '—';
                }

                return value;
            },
        },
        {
            field: 'regions',
            headerName: `${translate('organization.list.table.region')}`,
            flex: 0.75,
            valueGetter: (value: string[]) => {
                if (!value.length) {
                    return '—';
                }

                return value.join();
            },
        },
        {
            field: 'type',
            headerName: `${translate('organization.list.table.type')}`,
            flex: 0.75,
            valueGetter: (value: string) => {
                if (!value) {
                    return '—';
                }

                return value;
            },
        },
    ];

    return (
        <Box>
            <Typography variant="h6" sx={{ m: 3 }}>
                {`${translate('request.messages.researchCustomer')}`}
            </Typography>

            <Stack spacing={2} direction={{ xs: 'column', sm: 'row' }} sx={{ py: 2.5, px: 3 }}>
                <TextField
                    fullWidth
                    label={`${translate("returns.searchType")}`}
                    value={searchBy}
                    onChange={(e) => {
                        setSearchBy(e.target.value);

                    }}
                    select
                    SelectProps={{
                        MenuProps: {
                            sx: { '& .MuiPaper-root': { maxHeight: 260 } },
                        },
                    }}
                    sx={{
                        maxWidth: { sm: 240 },
                        textTransform: 'capitalize',
                    }}
                >
                    {SELECT_ITEMS.map((item, index) =>
                        <MenuItem
                            key={index}
                            sx={{
                                mx: 1,
                                my: 0.5,
                                borderRadius: 0.75,
                                typography: 'body2',
                                textTransform: 'capitalize',
                            }}
                            value={item.key} >
                            {item.label}
                        </MenuItem>
                    )}

                </TextField>

                <TextField
                    fullWidth
                    sx={{ m: 2 }}
                    id="orderSelect"
                    value={search}
                    label={`${translate('commons.search')}`}
                    onChange={e => searchOrg(e.target.value)}
                />

                <Button
                    variant="text"
                    sx={{ borderRadius: '8px' }}
                    onClick={() => handleSearch()}
                >
                    <Iconify
                        icon={'eva:search-fill'}
                        sx={{ width: 20, height: 20 }}
                    />
                </Button>
                {
                    isFiltered &&
                    <Button
                        variant="text"
                        onClick={() => {
                            setSearch('');
                            setIsFiltered(false);
                            handleSearch(true);
                        }}
                    >
                        <Delete />
                    </Button>
                }
            </Stack>
            <DataGrid
                disableColumnResize
                columns={columns}
                rows={organizationList}
                loading={isSearchLoading}
                disableColumnMenu
                rowCount={totalCount}
                disableRowSelectionOnClick
                pagination
                paginationModel={{
                    page: page,
                    pageSize: rowsPerPage
                }}
                density={dense ? 'compact' : 'standard'}
                sortingMode={"server"}
                paginationMode="server"
                onSortModelChange={handleSort}
                onRowClick={(p) => {
                    handleChange(p);
                }}
                sx={{
                    ...DataGridStyle,
                    //cursor: 'pointer',
                    height: rowsPerPage === 5 ? 380 : 650,
                    maxHeight: 650,
                    '& .MuiDataGrid-columnHeaderTitle': {
                        whiteSpace: 'break-spaces',
                        lineHeight: 1,
                    }
                }}
                pageSizeOptions={[5, 10, 15, 30]}
                slots={{
                    noRowsOverlay: noData,
                    footer: () => (
                        <Box sx={{ position: 'relative' }} >
                            <TablePagination
                                rowsPerPageOptions={[5, 10, 15, 30]}
                                component="div"
                                count={totalCount}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                onPageChange={onChangePage}
                                onRowsPerPageChange={onChangeRowsPerPage}
                                labelRowsPerPage={`${translate('commons.rowsPerPage')}`} />

                            <FormControlLabel
                                control={<Switch checked={dense} onChange={onChangeDense} />}
                                label={`${translate('commons.dense')}`}
                                sx={{ px: 3, py: 1.5, top: 0, position: { md: 'absolute' } }} />
                        </Box>
                    )
                }}
            />
        </Box >
    );
}
