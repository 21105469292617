import { Box, Button, Card, Divider, InputAdornment, Modal, TextField, Typography } from "@mui/material";
import SearchIcon from '@mui/icons-material/Search';
import { useEffect, useState } from "react";
import QrCodeScannerIcon from '@mui/icons-material/QrCodeScanner';
import useResponsive from "src/hooks/useResponsive";
import { useNavigate } from "react-router";
import { useLocales } from "src/locales";
import { PATH_DASHBOARD } from "src/routes/paths";
import BarcodeScannerComponent from "react-qr-barcode-scanner-18";
import { requestOperations } from "src/redux/request";
import { dispatch } from "src/redux/store";
import ErrorModal from "src/components/modals/ErrorModal";

interface DriverBarcodeScanProps {
    handleNextStep: VoidFunction,
    carrier: boolean,
    customer: boolean
}

export default function DriverBarcodeScan({ handleNextStep, carrier, customer }: DriverBarcodeScanProps) {

    const [code, setCode] = useState("");

    const isDesktop = useResponsive('up', 'sm');

    const navigate = useNavigate();

    const { translate } = useLocales();

    const [videoOn, setVideoOn] = useState(false);

    const [openErr, setOpenErr] = useState<string>();

    const handleGetBarcode = async (cod?: string) => {
        try {
            const { status } = await dispatch(requestOperations.getRequestBarcode(cod || code)).unwrap();

            if (status === "ReadyForPickUp" || status === "PickedUp") {
                handleNextStep();

                return;
            }

            setOpenErr('request.messages.invalidStatus');

        }
        catch {
            setOpenErr('request.messages.notFound');
        }

    };

    return (
        <Card sx={{ my: 2, p: 3 }}>
            <ErrorModal errorText={`${translate(openErr || "")}`} isOpen={!!openErr} toggle={() => setOpenErr(undefined)} />
            <BarCodeScanner isOpen={videoOn} toggle={() => setVideoOn(prev => !prev)} handleData={(v) => {
                setCode(v);
                handleGetBarcode(v);
            }}
            />
            <Box
                sx={{
                    gap: 5,
                    display: 'flex',
                    flexDirection: 'column',
                    flex: '1 1 auto',
                    justifyContent: 'center',
                    alignItems: 'center'
                }}
            >
                <TextField
                    label={`${translate('request.insertManual')}`}
                    InputProps={{ endAdornment: <InputAdornment position="end"><SearchIcon /></InputAdornment> }}
                    value={code}
                    onChange={(e) => setCode(e.target.value)}
                    sx={{ width: isDesktop ? '70%' : '100%' }}
                />

                <Typography>{`${translate('commons.or')}`}</Typography>

                <Button
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignContent: 'center',
                        justifyContent: 'center',
                        border: '1px dotted',
                        width: isDesktop ? '70%' : '100%'
                    }}
                    onClick={() => setVideoOn(true)}
                >
                    <QrCodeScannerIcon sx={{ mt: 5 }} />
                    <Typography sx={{ pb: 5 }}>{`${translate('request.scanCode')}`}</Typography>
                </Button>

            </Box>

            <Divider sx={{ my: 3 }} />

            <Box
                sx={{
                    display: 'flex',
                    px: 5,
                    py: 3,
                    gap: 2,
                    flexDirection: isDesktop ? 'row' : 'column',
                    justifyContent: 'space-between'
                }}
            >
                <Button
                    variant="soft"
                    onClick={() => navigate(carrier ? PATH_DASHBOARD.request.carrier.list :
                        customer ?
                            PATH_DASHBOARD.request.customer.list :
                            PATH_DASHBOARD.request.vendor.list
                    )}
                >
                    {`${translate('request.goBack')}`}
                </Button>

                <Button
                    disabled={!code}
                    variant="contained"
                    onClick={() => handleGetBarcode()}>
                    {`${translate('commons.confirm')}`}
                </Button>
            </Box>
        </Card>
    );
}

interface BarCodeScannerProps {
    isOpen: boolean,
    toggle: VoidFunction,
    handleData: (value: string) => void
}

function BarCodeScanner({ isOpen, toggle, handleData }: BarCodeScannerProps) {
    const { translate } = useLocales();

    const isDesktop = useResponsive('up', 'sm');

    const [media, setMedia] = useState<MediaStream>();

    useEffect(() => {

        if (isOpen && !media)
            navigator.mediaDevices.getUserMedia({ video: true }).then(v => setMedia(v));
        else if (!isOpen && media)
            media.getTracks().forEach(t => t.stop());
    }, [isOpen, media]);

    return (
        <Modal open={isOpen} onClose={() => toggle()}>
            <Card sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: 'fit-content',
                display: 'flex',
                flexDirection: 'column',
                justifyItems: 'center',
                p: isDesktop ? 5 : 2,
            }}>
                <BarcodeScannerComponent
                    onError={(e) => console.error(e)}
                    height={500}
                    width={500}
                    onUpdate={(err, result) => {
                        if (result) {
                            handleData(result.getText());
                            toggle();
                        }
                    }}
                    stopStream={!isOpen}
                />
                <Box sx={{ display: 'flex', flexDirection: 'row', gap: 5, justifyContent: 'center', mb: 2 }}>
                    <Button variant="soft" sx={{ height: "25%", my: 'auto' }} onClick={toggle}>
                        {`${translate('commons.close')}`}
                    </Button>
                </Box>
            </Card>
        </Modal >
    );
}