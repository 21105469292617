import { Box, Card, Container, MenuItem, TextField, Button, FormHelperText } from "@mui/material";
import Page from "src/appComponents/Page";
import useLocales from "src/appHooks/useLocales";
import { useSettingsContext } from "src/components/settings";
import HeaderBreadcrumbs from '../../../../components/custom-breadcrumbs';
import { PATH_DASHBOARD } from '../../../../routes/paths';
import { useEffect, useMemo, useState } from "react";
import { organizationRoleCreate } from "src/services/roleServices";
import { LoadingButton } from "@mui/lab";
import { useNavigate } from "react-router";
import { Stack } from "@mui/system";
import useTenant from "src/appHooks/useTenant";
import ErrorModal from "src/components/modals/ErrorModal";
import SuccessModal from "src/components/modals/SuccessModal";
import { OrganizationPermissionTypes } from "src/@types/permissions";
import { getOrgPermissionList } from "src/utils/permissionList";
import PermissionTable from "src/components/table/PermissionTable";

export default function NewOrganizationRole() {
    const { translate } = useLocales();

    const navigate = useNavigate();

    const { themeStretch } = useSettingsContext();

    const tenant = useTenant();

    const PermissionT = useMemo<Record<string, OrganizationPermissionTypes[]>>(() => getOrgPermissionList(tenant), [tenant]);

    const [newRole, setNewRole] = useState<string[]>([]);

    const [roleName, setRoleName] = useState<string>('');

    const [type, setType] = useState('Organization');

    const [isOpenErr, setIsOpenErr] = useState(false);

    const [isOpenSucc, setIsOpenSucc] = useState(false);

    const [nameErrorText, setNameErrorText] = useState('');

    const [generalErrorText, setGeneralErrorText] = useState('');

    const handleSubmit = async () => {

        const param = {
            name: roleName.trim(),
            permissions: newRole as OrganizationPermissionTypes[]
        };

        try {
            await organizationRoleCreate(param);
            toggleSucc();
        } catch (e) {
            console.error(e);
            toggleErr();
        }
    };

    const toggleErr = () => {
        isOpenErr ? setIsOpenErr(false) : setIsOpenErr(true);
    };

    const toggleSucc = () => {
        isOpenSucc ? setIsOpenSucc(false) : setIsOpenSucc(true);
    };

    const handleBlur = (e: any) => {
        if (!e.target.value) {
            setNameErrorText(`${translate('commons.validation.requiredField')}`);
        } else if (e.target.value.length > 15) {
            setNameErrorText(`${translate('role.validation.nameLength')}`);
        } else {
            setNameErrorText('');
        }
    };

    useEffect(() => {
        if (newRole.length === 0 && !(roleName.trim()) && !nameErrorText) {
            setGeneralErrorText(`${translate('role.validation.roleAndName')}`);
        } else if (newRole.length === 0) {
            setGeneralErrorText(`${translate('role.validation.oneRole')}`);
        } else if (!(roleName.trim()) && !nameErrorText) {
            setGeneralErrorText(`${translate('role.validation.justName')}`);
        } else {
            setGeneralErrorText("");
        }
    }, [newRole, roleName, translate, nameErrorText]);

    return (
        <Page title={`${translate('role.form.new.operation')}`}>

            <ErrorModal
                isOpen={isOpenErr}
                errorText={`${translate('commons.error')}`}
                toggle={toggleErr}
            />
            <SuccessModal
                isOpen={isOpenSucc}
                successText={`${translate('role.messages.success')}`}
                toggle={toggleSucc}
                returnPage={PATH_DASHBOARD.tenant.role.organization.list}
            />

            <Container maxWidth={themeStretch ? false : 'lg'} >
                <HeaderBreadcrumbs
                    heading={`${translate('role.form.new.operation')}`}
                    links={[
                        { name: `${translate('commons.home')}`, href: PATH_DASHBOARD.root },
                        { name: `${translate('commons.list')}`, href: PATH_DASHBOARD.tenant.role.organization.list },
                        { name: `${translate('role.form.new.operation')}` }
                    ]}
                />

                <Card sx={{ p: 3 }}>
                    <Box>
                        <Stack spacing={2} direction={{ xs: 'column', sm: 'row' }} sx={{ py: 2.5 }}>
                            <TextField
                                select
                                fullWidth
                                id='tipoRuolo'
                                SelectProps={{
                                    MenuProps: {
                                        sx: { '& .MuiPaper-root': { maxHeight: 260 } },
                                    },
                                }}
                                sx={{
                                    maxWidth: { sm: 240 },
                                    textTransform: 'capitalize',
                                }}
                                value={type}
                                onChange={e => setType(e.target.value)} >
                                {Object.keys(PermissionT).map((type) => (
                                    <MenuItem
                                        key={type}
                                        value={type}
                                        sx={{
                                            mx: 1,
                                            my: 0.5,
                                            borderRadius: 0.75,
                                            typography: 'body2',
                                            textTransform: 'capitalize',
                                        }}
                                    >
                                        {`${translate(`role.type.${type[0].toLowerCase() + type.slice(1)}`)}`}
                                    </MenuItem>
                                ))}
                            </TextField>

                            <TextField
                                label={`${translate('role.form.name')}`}
                                fullWidth
                                required
                                sx={{ textAlign: 'center', width: '50%', alignSelf: 'center', '& .MuiInputLabel-asterisk': { color: 'error.main' } }}
                                onChange={e => setRoleName(e.target.value)}
                                onBlur={e => handleBlur(e)}
                                error={!!nameErrorText}
                                helperText={nameErrorText}
                            />
                        </Stack>

                        <PermissionTable PermissionT={PermissionT} type={type} onChange={(per) => setNewRole(per)} />

                    </Box>
                    <Box hidden={!generalErrorText}>
                        <FormHelperText sx={{ pb: 1.2, textAlign: "right !important" }} error>
                            {generalErrorText}
                        </FormHelperText>
                    </Box>

                    <Box sx={{ mt: 3, display: 'flex', justifyContent: 'flex-end', gap: 2 }}>
                        <Button variant="soft" color="inherit" onClick={() => navigate(PATH_DASHBOARD.tenant.role.organization.list)}>
                            {`${translate("commons.cancel")}`}
                        </Button>
                        <LoadingButton disabled={!!nameErrorText || !!generalErrorText} type="submit" variant="contained" onClick={handleSubmit}>
                            {`${translate("role.form.create")}`}
                        </LoadingButton>
                    </Box>

                </Card>
            </Container>

        </Page>
    );
}