import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { DEFAULT_STATISTIC, ItemSearchResult, Request, RequestSearchResult, Statistic } from "src/@types/request";
import requestOperations from "./request-operations";
import { ErrorResponse, PagedResponseType } from "src/@types/commons";

interface RequestReducer {
    /* COMMONS */
    error: ErrorResponse,
    isLogsLoading: boolean,
    suggest: string[],
    filtersInUrl: string,
    /* REQUEST */
    isLoading: boolean,
    request: Request | null,
    requestMaxQuantity: number,
    totalCount: number,
    requestList: RequestSearchResult[],
    statistics: Statistic,
    requestLogs: PagedResponseType<any>,
    isQuantityLoading: boolean
    pageIndex: number,
    pageSize: number,
    /* ANOMALY */
    isLoadingAnomaly: boolean,
    anomaly: Request | null,
    isItemsLoading: boolean,
    requestItems: PagedResponseType<ItemSearchResult>
};

const initialState: RequestReducer = {
    /* COMMONS */
    error: null,
    isLogsLoading: false,
    suggest: [],
    filtersInUrl: "",
    /* REQUEST */
    isLoading: false,
    isQuantityLoading: false,
    request: null,
    requestMaxQuantity: 0,
    totalCount: 0,
    requestList: [],
    statistics: DEFAULT_STATISTIC,
    requestLogs: {
        pageIndex: 0,
        pageSize: 0,
        items: [],
        totalCount: 0
    },
    /* ANOMALY */
    isLoadingAnomaly: false,
    anomaly: null,
    isItemsLoading: false,
    requestItems: {
        items: [],
        pageIndex: 0,
        pageSize: 0,
        totalCount: 0
    },
    pageIndex: 0,
    pageSize: 10
};

const RequestSlice = createSlice({
    name: 'requests',
    initialState,
    extraReducers: (builder) => {
        builder
            .addCase(requestOperations.createRequest.rejected, (state, action) => {
                state.request = initialState.request;
                state.error = action.error.message as string;
                state.isLoading = false;
            })
            .addCase(requestOperations.createRequest.fulfilled, (state, action) => {
                state.request = action.payload;
                state.isLoading = false;
            })
            .addCase(requestOperations.getRequest.rejected, (state, action) => {
                state.request = initialState.request;
                state.anomaly = initialState.request;
                state.error = action.error.message as string;
                state.isLoading = false;
            })
            .addCase(requestOperations.getRequest.fulfilled, (state, action) => {
                if (action.payload[1]) {
                    state.anomaly = action.payload[0] as Request;
                } else {
                    state.request = action.payload[0] as Request;
                }

                state.isLoading = false;
            })
            .addCase(requestOperations.searchRequests.fulfilled, (state, action) => {
                state.isLoading = false;
                state.totalCount = action.payload.totalCount;
                state.requestList = action.payload.items;
                state.pageIndex = action.payload.pageIndex;
                state.pageSize = action.payload.pageSize;
            })
            .addCase(requestOperations.searchRequests.rejected, (state, action) => {
                state.requestList = initialState.requestList;
                state.totalCount = initialState.totalCount;
                state.pageIndex = initialState.pageIndex;
                state.pageSize = initialState.pageSize;
                state.error = action.error.message as string;
                state.isLoading = false;
            })
            .addCase(requestOperations.searchStatistic.rejected, (state, action) => {
                state.statistics = initialState.statistics;
                state.error = action.error.message as string;
                state.isLoading = false;
            })
            .addCase(requestOperations.searchStatistic.fulfilled, (state, action) => {
                state.statistics = action.payload;
                state.isLoading = false;
            })
            .addCase(requestOperations.getMaxQuantity.rejected, (state, action) => {
                state.requestMaxQuantity = initialState.requestMaxQuantity;
                state.error = action.error.message as string;
                state.isQuantityLoading = false;
            })
            .addCase(requestOperations.getMaxQuantity.fulfilled, (state, action) => {
                state.requestMaxQuantity = action.payload.quantity;
                state.isQuantityLoading = false;
            })
            .addCase(requestOperations.removeRequest.rejected, (state, action) => {
                state.error = action.error.message as string;
            })
            .addCase(requestOperations.approveRequest.rejected, (state, action) => {
                state.error = action.error.message as string;
            })
            .addCase(requestOperations.rejectRequestBackoffice.rejected, (state, action) => {
                state.error = action.error.message as string;
            })
            .addCase(requestOperations.rejectRequestWarehouse.rejected, (state, action) => {
                state.error = action.error.message as string;
            })
            .addCase(requestOperations.printedRequest.rejected, (state, action) => {
                state.error = action.error.message as string;
            })
            .addCase(requestOperations.pickedUpRequest.rejected, (state, action) => {
                state.error = action.error.message as string;
            })
            .addCase(requestOperations.paidRequest.rejected, (state, action) => {
                state.error = action.error.message as string;
            })
            .addCase(requestOperations.warehouseAcceptRequest.rejected, (state, action) => {
                state.error = action.error.message as string;
            })
            .addCase(requestOperations.getRequestBarcode.fulfilled, (state, action) => {
                state.request = action.payload;
                state.isLoading = false;
            })
            .addCase(requestOperations.getRequestBarcode.rejected, (state, action) => {
                state.request = initialState.request;
                state.error = action.error.message as string;
                state.isLoading = false;
            })
            .addCase(requestOperations.getRequestsLogs.rejected, (state, action) => {
                state.isLogsLoading = false;
                state.error = action.error.message as string;
                state.requestLogs = initialState.requestLogs;
            })
            .addCase(requestOperations.getRequestsLogs.fulfilled, (state, action) => {
                state.isLogsLoading = false;
                state.requestLogs = action.payload;
            })
            .addCase(requestOperations.searchVendorReturnItems.fulfilled, (state, action) => {
                state.requestItems = action.payload;
                state.isItemsLoading = false;
            })
            .addCase(requestOperations.searchVendorReturnItems.rejected, (state, action) => {
                state.requestItems = initialState.requestItems;
                state.isItemsLoading = false;
                state.error = action.error.message as string;
            })
            .addCase(requestOperations.searchCustomerReturnItems.fulfilled, (state, action) => {
                state.requestItems = action.payload;
                state.isItemsLoading = false;
            })
            .addCase(requestOperations.searchCustomerReturnItems.rejected, (state, action) => {
                state.requestItems = initialState.requestItems;
                state.isItemsLoading = false;
                state.error = action.error.message as string;
            })
            .addCase(requestOperations.searchCarrierRequests.fulfilled, (state, action) => {
                state.requestList = action.payload.items;
                state.totalCount = action.payload.totalCount;
                state.pageIndex = action.payload.pageIndex;
                state.pageSize = action.payload.pageSize;
                state.isLoading = false;
            })
            .addCase(requestOperations.searchCarrierRequests.rejected, (state, action) => {
                state.requestList = initialState.requestList;
                state.totalCount = initialState.totalCount;
                state.pageIndex = initialState.pageIndex;
                state.pageSize = initialState.pageSize;
                state.error = action.error.message as string;
                state.isLoading = false;
            })
            .addCase(requestOperations.searchCarrierStatistic.rejected, (state, action) => {
                state.statistics = initialState.statistics;
                state.error = action.error.message as string;
                state.isLoading = false;
            })
            .addCase(requestOperations.searchCarrierStatistic.fulfilled, (state, action) => {
                state.statistics = action.payload;
                state.isLoading = false;
            });
    },
    reducers: {
        startLoading(state) {
            state.isLoading = true;
        },
        startLogsLoading(state) {
            state.isLogsLoading = true;
        },
        resetRequest(state) {
            state.request = null;
        },
        startQuantityLoading(state) {
            state.isQuantityLoading = true;
        },
        startItemsLoading(state) {
            state.isItemsLoading = true;
        },
        setFiltersInUrl(state, action: PayloadAction<string>) {
            state.filtersInUrl = action.payload;
        },
        //RESET REQUEST LIST
        resetPageIndexSize(state) {
            state.pageIndex = 0;
            state.pageSize = 10;
        }
    }
});

export const {
    startLoading,
    resetRequest,
    startQuantityLoading,
    startLogsLoading,
    startItemsLoading,
    setFiltersInUrl,
    resetPageIndexSize
} = RequestSlice.actions;

export default RequestSlice.reducer;
