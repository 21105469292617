import { Box, Card, Typography } from "@mui/material";
import { ItemSearchResult, RequestItem, RequestNew, RequestType } from "src/@types/request";
import CancelIcon from '@mui/icons-material/Cancel';
import KeyboardReturnIcon from '@mui/icons-material/KeyboardReturn';
import PublishedWithChangesIcon from '@mui/icons-material/PublishedWithChanges';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import FactCheckIcon from '@mui/icons-material/FactCheck';
import BrokenImageIcon from '@mui/icons-material/BrokenImage';
import { useLocales } from "src/locales";
import DetailDamaged from "./detail/DetailDamaged";
import DetailNewParts from "./detail/DetailNewParts";
import DetailQuality from "./detail/DetailQuality";
import DetailWarranty from "./detail/DetailWarranty";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { RootState, dispatch } from "src/redux/store";
import { ordersOperations } from "src/redux/orders";
import { DeliveryNote, Invoice, OrderDetailItem } from "src/@types/orders";
import LoadingScreen from "src/appComponents/loading-screen";
import { requestOperations } from "src/redux/request";
import { OrganizationSearchResult } from "src/@types/organizations";
import useResponsive from "src/hooks/useResponsive";
import DetailWrong from "./detail/DetailWrong";
import PartsDetail, { getExceededItems } from "src/components/request-utils/PartsDetail";
import { SparePartSearchResult } from "src/@types/spareParts";
import { FileWithSection } from "src/appUtils/requestUpload";
import RequestDocumentsDetail from "src/components/request-utils/RequestDocumentsDetail";

interface DetailProps {
    request: RequestNew,
    onChange: (name: string, value: any, item?: boolean) => void,
    setDocument: (document: (Invoice | DeliveryNote)[], inv: boolean) => void,
    defaultAddress: OrganizationSearchResult,
    isVendor: boolean,
    organization: string,
    files: FileWithSection[],
    ar: boolean
    warrantyParts: ItemSearchResult[],
    chekFlag: boolean,
    exceededParts: (SparePartSearchResult & { quantity: number })[]
}

export default function Detail({ exceededParts, chekFlag, request, onChange, setDocument, defaultAddress, isVendor, organization, files, ar, warrantyParts }: DetailProps) {

    const { translate } = useLocales();

    const { order } = useSelector((state: RootState) => state.orders);

    const [isOrderLoading, setOrderLoading] = useState(false);

    const [isDocumentLoading, setDocumentLoading] = useState(false);

    const { requestMaxQuantity } = useSelector((state: RootState) => state.request);

    const [invoices, setInvoices] = useState<Invoice[]>([]);

    const [deliveryNotes, setDeliveryNotes] = useState<DeliveryNote[]>([]);

    const [docsIds, setDocsIds] = useState<{ invoiceId?: string | null, deliveryNoteId?: string | null }[]>([]);

    const requestItems = request.items.map(i => i.productId);

    const parts = request.items.length > 0 ? (order && order.items.filter(i => requestItems.includes(i.id))) : null;

    const isDesktop = useResponsive('up', 'md');

    const getOrders = useCallback(async (orders: Set<string>) => {

        const orderArr = Array.from(orders);

        const promises = orderArr.map(order => {
            return dispatch(ordersOperations.getOrder(order)).unwrap();
        });

        const docsId = (await Promise.all(promises)).map((val) => ({
            deliveryNoteId: val.deliveryNoteId,
            invoiceId: val.invoiceId
        }));

        setDocsIds(docsId);

    }, []);

    useEffect(() => {
        if (request.requestReason !== "SurplusDelivered") {
            setOrderLoading(true);

            const orderIds = new Set<string>(request.items.map(req => req.orderId).filter(v => !!v) as string[]);

            getOrders(orderIds);

            if (request.items.length === 1 && (request.requestType !== "Warranty" || !ar))
                dispatch(requestOperations.getMaxQuantity({ orderId: request.items[0].orderId!, productId: request.items[0].productId }));

        }
        setOrderLoading(false);

    }, [getOrders, request.requestReason, request.requestType, ar]);

    useEffect(() => {
        if (docsIds.length > 0) {
            setDocumentLoading(true);

            const invoiceSet = new Set(docsIds.filter(i => !!i.invoiceId).map(v => v.invoiceId));

            const deliveryNoteSet = new Set(docsIds.filter(i => !!i.deliveryNoteId).map(v => v.deliveryNoteId));

            invoiceSet.forEach((id) => {
                dispatch(ordersOperations.getInvoice(id!)).unwrap().then(invoice => setInvoices(prev => {
                    if (!prev.find(i => i.id === invoice.id)) {

                        return prev.concat([invoice]);

                    }

                    return prev;
                }));
            });

            deliveryNoteSet.forEach((id) => {
                dispatch(ordersOperations.getDeliveryNote(id!)).unwrap().then(delivery => setDeliveryNotes(prev => {
                    if (!prev.find(i => i.id === delivery.id))
                        return prev.concat([delivery]);

                    return prev;
                }));
            });
        }
        setDocumentLoading(false);
    }, [docsIds]);

    useEffect(() => {
        setDocument(invoices, true);
    }, [invoices, setDocument]);

    useEffect(() => {
        setDocument(deliveryNotes, false);
    }, [deliveryNotes, setDocument]);

    const getIcon = () => {
        switch (request.requestType) {
            case 'NewPart':
                return <PublishedWithChangesIcon />;
            case 'DamagedPart':
                return <CancelIcon />;
            case 'WrongShipment':
                return <LocalShippingIcon />;
            case 'Core':
                return <KeyboardReturnIcon />;
            case 'Quality':
                return <FactCheckIcon />;
            case 'Warranty':
                return <BrokenImageIcon />;
            default:
                return <></>;
        };
    };

    const details: Record<RequestType, JSX.Element> = {
        "NewPart": <DetailNewParts defaultAddress={defaultAddress} available={requestMaxQuantity} request={request} onChange={onChange} isVendor={isVendor} organization={organization} />,
        "WrongShipment": <DetailWrong exceededParts={exceededParts} keep={chekFlag} parts={parts!} isVendor={isVendor} organization={organization} defaultAddress={defaultAddress} available={requestMaxQuantity} request={request} onChange={onChange} />,
        "Core": <DetailNewParts defaultAddress={defaultAddress} available={requestMaxQuantity} request={request} onChange={onChange} isVendor={isVendor} organization={organization} />,
        "Quality": <DetailQuality organization={organization} isVendor={isVendor} images={files} defaultAddress={defaultAddress} available={requestMaxQuantity} request={request} onChange={onChange} />,
        "DamagedPart": <DetailDamaged images={files} defaultAddress={defaultAddress} available={requestMaxQuantity} request={request} onChange={onChange} isVendor={isVendor} organization={organization} />,
        "Warranty": <DetailWarranty parts={warrantyParts} ar={ar} files={files} defaultAddress={defaultAddress} onChange={onChange} available={requestMaxQuantity} request={request} organization={organization} isVendor={isVendor} />,
        "": <></>
    };

    const partsDetailItems: RequestItem[] = useMemo(() => {
        if (request.requestType !== "Warranty") {
            if (parts && parts.length > 0) {
                return parts.map((part) => {
                    return {
                        orderId: order?.id,
                        itemIndex: part.itemIndex,
                        product: part,
                        bigAndHeavy: false,
                        requiredQuantity: part.quantity
                    } as RequestItem;
                });
            }
        }
        else if (warrantyParts && warrantyParts.length > 0) {
            return warrantyParts.map((part) => {
                return {
                    orderId: order?.id,
                    itemIndex: part.itemIndex,
                    product: part as unknown as OrderDetailItem,
                    bigAndHeavy: false,
                    requiredQuantity: part.quantity
                } as RequestItem;
            });
        }

        return [];
    }, [request.requestType, warrantyParts, parts, order?.id]);

    return (
        <Box sx={{ p: 2 }}>
            {isOrderLoading || isDocumentLoading ? <LoadingScreen /> :
                <>
                    <Box sx={{ p: 2 }}>
                        <Typography variant="h4">
                            {`${translate('request.form.title')}`}
                        </Typography>

                        <Typography
                            variant="h6"
                            sx={{
                                mb: isDesktop ? 1 : 0,
                                mt: isDesktop ? 1 : 3,
                                display: 'flex',
                                alignContent: 'center',
                                gap: 2
                            }}
                        >
                            {getIcon()}
                            {`${translate(`request.${request.requestType}`)}`}
                            {request.requestReason && ` - ${translate(`request.selectReason.${request.requestReason[0].toLowerCase() + request.requestReason.slice(1)}`)}`}
                        </Typography>
                    </Box>

                    {partsDetailItems.length > 0 &&
                        <Card>
                            <PartsDetail
                                items={partsDetailItems}
                                clickToDetail
                                missingChanges={request.requestType === "WrongShipment" && request.requestReason === "SurplusDelivered"}
                                exceededItems={getExceededItems(exceededParts)}
                                showDepositRef={request.requestType === "Warranty" || request.requestType === "Core"}
                            />
                        </Card>
                    }

                    {invoices.length > 0 &&
                        <RequestDocumentsDetail isInvoices documents={invoices} sx={{ my: 2 }} />
                    }
                    {deliveryNotes.length > 0 &&
                        <RequestDocumentsDetail documents={deliveryNotes} sx={{ my: 2 }} />
                    }

                    {details[request.requestType]}
                </>
            }
        </Box>
    );
}