export enum ClusterPermissionTypes {
    // Users
    UserView = "User_View",
    UserViewLogs = "User_ViewLogs",
    UserCreate = "User_Create",
    UserEdit = "User_Edit",
    UserEnableDisable = "User_EnableDisable",
    UserManageRoles = "User_ManageRoles",
    UserManageOrganizations = "User_ManageOrganizations",

    // Roles
    RoleView = "Role_View",
    RoleViewLogs = "Role_ViewLogs",
    RoleCreate = "Role_Create",
    RoleEdit = "Role_Edit",
    RoleEnableDisable = "Role_EnableDisable",

    //Notifications
    Notification_Create = "Notification_Create",
    Notification_Edit = "Notification_Edit",
    Notification_View = "Notification_View",
}

export const ClusterPermissionsList = {
    Users: [
        ClusterPermissionTypes.UserView,
        ClusterPermissionTypes.UserViewLogs,
        ClusterPermissionTypes.UserCreate,
        ClusterPermissionTypes.UserEdit,
        ClusterPermissionTypes.UserEnableDisable,
        ClusterPermissionTypes.UserManageRoles,
        ClusterPermissionTypes.UserManageOrganizations
    ],
    Roles: [
        ClusterPermissionTypes.RoleView,
        ClusterPermissionTypes.RoleViewLogs,
        ClusterPermissionTypes.RoleCreate,
        ClusterPermissionTypes.RoleEdit,
        ClusterPermissionTypes.RoleEnableDisable
    ],
    Notifications: [
        ClusterPermissionTypes.Notification_Create,
        ClusterPermissionTypes.Notification_Edit,
        ClusterPermissionTypes.Notification_View,
    ]
} as Readonly<{ [key: string]: ClusterPermissionTypes[] }>;

export enum OrganizationPermissionTypes {
    // Organization
    OrganizationView = "Organization_View",
    OrganizationViewLogs = "Organization_ViewLogs",
    OrganizationCreate = "Organization_Create",
    OrganizationEdit = "Organization_Edit",
    OrganizationEnableDisable = "Organization_EnableDisable",
    Organization_ManageReports = "Organization_ManageReports",

    // RV Template
    RvTemplateView = "Rv_Template_View",
    RvTemplateViewLogs = "Rv_Template_ViewLogs",
    RvTemplateCreate = "Rv_Template_Create",
    RvTemplateEdit = "Rv_Template_Edit",
    RvTemplateEnableDisable = "Rv_Template_EnableDisable",
    Rv_Template_ManageReports = "Rv_Template_ManageReports",

    // Rv Matrix
    RvMatrixView = "Rv_Matrix_View",
    RvMatrixViewLogs = "Rv_Matrix_ViewLogs",
    RvMatrixCreate = "Rv_Matrix_Create",
    RvMatrixEdit = "Rv_Matrix_Edit",
    RvMatrixEnableDisable = "Rv_Matrix_EnableDisable",
    RvMatrixDownload = "Rv_Matrix_Download",
    RvMatrixSendToApproval = "Rv_Matrix_SendToApproval",
    RvMatrixApproveReject = "Rv_Matrix_ApproveReject",
    Rv_Matrix_ManageReports = "Rv_Matrix_ManageReports",

    // WebShop
    WebShop_Orders_VendorView = "WebShop_Orders_VendorView",
    WebShop_Orders_VendorViewLogs = "WebShop_Orders_VendorViewLogs",
    WebShop_Orders_CustomerView = "WebShop_Orders_CustomerView",
    WebShop_Orders_CustomerViewLogs = "WebShop_Orders_CustomerViewLogs",
    WebShop_Orders_ManageReports = "WebShop_Orders_ManageReports",
    WebShop_View = "WebShop_View",
    WebShop_ViewLogs = "WebShop_ViewLogs",
    WebShop_Create = "WebShop_Create",
    WebShop_Edit = "WebShop_Edit",
    WebShop_EnableDisable = "WebShop_EnableDisable",
    WebShop_ManageDiscounts = "WebShop_ManageDiscounts",
    WebShop_PublishUnpublish = "WebShop_PublishUnpublish",
    WebShop_ViewBaskets = "WebShop_ViewBaskets",
    //Basket
    WebShop_BasketRule_View = "WebShop_BasketRule_View",
    WebShop_BasketRule_ViewLogs = "WebShop_BasketRule_ViewLogs",
    WebShop_BasketRule_Create = "WebShop_BasketRule_Create",
    WebShop_BasketRule_Edit = "WebShop_BasketRule_Edit",
    WebShop_BasketRule_EnableDisable = "WebShop_BasketRule_EnableDisable",

    // Return Requests Customer
    WebShop_ReturnRequests_Requests_CustomerView = "WebShop_ReturnRequests_Requests_CustomerView",
    WebShop_ReturnRequests_Requests_CustomerViewLogs = "WebShop_ReturnRequests_Requests_CustomerViewLogs",
    WebShop_ReturnRequests_Requests_CustomerCreate = "WebShop_ReturnRequests_Requests_CustomerCreate",
    WebShop_ReturnRequests_Requests_CustomerEdit = "WebShop_ReturnRequests_Requests_CustomerEdit",
    WebShop_ReturnRequests_Requests_CustomerEnableDisable = "WebShop_ReturnRequests_Requests_CustomerEnableDisable",
    WebShop_ReturnRequests_Requests_CustomerManageLabel = "WebShop_ReturnRequests_Requests_CustomerManageLabel",
    WebShop_ReturnRequests_Requests_CustomerManageReports = "WebShop_ReturnRequests_Requests_CustomerManageReports",
    WebShop_ReturnRequests_Requests_CustomerSkipRules = "WebShop_ReturnRequests_Requests_CustomerSkipRules",

    // Return Requests Vendor
    WebShop_ReturnRequests_Requests_VendorView = "WebShop_ReturnRequests_Requests_VendorView",
    WebShop_ReturnRequests_Requests_VendorViewLogs = "WebShop_ReturnRequests_Requests_VendorViewLogs",
    WebShop_ReturnRequests_Requests_VendorCreate = "WebShop_ReturnRequests_Requests_VendorCreate",
    WebShop_ReturnRequests_Requests_VendorEdit = "WebShop_ReturnRequests_Requests_VendorEdit",
    WebShop_ReturnRequests_Requests_VendorEnableDisable = "WebShop_ReturnRequests_Requests_VendorEnableDisable",
    WebShop_ReturnRequests_Requests_VendorValidation = "WebShop_ReturnRequests_Requests_VendorValidation",
    WebShop_ReturnRequests_Requests_VendorAnomaly = "WebShop_ReturnRequests_Requests_VendorAnomaly",
    WebShop_ReturnRequests_Requests_VendorManageReports = "WebShop_ReturnRequests_Requests_VendorManageReports",
    WebShop_ReturnRequests_Requests_VendorSkipRules = "WebShop_ReturnRequests_Requests_VendorSkipRules",

    // Return Requests Carrier
    WebShop_ReturnRequests_Requests_CarrierManagePickUp = "WebShop_ReturnRequests_Requests_CarrierManagePickUp",
    WebShop_ReturnRequests_Requests_CarrierView = "WebShop_ReturnRequests_Requests_CarrierView",

    // Return Requests Warehouse
    WebShop_ReturnRequests_Requests_VendorWarehouseValidation = "WebShop_ReturnRequests_Requests_VendorWarehouseValidation",

    // Rules
    WebShop_ReturnRequests_Rules_VendorView = "WebShop_ReturnRequests_Rules_VendorView",
    WebShop_ReturnRequests_Rules_VendorViewLogs = "WebShop_ReturnRequests_Rules_VendorViewLogs",
    WebShop_ReturnRequests_Rules_VendorEdit = "WebShop_ReturnRequests_Rules_VendorEdit",
    WebShop_ReturnRequests_Rules_VendorEnableDisable = "WebShop_ReturnRequests_Rules_VendorEnableDisable",

    // Product
    Product_SparePartsView = "Product_SpareParts_View",
    Product_SparePartsViewLogs = "Product_SpareParts_ViewLogs",
    Product_SparePartsCreate = "Product_SpareParts_Create",
    Product_SparePartsEdit = "Product_SpareParts_Edit",
    Product_SparePartsEnableDisable = "Product_SpareParts_EnableDisable",
    Product_SpareParts_ManageReports = "Product_SpareParts_ManageReports",

    Product_VehiclesView = "Product_Vehicles_View",
    Product_VehiclesViewLogs = "Product_Vehicles_ViewLogs",
    Product_VehiclesCreate = "Product_Vehicles_Create",
    Product_VehiclesEdit = "Product_Vehicles_Edit",
    Product_VehiclesEnableDisable = "Product_Vehicles_EnableDisable",
    Product_VehiclesViewContractualData = "Product_Vehicles_ViewContractualData",
    Product_VehiclesViewContractualDataLogs = "Product_Vehicles_ViewContractualDataLogs",
    Product_VehiclesEditContractualData = "Product_Vehicles_EditContractualData",
    Product_VehiclesViewLogisticData = "Product_Vehicles_ViewLogisticData",
    Product_VehiclesViewLogisticDataLogs = "Product_Vehicles_ViewLogisticDataLogs",
    Product_VehiclesEditLogisticData = "Product_Vehicles_EditLogisticData",
    Product_VehiclesViewCommercialData = "Product_Vehicles_ViewCommercialData",
    Product_VehiclesViewCommercialDataLogs = "Product_Vehicles_ViewCommercialDataLogs",
    Product_VehiclesEditCommercialData = "Product_Vehicles_EditCommercialData",
    Product_Vehicles_ManageReports = "Product_Vehicles_ManageReports",

    //customer
    Customer_View = "Customer_View",
    Customer_Create = "Customer_Create",
    Customer_Edit = "Customer_Edit",
    Customer_EnableDisable = "Customer_EnableDisable",
    Customer_ManageReports = "Customer_ManageReports",

    //shipping
    Shipping_Container_Template_View = "Shipping_Container_Template_View",
    Shipping_Container_Template_Create = "Shipping_Container_Template_Create",
    Shipping_Container_Template_Edit = "Shipping_Container_Template_Edit",
    Shipping_Container_Template_EnableDisable = "Shipping_Container_Template_EnableDisable",
    Shipping_Container_View = "Shipping_Container_View",
    Shipping_Container_ViewLogs = "Shipping_Container_ViewLogs",
    Shipping_Container_Open = "Shipping_Container_Open",
    Shipping_Container_Create = "Shipping_Container_Create",
    Shipping_Container_Ship = "Shipping_Container_Ship",
    Shipping_Container_Close = "Shipping_Container_Close",
    Shipping_Container_AddItems = "Shipping_Container_AddItems",
    Shipping_Container_RemoveItems = "Shipping_Container_RemoveItems",
    Shipping_Container_ManageReports = "Shipping_Container_ManageReports",

    //groups
    Group_View = "Group_View",
    Group_Create = "Group_Create",
    Group_Edit = "Group_Edit",
    Group_EnableDisable = "Group_EnableDisable",

    //Terms and Condition
    TermsAndCondition_View = "TermsAndCondition_View",
    TermsAndCondition_ViewLogs = "TermsAndCondition_ViewLogs",
    TermsAndCondition_Create = "TermsAndCondition_Create",
    TermsAndCondition_Edit = "TermsAndCondition_Edit",
    TermsAndCondition_EnableDisable = "TermsAndCondition_EnableDisable",
}

export const ReportPermissionsList = [
    OrganizationPermissionTypes.Customer_ManageReports,
    OrganizationPermissionTypes.Rv_Matrix_ManageReports,
    OrganizationPermissionTypes.Organization_ManageReports,
    OrganizationPermissionTypes.Rv_Template_ManageReports,
    OrganizationPermissionTypes.WebShop_Orders_ManageReports,
    OrganizationPermissionTypes.WebShop_ReturnRequests_Requests_VendorManageReports,
    OrganizationPermissionTypes.WebShop_ReturnRequests_Requests_CustomerManageReports,
    OrganizationPermissionTypes.Product_SpareParts_ManageReports,
    OrganizationPermissionTypes.Product_Vehicles_ManageReports,
    OrganizationPermissionTypes.Shipping_Container_ManageReports,
];

export const OrganizationPermissionsList = {
    Organization: [
        OrganizationPermissionTypes.OrganizationView,
        OrganizationPermissionTypes.OrganizationViewLogs,
        OrganizationPermissionTypes.OrganizationCreate,
        OrganizationPermissionTypes.OrganizationEdit,
        OrganizationPermissionTypes.OrganizationEnableDisable,
        OrganizationPermissionTypes.Organization_ManageReports
    ],
    RvTemplate: [
        OrganizationPermissionTypes.RvTemplateView,
        OrganizationPermissionTypes.RvTemplateViewLogs,
        OrganizationPermissionTypes.RvTemplateCreate,
        OrganizationPermissionTypes.RvTemplateEdit,
        OrganizationPermissionTypes.RvTemplateEnableDisable,
        OrganizationPermissionTypes.Rv_Template_ManageReports
    ],
    RvMatrix: [
        OrganizationPermissionTypes.RvMatrixView,
        OrganizationPermissionTypes.RvMatrixViewLogs,
        OrganizationPermissionTypes.RvMatrixCreate,
        OrganizationPermissionTypes.RvMatrixEdit,
        OrganizationPermissionTypes.RvMatrixEnableDisable,
        OrganizationPermissionTypes.RvMatrixDownload,
        OrganizationPermissionTypes.RvMatrixSendToApproval,
        OrganizationPermissionTypes.RvMatrixApproveReject,
        OrganizationPermissionTypes.Rv_Matrix_ManageReports
    ],
    WebShop: [
        OrganizationPermissionTypes.WebShop_ReturnRequests_Rules_VendorView,
        OrganizationPermissionTypes.WebShop_ReturnRequests_Rules_VendorViewLogs,
        OrganizationPermissionTypes.WebShop_ReturnRequests_Rules_VendorEdit,
        OrganizationPermissionTypes.WebShop_ReturnRequests_Rules_VendorEnableDisable,
        OrganizationPermissionTypes.WebShop_Orders_VendorView,
        OrganizationPermissionTypes.WebShop_Orders_VendorViewLogs,
        OrganizationPermissionTypes.WebShop_Orders_CustomerView,
        OrganizationPermissionTypes.WebShop_Orders_CustomerViewLogs,
        OrganizationPermissionTypes.WebShop_Orders_ManageReports,
        OrganizationPermissionTypes.WebShop_ReturnRequests_Requests_CustomerView,
        OrganizationPermissionTypes.WebShop_ReturnRequests_Requests_CustomerViewLogs,
        OrganizationPermissionTypes.WebShop_ReturnRequests_Requests_CustomerCreate,
        OrganizationPermissionTypes.WebShop_ReturnRequests_Requests_CustomerEdit,
        OrganizationPermissionTypes.WebShop_ReturnRequests_Requests_CustomerEnableDisable,
        OrganizationPermissionTypes.WebShop_ReturnRequests_Requests_CustomerManageLabel,
        OrganizationPermissionTypes.WebShop_ReturnRequests_Requests_VendorView,
        OrganizationPermissionTypes.WebShop_ReturnRequests_Requests_VendorViewLogs,
        OrganizationPermissionTypes.WebShop_ReturnRequests_Requests_VendorCreate,
        OrganizationPermissionTypes.WebShop_ReturnRequests_Requests_VendorEdit,
        OrganizationPermissionTypes.WebShop_ReturnRequests_Requests_VendorEnableDisable,
        OrganizationPermissionTypes.WebShop_ReturnRequests_Requests_VendorValidation,
        OrganizationPermissionTypes.WebShop_ReturnRequests_Requests_VendorAnomaly,
        OrganizationPermissionTypes.WebShop_ReturnRequests_Requests_CarrierManagePickUp,
        OrganizationPermissionTypes.WebShop_ReturnRequests_Requests_CarrierView,
        OrganizationPermissionTypes.WebShop_ReturnRequests_Requests_VendorWarehouseValidation,
        OrganizationPermissionTypes.WebShop_ReturnRequests_Requests_VendorManageReports,
        OrganizationPermissionTypes.WebShop_ReturnRequests_Requests_CustomerManageReports,
        OrganizationPermissionTypes.WebShop_ReturnRequests_Requests_VendorSkipRules,
        OrganizationPermissionTypes.WebShop_ReturnRequests_Requests_CustomerSkipRules,
        OrganizationPermissionTypes.TermsAndCondition_View,
        OrganizationPermissionTypes.TermsAndCondition_ViewLogs,
        OrganizationPermissionTypes.TermsAndCondition_Create,
        OrganizationPermissionTypes.TermsAndCondition_Edit,
        OrganizationPermissionTypes.TermsAndCondition_EnableDisable,
        OrganizationPermissionTypes.WebShop_View,
        OrganizationPermissionTypes.WebShop_ViewLogs,
        OrganizationPermissionTypes.WebShop_Create,
        OrganizationPermissionTypes.WebShop_Edit,
        OrganizationPermissionTypes.WebShop_EnableDisable,
        OrganizationPermissionTypes.WebShop_ManageDiscounts,
        OrganizationPermissionTypes.WebShop_PublishUnpublish,
        OrganizationPermissionTypes.WebShop_ViewBaskets,
        OrganizationPermissionTypes.WebShop_BasketRule_View,
        OrganizationPermissionTypes.WebShop_BasketRule_ViewLogs,
        OrganizationPermissionTypes.WebShop_BasketRule_Create,
        OrganizationPermissionTypes.WebShop_BasketRule_Edit,
        OrganizationPermissionTypes.WebShop_BasketRule_EnableDisable,
    ],
    ProductVehicle: [
        OrganizationPermissionTypes.Product_VehiclesView,
        OrganizationPermissionTypes.Product_VehiclesViewLogs,
        OrganizationPermissionTypes.Product_VehiclesCreate,
        OrganizationPermissionTypes.Product_VehiclesEdit,
        OrganizationPermissionTypes.Product_VehiclesEnableDisable,
        OrganizationPermissionTypes.Product_VehiclesViewContractualData,
        OrganizationPermissionTypes.Product_VehiclesViewContractualDataLogs,
        OrganizationPermissionTypes.Product_VehiclesEditContractualData,
        OrganizationPermissionTypes.Product_VehiclesViewLogisticData,
        OrganizationPermissionTypes.Product_VehiclesViewLogisticDataLogs,
        OrganizationPermissionTypes.Product_VehiclesEditLogisticData,
        OrganizationPermissionTypes.Product_VehiclesViewCommercialData,
        OrganizationPermissionTypes.Product_VehiclesViewCommercialDataLogs,
        OrganizationPermissionTypes.Product_VehiclesEditCommercialData,
        OrganizationPermissionTypes.Product_Vehicles_ManageReports
    ],
    ProductSparePart: [
        OrganizationPermissionTypes.Product_SparePartsView,
        OrganizationPermissionTypes.Product_SparePartsViewLogs,
        OrganizationPermissionTypes.Product_SparePartsCreate,
        OrganizationPermissionTypes.Product_SparePartsEdit,
        OrganizationPermissionTypes.Product_SparePartsEnableDisable,
        OrganizationPermissionTypes.Product_SpareParts_ManageReports,
    ],
    Customer: [
        OrganizationPermissionTypes.Customer_Create,
        OrganizationPermissionTypes.Customer_Edit,
        OrganizationPermissionTypes.Customer_EnableDisable,
        OrganizationPermissionTypes.Customer_View,
        OrganizationPermissionTypes.Customer_ManageReports
    ],
    Shipping: [
        OrganizationPermissionTypes.Shipping_Container_Template_View,
        OrganizationPermissionTypes.Shipping_Container_Template_Create,
        OrganizationPermissionTypes.Shipping_Container_Template_Edit,
        OrganizationPermissionTypes.Shipping_Container_Template_EnableDisable,
        OrganizationPermissionTypes.Shipping_Container_View,
        OrganizationPermissionTypes.Shipping_Container_ViewLogs,
        OrganizationPermissionTypes.Shipping_Container_Open,
        OrganizationPermissionTypes.Shipping_Container_Create,
        OrganizationPermissionTypes.Shipping_Container_Ship,
        OrganizationPermissionTypes.Shipping_Container_Close,
        OrganizationPermissionTypes.Shipping_Container_RemoveItems,
        OrganizationPermissionTypes.Shipping_Container_AddItems,
        OrganizationPermissionTypes.Shipping_Container_ManageReports
    ],
    Groups: [
        OrganizationPermissionTypes.Group_View,
        OrganizationPermissionTypes.Group_Create,
        OrganizationPermissionTypes.Group_Edit,
        OrganizationPermissionTypes.Group_EnableDisable,
    ]
} as Readonly<{ [key: string]: OrganizationPermissionTypes[] }>;

export const ALL_PERMISSIONS = { ...ClusterPermissionsList, ...OrganizationPermissionsList };
