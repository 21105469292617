import { Box, Button, Card, Container } from "@mui/material";
import Page from "src/appComponents/Page";
import HeaderBreadcrumbs from '../../../components/custom-breadcrumbs';
import { useSettingsContext } from "src/components/settings";
import useLocales from "src/appHooks/useLocales";
import { PATH_DASHBOARD } from "src/routes/paths";
import { RHFTextField } from "src/components/hook-form";
import FormProvider from "src/components/hook-form/FormProvider";
import { useForm } from "react-hook-form";
import useTenant from "src/appHooks/useTenant";
import { useEffect, useMemo, useState } from "react";
import LoadingScreen from "src/appComponents/loading-screen/LoadingScreen";
import { CustomField } from "src/@types/customField";
import { LoadingButton } from "@mui/lab";
import { useLocation, useNavigate, useParams } from "react-router";
import * as Yup from 'yup';
import { yupResolver } from "@hookform/resolvers/yup";
import ErrorModal from "src/components/modals/ErrorModal";
import SuccessModal from "src/components/modals/SuccessModal";
import { useSelector } from "react-redux";
import { RootState, dispatch } from "src/redux/store";
import { brandOperations } from "src/redux/brand";
import { Brand, DEFAULT_BRAND, NewBrand } from "src/@types/brand";
import CustomFieldFormRenderer from "src/utils/CustomFieldFormRenderer";
import { convert } from "src/utils/currency";
import useCurrency from "src/appHooks/useCurrency";
import { CurrencyTypes } from "src/@types/vehicle";
import { resetBrand } from "src/redux/brand/brand-slices";
import SaveModal from "src/components/modals/SaveModal";

export default function NewEditBrand() {
    const { themeStretch, currency: currentCurrency } = useSettingsContext();

    const { translate, currentLang } = useLocales();

    const { rates } = useCurrency();

    const navigate = useNavigate();

    const tenant = useTenant();

    const { customFields } = useTenant();

    const { id } = useParams();

    const { pathname } = useLocation();

    const isEdit = pathname.includes('edit');

    const { brand, isBrandLoading } = useSelector((state: RootState) => state.brand);

    const [isFormValid, setIsFormValid] = useState(true);

    const [onEditForm, setOnEditForm] = useState(false);

    useEffect(() => {
        if (id) {
            dispatch(brandOperations.getBrand(id!));
        }
        if (!isEdit) {
            dispatch(resetBrand());
        }
    }, [id, isEdit, currentLang]);

    //---- CREATING FORM - START ----//

    const customContext = useMemo(() => {
        if (customFields) {
            return [...customFields.filter(x => x.enabled && x.entityType.toLowerCase() === "brand")];
        }
    }, [customFields]);

    const getDefaultCustomFields = () => {
        if (customContext) {

            const customWithDef = customContext.filter(x => {
                if (x.type === "Select") {
                    return !!x.selectOptions?.find(f => f.isDefault);
                } else return !!x.defaultValue;
            });

            if (customWithDef && customWithDef.length >= 1) {
                const output = {};

                customWithDef.forEach(x => {
                    if (x.type === "Select") {
                        const def = x.selectOptions?.find(f => f.isDefault);

                        Object.assign(output, { [x.id]: def?.values[0].text });
                    } else {
                        Object.assign(output, { [x.id]: x.defaultValue });
                    }
                });

                return output;
            }
        }

        return null;
    };

    const defaultCustomFields = useMemo(() => getDefaultCustomFields(), [customContext]);

    const defaultValues = useMemo(() => {

        const out: Brand = {
            id: brand?.id || DEFAULT_BRAND.id,
            externalId: brand?.externalId || DEFAULT_BRAND.externalId,
            createdAt: brand?.createdAt || DEFAULT_BRAND.createdAt,
            name: brand?.name || DEFAULT_BRAND.name,
            enabled: brand?.enabled || DEFAULT_BRAND.enabled,
            customFields: isEdit ? brand?.customFields || defaultCustomFields : defaultCustomFields
        };

        return out;
    }, [brand, defaultCustomFields]);

    useEffect(() => {
        reset(defaultValues);
    }, [defaultValues]);

    const getYupSchema = () => {
        const yupSchema: { [key: string]: Yup.Schema<any> } = {
            name: Yup.string().required(`${translate('organization.form.brands.name')} ${translate('commons.required')}`),
            externalId: Yup.string().required(`${translate('organization.form.brands.id')} ${translate('commons.required')}`)
        };

        return Yup.object().shape(yupSchema);
    };

    const methods = useForm<Brand>({
        mode: "onChange",
        resolver: yupResolver<any>(getYupSchema()),
        defaultValues
    });

    const { getValues, setValue, clearErrors, reset, trigger, setError, watch } = methods;
    //---- CREATING FORM - END ----//

    //---- CUSTOMFIELDS SECTION - START ----//
    const [fieldsWithError, setFieldsWithError] = useState<CustomField[]>([]);

    const [errorInCustom, setErrorInCustom] = useState<boolean>(false);

    const customFieldsValues = watch("customFields");

    const getErrorItem = (name: string, type: "required" | "regEx") => {

        if (customContext) {
            const correctCustom = customContext.find(customField => customField.id === name);

            if (type === "required") {
                return correctCustom!.required;
            } else if (correctCustom!.regEx) {
                return new RegExp(correctCustom!.regEx.slice(1, -1));
            }
        }

        return new RegExp("");

    };

    const removeField = (name: string) => {
        const newFields: CustomField[] = [];

        fieldsWithError.forEach(x => {
            if (x.id !== name) {
                newFields.push(x);
            }

            setFieldsWithError(newFields);
        });
    };

    const addField = (name: string) => {

        let field = fieldsWithError.find(customField => customField.id === name);

        if (!field) {
            const newFields: CustomField[] = fieldsWithError;

            const correctCustom = customContext!.find(customField => customField.id === name);

            newFields.push(correctCustom!);
            setFieldsWithError(newFields);
        }
    };

    useEffect(() => {
        if (fieldsWithError.length === 0) {
            setErrorInCustom(false);
        }
    }, [fieldsWithError]);

    const checkRegEx = (name: string, value: any, actualName: string) => {
        const regEx = getErrorItem(name, "regEx") as RegExp;

        if (regEx && value && !regEx.test(value)) {
            if (name === actualName) {
                setError(`customFields.${name}`, { type: 'regExError', message: '' });
            }
            addField(name);

            setErrorInCustom(true);

        } else {
            clearErrors(`customFields.${name}`);
            removeField(name);
        }
    };

    const getCustomFieldType = (id: string) => {

        const customField = tenant.customFields.find(x => x.enabled && x.entityType.toLowerCase() === "brand" && x.id === id);

        return customField?.type;
    };

    const handleCustomFields = (event: any, dateValue?: string, dateFieldName?: string) => {

        let customFieldList = getValues(`customFields`) || {};

        let newVal = "";

        let fieldName = "";

        if (dateValue && dateFieldName) {
            newVal = dateValue;

            fieldName = dateFieldName;

        } else {
            newVal = event.target.type !== "checkbox" ? event.target.value : (event.target.checked ? "enabled" : "disabled");

            if (getCustomFieldType(event.target.id) === "Currency") {
                newVal = Math.round(convert(Number(event.target.value), currentCurrency.label, CurrencyTypes.EUR, rates)).toString();
            }

            fieldName = event.target.name;

        }

        customFieldList[fieldName] = newVal;

        setValue("customFields", customFieldList);

        if (newVal === "" || (event && event.target.type === "checkbox" && !event.target.checked)) {
            addField(fieldName);
        }

        if (fieldsWithError.length > 0) {
            fieldsWithError.forEach((field) => {
                const name = field.id;

                const required = getErrorItem(name, "required") as boolean;

                const value = getValues(`customFields.${name}`);

                if (required) {
                    if (field.type === 'Checkbox') {
                        if (value !== "enabled") {
                            if (name === fieldName) {
                                setError(`customFields.${name}`, { type: 'required', message: '' });
                            }
                            addField(name);

                            setErrorInCustom(true);
                        } else {
                            clearErrors(`customFields.${name}`);
                            removeField(name);
                        }
                    } else if (!value || value === "") {
                        if (name === fieldName) {
                            setError(`customFields.${name}`, { type: 'required', message: '' });
                        }
                        addField(name);

                        setErrorInCustom(true);
                    } else {
                        checkRegEx(name, value, fieldName);
                    }
                } else {
                    checkRegEx(name, value, fieldName);
                }
            });
        }

    };
    //---- CUSTOMFIELDS SECTION - END ----//

    //---- CHECK FORM VALIDITY - START ----//
    const checkValidity = async () => {

        const schema = getYupSchema();

        if (!errorInCustom) {
            try {
                await schema.validate(getValues(), { abortEarly: false });
                clearErrors();
                setIsFormValid(true);
            } catch (errors: any) {
                setIsFormValid(false);
            }
        } else {
            setIsFormValid(false);
        }
    };

    useEffect(() => {
        checkValidity();
    }, [onEditForm, errorInCustom]);

    const onChangeValidate = async (field: any) => {
        await trigger(field, { shouldFocus: true });
    };
    //---- CHECK FORM VALIDITY - END ----//

    //---- HANDLING MODALS START ----//
    const [isOpenSave, setIsOpenSave] = useState(false);

    const [isOpenSuccess, setIsOpenSuccess] = useState(false);

    const [isOpenError, setIsOpenError] = useState(false);

    const toggleSave = () => {
        isOpenSave ? setIsOpenSave(false) : setIsOpenSave(true);
    };

    const toggleSuccess = () => {
        isOpenSuccess ? setIsOpenSuccess(false) : setIsOpenSuccess(true);
    };

    const toggleError = () => {
        isOpenError ? setIsOpenError(false) : setIsOpenError(true);
    };

    //---- HANDLING MODALS END ----//

    //---- HANDLING SAVE START ----//

    const customFieldRemover = (values: Brand) => {

        if (customFields && customContext) {

            const customActualValues = getValues("customFields") || {};

            customContext.forEach(customField => {
                if (customField.defaultValue && !customActualValues[customField.id]) {
                    customActualValues[customField.id] = customField.defaultValue;
                }
                delete values[customField.id];
            });
        }

        return values;
    };

    const handleSaveBrand = async () => {
        try {
            if (isFormValid) {

                const clean = customFieldRemover(getValues());

                if (clean) {

                    if (isEdit) {
                        await dispatch(brandOperations.updateBrand({ ...clean, id: brand!.id })).unwrap();
                    } else {
                        await dispatch(brandOperations.createBrand(clean as NewBrand)).unwrap();
                    }
                }
            }
            toggleSuccess();
        } catch (e) {
            toggleError();
        }
    };
    //---- HANDLING SAVE END ----//

    return (
        <Page title={`${translate("organization.form.brands.new")}`}>
            {isBrandLoading || (isEdit && !brand) ? <LoadingScreen /> :
                <Container maxWidth={themeStretch ? false : 'lg'}>

                    <ErrorModal
                        toggle={toggleError}
                        toggleSave={toggleSave}
                        isOpen={isOpenError}
                        errorText={`${translate('commons.error')}`}
                    />
                    <SuccessModal
                        toggle={toggleSuccess}
                        toggleSave={toggleSave}
                        isOpen={isOpenSuccess}
                        returnPage={PATH_DASHBOARD.brands.list}
                        successText={`${translate('organization.form.brands.save')}`}
                    />
                    <SaveModal
                        toggle={toggleSave}
                        isOpen={isOpenSave}
                        handleSave={handleSaveBrand}
                        saveText={`${translate('organization.form.brands.saveConfirm')}`}
                    />

                    <HeaderBreadcrumbs
                        heading={!isEdit ? `${translate("organization.form.brands.new")}` : `${translate("organization.form.brands.edit")}`}
                        links={[
                            { name: `${translate("commons.home")}`, href: PATH_DASHBOARD.root },
                            { name: `${translate("organization.form.brands.title")}`, previousPage: 1 },
                            { name: !isEdit ? `${translate("organization.form.brands.new")}` : `${translate("organization.form.brands.edit")}` },
                        ]}
                    />

                    <FormProvider methods={methods}>

                        <Card sx={{ p: 3 }}>
                            <Box
                                sx={{
                                    display: 'grid',
                                    columnGap: 2,
                                    rowGap: 3,
                                    mb: 3,
                                    gridTemplateColumns: { xs: 'repeat(1, 1fr)', sm: (isEdit ? 'repeat(1, 1fr)' : 'repeat(2, 1fr)') },
                                }}
                            >
                                <RHFTextField
                                    name={"name"}
                                    label={`${translate('organization.form.brands.name')}`}
                                    onChangeVal={onChangeValidate}
                                    onEditForm={onEditForm}
                                    setOnEditForm={setOnEditForm}
                                    required
                                />

                                {!isEdit &&
                                    <RHFTextField
                                        name={"externalId"}
                                        label={`${translate('commons.externalId')}`}
                                        //disabled={isEdit}
                                        onChangeVal={onChangeValidate}
                                        onEditForm={onEditForm}
                                        setOnEditForm={setOnEditForm}
                                        required={!isEdit}
                                    />
                                }

                            </Box>

                            {/*                             {(customContext) && (
                                <CustomFieldFormRenderer
                                    context={"brand"}
                                    handleCustomFields={handleCustomFields}
                                    values={customFieldsValues}
                                    columnGap={2}
                                    repeat={'repeat(2, 1fr)'}
                                    //isDisabled={isDisabled}
                                    //section={["General"]}
                                    onChangeValidate={onChangeValidate}
                                    setOnEditForm={setOnEditForm}
                                    onEditForm={onEditForm}
                                    setErrorInCustom={setErrorInCustom}
                                    setFieldsWithError={setFieldsWithError}
                                />
                            )} */}

                            <Box sx={{ mt: 3, display: 'flex', justifyContent: 'flex-end', gap: 2 }}>
                                <Button variant='soft' color="inherit" onClick={() => navigate(-1)}>
                                    {`${translate("commons.cancel")}`}
                                </Button>
                                <LoadingButton
                                    variant="contained"
                                    disabled={!isFormValid}
                                    onClick={toggleSave}
                                >
                                    {!isEdit ? `${translate("organization.form.brands.createButton")}` : `${translate("organization.form.brands.updateButton")}`}
                                </LoadingButton>
                            </Box>
                        </Card>

                    </FormProvider>
                </Container>}
        </Page>
    );
}