import { ClusterPermissionTypes, OrganizationPermissionTypes } from 'src/@types/permissions';
import { useAuth } from '../appHooks/useAuth';
import Error403 from './errors/403';
import { hasPermissions } from 'src/utils/user';
import { useUserOrganizationContext } from 'src/contexts/UserOrganizationContext';

// ----------------------------------------------------------------------

type PermissionBasedGuardProp = {
  hasContent?: boolean;
  permissions?: (ClusterPermissionTypes | OrganizationPermissionTypes)[];
  absoluteVerification?: boolean;
  children: React.ReactNode;
};

export default function PermissionBasedGuard({
  hasContent,
  permissions,
  absoluteVerification,
  children,
}: PermissionBasedGuardProp) {
  const { user } = useAuth();

  const { organization } = useUserOrganizationContext();

  const currentRoles = user?.roles; // admin;

  if (
    typeof permissions === 'undefined' ||
    !hasPermissions(permissions, currentRoles, absoluteVerification ?? false) &&
    !hasPermissions(permissions, organization.roles, absoluteVerification ?? false)
  ) {
    return hasContent ? (
      <Error403 />
    ) : null;
  }

  return <>{children}</>;
}