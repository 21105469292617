import { Box, Container, styled, Typography } from '@mui/material';
import { useNavigate } from 'react-router';
import { useLocales } from 'src/locales';
import Page from 'src/appComponents/Page';
import { useEffect } from 'react';
import { PATH_DASHBOARD } from 'src/routes/paths';
import useTenant from 'src/appHooks/useTenant';

// ----------------------------------------------------------------------
const ContentStyle = styled('div')(({ theme }) => ({
    maxWidth: 480,
    margin: 'auto',
    minHeight: '100vh',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    padding: theme.spacing(12, 0),
}));
// ----------------------------------------------------------------------

export default function Error503Maintenance() {

    const navigate = useNavigate();

    const { translate } = useLocales();

    const { startFrom, endTo, status } = useTenant();

    const getMessage = () => {
        let message = "";

        if (startFrom && endTo) message = `${translate('maintenance.messages.complete')}`;
        else if (startFrom) message = `${translate('maintenance.messages.start')}`;
        else if (endTo) message = `${translate('maintenance.messages.end')}`;

        return message.replace("{0}", (startFrom || "").toLocaleString()).replace("{1}", (endTo || "").toLocaleString());
    };

    useEffect(() => {
        if (status !== 503) navigate(PATH_DASHBOARD.root);

        const timeoutId = setTimeout(() => {
            window.location.reload();
        }, 30000);

        return () => clearTimeout(timeoutId);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Page title={`${translate('maintenance.title')}`} sx={{ bgcolor: (theme) => theme.palette.divider }}>
            <Container >
                <ContentStyle sx={{ textAlign: 'center', alignItems: 'center' }}>
                    <Box>
                        <Typography variant="h3" paragraph>
                            {`${translate('maintenance.title')}`}
                        </Typography>
                    </Box>

                    <Box>
                        <Typography sx={{ color: 'text.secondary' }} paragraph>
                            {getMessage()}
                        </Typography>
                    </Box>

                    <Box sx={{ my: 5, display: 'flex', justifyContent: 'center' }}>
                        <img width='75%' src="/assets/illustrations/characters/character_5.png" alt="waiting" />
                    </Box>
                </ContentStyle>
            </Container>
        </Page>
    );
}