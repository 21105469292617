import Page from "src/appComponents/Page";
import LoadingScreen from "src/appComponents/loading-screen";
import HeaderBreadcrumbs from '../../../../components/custom-breadcrumbs';
import { Container, Stack } from "@mui/system";
import ErrorModal from "src/components/modals/ErrorModal";
import SuccessModal from "src/components/modals/SuccessModal";
import { PATH_DASHBOARD } from "src/routes/paths";
import { useLocales } from "src/locales";
import { useEffect, useState } from "react";
import { useSettingsContext } from "src/components/settings";
import { useNavigate, useParams } from "react-router";
import { DEFAULT_ROLE, Role } from "src/@types/role";
import { administrationRoleDetail, administrationRoleUpdate } from "src/services/roleServices";
import { Box, Button, Card, FormHelperText, MenuItem, TextField } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { ClusterPermissionsList, ClusterPermissionTypes } from "src/@types/permissions";
import PermissionTable from "src/components/table/PermissionTable";

export default function OrganizationRoleEdit() {

    const { translate, currentLang } = useLocales();

    const [isLoading, setIsLoading] = useState<boolean>(true);

    const [type, setType] = useState<string>('Users');

    const [openSucc, setOpenSucc] = useState(false);

    const [openErr, setOpenErr] = useState(false);

    const { themeStretch } = useSettingsContext();

    const { id } = useParams();

    const navigate = useNavigate();

    const [role, setRole] = useState<Role>(DEFAULT_ROLE);

    useEffect(() => {
        async function getRole(id: string) {
            try {
                const response = await administrationRoleDetail(id);

                setRole(response.data);
            }
            catch (e) {
                console.error(e);
            }
        }

        let roleId = id ? id : '';
        getRole(roleId);
    }, [id, currentLang]);

    useEffect(() => {
        if (role.id) {
            setIsLoading(false);
        }
    }, [role.id, currentLang]);

    const handleSave = async () => {
        const param = {
            name: role.name,
            permissions: role.permissions,
            id: role.id,
            enabled: role.enabled
        };

        try {
            await administrationRoleUpdate(param);
            setOpenSucc(true);
        }
        catch {
            setOpenErr(true);
        }
    };

    return (
        <Page title={`${translate('role.form.edit.admin')}`}>
            {isLoading ? (
                <LoadingScreen />
            ) : (
                <Container maxWidth={themeStretch ? false : 'lg'}>

                    <SuccessModal
                        isOpen={openSucc}
                        returnPage={PATH_DASHBOARD.tenant.role.admin.list}
                        successText={`${translate('role.messages.updated')}`}
                        toggle={() => setOpenSucc(p => !p)}
                    />
                    <ErrorModal
                        isOpen={openErr}
                        errorText={`${translate('commons.error')}`}
                        toggle={() => setOpenErr(p => !p)}
                    />

                    <HeaderBreadcrumbs
                        heading={role.name}
                        links={[
                            { name: `${translate('commons.home')}`, href: PATH_DASHBOARD.root },
                            { name: `${translate('role.title_plural')}`, href: PATH_DASHBOARD.tenant.role.admin.list },
                            { name: `${translate('commons.edit')}` },
                            { name: `${role.name}` },
                        ]}
                    />

                    <Card sx={{ p: 3 }}>
                        <Stack
                            spacing={2}
                            direction={{ xs: 'column', sm: 'row' }}
                            sx={{ py: 2.5 }}
                        >
                            <TextField
                                select
                                fullWidth
                                id='tipoRuolo'
                                SelectProps={{
                                    MenuProps: {
                                        sx: { '& .MuiPaper-root': { maxHeight: 260 } },
                                    },
                                }}
                                sx={{
                                    maxWidth: { sm: 240 },
                                    textTransform: 'capitalize',
                                }}
                                value={type}
                                onChange={e => setType(e.target.value)} >
                                {Object.keys(ClusterPermissionsList).map((type) => (
                                    <MenuItem
                                        key={type}
                                        value={type}
                                        sx={{
                                            mx: 1,
                                            my: 0.5,
                                            borderRadius: 0.75,
                                            typography: 'body2',
                                            textTransform: 'capitalize',
                                        }}
                                    >
                                        {`${translate(`role.type.${type[0].toLowerCase() + type.slice(1)}`)}`}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </Stack>

                        <PermissionTable
                            PermissionT={ClusterPermissionsList}
                            onChange={(per) => setRole(old => ({ ...old, permissions: (per as ClusterPermissionTypes[]) }))}
                            type={type}
                            isCluster
                            defaultValue={role.permissions}
                        />

                        <Box hidden={role.permissions.length > 0}>
                            <FormHelperText sx={{ pb: 1.2, textAlign: "right !important" }} error>
                                {`${translate('role.validation.oneRole')}`}
                            </FormHelperText>
                        </Box>

                        <Box sx={{ mt: 3, display: 'flex', justifyContent: 'flex-end', gap: 2 }}>
                            <Button variant="soft" color="inherit" onClick={() => navigate(PATH_DASHBOARD.tenant.role.admin.list)}>
                                {`${translate("commons.cancel")}`}
                            </Button>
                            <LoadingButton disabled={role.permissions.length === 0} type="submit" variant="contained" onClick={handleSave}>
                                {`${translate("role.form.update")}`}
                            </LoadingButton>
                        </Box>

                    </Card>
                </Container>)}
        </Page>

    );
}