import { createAsyncThunk } from "@reduxjs/toolkit";
import { startItemsLoading, startLoading, startLogsLoading, startQuantityLoading } from "./request-slices";
import { RequestFilters, RequestNew, RejectOptions, ApproveOptions, PrintOptions, WarehouseAcceptOptions, CloseAnomalyOptions, ItemFilters, RequestUpdate } from "src/@types/request";
import { customerClaim, approve, create, detail, detailBarcode, maxQuantity, pickedUp, printed, rejectByBackoffice, rejectByWarehouse, remove, search, searchStatistics, warehouseAccept, returnToCustomer, closeAnomaly, searchLogs, searchVendorItems, searchCarrier, searchCarrierStatistics, searchCustomerItems, refused, refusedPrinted, refusedPickedUp, labelRestore, approveRestore, toBeAmended, update, paid } from "src/services/requestsServices";
import removeEmptyKeys from "src/utils/removeEmptyKeys";

const createRequest = createAsyncThunk(
    'requests/createRequest',
    async (newRequest: RequestNew, { rejectWithValue, dispatch }) => {
        dispatch(startLoading());
        try {
            const { data } = await create(newRequest);

            if (!data)
                throw new Error('Something went wrong');

            return data;
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
);

const getRequest = createAsyncThunk(
    'requests/getRequest',
    async (params: { id: string, anomaly?: boolean }, { rejectWithValue, dispatch }) => {
        dispatch(startLoading());
        try {
            const { data } = await detail(params.id);

            if (!data)
                throw new Error('Something went wrong');

            return [data, params.anomaly];
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
);

const searchRequests = createAsyncThunk(
    'requests/searchRequests',
    async (options: { options: RequestFilters, isMine: boolean }, { rejectWithValue, dispatch }) => {
        dispatch(startLoading());
        try {
            const { data } = await search({ option: options.options, isMine: options.isMine });

            if (!data) {
                throw new Error('Something went wrong');
            }

            return data;
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
);

const searchCarrierRequests = createAsyncThunk(
    'requests/searchCarrierRequests',
    async (options: RequestFilters, { rejectWithValue, dispatch }) => {
        dispatch(startLoading());
        try {
            const { data } = await searchCarrier(options);

            if (!data) {
                throw new Error('Something went wrong');
            }

            return data;
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
);

const searchStatistic = createAsyncThunk(
    'requests/searchStatistic',
    async (options: { options: RequestFilters, isMine: boolean }, { rejectWithValue, dispatch }) => {
        dispatch(startLoading());
        try {
            const { data } = await searchStatistics({ option: options.options, isMine: options.isMine });

            if (!data) {
                throw new Error('Something went wrong');
            }

            return data;
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
);

const searchCarrierStatistic = createAsyncThunk(
    'requests/searchCarrierStatistic',
    async (options: RequestFilters, { rejectWithValue, dispatch }) => {
        dispatch(startLoading());
        try {
            const { data } = await searchCarrierStatistics(options);

            if (!data) {
                throw new Error('Something went wrong');
            }

            return data;
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
);

const getMaxQuantity = createAsyncThunk(
    'requests/getMaxQuantity',
    async (options: { orderId: string, productId: string }, { rejectWithValue, dispatch }) => {
        dispatch(startQuantityLoading());
        try {
            const { data } = await maxQuantity(options);

            if (!data) {
                throw new Error('Something went wrong');
            }

            return data;
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
);

const removeRequest = createAsyncThunk(
    'requests/removeRequest',
    async (id: string, { rejectWithValue }) => {
        try {
            const { data, status } = await remove(id);

            if (!data && status !== 204) {
                throw new Error('Something went wrong');
            }

            return data;
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
);

const rejectRequestWarehouse = createAsyncThunk(
    'requests/rejectRequestWarehouse',
    async (options: RejectOptions, { rejectWithValue }) => {
        try {
            const { data, status } = await rejectByWarehouse(options);

            if (!data && status !== 204) {
                throw new Error('Something went wrong');
            }
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
);

const rejectRequestBackoffice = createAsyncThunk(
    'requests/rejectRequestBackoffice',
    async (options: RejectOptions, { rejectWithValue }) => {
        try {
            const { data, status } = await rejectByBackoffice(options);

            if (!data && status !== 204) {
                throw new Error('Something went wrong');
            }
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
);

const approveRequest = createAsyncThunk(
    'requests/approveRequest',
    async (options: ApproveOptions, { rejectWithValue }) => {
        try {
            const { data, status } = await approve(options);

            if (!data && status !== 204) {
                throw new Error('Something went wrong');
            }
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
);

const printedRequest = createAsyncThunk(
    'requests/printedRequest',
    async (option: PrintOptions, { rejectWithValue }) => {
        try {
            const { data, status } = await printed(option);

            if (!data && status !== 204) {
                throw new Error('Something went wrong');
            }
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
);

const pickedUpRequest = createAsyncThunk(
    'requests/pickedUpRequest',
    async (option: PrintOptions, { rejectWithValue }) => {
        try {
            const { data, status } = await pickedUp(option);

            if (!data && status !== 204) {
                throw new Error('Something went wrong');
            }
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
);

const warehouseAcceptRequest = createAsyncThunk(
    'requests/warehouseAcceptRequest',
    async (option: WarehouseAcceptOptions, { rejectWithValue }) => {
        try {
            const { data, status } = await warehouseAccept(option);

            if (!data && status !== 204) {
                throw new Error('Something went wrong');
            }
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
);

const getRequestBarcode = createAsyncThunk(
    'requests/getRequestBarcode',
    async (id: string, { rejectWithValue, dispatch }) => {
        dispatch(startLoading());
        try {
            const { data } = await detailBarcode(id);

            if (!data)
                throw new Error('Something went wrong');

            return data;
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
);

const returnToCustomerOp = createAsyncThunk(
    'requests/returnToCustomer',
    async (options: RejectOptions, { rejectWithValue }) => {
        try {
            const { data, status } = await returnToCustomer(options);

            if (!data && status !== 204) {
                throw new Error('Something went wrong');
            }
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
);

const refusedOp = createAsyncThunk(
    'requests/refused',
    async (options: RejectOptions, { rejectWithValue }) => {
        try {
            const { data, status } = await refused(options);

            if (!data && status !== 204) {
                throw new Error('Something went wrong');
            }
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
);

const refusedPrintedOp = createAsyncThunk(
    'requests/refusedPrinted',
    async (options: RejectOptions, { rejectWithValue }) => {
        try {
            const { data, status } = await refusedPrinted(options);

            if (!data && status !== 204) {
                throw new Error('Something went wrong');
            }
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
);

const refusedPickedUpOp = createAsyncThunk(
    'requests/refusedPickedUp',
    async (options: RejectOptions, { rejectWithValue }) => {
        try {
            const { data, status } = await refusedPickedUp(options);

            if (!data && status !== 204) {
                throw new Error('Something went wrong');
            }
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
);

const closeAnomalyOp = createAsyncThunk(
    'requests/closeAnomaly',
    async (options: CloseAnomalyOptions, { rejectWithValue }) => {
        try {
            const { data, status } = await closeAnomaly(options);

            if (!data && status !== 204) {
                throw new Error('Something went wrong');
            }
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
);

const getRequestsLogs = createAsyncThunk(
    'requests/getRequestsLogs',
    async (options: { id: string, filters: any }, { rejectWithValue, dispatch }) => {
        dispatch(startLogsLoading());
        try {

            const { data } = await searchLogs(options.id, removeEmptyKeys(options.filters));

            return data;
        } catch (err) {
            return rejectWithValue(err.message);
        }
    }
);

const searchVendorReturnItems = createAsyncThunk(
    'requests/searchVendorReturnItems',
    async (options: ItemFilters, { rejectWithValue, dispatch }) => {
        dispatch(startItemsLoading());
        try {

            const { data, status } = await searchVendorItems(options);

            if (!data && status !== 204) {
                throw new Error('Something went wrong');
            }

            return data;
        } catch (err) {
            return rejectWithValue(err.message);
        }
    }
);

const searchCustomerReturnItems = createAsyncThunk(
    'requests/searchCustomerReturnItems',
    async (options: ItemFilters, { rejectWithValue, dispatch }) => {
        dispatch(startItemsLoading());
        try {

            const { data, status } = await searchCustomerItems(options);

            if (!data && status !== 204) {
                throw new Error('Something went wrong');
            }

            return data;
        } catch (err) {
            return rejectWithValue(err.message);
        }
    }
);

const restoreLabel = createAsyncThunk(
    'orders/restoreLabel',
    async (options: RejectOptions, { rejectWithValue }) => {
        try {
            const { id, ...rest } = options;

            const { data, status } = await labelRestore(id, rest);

            if (!data && status > 400) {
                throw new Error('Something went wrong');
            }
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
);

const restoreApprove = createAsyncThunk(
    'orders/restoreApprove',
    async (options: RejectOptions, { rejectWithValue }) => {
        try {
            const { id, ...rest } = options;

            const { data, status } = await approveRestore(id, rest);

            if (!data && status > 400) {
                throw new Error('Something went wrong');
            }
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
);

const toBeAmend = createAsyncThunk(
    'orders/toBeAmend',
    async (options: RejectOptions, { rejectWithValue }) => {
        try {
            const { id, ...rest } = options;

            const { data, status } = await toBeAmended(id, rest);

            if (!data && status > 400) {
                throw new Error('Something went wrong');
            }
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
);

const paidRequest = createAsyncThunk(
    'requests/paidRequest',
    async (options: RejectOptions, { rejectWithValue }) => {
        try {
            const { id, ...rest } = options;

            const { data, status } = await paid(id, rest);

            if (!data && status > 400) {
                throw new Error('Something went wrong');
            }

            return rest.note;
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
);

const updateRequest = createAsyncThunk(
    'orders/updateRequest',
    async (params: { id: string, request: RequestUpdate }, { rejectWithValue }) => {
        try {
            const { id, request } = params;

            const { data, status } = await update(id, request);

            if (!data && status > 400) {
                throw new Error('Something went wrong');
            }
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
);

const customerComplaint = createAsyncThunk(
    'requests/customerComplaint',
    async (options: any, { rejectWithValue }) => {
        try {
            const { data, status } = await customerClaim(options);

            if (!data && status !== 204) {
                throw new Error('Something went wrong');
            }
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
);

const requestOperations = {
    createRequest,
    getRequest,
    searchRequests,
    searchStatistic,
    getMaxQuantity,
    removeRequest,
    approveRequest,
    rejectRequestWarehouse,
    rejectRequestBackoffice,
    printedRequest,
    pickedUpRequest,
    warehouseAcceptRequest,
    getRequestBarcode,
    closeAnomalyOp,
    returnToCustomerOp,
    getRequestsLogs,
    searchVendorReturnItems,
    searchCustomerReturnItems,
    searchCarrierStatistic,
    searchCarrierRequests,
    refusedPickedUpOp,
    refusedPrintedOp,
    refusedOp,
    restoreApprove,
    restoreLabel,
    updateRequest,
    toBeAmend,
    paidRequest,
    customerComplaint
};

export default requestOperations;
