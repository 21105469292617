import { TableContainer, Table, TableBody, TableRow, TableCell, Checkbox, Switch } from "@mui/material";
import { cloneDeep } from "lodash";
import { useMemo, useState } from "react";
import { OrganizationPermissionTypes, ClusterPermissionTypes } from "src/@types/permissions";
import { useLocales } from "src/locales";
import { permDependencies } from "src/pages/dashboard/role/adminRoles/NewAdminRole";
import { lokalizableRoleStr } from "src/pages/dashboard/role/utils";
import { scrollBarStyle } from "../scrollbar/Scrollbar";
import TableHeadCustom from "./TableHeadCustom";
import useTable from "./useTable";

interface PermissionTableProps {
    PermissionT: Record<string, (OrganizationPermissionTypes | ClusterPermissionTypes)[]>
    type: string,
    isCluster?: boolean,
    isAll?: boolean
    checkbox?: boolean
    onChange: (permissons: string[]) => void,
    defaultValue?: string[]
}

export default function PermissionTable({ PermissionT, type, isCluster, checkbox, onChange, defaultValue, isAll }: PermissionTableProps) {

    const { translate } = useLocales();

    const { dense } = useTable();

    const [permissions, setPermissions] = useState<string[]>(defaultValue ?? []);

    const handleChange = (name: string) => {

        let checked = false;

        if (!permissions.includes(name))
            checked = true;

        let perm: string[];

        if (checkbox) {
            let permissionList: string[] = cloneDeep(permissions);

            if (permissionList.includes(name))
                permissionList = permissionList.filter((v) => v !== name);
            else
                permissionList.push(name);

            setPermissions(permissionList);

            onChange(permissionList);
        }
        else if (checked) {

            if (!isAll) {
                let permissionFather = "";

                if (name.includes("Logs")) {
                    permissionFather = "Logs";
                } else if (name.includes("Create")) {
                    permissionFather = "Create";
                } else if (name.includes("Edit")) {
                    permissionFather = "Edit";
                }

                if (permissionFather) {
                    perm = permDependencies(name, permissionFather, type, isCluster ? "Cluster" : "Organization");
                } else {
                    perm = [name];
                }
            }
            setPermissions((p) => {
                const permList = Array.from(new Set([...p, ...perm]));

                onChange(permList);

                return permList;
            });
        }
        else {
            perm = [...permissions];

            perm.splice(permissions.indexOf(name), 1);

            setPermissions(perm);

            onChange(perm);
        }
    };

    const TABLE_HEAD = useMemo(() => checkbox ?
        [
            { id: 'enable', align: 'left', width: '10vw', label: `${translate('commons.enabled')}` },
            { id: 'permissionName', label: `${translate('role.form.permissionName')}`, align: 'left' },
            { id: 'description', label: `${translate('commons.description')}`, align: 'left' },
        ] : [
            { id: 'permissionName', label: `${translate('role.form.permissionName')}`, align: 'left' },
            { id: 'description', label: `${translate('commons.description')}`, align: 'left' },
            { id: 'enable', label: `${translate('commons.enabled')}`, align: 'left' }
        ], [checkbox, translate]);

    return (
        <TableContainer sx={[{ minWidth: 800, maxHeight: 650, position: 'relative' }, scrollBarStyle]}>

            <Table stickyHeader size={dense ? 'small' : 'medium'}>
                <TableHeadCustom
                    headLabel={TABLE_HEAD}
                    rowCount={PermissionT[type].length}
                />
                <TableBody>
                    {PermissionT[type]
                        .map((role: OrganizationPermissionTypes | ClusterPermissionTypes) => (
                            <TableRow key={role} hover onClick={() => handleChange(role)}>
                                {checkbox &&
                                    <TableCell align="left">
                                        <Checkbox checked={permissions.includes(role)} />
                                    </TableCell>}
                                <TableCell >
                                    {`${translate(`role.permission.${lokalizableRoleStr(role)}.title`)}`}
                                </TableCell>
                                <TableCell align="left" >
                                    {`${translate(`role.permission.${lokalizableRoleStr(role)}.description`)}`}
                                </TableCell>
                                {!checkbox &&
                                    <TableCell colSpan={1} align="left">
                                        <Switch checked={permissions.includes(role)} name={role} />
                                    </TableCell>}
                            </TableRow>
                        ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
}