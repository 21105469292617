import { Stack, TextField, MenuItem, Button } from '@mui/material';
import useLocales from "src/appHooks/useLocales";
import { useState } from 'react';
import Iconify from 'src/components/iconify';
import { CustomFieldSearchFilters } from 'src/@types/customField';

type FilterProps = {
  optionsFields: CustomFieldSearchFilters[];
  filterField: string;
  setFilterField: (value: string) => void;
};

export default function CustomFieldTableToolbar({
  filterField,
  optionsFields,
  setFilterField
}: FilterProps) {

  const [filterFieldText, setFilterFieldText] = useState(filterField);

  const handleFilterField = (filterField: string) => {
    setFilterFieldText(filterField);
  };

  const setParams = () => {
    setFilterField(filterFieldText);
  };

  const { translate } = useLocales();

  return (
    <Stack spacing={2} direction={{ xs: 'column', sm: 'row' }} sx={{ py: 2.5, px: 2 }}>
      <TextField
        fullWidth
        select
        label={`${translate("returns.searchType")}`}
        value={filterFieldText}
        onChange={(event) => handleFilterField(event.target.value)}
        SelectProps={{
          MenuProps: {
            sx: { '& .MuiPaper-root': { maxHeight: 260 } },
          },
        }}
        sx={{
          maxWidth: { sm: 240 },
          textTransform: 'capitalize',
        }}
      >
        {optionsFields.map((option) => (
          <MenuItem
            key={option.key}
            value={option.key}
            sx={{
              mx: 1,
              my: 0.5,
              borderRadius: 0.75,
              typography: 'body2',
              textTransform: 'capitalize',
            }}
          >
            {option.label}
          </MenuItem>
        ))}
      </TextField>

      <Button
        startIcon={<Iconify icon={'bx:search-alt'} />}
        onClick={setParams}
      />
    </Stack>
  );
}
