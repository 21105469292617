import { Box, Button, Card, Divider, Modal, Stack, Typography } from '@mui/material';
import { useLocales } from 'src/locales';
import CancelIcon from '@mui/icons-material/Cancel';
import KeyboardReturnIcon from '@mui/icons-material/KeyboardReturn';
import PublishedWithChangesIcon from '@mui/icons-material/PublishedWithChanges';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import FactCheckIcon from '@mui/icons-material/FactCheck';
import BrokenImageIcon from '@mui/icons-material/BrokenImage';
import { DEFAULT_ALERT, GreenStatusType, GreenStatusesArr, RedStatusType, RedStatusesArr, Alert, Request, YellowStatusType, YellowStatusesArr, Warranty, DEFAULT_WARRANTY_AR, DEFAULT_WARRANTY_NAR, RequestUpdate } from 'src/@types/request';
import useResponsive from 'src/hooks/useResponsive';
import { useEffect, useMemo, useRef, useState } from 'react';
import Label, { LabelColor } from 'src/components/label';
import { DeliveryNote, Invoice } from 'src/@types/orders';
import dayjs from 'dayjs';
import { dispatch } from 'src/redux/store';
import { useParams } from 'react-router';
import { PATH_DASHBOARD } from 'src/routes/paths';
import { requestOperations } from 'src/redux/request';
import ErrorModal from 'src/components/modals/ErrorModal';
import ReturnLabel from './ReturnLabel';
import LoadingScreen from 'src/appComponents/loading-screen/LoadingScreen';
import SuccessModal from 'src/components/modals/SuccessModal';
import { DeleteForever } from '@mui/icons-material';
import PartsDetail from 'src/components/request-utils/PartsDetail';
import { getAlert, mediaDelete } from 'src/services/requestsServices';
import { AmendNotesAlert, ApproverNotesAlert, FrequencyFeeAlert, HasSurveillanceAlert, InvoiceFeeAlert, RefusedNotesAlert } from 'src/components/request-utils/FeesAndNotes';
import { CustomerDeliveryDetail } from './CustomerDeliveryDetail';
import { DamagedQuality, IncompleteCustomerForm } from './IncompleteCustomerForm';
import { ApprovedTypeDetail, BigAndHeavyDetail, QuantityDetail } from 'src/components/request-utils/LabelDetail';
import { DocsIds } from 'src/pages/dashboard/requests/RequestDetail';
import { FileWithSection, handleUpload } from 'src/appUtils/requestUpload';

interface ReturnDetailProp {
  request: Request,
  docsIds: DocsIds[],
  invoices: Invoice[],
  deliveryNotes: DeliveryNote[],
}

export default function ReturnDetail({ request, docsIds, invoices, deliveryNotes }: ReturnDetailProp) {

  const { translate } = useLocales();

  const isDesktop = useResponsive('up', 'sm');

  const componentRef = useRef(null);

  const [openError, setOpenError] = useState(false);

  const [openSuccess, setOpenSuccess] = useState(false);

  const [openConfirm, setOpenConfirm] = useState(false);

  const [alerts, setAlerts] = useState<Alert>(DEFAULT_ALERT);

  const [deleteList, setDeleteList] = useState<string[]>([]);

  const { id } = useParams();

  useEffect(() => {
    if (
      docsIds.length > 0 &&
      id === request.id &&
      request.items.length > 0 &&
      request.status === 'Created' &&
      request.items.length === 1 &&
      request.requestType !== 'WrongShipment' &&
      request.requestType !== 'Warranty'
    ) {
      getAlert({ orderId: docsIds[0].orderId, productId: request.items[0].product.id }).then(
        (val) => setAlerts(val.data)
      );
    } else if (request.approverNotes) {
      setAlerts({
        frequencyFee: request.approverNotes.returnFee,
        invoiceFee: request.approverNotes.invoiceDataFee,
        hasSurveillance: false,
      });
    }
  }, [id, request, docsIds]);

  const docLabels = useMemo(() => {
    let docs = [];

    let items = request.items.map(i => i.orderId).concat(request.exceededItems.map(i => i.order?.id || ""));

    docs = items.map(v => {
      let doc = docsIds.find(d => d.orderId === v);

      return {
        orderId: doc?.orderId || "",
        invoiceExternalId: invoices.find(inv => inv.id === doc?.invoiceId)?.externalId || "",
        deliveryNoteExternalId: deliveryNotes.find(deli => deli.id === doc?.deliveryNoteId)?.externalId || ""
      };
    });

    return docs;
  }, [deliveryNotes, docsIds, invoices, request]);

  const getIcon = () => {
    switch (request.requestType) {
      case 'NewPart':
        return <PublishedWithChangesIcon />;
      case 'DamagedPart':
        return <CancelIcon />;
      case 'WrongShipment':
        return <LocalShippingIcon />;
      case 'Core':
        return <KeyboardReturnIcon />;
      case 'Quality':
        return <FactCheckIcon />;
      case 'Warranty':
        return <BrokenImageIcon />;
      default:
        return <></>;
    }
  };

  const handleRemove = async () => {
    setOpenConfirm(false);
    try {
      await dispatch(requestOperations.removeRequest(request.id)).unwrap();
      setOpenSuccess(true);
    } catch {
      setOpenError(true);
    }
  };

  //----------------------------- AMEND SECTION
  const isAmend = useMemo(() => request.status === 'ToBeAmended', [request]);

  const isAR = useMemo(() => request?.requestType === "Warranty" && request.warranty && request.warranty.invoices.length === 1, [request]);

  const [isUploadLoading, setIsUploadLoading] = useState(false);

  const [warranty, setWarranty] = useState<Warranty>(request?.warranty || (isAR ? DEFAULT_WARRANTY_AR : DEFAULT_WARRANTY_NAR));

  const [damagedQuality, setDamagedQuality] = useState<DamagedQuality>({
    medias: [],
    boolCheck: false,
    notes: request.note || ""
  });

  const [files, setFiles] = useState<Record<string, (File & { section?: string } | null)>>({});

  const handleAmend = async () => {
    setOpenConfirm(false);
    try {
      const updatedRequest: RequestUpdate = {
        note: (request.requestType === "DamagedPart" || request.requestType === "Quality") ? damagedQuality.notes : request.note,
        requestReason: request.requestReason || null,
        deliveryCompanyId: request.deliveryOrganization.id,
        warranty: (request.requestType === "Warranty") ? warranty : null,
        customFields: request.customFields,
        tags: request.tags
      };

      await dispatch(requestOperations.updateRequest({ id: request.id, request: updatedRequest })).unwrap();

      const promises = deleteList.map((media) => mediaDelete(request.id, media || ""));

      await Promise.all(promises);

      if (files) {
        const updatedFiles: FileWithSection[] = Object.entries(files).map(([section, file]) => {
          return Object.assign(file as FileWithSection, { section });
        });

        setIsUploadLoading(true);
        await handleUpload(updatedFiles, request.id, setIsUploadLoading);
      }

      setOpenSuccess(true);
    } catch {
      setOpenError(true);
    }
  };
  //-----------------------------

  const getStatusColor = (status: string): LabelColor => {
    let color = 'default';

    if (status) {
      if (GreenStatusesArr.includes(status as GreenStatusType)) color = 'success';
      else if (RedStatusesArr.includes(status as RedStatusType)) color = 'error';
      else if (YellowStatusesArr.includes(status as YellowStatusType)) color = 'warning';
    }

    return color as LabelColor;
  };

  const reload = () => {
    setOpenSuccess((p) => !p);
    dispatch(requestOperations.getRequest({ id: request.id }));
  };

  const allQuantities = useMemo(() => {
    let counter = 0;

    if (request.items.length > 0)
      request.items.forEach((item) => (counter += item.requiredQuantity || item.approvedQuantity));
    if (request.missingItems.length > 0)
      request.missingItems.forEach(
        (item) => (counter += item.requiredQuantity || item.approvedQuantity)
      );
    if (request.exceededItems.length > 0)
      request.exceededItems.forEach((item) => (counter += item.quantity));

    return counter;
  }, [request]);

  const isMissingParts = useMemo(() => request.requestType === 'WrongShipment' && request.requestReason === 'MissingParts', [request]);

  return (
    <Box>
      {isUploadLoading ? (<LoadingScreen />) : (
        <Box sx={{ mt: 3 }}>
          <Box sx={{ display: 'none' }}>
            <div ref={componentRef} style={{ paddingTop: '20px' }}>
              <style type="text/css" media="print">
                {'@page { size: landscape; }'}
              </style>
              <ReturnLabel
                request={request}
                docExternalId={docLabels}
              />
            </div>
          </Box>

          <ConfirmModal
            isOpen={openConfirm}
            isAmend={isAmend}
            submit={isAmend ? handleAmend : handleRemove}
            toggle={() => setOpenConfirm((p) => !p)}
          />
          <SuccessModal
            successText={""}
            isOpen={openSuccess}
            returnPage={PATH_DASHBOARD.request.customer.list}
            toggle={() => reload()}
          />
          <ErrorModal
            isOpen={openError}
            errorText={`${translate('commons.error')}`}
            toggle={() => setOpenError((prev) => !prev)}
          />

          <Card>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                p: 2
              }}
            >
              <Stack>
                <Box
                  sx={{
                    display: 'inline-flex',
                    justifyContent: 'flex-start',
                    alignItems: "flex-start",
                    flexDirection: isDesktop ? "row" : "column",
                    gap: 1.5, mb: 1
                  }}
                >
                  <Box>
                    <Typography variant="h4">
                      {`${translate('returns.filter.idRequest')} ${request.prettyName}`}
                    </Typography>

                    <Typography variant="body2">
                      {`${translate(`commons.createdOn`)} `}
                      <b>{dayjs(request.createdOn).format('DD MMM YYYY, HH:mm')}</b>
                    </Typography>
                  </Box>

                  <Label
                    color={getStatusColor(request.status)}
                    sx={{ textTransform: 'uppercase', ml: 2, mt: 1 }}>
                    {`${translate(`commons.order.returnRequest.${request.status}`)}`}
                  </Label>
                </Box>

                <Typography
                  variant="h6"
                  sx={{
                    mb: isDesktop ? 1 : 0,
                    mt: isDesktop ? 1 : 3,
                    display: 'flex',
                    alignContent: 'center',
                    gap: 2
                  }}
                >
                  {getIcon()}
                  {`${translate(`request.${request.requestType}`)}`}
                  {request.requestReason && ` - ${translate(`request.selectReason.${request.requestReason[0].toLowerCase() + request.requestReason.slice(1)}`)}`}
                </Typography>
              </Stack>

              <Box
                sx={{
                  display: 'flex', gap: 2,
                  alignContent: 'center',
                  flexDirection: isDesktop ? 'row' : 'column',
                }}
              >
                {request.approverNotes && request.approverNotes.approvedType && (
                  <ApprovedTypeDetail approvedType={request.approverNotes.approvedType} />
                )}

                {(request.items[0]?.bigAndHeavy ||
                  request.missingItems[0]?.bigAndHeavy ||
                  request.exceededItems[0]?.bigAndHeavy) && <BigAndHeavyDetail />}

                <QuantityDetail quantity={allQuantities} />
              </Box>
            </Box>

            <Divider />

            <PartsDetail
              items={request.requestType === "WrongShipment" ? request.missingItems : request.items}
              clickToDetail
              missingChanges={isMissingParts}
              exceededItems={request.exceededItems}
              customFields={request.customFields}
              showDepositRef={request.requestType === "Warranty" || request.requestType === "Core"}
            />
          </Card>

          {request.refusedNotes && <RefusedNotesAlert refusedNotes={request.refusedNotes} />}

          {request.toBeAmendedNotes && request.status === "ToBeAmended" && <AmendNotesAlert amendNotes={request.toBeAmendedNotes} />}

          {alerts.frequencyFee > 0 && <FrequencyFeeAlert frequencyFee={alerts.frequencyFee} />}

          {alerts.invoiceFee > 0 && <InvoiceFeeAlert invoiceFee={alerts.invoiceFee} />}

          {alerts.hasSurveillance && <HasSurveillanceAlert />}

          {request.approverNotes && request.approverNotes.surveillanceFee > 0 && (
            <ApproverNotesAlert approverNotes={request.approverNotes} />
          )}

          {!isAmend ? (
            <CustomerDeliveryDetail
              request={request}
              setOpenConfirm={setOpenConfirm}
              componentRef={componentRef}
              setOpenSuccess={setOpenSuccess}
              setOpenError={setOpenError}
              deliveryNotes={deliveryNotes}
              invoices={invoices}
            />
          ) : (
            <IncompleteCustomerForm
              warranty={warranty}
              setWarranty={setWarranty}
              damagedQuality={damagedQuality}
              setDamagedQuality={setDamagedQuality}
              setOpenConfirm={setOpenConfirm}
              files={files}
              setFiles={setFiles}
              isAR={isAR}
              medias={request.media}
              setDeleteList={setDeleteList}
              deleteList={deleteList}
            />
          )}

          <Box display={'flex'}>
            <Typography sx={{ mx: 'auto', mt: 3 }} fontSize={'12px'}>
              {`${translate('request.lastModified')} `}
              <b>{dayjs(request.modifiedOn ? request.modifiedOn : request.createdOn).format('DD MMM YYYY, HH:mm')}</b>
            </Typography>
          </Box>
        </Box>
      )}
    </Box>
  );
}

//----------------------------------------------------------------------------------------------------
interface ConfirmModalProps {
  isOpen: boolean,
  isAmend: boolean,
  toggle: VoidFunction,
  submit: VoidFunction
}

function ConfirmModal({ isOpen, toggle, submit, isAmend }: ConfirmModalProps) {

  const { translate } = useLocales();

  const isDesktop = useResponsive('up', 'sm');

  return (
    <Modal
      open={isOpen}
      onClose={() => toggle()}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Card
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: isDesktop ? '23vw' : '63vw',
          p: isDesktop ? 5 : 2
        }}
      >
        <Box sx={{ textAlign: 'center', mb: 2 }}>
          <Typography variant="h6" sx={{ mt: 3 }}>
            {`${translate(`request.messages.${isAmend ? "amendConfirm" : "delConfirm"}`)}`}
          </Typography>
        </Box>

        <Box sx={{ textAlign: 'center', mb: 1 }}>
          <Typography variant="body1">{`${translate(`request.messages.${isAmend ? "amendQuestion" : "delQuestion"}`)}`}</Typography>

          <Box
            sx={{
              justifyContent: 'space-between',
              display: 'flex', gap: 1, p: 3, pb: 1.5,
              flexDirection: isDesktop ? 'default' : 'column-reverse',
            }}>
            <Button
              variant="contained"
              size={isDesktop ? 'small' : 'large'}
              onClick={() => toggle()}
              sx={{ borderRadius: '50px !important' }}
            >
              {`${translate('commons.close')}`}
            </Button>
            <Button
              variant={"contained"}
              color={isAmend ? "primary" : "error"}
              startIcon={!isAmend && <DeleteForever />}
              size={isDesktop ? 'small' : 'large'}
              onClick={submit}
              sx={{ px: isAmend ? 1.5 : 1, borderRadius: '50px !important' }}
            >
              {`${translate(`request.${isAmend ? "detail.amend" : "delete"}`)}`}
            </Button>
          </Box>

        </Box>
      </Card>
    </Modal>
  );
}
