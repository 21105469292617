import { OrganizationPermissionTypes, OrganizationPermissionsList, ReportPermissionsList } from "src/@types/permissions";
import { TenantType, LicenseTypes } from "src/@types/tenant";
import { hasLicense } from "./tenant";

export function getOrgPermissionList(tenant: TenantType) {

    let permissions: Record<string, OrganizationPermissionTypes[]> = {
        ...(hasLicense(tenant, LicenseTypes.Core) ? { 'Organization': OrganizationPermissionsList['Organization'], "Customer": OrganizationPermissionsList['Customer'] } : {}),
        ...(hasLicense(tenant, LicenseTypes.ProductSparePart) ? { 'ProductSparePart': OrganizationPermissionsList['ProductSparePart'] } : {}),
        ...(hasLicense(tenant, LicenseTypes.ProductVehicle) ? { 'ProductVehicle': OrganizationPermissionsList['ProductVehicle'] } : {}),
        ...(hasLicense(tenant, LicenseTypes.Rv) ? { 'RvTemplate': OrganizationPermissionsList['RvTemplate'], 'RvMatrix': OrganizationPermissionsList['RvMatrix'] } : {}),
        ...(hasLicense(tenant, LicenseTypes.WebShop) ? { 'WebShop': OrganizationPermissionsList['WebShop'] } : {}),
        ...(hasLicense(tenant, LicenseTypes.Shipping) ? { 'Shipping': OrganizationPermissionsList['Shipping'] } : {}),
        ...(hasLicense(tenant, LicenseTypes.Group) ? { 'Groups': OrganizationPermissionsList['Groups'] } : {})
    };

    if (!hasLicense(tenant, LicenseTypes.Reports)) {
        Object.keys(permissions).forEach(key => {
            permissions[key] = permissions[key].filter(item => !ReportPermissionsList.includes(item));
        });
    }

    return permissions;
}