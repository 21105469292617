import { useLocales } from "src/locales";
import { Autocomplete, Button, Card, Checkbox, Chip, Divider, FormControl, FormControlLabel, FormHelperText, InputLabel, ListItemText, MenuItem, Modal, Select, TextField, Typography, useTheme } from "@mui/material";
import { Box } from "@mui/system";
import { RequestType, RequestTypeArr } from "src/@types/request";
import { useCallback, useEffect, useMemo, useState } from "react";
import { DEFAULT_NEW_CONTAINER, NewContainerTemplate, EditSliceProps } from "src/@types/container";
import SuccessModal from "src/components/modals/SuccessModal";
import ErrorModal from "src/components/modals/ErrorModal";
import { RootState, dispatch } from "src/redux/store";
import { containerOperations } from "src/redux/container";
import useResponsive from "src/hooks/useResponsive";
import { useSelector } from "react-redux";
import { Tag } from "src/@types/commons";
import { debounce, isUndefined } from "lodash";
import containerService from "src/services/containerService";
import CustomFieldFormRenderer from "src/utils/CustomFieldFormRenderer";
import useTenant from "src/appHooks/useTenant";
import { handleCustomFields } from "src/utils/CustomFieldManagment";
import { useForm } from "react-hook-form";
import FormProvider from "src/components/hook-form/FormProvider";
import PermissionBasedGuard from "src/guards/PermissionBasedGuard";
import { OrganizationPermissionTypes } from "src/@types/permissions";

interface NewContainerProps {
    isOpen: boolean,
    toggle: (value: boolean) => void,
    id?: string,
    edit: (id: string | undefined) => void,
    options: EditSliceProps
}

export default function NewContainer({ isOpen, toggle, id, edit, options }: NewContainerProps) {

    const { translate } = useLocales();

    const tenant = useTenant();

    const theme = useTheme();

    const [container, setContainer] = useState<NewContainerTemplate>(DEFAULT_NEW_CONTAINER);

    const [errors, setErrors] = useState({
        name: "",
        contentTypes: "",
    });

    const { isTemplateLoading } = useSelector((state: RootState) => state.container);

    const isDesktop = useResponsive('up', 'lg');

    const [disabled, setDisabled] = useState(false);

    const [openSuccess, setOpenSuccess] = useState(false);

    const [openError, setOpenError] = useState(false);

    const [tags, setTags] = useState<Tag[]>([]);

    const [active, setActive] = useState(false);

    const [errorInCustom, setErrorInCustom] = useState<boolean>(false);

    const methods = useForm();

    useEffect(() => {
        if (id)
            dispatch(containerOperations.getContainerTemplate(id)).unwrap().then(v => {
                setContainer({
                    name: v.name,
                    contentTypes: v.contentTypes,
                    customFields: v.customFields || {},
                    tags: v.tags || [],
                    enabled: v.enabled
                });
            });
    }, [id]);

    const search = useMemo(() => debounce(
        (val: string) => containerService.searchTagsContainerTemplate(val, 5).then(v => setTags(v.data)),
        500),
        []);

    const submit = async () => {
        toggle(false);
        try {
            if (id) {
                await dispatch(containerOperations.updateContainerTemplate({
                    container: container,
                    id: id,
                    options: options
                })).unwrap();
            }
            else {
                const { id } = await dispatch(containerOperations.createContainerTemplate({ container: container, tab: options.tab })).unwrap();

                if (active)
                    await dispatch(containerOperations.openContainer(id)).unwrap();
            }
            setOpenSuccess(true);
        }
        catch (e) {
            setOpenError(true);
        }
        finally {
            setContainer(DEFAULT_NEW_CONTAINER);
            edit(undefined);
            setActive(false);
        }
    };

    useEffect(() => {
        if (!container.name || container.contentTypes.length <= 0 || Object.values(errors).some(v => !!v) || errorInCustom)
            setDisabled(true);
        else
            setDisabled(false);
    }, [container, errors, errorInCustom]);

    const handleName = (name: string) => {
        setContainer(prev => ({ ...prev, name }));

        setErrors(prev => ({ ...prev, name: name ? "" : `${translate('commons.validation.requiredField')}` }));

    };

    const handleContentType = (values: string[]) => {
        setContainer(prev => ({ ...prev, contentTypes: values as (RequestType | "All")[] }));

        setErrors(prev => ({ ...prev, contentTypes: values.length > 0 ? "" : `${translate('commons.validation.requiredField')}` }));
    };

    //----------HANDLE CUSTOMFIELDS
    const getValues = useCallback((field: string) => {
        if (field === "customFields") return container.customFields;

        const key = field.split(".")[1];

        return container.customFields[key];
    }, [container]);

    const setValue = useCallback((field: string, obj: any) => {
        setContainer({ ...container, customFields: obj });
    }, [container]);

    return (
        <>
            <SuccessModal isOpen={openSuccess} successText={`${translate('containers.messages.success')}`} toggle={() => setOpenSuccess(prev => !prev)} />
            <ErrorModal isOpen={openError} errorText={`${translate('commons.error')}`} toggle={() => setOpenError(prev => !prev)} />
            {isTemplateLoading ? <></> :
                < Modal
                    open={isOpen}

                >
                    <Card sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: isDesktop ? '50vw' : '90vw',
                        p: isDesktop ? 4 : 2,
                    }}>

                        <Box>
                            <Box sx={{ px: 3, mb: 2 }}>
                                <Typography variant="h4">
                                    {`${translate('containers.new')}`}
                                </Typography>

                            </Box>

                            <Divider />

                            <Box sx={{ maxHeight: '65vh', overflowY: 'auto' }}>
                                <Box sx={{ display: 'flex', width: '100%', gap: 3, p: 3, flexDirection: 'column' }}>
                                    <Box sx={{ display: 'flex', gap: 3, flexDirection: isDesktop ? 'row' : 'column', width: '100%' }}>
                                        <TextField
                                            label={`${translate('request.containerCode')}`}
                                            fullWidth
                                            required
                                            value={container.name}
                                            onChange={(e) => handleName(e.target.value)}
                                            autoComplete='false'
                                            error={!!errors.name}
                                            helperText={errors.name}
                                            sx={{
                                                '& .MuiInputLabel-asterisk': { color: 'error.main' }
                                            }}
                                        />

                                    </Box>

                                    <Box sx={{ width: '100%' }}>
                                        <FormControl sx={{ width: '100%' }} error={!!errors.contentTypes}>
                                            <InputLabel id="select">
                                                {`${translate('containers.tableHeaders.contentTypes')}`} <span style={{ color: theme.palette.error.main }}>*</span>
                                            </InputLabel>

                                            <Select
                                                multiple
                                                value={container.contentTypes}
                                                labelId="select"
                                                label={`${translate('containers.tableHeaders.contentTypes')}`}
                                                onChange={(e) => handleContentType(e.target.value as string[])}
                                                sx={{
                                                    '& .MuiInputLabel-asterisk': { color: 'error.main' }
                                                }}
                                                MenuProps={{
                                                    sx: {
                                                        // maxHeight: '40vh',
                                                        // mt: 1
                                                    }
                                                }}
                                                renderValue={(selected) => (
                                                    <Box
                                                        sx={{
                                                            display: 'flex',
                                                            flexWrap: 'wrap',
                                                            gap: 0.5
                                                        }}
                                                    >
                                                        {selected.map((value) => (
                                                            <Chip key={value} label={`${translate(`request.${value}`)}`} />
                                                        ))}
                                                    </Box>
                                                )}
                                            >
                                                {RequestTypeArr.slice(1).map((val, index) => (
                                                    <MenuItem key={index} value={val} >
                                                        <Checkbox checked={container.contentTypes.indexOf(val) > -1} />
                                                        <ListItemText primary={`${translate(`request.${val}`)}`} />
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                            {!!errors.contentTypes && <FormHelperText>{errors.contentTypes}</FormHelperText>}
                                        </FormControl>

                                    </Box>

                                    <Box sx={{ width: '100%' }}>
                                        <Autocomplete
                                            options={tags}
                                            multiple
                                            value={container.tags.map(v => ({ text: v, numberOfTimesUsed: 0 }))}
                                            getOptionLabel={(option) => typeof option !== "string" ? option.text : option}
                                            freeSolo
                                            onInputChange={(_, v) => {
                                                search(v);
                                            }}
                                            onChange={(_, value) => {

                                                setContainer(prev => ({ ...prev, tags: (value as Tag[]).map(v => !isUndefined(v.text) ? v.text : v as any) }));
                                            }}
                                            renderTags={(value: readonly Tag[], getTagProps) =>
                                                value.map((option: Tag, index: number) => {
                                                    const { key, ...tagProps } = getTagProps({ index });

                                                    return (
                                                        <Chip label={option.text} key={key} {...tagProps} />
                                                    );
                                                })
                                            }
                                            renderInput={(params) => <TextField label={`${translate('commons.tags')}`} {...params} />}
                                        />
                                    </Box>
                                    <Divider />
                                    {tenant.customFields && (
                                        <FormProvider methods={methods}>
                                            <CustomFieldFormRenderer
                                                context={"containers"}
                                                handleCustomFields={handleCustomFields}
                                                values={container.customFields}
                                                setErrorInCustom={setErrorInCustom}
                                                getValuesCustom={getValues}
                                                setValueCustom={setValue}
                                                useFromForm={false}
                                                columnGap={2}
                                                rowGap={3}
                                                repeat={'repeat(2, 1fr)'}
                                            />
                                        </FormProvider>
                                    )}

                                    <Box sx={{ display: 'flex', flexDirection: 'row', gap: 3 }}>
                                        <FormControlLabel
                                            label={<b>{`${translate('commons.enabled')}`}</b>}
                                            value={container.enabled}
                                            onChange={(_, checked) => {
                                                setContainer(prev => ({ ...prev, enabled: checked }));
                                                if (!checked)
                                                    setActive(false);
                                            }}
                                            control={<Checkbox checked={container.enabled} />}
                                        />
                                        {!id &&
                                            <PermissionBasedGuard permissions={[OrganizationPermissionTypes.Shipping_Container_Open]}>
                                                <FormControlLabel
                                                    disabled={!container.enabled}
                                                    label={<b>{`${translate('statuses.active')}`}</b>}
                                                    onChange={(_, checked) => setActive(checked)}
                                                    control={<Checkbox checked={active} />}

                                                />
                                            </PermissionBasedGuard>
                                        }
                                    </Box>
                                </Box>

                            </Box>

                            <Divider sx={{ mb: 1 }} />

                            <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: 2, px: 3 }}>
                                <Button variant='soft' color="inherit" onClick={() => {
                                    toggle(false);
                                    edit(undefined);
                                    setContainer(DEFAULT_NEW_CONTAINER);
                                    setActive(false);
                                }}
                                >
                                    {`${translate("commons.cancel")}`}
                                </Button>
                                <Button
                                    variant="contained"
                                    disabled={disabled}
                                    onClick={() => submit()}
                                >
                                    {`${translate('commons.justSave')}`}
                                </Button>
                            </Box>

                        </Box>
                    </Card>
                </Modal >}
        </>
    );
}