import axios, { AxiosResponse } from "axios";
import { Container, ContainerFilters, ContainerStatistics, ContainerTemplate, ContainerTemplateSearchResult, NewContainerTemplate, NewExtraItem } from "src/@types/container";
import { Shipping } from "./paths";
import { PagedResponseType, Tag } from "src/@types/commons";

const createContainerTemplate = (params: NewContainerTemplate): Promise<AxiosResponse> => {
    const url = Shipping.admin.template.create();

    return axios.post(url, params);
};

const updateContainerTemplate = (params: NewContainerTemplate, id: string): Promise<AxiosResponse> => {
    const url = Shipping.admin.template.update(id);

    return axios.put(url, params);
};

const detailContainerTemplate = (id: string): Promise<AxiosResponse<ContainerTemplate>> => {
    const url = Shipping.admin.template.detail(id);

    return axios.get(url);
};

const detailContainer = (id: string): Promise<AxiosResponse<Container>> => {
    const url = Shipping.admin.container.detail(id);

    return axios.get(url);
};

const searchContainerTemplate = (options: ContainerFilters): Promise<AxiosResponse<PagedResponseType<ContainerTemplateSearchResult>>> => {
    const url = Shipping.admin.template.search();

    return axios.get(url, { params: options, paramsSerializer: { indexes: null } });
};

const searchContainer = (options: ContainerFilters): Promise<AxiosResponse<PagedResponseType<ContainerTemplateSearchResult>>> => {
    const url = Shipping.admin.container.search();

    return axios.get(url, { params: options, paramsSerializer: { indexes: null } });
};

const addRequest = (params: { containerId: string, requestId: string }): Promise<AxiosResponse> => {
    const url = Shipping.admin.container.addRequest(params.containerId);

    return axios.post(url, { returnRequestId: params.requestId });
};

const searchStatisticsContainerTemplate = (options: ContainerFilters): Promise<AxiosResponse<ContainerStatistics>> => {
    const url = Shipping.admin.template.statistics();

    return axios.get(url, { params: options, paramsSerializer: { indexes: null } });
};

const searchStatisticsContainer = (options: ContainerFilters): Promise<AxiosResponse<ContainerStatistics>> => {
    const url = Shipping.admin.container.statistics();

    return axios.get(url, { params: options, paramsSerializer: { indexes: null } });
};

const enableContainerTemplate = (id: string): Promise<AxiosResponse> => {
    const url = Shipping.admin.template.enable(id);

    return axios.patch(url);
};

const disableContainerTemplate = (id: string): Promise<AxiosResponse> => {
    const url = Shipping.admin.template.disable(id);

    return axios.patch(url);
};

const openContainer = (id: string): Promise<AxiosResponse> => {
    const url = Shipping.admin.template.open(id);

    return axios.patch(url);
};

const closeContainer = (id: string): Promise<AxiosResponse> => {
    const url = Shipping.admin.container.close(id);

    return axios.patch(url);
};

const shipContainer = (id: string): Promise<AxiosResponse> => {
    const url = Shipping.admin.container.ship(id);

    return axios.patch(url);
};

const searchTagsContainerTemplate = (tag: string, size: number): Promise<AxiosResponse<Tag[]>> => {
    const url = Shipping.admin.template.searchTags();

    return axios.get(url, {
        params: {
            query: tag,
            limit: size
        },
        paramsSerializer: {
            indexes: null
        }
    });
};

const searchTagsContainer = (tag: string, size: number): Promise<AxiosResponse<Tag[]>> => {
    const url = Shipping.admin.container.searchTags();

    return axios.get(url, {
        params: {
            query: tag,
            limit: size
        },
        paramsSerializer: {
            indexes: null
        }
    });
};

const searchLogsContainer = (id: string, filters: any): Promise<AxiosResponse<PagedResponseType<any>>> => {
    const url = Shipping.admin.container.getLogs(id);

    return axios.get<any>(url, { params: filters });
};

const detailByTemplate = (id: string): Promise<AxiosResponse<Container>> => {
    const url = Shipping.admin.container.detailByTemplate(id);

    return axios.get(url);
};

const addExtraItems = (id: string, params: NewExtraItem): Promise<AxiosResponse> => {
    const url = Shipping.admin.container.extraItems(id);

    return axios.post(url, params);
};

const defaultExport = {
    createContainerTemplate,
    enableContainerTemplate,
    detailContainerTemplate,
    disableContainerTemplate,
    updateContainerTemplate,
    searchContainerTemplate,
    searchStatisticsContainerTemplate,
    searchTagsContainerTemplate,
    searchTagsContainer,
    searchLogsContainer,
    openContainer,
    closeContainer,
    shipContainer,
    detailContainer,
    addRequest,
    searchContainer,
    searchStatisticsContainer,
    addExtraItems,
    detailByTemplate
};

export default defaultExport;