// routes
import Router from './routes';
// theme
import ThemeProvider from './theme';
// locales
import ThemeLocalization from './locales';
// components
import { StyledChart } from './components/chart';
import SnackbarProvider from './components/snackbar';
import { ThemeSettings } from './components/settings';
import { MotionLazyContainer } from './components/animate';
import ChangeFavicon from './appComponents/ChangeFavicon';
import { StyledProgressBar } from './components/progress-bar/index';
import JiraIntegration from './appComponents/JiraIntegration';
import { CurrencyRatesProvider } from './contexts/CurrencyRatesContext';
import NotificationHandler from './components/push-notifications/NotificationHandler';

// ----------------------------------------------------------------------

export default function App() {
  return (
    <MotionLazyContainer>
      <ThemeProvider>
        <ThemeSettings>
          <ThemeLocalization>
            <SnackbarProvider>
              <CurrencyRatesProvider>
                <NotificationHandler />
                <StyledChart />
                <ChangeFavicon />
                <JiraIntegration />
                <StyledProgressBar />
                <Router />
              </CurrencyRatesProvider>
            </SnackbarProvider>
          </ThemeLocalization>
        </ThemeSettings>
      </ThemeProvider>
    </MotionLazyContainer>
  );
}
