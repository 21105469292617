import { Card, Typography, Divider, TextField, MenuItem, Container, FormControlLabel, Switch, Button, InputAdornment, IconButton, Stack, } from "@mui/material";
import { Box } from "@mui/system";
import { useCallback, useEffect, useMemo, useReducer, useState } from "react";
import { OrganizationPermissionTypes } from "src/@types/permissions";
import Page from "src/appComponents/Page";
import { useSettingsContext } from "src/components/settings";
import { useLocales } from "src/locales";
import HeaderBreadcrumbs from 'src/components/custom-breadcrumbs';
import { PATH_DASHBOARD } from "src/routes/paths";
import { useNavigate, useParams } from "react-router";
import { dispatch, RootState } from "src/redux/store";
import { useSelector } from "react-redux";
import LoadingScreen from "src/appComponents/loading-screen/LoadingScreen";
import SuccessModal from "src/components/modals/SuccessModal";
import ErrorModal from "src/components/modals/ErrorModal";
import useTenant from "src/appHooks/useTenant";
import { DEFAULT_NEW_GROUP, NewGroup } from "src/@types/group";
import { groupOperations } from "src/redux/group";
import { getCustomFieldLabel, getLocalizedString } from "src/utils/CustomFieldManagment";
import { CustomField } from "src/@types/customField";
import { DatePicker } from "@mui/x-date-pickers";
import { Add, Delete } from "@mui/icons-material";
import { cloneDeep } from "lodash";
import { format } from "date-fns";
import { LocalizedString } from "src/@types/commons";
import MenuPopover from "src/components/menu-popover";
import { getOrgPermissionList } from "src/utils/permissionList";
import PermissionTable from "src/components/table/PermissionTable";

enum ActionTypes {
    STANDARD,
    PERMISSION,
    PROPERTIES,
    RESET,
}

type Action = {
    type: ActionTypes
    payload: {
        name: string,
        value: any
    }
};

function reduce(state: NewGroup, action: Action): NewGroup {
    switch (action.type) {

        case ActionTypes.STANDARD:
            return { ...state, [action.payload.name]: action.payload.value };

        case ActionTypes.PERMISSION:

            return {
                ...state,
                permissionDependency: {
                    ...state.permissionDependency,
                    [action.payload.name]: action.payload.value
                }
            };

        case ActionTypes.PROPERTIES:

            return {
                ...state,
                propertyDependency: {
                    properties: {
                        ...action.payload.value
                    }
                }
            };

        case ActionTypes.RESET:
            return { ...DEFAULT_NEW_GROUP, ...action.payload.value };

        default:
            return state;
    };
}

export default function NewEditGroup() {
    const { translate } = useLocales();

    const { themeStretch } = useSettingsContext();

    const navigate = useNavigate();

    const { isLoading, group } = useSelector((state: RootState) => state.group);

    const tenant = useTenant();

    const PermissionList: Record<string, OrganizationPermissionTypes[]> = useMemo(() => getOrgPermissionList(tenant), [tenant]);

    const [state, setState] = useReducer(reduce, DEFAULT_NEW_GROUP);

    const [type, setType] = useState<string>("Organization");

    const [openSucc, setOpenSucc] = useState(false);

    const [openErr, setOpenErr] = useState(false);

    const [error, setError] = useState("");

    const { id } = useParams();

    const disabled = useMemo(() => {

        return !state.displayName;
    }, [state.displayName]);

    useEffect(() => {
        if (id) {
            dispatch(groupOperations.getGroup(id)).unwrap().then((val) => {

                setState({ payload: { name: "", value: val }, type: ActionTypes.RESET });
            });
        }
    }, [id]);

    const handleSubmit = async () => {
        try {
            if (id)
                await dispatch(groupOperations.updateGroup({ group: state, id })).unwrap();
            else
                await dispatch(groupOperations.createGroup(state)).unwrap();

            setOpenSucc(true);
        } catch (e) {
            setOpenErr(true);
        }
    };

    return (
        <Page title={id ? `${translate('groups.form.edit')}` : `${translate('menu.management.groups.new')}`}>
            {isLoading ?
                <LoadingScreen /> :

                <Container maxWidth={themeStretch ? false : 'lg'}>
                    <SuccessModal isOpen={openSucc} goToPage={PATH_DASHBOARD.groups.list} successText={`${translate('notificationTemplate.messages.success')}`} toggle={() => setOpenSucc(false)} />
                    <ErrorModal isOpen={openErr} errorText={`${translate('commons.error')}`} toggle={() => setOpenErr(false)} />
                    <HeaderBreadcrumbs
                        heading={id ? `${translate('groups.form.edit')}` : `${translate('groups.form.new')}`}
                        links={[
                            { name: `${translate('commons.home')}`, href: PATH_DASHBOARD.root },
                            { name: `${translate('commons.list')}`, href: PATH_DASHBOARD.groups.list },
                            { name: id ? `${translate('groups.form.edit')}` : `${translate('menu.management.groups.new')}` }
                        ]}
                    />
                    <Card sx={{
                        p: 3,
                    }}>

                        <TextField
                            fullWidth
                            label={`${translate('commons.name')}`}
                            name='displayName'
                            value={state.displayName}
                            required
                            error={!!error}
                            helperText={!!error && error}
                            sx={{ '& .MuiInputLabel-asterisk': { color: 'error.main' }, my: 2 }}
                            onChange={(e) => {
                                setState({
                                    payload: {
                                        name: e.target.name,
                                        value: e.target.value
                                    },
                                    type: ActionTypes.STANDARD
                                });
                                if (e.target.value)
                                    setError("");
                                else
                                    setError(`${translate('commons.validation.requiredField')}`);

                            }} />
                        <Divider />
                        <Box sx={{ my: 2 }}>
                            <Typography variant="h5">{`${translate('groups.form.property_plural')}`}</Typography>
                            {/**await when in edit for the group to be loaded */}
                            {(!id || (state && state.displayName)) && <PropertiesInput
                                properties={state.propertyDependency.properties}
                                setProperties={(v) => setState({ payload: { name: "", value: v }, type: ActionTypes.PROPERTIES })}
                            />}
                        </Box>
                        <Divider sx={{ mt: 3 }} />
                        <Typography variant="h5" sx={{ mt: 2 }}>{`${translate('notificationTemplate.form.permission_plural')}`}</Typography>
                        <Box sx={{ display: 'grid', gridTemplateColumns: '1fr 1fr', width: '100%', gap: 3, mt: 2 }}>
                            <TextField
                                select
                                fullWidth
                                value={type}
                                onChange={(e) => setType(e.target.value)}
                                SelectProps={{
                                    MenuProps: {
                                        sx: { '& .MuiPaper-root': { maxHeight: 260 } },
                                    },
                                }}
                                sx={{
                                    pr: 3,
                                    textTransform: 'capitalize'
                                }}>
                                {Object.keys((PermissionList)).map((type) => (
                                    <MenuItem
                                        key={type}
                                        value={type}
                                        sx={{
                                            mx: 1,
                                            my: 0.5,
                                            borderRadius: 0.75,
                                            typography: 'body2',
                                            textTransform: 'capitalize',
                                        }}
                                    >
                                        {`${translate(`role.type.${type[0].toLowerCase() + type.slice(1)}`)}`}
                                    </MenuItem>
                                ))}
                            </TextField>
                            <FormControlLabel
                                name="atLeastOne"
                                label={`${translate('notificationTemplate.form.atLeastOne')}`}
                                onChange={(_, check) => setState({ payload: { name: 'atLeastOne', value: check }, type: ActionTypes.PERMISSION })}
                                control={<Switch checked={state.permissionDependency.atLeastOne} />}
                            />
                        </Box>
                        <Box sx={{ mt: 3 }}>
                            <PermissionTable
                                checkbox
                                PermissionT={PermissionList}
                                defaultValue={group?.permissionDependency.permissions || undefined}
                                type={type}
                                onChange={(perm) => setState({
                                    payload: {
                                        name: "permissions",
                                        value: perm
                                    },
                                    type: ActionTypes.PERMISSION
                                })} />
                        </Box>
                        <Divider sx={{ mt: 3 }} />
                        <Box sx={{ mt: 3, display: 'flex', justifyContent: 'flex-end', gap: 2 }}>
                            <Button variant="soft" color="inherit" onClick={() => navigate(PATH_DASHBOARD.groups.list)}>
                                {`${translate("commons.cancel")}`}
                            </Button>
                            <Button type="submit" variant="contained" disabled={disabled} onClick={handleSubmit}>
                                {`${translate("commons.justSave")}`}
                            </Button>
                        </Box>
                    </Card>
                </Container>}
        </Page>
    );

}

interface PropertiesInputProps {
    setProperties: (value: Record<string, any>) => void
    properties: Record<string, any>
}

function PropertiesInput({ properties, setProperties }: PropertiesInputProps) {

    const { customFields } = useTenant();

    const { translate, currentLang } = useLocales();

    const [selected, setSelected] = useState<CustomField[]>(() => {
        return Object.keys(properties).reduce((acc, p) => {
            const x = customFields.find(c => c.id === p);

            if (x) {
                acc.push(x);
            }

            return acc;
        }, [] as CustomField[]);
    });

    const { currency } = useSettingsContext();

    const [openPopover, setOpenPopover] = useState<HTMLElement | null>(null);

    const parsedFields = useMemo(() => {
        return customFields.filter(p => p.entityType === "User" && p.enabled);
    }, [customFields]);

    const getLabel = useCallback((id: string) => getCustomFieldLabel(customFields, id, currentLang), [currentLang, customFields]);

    const handleProperties = (field: CustomField, value: any) => {

        let propertiesCopy = cloneDeep(properties);

        propertiesCopy[field.id] = value;

        setProperties(propertiesCopy);
    };

    const handleRemove = (field: CustomField) => {

        setSelected(prev => prev.filter((v) => v.id !== field.id));

        let propertiesCopy = cloneDeep(properties);

        propertiesCopy[field.id] = undefined;

        setProperties(propertiesCopy);

    };

    const getField = (field: CustomField, key: number) => {

        switch (field.type.toLowerCase()) {
            case 'string':
                return (
                    <TextField
                        name={field.id}
                        key={field.id + key}
                        id={field.id}
                        sx={{ width: '80%' }}
                        value={properties[field.id] ? properties[field.id] : ""}
                        label={getLabel(field.id)}
                        onChange={(e) => handleProperties(field, e.target.value)}
                    />
                );
            case 'number':
                return (
                    <TextField
                        name={field.id}
                        id={field.id}
                        sx={{ width: '80%' }}
                        value={properties[field.id] + ""}
                        key={field.id + key}
                        label={getLabel(field.id)}
                        required={field.required}
                        type="number"
                        onChange={(e) => {
                            if (!isNaN(+e.target.value))
                                handleProperties(field, +e.target.value);
                        }}

                    />
                );
            case 'currency':
                return (
                    <TextField
                        type="number"
                        label={getLabel(field.id)}
                        sx={{ width: '80%' }}
                        value={properties[field.id] + ""}
                        onChange={(e) => {
                            if (!isNaN(+e.target.value))
                                handleProperties(field, +e.target.value);
                        }}
                        InputProps={{
                            startAdornment: <InputAdornment position="start">{currency.symbol}</InputAdornment>
                        }}
                    />
                );
            case 'date':
                return (
                    <DatePicker
                        name={field.id}
                        key={field.id + key}
                        sx={{ width: '80%' }}
                        label={getLabel(field.id)}
                        value={properties[field.id] ? new Date(properties[field.id]) : null}
                        onAccept={(value) => {
                            if (value)
                                handleProperties(field, format(new Date(value), 'yyyy-MM-dd'));
                        }}
                        onChange={(fromDateValue, inputValue) => {
                            if (!inputValue.validationError && fromDateValue)
                                handleProperties(field, format(new Date(fromDateValue), 'yyyy-MM-dd'));
                            else
                                handleProperties(field, undefined);

                        }}
                    />
                );
            case 'checkbox':
                return (
                    <FormControlLabel

                        name={field.id}
                        id={field.id}
                        key={field.id + key}
                        onChange={(_, checked) => handleProperties(field, checked)}
                        control={
                            <Switch
                                checked={properties[field.id]}
                            />}
                        label={getLabel(field.id)}
                    />
                );
            case 'select':
                return <TextField
                    select
                    label={getLabel(field.id)}
                    value={properties[field.id] || []}
                    sx={{ width: '80%' }}
                    SelectProps={{
                        renderValue: (option) => getLocalizedString(option as LocalizedString[], currentLang)
                    }}
                >

                    {field.selectOptions && field.selectOptions.map((option, index) => (
                        <MenuItem
                            key={index + '  ' + key}
                            hidden={!!option.values.find(l => l.text === '')}
                            value={option.values[0]?.text || ""}
                            onClick={() => handleProperties(field, option.values)}
                        >
                            {getLocalizedString(option.values, currentLang)}
                        </MenuItem>)
                    )}

                </TextField>;
        }
    };

    return (
        <Box sx={{ my: 2 }}>
            <Box >
                <Button
                    variant="outlined"
                    size='small'
                    sx={{ mr: 2 }}
                    onClick={(e) => setOpenPopover(e.currentTarget)}
                    disabled={parsedFields.length === selected.length}
                >
                    <Add />
                    <Typography>{`${translate('groups.form.addProperty')}`}</Typography>
                </Button>

                <MenuPopover
                    disableScrollLock
                    open={openPopover}
                    onClose={() => setOpenPopover(null)}
                    sx={{
                        maxHeight: '30vh',
                        overflowY: 'auto'
                    }}
                >
                    <Stack>
                        {parsedFields.filter(v => !selected.find(sel => sel.id === v.id)).map((val, index) =>
                            <MenuItem
                                key={index}
                                onClick={() => {
                                    setSelected(prev => {
                                        if (prev.find(p => p.id === val.id))
                                            return prev;

                                        return prev.concat([val]);
                                    });

                                }}
                            >
                                {getLabel(val.id)}
                            </MenuItem>
                        )}
                    </Stack>
                </MenuPopover>
            </Box>

            <Box sx={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gap: 3, my: 3 }}>
                {selected.filter(v => v.type.toLowerCase() !== "checkbox").map((val, index) =>
                    <Box key={index} sx={{ display: 'flex', flexDirection: 'row', gap: 3, width: '100%', alignItems: 'center' }}>
                        {getField(val, index)}
                        <IconButton onClick={() => handleRemove(val)}>
                            <Delete />
                        </IconButton>

                    </Box>
                )}
            </Box>
            {selected.filter(v => v.type.toLowerCase() === "checkbox").length > 0 && <Divider />}
            <Box sx={{ display: 'grid', gridTemplateColumns: '1fr 1fr 1fr 1fr', gap: 3, my: 3 }}>
                {selected.filter(v => v.type.toLowerCase() === "checkbox").map((val, index) =>
                    <Box key={index} sx={{ display: 'flex', flexDirection: 'row', gap: 3, width: '100%', alignItems: 'center' }}>
                        {getField(val, index)}
                        <IconButton onClick={() => handleRemove(val)}>
                            <Delete />
                        </IconButton>
                    </Box>
                )}
            </Box>
        </Box>
    );
}