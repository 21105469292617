import { createAsyncThunk } from "@reduxjs/toolkit";
import { EnableDisableGroupSliceProps, GroupFilters, GroupMemberFilters, NewGroup } from "src/@types/group";
import * as groupService from '../../services/groupService';
import removeEmptyKeys from "src/utils/removeEmptyKeys";
import { startLoading, startMembersLoading, startLogsLoading, enableDisableTemplate } from "./group-slices";

const createGroup = createAsyncThunk(
    'group/createGroup',
    async (group: NewGroup, { dispatch, rejectWithValue }) => {
        dispatch(startLoading());
        try {
            const { data, status } = await groupService.createGroup(group);

            if (status >= 400) {
                throw new Error('Something went wrong');
            }

            return data;
        } catch (err) {
            if (err?.response?.data?.errors) {
                return rejectWithValue(err.response.data.errors);
            }

            return rejectWithValue(err?.response?.statusText);
        }
    }
);

const updateGroup = createAsyncThunk(
    'group/updateGroup',
    async (params: { group: NewGroup, id: string }, { dispatch, rejectWithValue }) => {
        dispatch(startLoading());
        try {
            const { group, id } = params;

            const { data, status } = await groupService.updateGroup(group, id);

            if (status >= 400) {
                throw new Error('Something went wrong');
            }

            return data;
        } catch (err) {
            if (err?.response?.data?.errors) {
                return rejectWithValue(err.response.data.errors);
            }

            return rejectWithValue(err?.response?.statusText);
        }
    }
);

const getGroup = createAsyncThunk(
    'group/getGroup',
    async (id: string, { dispatch, rejectWithValue }) => {
        dispatch(startLoading());
        try {
            const { data } = await groupService.detailGroup(id);

            if (!data) {
                throw new Error('Something went wrong');
            }

            return data;
        } catch (err) {
            if (err?.response?.data?.errors) {
                return rejectWithValue(err.response.data.errors);
            }

            return rejectWithValue(err?.response?.statusText);
        }
    }
);

const searchGroups = createAsyncThunk(
    'group/searchGroups',
    async (filters: GroupFilters, { dispatch, rejectWithValue }) => {
        dispatch(startLoading());
        try {
            const { data } = await groupService.searchGroups(filters);

            if (!data) {
                throw new Error('Something went wrong');
            }

            return data;
        } catch (err) {
            if (err?.response?.data?.errors) {
                return rejectWithValue(err.response.data.errors);
            }

            return rejectWithValue(err?.response?.statusText);
        }
    }
);

const searchGroupMembers = createAsyncThunk(
    'group/searchGroupMembers',
    async (params: { filters: GroupMemberFilters, id: string }, { dispatch, rejectWithValue }) => {
        dispatch(startMembersLoading());
        try {
            const { data } = await groupService.searchGroupMembers(params.filters, params.id);

            if (!data) {
                throw new Error('Something went wrong');
            }

            return data;
        } catch (err) {
            if (err?.response?.data?.errors) {
                return rejectWithValue(err.response.data.errors);
            }

            return rejectWithValue(err?.response?.statusText);
        }
    }
);

const getGroupLogs = createAsyncThunk(
    'group/getGroupLogs',
    async (params: { id: string, filters: any }, { rejectWithValue, dispatch }) => {
        dispatch(startLogsLoading());
        try {

            const { data } = await groupService.searchGroupLogs(params.id, removeEmptyKeys(params.filters));

            return data;
        } catch (err) {
            return rejectWithValue(err.message);
        }
    }
);

const enableDisableGroup = createAsyncThunk(
    'group/enableDisableGroup',
    async (options: EnableDisableGroupSliceProps, { dispatch, rejectWithValue }) => {

        try {

            const { data, status } = options.action === 'Enable' ?
                await groupService.enableGroup(options.id) :
                await groupService.disableGroup(options.id);

            if (status >= 400) {
                throw new Error('Something went wrong');
            }

            dispatch(enableDisableTemplate(options));

            return data;
        } catch (err) {
            if (err?.response?.data?.errors) {
                return rejectWithValue(err.response.data.errors);
            }

            return rejectWithValue(err?.response?.statusText);
        }
    }
);

const groupOperations = {
    createGroup,
    updateGroup,
    getGroup,
    searchGroups,
    searchGroupMembers,
    getGroupLogs,
    enableDisableGroup
};

export default groupOperations;