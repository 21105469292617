export type NewGroup = {
    displayName: string,
    permissionDependency: {
        permissions: string[],
        atLeastOne: boolean
    },
    propertyDependency: {
        properties: Record<string, string>
    }
};

export type GroupSearchResult = {
    groupId: string,
    organizationId: string,
    displayName: string,
    permissionDependency: {
        permissions: string[],
        atLeastOne: true
    },
    propertyDependency: {
        properties: Record<string, string>
    },
    createdOn: string,
    enabled: boolean
};

export type GroupFilters = {
    [key: string]: any
    pageIndex: number,
    pageSize: number
};

export type GroupType = {
    groupId: string,
    organizationId: string,
    displayName: string,
    permissionDependency: {
        permissions: string[],
        atLeastOne: true
    },
    propertyDependency: {
        properties: Record<string, string>
    },
    createdOn: string,
    enabled: boolean
};

export type GroupMemberSearchResult = {
    userId: string,
    firstName: string,
    lastName: string,
    email: string
};

export type GroupMemberFilters = {
    [key: string]: any
    pageIndex: number,
    pageSize: number
};

export type EnableDisableGroupSliceProps = {
    id: string,
    action: "Enable" | "Disable"
}

//-------------------------------------------------------------------------
export const DEFAULT_NEW_GROUP: NewGroup = {
    displayName: "",
    permissionDependency: {
        atLeastOne: false,
        permissions: []
    },
    propertyDependency: {
        properties: {}
    }
};

export const DEFAULT_GROUP_FILTERS: GroupFilters = {
    pageIndex: 0,
    pageSize: 10
};

export const DEFAULT_GROUP_MEMBER_FILTERS: GroupMemberFilters = {
    pageIndex: 0,
    pageSize: 10
};