import { useCallback, useEffect, useMemo, useState } from 'react';
import { Link as RouterLink, useLocation, useNavigate } from 'react-router-dom';
import { Tab, Tabs, Card, Button, Divider, Container, Box, FormControlLabel, Switch, TablePagination, Typography, MenuItem } from '@mui/material';
import { dispatch, RootState } from 'src/redux/store';
import { useSelector } from 'react-redux';
import useLocales from 'src/appHooks/useLocales';
import Label, { LabelColor } from 'src/components/label';
import useTable from 'src/appHooks/useTable';
import { useSettingsContext } from 'src/components/settings';
import useTabs from 'src/appHooks/useTabs';
import { PATH_DASHBOARD } from 'src/routes/paths';
import Page from 'src/appComponents/Page';
import Iconify from 'src/components/iconify';
import HeaderBreadcrumbs from 'src/components/custom-breadcrumbs';
import { DEFAULT_ORGANIZATION_FILTERS, OrganizationFilters, OrganizationSearchResult } from 'src/@types/organizations';
import { organizationsOperations } from 'src/redux/organizations';
import FormProvider from '../../../components/hook-form';
import { isArray, isEqual, isObject, remove } from 'lodash';
import { useForm } from 'react-hook-form';
import { brandOperations } from 'src/redux/brand';
import { ToolbarSearchFilters } from 'src/@types/commons';
import useCurrency from 'src/appHooks/useCurrency';
import useResponsive from 'src/hooks/useResponsive';
import { Currency } from 'src/config';
import { convert } from 'src/utils/currency';
import { CurrencyTypes } from 'src/@types/vehicle';
import { MAX_BALANCE_ORGANIZATION_FILTER, MAX_LIMIT_ORGANIZATION_FILTER } from 'src/sections/@dashboard/organizations/Constants';
import { DataGrid, GridCellParams, GridColDef, GridSortModel } from '@mui/x-data-grid';
import { OrganizationPermissionTypes } from 'src/@types/permissions';
import TableMoreMenu from 'src/appComponents/TableMoreMenu';
import PermissionBasedGuard from 'src/guards/PermissionBasedGuard';
import { noData } from 'src/components/empty-content/EmptyContent';
import { DataGridStyle } from 'src/utils/DataGridStyle';
import CurrencyAdapter from 'src/appComponents/CurrencyAdapter';
import OrganizationFilterSidebar from 'src/sections/@dashboard/organizations/filters/OrganizationFilterSidebar';
import OrganizationFilterSummary from 'src/sections/@dashboard/organizations/filters/OrganizationFilterSummary';
import OrganizationGeneralFilters from 'src/sections/@dashboard/organizations/filters/OrganizationGeneralFilters';
import { QuickFilters } from 'src/@types/list';

const BASIC_FILTER_OPTIONS = {
    pageIndex: 0,
    pageSize: 10,
    status: ""
};

interface OrganizationListProps {
    isVendor: boolean
}

export default function OrganizationList({ isVendor }: OrganizationListProps) {
    const {
        page,
        setPage,
        order,
        setOrder,
        orderBy,
        setOrderBy,
        rowsPerPage,
        setRowsPerPage,
        dense,
        onChangePage,
        onChangeDense,
        onChangeRowsPerPage
    } = useTable();

    const navigate = useNavigate();

    const { themeStretch } = useSettingsContext();

    const isDesktop = useResponsive('up', 'md');

    const { translate, currentLang } = useLocales();

    const { rates } = useCurrency();

    const { isSearchLoading: isLoading, organizationList, totalCount, statistics, pageIndex, pageSize } = useSelector((state: RootState) => state.organizations);

    const { isLoading: brandLoading, publicBrandList } = useSelector((state: RootState) => state.brand);

    const [openSidebar, setOpenSidebar] = useState(false);

    const [resetForm, setResetForm] = useState(true);

    const [filters, setFilters] = useState<OrganizationFilters>({ ...DEFAULT_ORGANIZATION_FILTERS });

    const [resetFormElement, setResetFormElement] = useState("");

    const [showSummary, setShowSummary] = useState(true);

    const [lastUsedLang, setLastUsedLang] = useState(currentLang.label);

    const [lastIsVendor, setLastIsVendor] = useState(isVendor);

    useEffect(() => {
        if (!publicBrandList)
            dispatch(brandOperations.getBrandList(false));
    }, [publicBrandList]);

    //---- MANAGING CURRENCY CHANGE - START ----//
    const { currency: currentCurrency } = useSettingsContext();

    const [oldCurrency, setOldCurrency] = useState<Currency>(currentCurrency);

    const MAX_BALANCE_CONVERTED = useMemo(() => Math.round(convert(MAX_BALANCE_ORGANIZATION_FILTER, CurrencyTypes.EUR, currentCurrency.label, rates)), [currentCurrency, rates]);

    const MAX_LIMIT_CONVERTED = useMemo(() => Math.round(convert(MAX_LIMIT_ORGANIZATION_FILTER, CurrencyTypes.EUR, currentCurrency.label, rates)), [currentCurrency, rates]);
    //---- MANAGING CURRENCY CHANGE - START ----//

    const methods = useForm({
        defaultValues: {
            ...DEFAULT_ORGANIZATION_FILTERS,
            maxCreditBalance: MAX_BALANCE_CONVERTED,
            maxCreditLimit: MAX_LIMIT_CONVERTED
        } as OrganizationFilters,
    });

    const { watch, reset, setValue, getValues } = methods;

    var formValues = watch();

    const getMinMaxPriceConverted = useCallback((from: any, to: any, values: any) => {
        const min = values[0] ? Math.round(convert(values[0], from, to, rates)) : values[0];

        const max = Math.round(convert(values[1], from, to, rates));

        return [min, max];
    }, [rates]);

    useEffect(() => {
        if (oldCurrency.label !== currentCurrency.label) {

            const [minBalance, maxBalance] = getMinMaxPriceConverted(oldCurrency.label, currentCurrency.label, [formValues.minCreditBalance, formValues.maxCreditBalance]);

            const [minLimit, maxLimit] = getMinMaxPriceConverted(oldCurrency.label, currentCurrency.label, [formValues.minCreditLimit, formValues.maxCreditLimit]);

            const valuesFormatted = {
                ...formValues,
                minCreditBalance: minBalance,
                maxCreditBalance: maxBalance,
                minCreditLimit: minLimit,
                maxCreditLimit: maxLimit
            };

            if (JSON.stringify(valuesFormatted) !== JSON.stringify(formValues)) {
                setValue('minCreditBalance', minBalance);
                setValue('maxCreditBalance', maxBalance);
                setValue('minCreditLimit', minLimit);
                setValue('maxCreditLimit', maxLimit);
                setOldCurrency(currentCurrency);
            }

        }
    }, [currentCurrency, oldCurrency, rates, getMinMaxPriceConverted, formValues, setValue]);

    //---- IS DEFAULT FUNC ----//
    // Checks if there are some filters selected
    const statsKeysToRemove: string[] = useMemo(() => ["pageIndex", "pageSize", "sortField", "sortAscending", "status"], []);

    const fullKeysToRemove: string[] = useMemo(() => ["all"], []);

    const statsKeyRemover = useCallback((key: string) => {
        return !(statsKeysToRemove.includes(key));
    }, [statsKeysToRemove]);

    const fullKeyRemover = useCallback((key: string) => {
        return !(statsKeysToRemove.includes(key) || fullKeysToRemove.includes(key));
    }, [statsKeysToRemove, fullKeysToRemove]);

    const isDefault = useCallback((filter: OrganizationFilters, controller?: OrganizationFilters, forStats?: boolean) => {

        const check = controller ? controller : { ...DEFAULT_ORGANIZATION_FILTERS, maxCreditBalance: MAX_BALANCE_CONVERTED, maxCreditLimit: MAX_LIMIT_CONVERTED };

        const found = remove(Object.keys(check), (forStats ? statsKeyRemover : fullKeyRemover))
            .find((element) => {
                if (isArray(filter[element]) && isArray(check[element])) {
                    return !isEqual(filter[element], check[element]);
                } else if (isObject(filter[element]) && isObject(check[element])) {

                    const strFilters = JSON.stringify(filter[element], Object.keys(filter[element]).sort());

                    const strDefaultFilters = JSON.stringify(check[element], Object.keys(check[element]).sort());

                    return strFilters !== strDefaultFilters;
                } else {
                    if ((element === "maxCreditBalance" || element === "maxCreditLimit") && oldCurrency.label !== currentCurrency.label) {
                        return Math.round(convert(filter[element]!, oldCurrency.label, currentCurrency.label, rates)) !== check[element]!;
                    }

                    return filter[element] !== check[element];
                }
            });

        return (!found);
    }, [statsKeyRemover, fullKeyRemover, MAX_BALANCE_CONVERTED, MAX_LIMIT_CONVERTED, currentCurrency, oldCurrency, rates]);
    //---- IS DEFAULT - END ----//

    //---- TOOLBAR OPTIONS ----//
    const toolbarFiltersList: ToolbarSearchFilters[] = useMemo(() => [
        {
            key: 'all',
            label: `${translate('commons.all')}`
        },
        {
            key: 'name',
            label: `${translate('commons.name')}`
        },
        {
            key: 'externalId',
            label: `${translate('commons.code')}`
        }
    ], [translate]);

    //---- CLOSE AND OPEN SIDEBAR FUNC ----//
    const handleOpenSidebar = () => {
        setOpenSidebar(true);
    };

    const handleCloseSidebar = () => {
        if (resetForm) {
            reset({ ...DEFAULT_ORGANIZATION_FILTERS, maxCreditBalance: MAX_BALANCE_CONVERTED, maxCreditLimit: MAX_LIMIT_CONVERTED });
        };
        setOpenSidebar(false);
    };

    //---- CLEAR FROM SUMMARY FUNC ----//
    const handleClearFromSummary = (section: string) => {
        setResetFormElement(section);
        if (isDefault(formValues)) {
            setResetForm(true);
        }
    };

    //---- FILTERS IN URL GET/SET START ----//
    const location = useLocation();

    const [firstRender, setFirstRender] = useState(true);

    const [lastStatsFilters, setLastStatsFilters] = useState<any>(BASIC_FILTER_OPTIONS);

    const check = useCallback((filters: any, param: any) => {

        if (param === "minCreditBalance" || param === "minCreditLimit") {
            const check = filters[param] / 1000;

            if (check > DEFAULT_ORGANIZATION_FILTERS[param]!) return true;

            return false;
        } else if (param === "maxCreditBalance") {
            const check = filters[param] / 1000;

            if (filters[param] !== MAX_BALANCE_CONVERTED && check < MAX_BALANCE_CONVERTED) return true;

            return false;

        } else if (param === "maxCreditLimit") {
            const check = filters[param] / 1000;

            if (filters[param] !== MAX_LIMIT_CONVERTED && check < MAX_LIMIT_CONVERTED) return true;

            return false;

        }

        return filters[param] && (isArray(filters[param]) ? filters[param].length > 0 : filters[param] !== DEFAULT_ORGANIZATION_FILTERS[param]);
    }, [MAX_BALANCE_CONVERTED, MAX_LIMIT_CONVERTED]);

    const updateFiltersInUrl = useCallback((filters: any) => {

        let queryString = Object.keys(filters).filter(x => check(filters, x))
            .map((key) => {
                if (isArray(filters[key]) || isObject(filters[key]))
                    return `${encodeURIComponent(key)}=${encodeURIComponent(JSON.stringify(filters[key]).replace(/stellantis-europe-/g, ""))}`;

                return `${encodeURIComponent(key)}=${encodeURIComponent(filters[key])}`;
            })
            .join('&');

        if (queryString) queryString = "#" + queryString;

        navigate(location.pathname + queryString, { replace: true });

    }, [location, navigate, check]);

    const formatFromK = useCallback((value: any, type: string) => {

        if (value) {
            const numbX = Number(value);

            if (numbX) {
                return Math.round(convert(numbX / 1000, CurrencyTypes.EUR, currentCurrency.label, rates));
            }

            if (type === "maxCreditBalance") return MAX_BALANCE_CONVERTED;

            if (type === "maxCreditLimit") return MAX_LIMIT_CONVERTED;

            return DEFAULT_ORGANIZATION_FILTERS[type];
        }

        return null;
    }, [MAX_BALANCE_CONVERTED, MAX_LIMIT_CONVERTED, currentCurrency.label, rates]);

    const getPageAndSize = useCallback(() => {
        if (firstRender) {
            return [pageSize, pageIndex];
        }
        else return [rowsPerPage, page];
    }, [firstRender, page, pageIndex, pageSize, rowsPerPage]);

    const getFiltersFromUrl = useCallback(() => {

        const { hash } = location;

        let searchFilters = {};

        const [pageSizeFunc, pageIndexFunc] = getPageAndSize();

        if (hash) {

            const cleanedHash = hash.slice(1);

            const decodedQuery = decodeURIComponent(cleanedHash);

            const searchParams = new URLSearchParams(decodedQuery);

            searchFilters = {
                pageIndex: pageIndexFunc,
                pageSize: pageSizeFunc,
                sortField: orderBy || null,
                sortAscending: orderBy ? (order === 'desc' ? true : false) : null,
                all: searchParams.get('all'),
                externalId: searchParams.get('externalId'),
                type: searchParams.get('type'),
                name: searchParams.get('name'),
                email: searchParams.get('email'),
                vat: searchParams.get('vat'),
                minCreditBalance: formatFromK(searchParams.get('minCreditBalance'), 'minCreditBalance'),
                maxCreditBalance: formatFromK(searchParams.get('maxCreditBalance'), 'maxCreditBalance'),
                minCreditLimit: formatFromK(searchParams.get('minCreditLimit'), 'minCreditLimit'),
                maxCreditLimit: formatFromK(searchParams.get('maxCreditLimit'), 'maxCreditLimit'),
                tags: searchParams.get('tags') ? JSON.parse(searchParams.get('tags')!) : null,
                status: searchParams.get('status'),
                isMonitored: searchParams.get('isMonitored'),
                customFields: searchParams.get('customFields') ? JSON.parse(searchParams.get('customFields')!.replace(/organization/g, "stellantis-europe-organization")) : null
            };

        } else {
            searchFilters = {
                pageIndex: pageIndexFunc,
                pageSize: pageSizeFunc,
                sortField: orderBy || null,
                sortAscending: orderBy ? (order === 'desc' ? true : false) : null
            };
        }

        searchFilters = Object.fromEntries(Object.entries(searchFilters).filter(([_, value]) => value !== null && value !== 'null'));

        return searchFilters as OrganizationFilters;
    }, [formatFromK, getPageAndSize, location, order, orderBy]);
    //---- FILTERS IN URL GET/SET END ----//

    //---- QUICKFILTERS START ----//
    const { currentTab: filterStatus, onChangeTab: onChangeFilterStatus } = useTabs(getFiltersFromUrl().status || "");

    const quickFilters: QuickFilters[] = useMemo(() => [
        {
            key: '',
            label: `${translate('commons.all')}`,
            color: 'primary',
        },
        {
            key: 'Enabled',
            label: `${translate('statuses.enabled')}`,
            color: 'success',
        },
        {
            key: 'Disabled',
            label: `${translate('statuses.disabled')}`,
            color: 'warning',
        },
        {
            key: 'Blocked',
            label: `${translate('statuses.blocked')}`,
            color: 'error',
        }
    ], [translate]);

    const renderQuickFilters = (key: string) => {
        if (statistics) {
            switch (key) {
                case "":
                    return statistics.all;
                case "Enabled":
                    return statistics.enabled;
                case "Disabled":
                    return statistics.disabled;
                case "Blocked":
                    return statistics.blocked;
            }
        } else return 0;
    };
    //---- QUICKFILTERS END ----//

    //---- FETCH DATA FUNC ----//
    // Gets all filter values ​​other than the default ones and puts them in the url
    const fetchData = useCallback(async (values: OrganizationFilters) => {

        var searchFilters: any = {};

        if (JSON.stringify(values) === JSON.stringify(DEFAULT_ORGANIZATION_FILTERS)) {
            searchFilters = BASIC_FILTER_OPTIONS;
        } else {

            searchFilters = {
                pageIndex: page,
                pageSize: rowsPerPage,
                sortField: orderBy,
                sortAscending: order === 'desc' ? false : true,
                all: values.all !== DEFAULT_ORGANIZATION_FILTERS.all ? values.all : null,
                externalId: values.externalId !== DEFAULT_ORGANIZATION_FILTERS.externalId ? values.externalId : null,
                type: values.type !== DEFAULT_ORGANIZATION_FILTERS.type ? values.type : null,
                name: values.name !== DEFAULT_ORGANIZATION_FILTERS.name ? values.name : null,
                vat: values.vat !== DEFAULT_ORGANIZATION_FILTERS.vat ? values.vat : null,
                email: values.email !== DEFAULT_ORGANIZATION_FILTERS.email ? values.email : null,
                isMonitored: values.isMonitored !== DEFAULT_ORGANIZATION_FILTERS.isMonitored ? values.isMonitored : null,
                tags: JSON.stringify(values.tags) !== JSON.stringify(DEFAULT_ORGANIZATION_FILTERS.tags) ? values.tags : null,
                status: filterStatus || null,
                minCreditBalance: values.minCreditBalance! <= DEFAULT_ORGANIZATION_FILTERS.minCreditBalance! ? DEFAULT_ORGANIZATION_FILTERS.minCreditBalance : values.minCreditBalance! * 1000,
                maxCreditBalance: values.maxCreditBalance! >= MAX_BALANCE_CONVERTED ? MAX_BALANCE_CONVERTED : values.maxCreditBalance! * 1000,
                minCreditLimit: values.minCreditLimit! <= DEFAULT_ORGANIZATION_FILTERS.minCreditLimit! ? DEFAULT_ORGANIZATION_FILTERS.minCreditLimit : values.minCreditLimit! * 1000,
                maxCreditLimit: values.maxCreditLimit! >= MAX_LIMIT_CONVERTED ? MAX_LIMIT_CONVERTED : values.maxCreditLimit! * 1000,
                customFields: JSON.stringify(values.customFields) !== JSON.stringify(DEFAULT_ORGANIZATION_FILTERS.customFields) ? values.customFields : null
            };
        }

        updateFiltersInUrl(searchFilters);
    }, [order, orderBy, page, rowsPerPage, updateFiltersInUrl, filterStatus, MAX_LIMIT_CONVERTED, MAX_BALANCE_CONVERTED]);

    //---- SEARCH FOR ITEMS AND STATISTICS FUNC ----//
    // This function is used to call APIs and get Organization list and statistics using filters
    const adjustLastFiltered = useCallback((values: OrganizationFilters) => {

        return {
            pageIndex: 0,
            pageSize: 10,
            sortField: "",
            sortAscending: false,
            all: values.all !== DEFAULT_ORGANIZATION_FILTERS.all ? values.all : undefined,
            externalId: values.externalId !== DEFAULT_ORGANIZATION_FILTERS.externalId ? values.externalId : undefined,
            type: values.type !== DEFAULT_ORGANIZATION_FILTERS.type ? values.type : undefined,
            name: values.name !== DEFAULT_ORGANIZATION_FILTERS.name ? values.name : undefined,
            vat: values.vat !== DEFAULT_ORGANIZATION_FILTERS.vat ? values.vat : undefined,
            email: values.email !== DEFAULT_ORGANIZATION_FILTERS.email ? values.email : undefined,
            isMonitored: values.isMonitored !== DEFAULT_ORGANIZATION_FILTERS.isMonitored ? values.isMonitored : undefined,
            tags: JSON.stringify(values.tags) !== JSON.stringify(DEFAULT_ORGANIZATION_FILTERS.tags) ? values.tags : undefined,
            status: values.status !== DEFAULT_ORGANIZATION_FILTERS.status ? values.status : undefined,
            minCreditBalance: values.minCreditBalance && values.minCreditBalance > DEFAULT_ORGANIZATION_FILTERS.minCreditBalance! ? values.minCreditBalance! * 1000 : undefined,
            maxCreditBalance: values.maxCreditBalance && values.maxCreditBalance < MAX_BALANCE_CONVERTED ? values.maxCreditBalance! * 1000 : undefined,
            minCreditLimit: values.minCreditLimit && values.minCreditLimit > DEFAULT_ORGANIZATION_FILTERS.minCreditLimit! ? values.minCreditLimit! * 1000 : undefined,
            maxCreditLimit: values.maxCreditLimit && values.maxCreditLimit < MAX_LIMIT_CONVERTED ? values.maxCreditLimit! * 1000 : undefined,
            customFields: JSON.stringify(values.customFields) !== JSON.stringify(DEFAULT_ORGANIZATION_FILTERS.customFields) ? values.customFields : undefined
        };
    }, [MAX_BALANCE_CONVERTED, MAX_LIMIT_CONVERTED]);

    const onSearch = useCallback((filtersFromUrl: OrganizationFilters) => {

        const filtersFromUrlCopy = JSON.parse(JSON.stringify(filtersFromUrl));

        if (filtersFromUrlCopy.minCreditBalance) filtersFromUrlCopy.minCreditBalance = Math.round(convert(filtersFromUrlCopy.minCreditBalance, currentCurrency.label, CurrencyTypes.EUR, rates)) * 1000;

        if (filtersFromUrlCopy.maxCreditBalance) filtersFromUrlCopy.maxCreditBalance = Math.round(convert(filtersFromUrlCopy.maxCreditBalance, currentCurrency.label, CurrencyTypes.EUR, rates)) * 1000;

        if (filtersFromUrlCopy.minCreditLimit) filtersFromUrlCopy.minCreditLimit = Math.round(convert(filtersFromUrlCopy.minCreditLimit, currentCurrency.label, CurrencyTypes.EUR, rates)) * 1000;

        if (filtersFromUrlCopy.maxCreditLimit) filtersFromUrlCopy.maxCreditLimit = Math.round(convert(filtersFromUrlCopy.maxCreditLimit, currentCurrency.label, CurrencyTypes.EUR, rates)) * 1000;

        updateFiltersInUrl(filtersFromUrlCopy);

        const customFieldsFromUrl: Record<string, string> = Object.entries(filtersFromUrlCopy.customFields || {})
            .map(([k, val]) => ({
                key: "customFields." + k,
                value: val
            }))
            .reduce((obj, item) => Object.assign(obj, { [item.key]: item.value }), {});

        const updatedFiltersFromUrl = { ...filtersFromUrlCopy, ...customFieldsFromUrl };

        delete updatedFiltersFromUrl.customFields;

        let searchStats = false;

        const strFiltersFromUrl = JSON.stringify(filtersFromUrl, Object.keys(filtersFromUrl).sort());

        const strBasicFilters = JSON.stringify(BASIC_FILTER_OPTIONS, Object.keys(BASIC_FILTER_OPTIONS).sort());

        if (strFiltersFromUrl === strBasicFilters
            || !isDefault(filtersFromUrl, lastStatsFilters as OrganizationFilters, true)
            || isVendor !== lastIsVendor
            || firstRender
        ) {
            searchStats = true;
            setLastStatsFilters(adjustLastFiltered(filtersFromUrl));
            setLastIsVendor(isVendor);
        }

        if (isVendor) {
            dispatch(organizationsOperations.searchVendorOrganizations(updatedFiltersFromUrl));
            if (searchStats) dispatch(organizationsOperations.searchVendorStatistic(updatedFiltersFromUrl));
        }
        else {
            dispatch(organizationsOperations.searchCustomerOrganizations(updatedFiltersFromUrl));
            if (searchStats) dispatch(organizationsOperations.searchCustomerStatistic(updatedFiltersFromUrl));
        }

        if (firstRender) setFirstRender(false);

    }, [currentCurrency.label, firstRender, isDefault, rates, updateFiltersInUrl, adjustLastFiltered, lastStatsFilters, isVendor, lastIsVendor]);

    //---- SEARCH FOR ITEMS AND STATISTICS HOOK ----//
    // This hook is used to call onSearch when filters or language are changed
    const isOrderDiff = useCallback((filterToCheck: OrganizationFilters, controller: OrganizationFilters) => {

        const differencesToController = Object.keys(filterToCheck).filter((filter) => filterToCheck[filter] !== controller[filter]);

        if (differencesToController.includes("sortField") || differencesToController.includes("sortAscending")) return true;

        const differencesToFilter = Object.keys(controller).filter((filter) => controller[filter] !== filterToCheck[filter]);

        return differencesToFilter.includes("sortField") || differencesToFilter.includes("sortAscending");
    }, []);

    useEffect(() => {
        let searchFilters = getFiltersFromUrl();

        if (filterStatus !== (searchFilters as any)?.status) {
            searchFilters.status = filterStatus;
        }

        const strSearchFilters = JSON.stringify(searchFilters, Object.keys(searchFilters).sort());

        const strFilters = JSON.stringify(filters, Object.keys(filters).sort());

        if (strSearchFilters !== strFilters || lastUsedLang !== currentLang.label || isVendor !== lastIsVendor) {

            if (!firstRender && isOrderDiff(searchFilters, filters)) searchFilters.pageIndex = 0;
            setFilters(searchFilters as OrganizationFilters);
            onSearch(searchFilters as OrganizationFilters);
            setLastUsedLang(currentLang.label);
            if (searchFilters.pageIndex !== page) setPage(searchFilters.pageIndex);
            if (searchFilters.pageSize !== rowsPerPage) setRowsPerPage(searchFilters.pageSize);
        }

    }, [location, page, firstRender, rowsPerPage, orderBy, order, filterStatus, currentLang, lastIsVendor, filters, getFiltersFromUrl, isOrderDiff, lastUsedLang, onSearch, setPage, setRowsPerPage, isVendor]);

    //---- FILTERS SEARCH FUNC ----//
    // Used for search buttons in filters
    const handleSearchFilters = () => {
        fetchData(getValues());
        setResetForm(false);
        setOpenSidebar(false);
        setPage(0);
    };

    //---- FILTERS RESET ALL FUNC ----//
    // Used for full clear buttons in filters
    const handleResetAllFilter = () => {
        if (openSidebar) {
            handleCloseSidebar();
        }
        setPage(0);
        setResetForm(true);
        setShowSummary(false);
        fetchData({ ...DEFAULT_ORGANIZATION_FILTERS, maxCreditBalance: MAX_BALANCE_CONVERTED, maxCreditLimit: MAX_LIMIT_CONVERTED });
        reset({ ...DEFAULT_ORGANIZATION_FILTERS, maxCreditBalance: MAX_BALANCE_CONVERTED, maxCreditLimit: MAX_LIMIT_CONVERTED });
    };

    const handleResetSingleFilter = (fieldName: string, value?: any) => {
        if (isArray(formValues[fieldName])) {
            const index = formValues[fieldName].indexOf(value);

            if (index > -1) {
                formValues[fieldName].splice(index, 1);
                setValue(fieldName, formValues[fieldName]);
                handleClearFromSummary(fieldName);
                fetchData(formValues);
            }
        } else if (isObject(formValues[fieldName])) {

            delete (formValues[fieldName] as Record<string, string>)[value];

            setValue(fieldName, formValues[fieldName]);
            handleClearFromSummary(fieldName);
            fetchData(formValues);

        } else {
            const fieldsToRemove = [];

            if (fieldName === 'creditBalance') {
                fieldsToRemove.push('minCreditBalance', 'maxCreditBalance');
            } else if (fieldName === 'creditLimit') {
                fieldsToRemove.push('minCreditLimit', 'maxCreditLimit');
            } else {
                fieldsToRemove.push(fieldName);
            }

            fieldsToRemove.forEach((element: string) => {
                if (element === 'maxCreditBalance') {
                    formValues[element] = MAX_BALANCE_CONVERTED;
                    setValue(element, MAX_BALANCE_CONVERTED);
                } else if (element === 'maxCreditLimit') {
                    formValues[element] = MAX_LIMIT_CONVERTED;
                    setValue(element, MAX_LIMIT_CONVERTED);
                } else {
                    formValues[element] = DEFAULT_ORGANIZATION_FILTERS[element];
                    setValue(element, DEFAULT_ORGANIZATION_FILTERS[element]);
                }
                handleClearFromSummary(element);
                fetchData(formValues);
            });
        }
    };

    //---- HANDLE TABLE START ----//
    const [openMenu, setOpenMenuActions] = useState<HTMLElement | null>(null);

    const [actualRow, setActualRow] = useState<any>(null);

    const handleOpenMenu = (event: React.MouseEvent<HTMLElement>) => {
        setOpenMenuActions(event.currentTarget);
    };

    const handleCloseMenu = () => {
        setOpenMenuActions(null);
    };

    const handleCellClick = (params: GridCellParams<any>) => {

        setActualRow(params);

        if (params.field !== 'options') {
            if (isVendor)
                navigate(PATH_DASHBOARD.organizations.vendor.detail(params.id.toString(), params.row.type.toString().toLowerCase()));
            else
                navigate(PATH_DASHBOARD.organizations.customer.detail(params.id.toString(), params.row.type.toString().toLowerCase()));
        }
    };

    const handleSort = (sortModel: GridSortModel) => {
        if (sortModel.length > 0) {
            setOrderBy(sortModel[0].field);
            setOrder(sortModel[0].sort!);
        } else {
            setOrderBy('');
            setOrder('asc');
        }
    };

    const getHeight = () => {
        let height: string | number = "auto";

        if (!dense || organizationList.length === 0) {
            if (isDesktop) height = rowsPerPage === 5 ? 380 : 650;
            else height = rowsPerPage === 5 ? 440 : 700;
        }

        return height;
    };

    const getMaxHeight = () => {
        return isDesktop ? 650 : 700;
    };

    const getStatusColor = (status: string): LabelColor => {

        let color = 'default';

        switch (status) {
            case "Enabled":
                color = 'success';
                break;
            case "Disabled":
                color = 'warning';
                break;
            case "Blocked":
                color = 'error';
                break;
        }

        return color as LabelColor;
    };

    const handleLogs = useCallback((id: string, type: string) => {
        if (isVendor)
            navigate(PATH_DASHBOARD.organizations.vendor.logs(id, type.toLowerCase()));
        else
            navigate(PATH_DASHBOARD.organizations.customer.logs(id, type.toLowerCase()));

    }, [navigate, isVendor]);

    const handleEdit = useCallback((id: string, type: string) => {
        if (isVendor)
            navigate(PATH_DASHBOARD.organizations.vendor.edit(id, type.toLowerCase()));
        else
            navigate(PATH_DASHBOARD.organizations.customer.logs(id, type.toLowerCase()));

    }, [navigate, isVendor]);

    const COLUMNS: GridColDef<OrganizationSearchResult>[] = useMemo(() => [
        {
            field: 'externalId',
            headerName: `${translate('commons.code')}`,
            flex: isDesktop ? 0.6 : undefined,
            minWidth: !isDesktop ? 100 : undefined
        },
        {
            field: 'name',
            headerName: `${translate('commons.name')}`,
            flex: isDesktop ? 1.5 : undefined,
            minWidth: !isDesktop ? 250 : undefined
        },
        {
            field: 'brands',
            headerName: `${translate('commons.brand')}/${translate('commons.category')}`,
            flex: isDesktop ? 1 : undefined,
            minWidth: !isDesktop ? 200 : undefined,
            renderCell: (obj) => {
                return (
                    <Typography variant="body2">
                        {(obj.row.brands && publicBrandList) && publicBrandList.filter(brand => obj.row.brands.includes(brand.id)).map(brand => brand.name).join(', ')}
                    </Typography>
                );
            }
        },
        {
            field: 'regions',
            headerName: `${translate('organization.list.table.region')}`,
            flex: isDesktop ? 1 : undefined,
            minWidth: !isDesktop ? 175 : undefined
        },
        {
            field: 'type',
            headerName: `${translate('organization.list.table.type')}`,
            flex: isDesktop ? 0.75 : undefined,
            minWidth: !isDesktop ? 150 : undefined
        },
        {
            field: 'credit',
            headerName: `${translate('organization.list.table.credit')}`,
            flex: isDesktop ? 0.6 : undefined,
            minWidth: !isDesktop ? 150 : undefined,
            renderCell: (obj) => {
                return (
                    <CurrencyAdapter
                        value={obj.row.creditLimit}
                        currency={CurrencyTypes.EUR}
                        fontSize={'0.875rem'}
                    />
                );

            },
        },
        {
            field: 'status',
            headerName: `${translate('returns.tableHeaders.status')}`,
            flex: isDesktop ? 1 : undefined,
            minWidth: !isDesktop ? 175 : undefined,
            headerAlign: 'center',
            align: "center",
            renderCell: (obj) => {
                return (
                    <Label
                        color={getStatusColor(obj.row.status)}
                        sx={{ textTransform: 'uppercase' }}
                        smallerText={obj.row.status.length >= 15}
                    >
                        {`${translate(`statuses.${obj.row.status[0].toLowerCase() + obj.row.status.slice(1)}`)}`}
                    </Label>
                );
            }
        },
        {
            field: 'options',
            headerName: ``,
            flex: isDesktop ? 0.4 : undefined,
            maxWidth: !isDesktop ? 70 : undefined,
            headerAlign: 'center',
            align: "center",
            sortable: false,
            renderCell: (obj: any) => {
                return (
                    <OptionsComponent
                        openMenu={openMenu}
                        handleOpenMenu={handleOpenMenu}
                        handleCloseMenu={handleCloseMenu}
                        handleLogs={handleLogs}
                        handleEdit={handleEdit}
                        object={obj}
                        currentRow={actualRow}
                        isVendor={isVendor}
                    />
                );
            }
        }
    ], [actualRow, handleLogs, openMenu, translate, isDesktop, handleEdit, publicBrandList, isVendor]);

    useEffect(() => {
        if (totalCount <= rowsPerPage * page) onChangePage(null, 0);
    }, [onChangePage, page, rowsPerPage, totalCount]);
    //---- HANDLE TABLE END ----//

    return (
        <Page title={`${translate(`menu.management.organizations.${isVendor ? "myTitle" : "cusTitle"}`)}`}>
            <Container maxWidth={themeStretch ? false : 'lg'}>

                <HeaderBreadcrumbs
                    heading={`${translate(`menu.management.organizations.${isVendor ? "myTitle" : "cusTitle"}`)}`}
                    links={[
                        { name: `${translate('commons.home')}`, href: PATH_DASHBOARD.root },
                        { name: `${translate('commons.list')}` },
                    ]}
                    action={
                        <Button
                            variant="contained"
                            sx={{ borderRadius: "60px !important" }}
                            component={RouterLink}
                            to={PATH_DASHBOARD.organizations.customer.new}
                            startIcon={<Iconify icon={'eva:plus-fill'} />}
                        >
                            {`${translate('organization.form.title.new')}`}
                        </Button>
                    }
                />

                <Card>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', px: { xs: 2, md: 3.5 }, pb: 2 }}>
                        <Box>
                            <Typography variant="body2" sx={{ mt: 2 }}>
                                {`${translate('organization.messages.subtitle')}`}
                            </Typography>
                        </Box>
                        <Box sx={{ mt: 2, display: 'flex' }}>

                            <FormProvider methods={methods}>
                                <OrganizationFilterSidebar
                                    filters={{ ...formValues, ...(getFiltersFromUrl()) }}
                                    isDefault={isDefault}
                                    isOpen={openSidebar}
                                    onOpen={handleOpenSidebar}
                                    onFilter={handleSearchFilters}
                                    onResetAll={handleResetAllFilter}
                                    onClose={handleCloseSidebar}
                                    reset={resetForm}
                                    resetFormElement={resetFormElement}
                                    setResetFormElement={setResetFormElement}
                                    MAX_BALANCE_CONVERTED={MAX_BALANCE_CONVERTED}
                                    MAX_LIMIT_CONVERTED={MAX_LIMIT_CONVERTED}
                                    setShowSummary={setShowSummary}
                                    isCustomer={!isVendor}
                                />
                            </FormProvider>

                        </Box>
                    </Box>

                    <Divider />

                    <Tabs
                        allowScrollButtonsMobile
                        variant="scrollable"
                        scrollButtons="auto"
                        value={filterStatus}
                        onChange={onChangeFilterStatus}
                        sx={{
                            px: { xs: 0, md: 2 },
                            bgcolor: 'background.neutral'
                        }}
                    >
                        {quickFilters.map((tab) => (
                            <Tab
                                disableRipple
                                key={tab.key}
                                label={tab.label}
                                value={tab.key}
                                icon={
                                    <Label color={tab?.color} sx={{ mr: 1 }}>
                                        {statistics ? renderQuickFilters(tab.key) : ""}
                                    </Label>
                                }
                            />
                        ))}
                    </Tabs>

                    <Divider />

                    <FormProvider methods={methods}>
                        <OrganizationGeneralFilters
                            filters={filters}
                            onFilter={handleSearchFilters}
                            optionsFields={toolbarFiltersList}
                            resetAll={handleResetAllFilter}
                            setShowSummary={setShowSummary}
                            showSummary={showSummary}
                        />
                    </FormProvider>

                    <OrganizationFilterSummary
                        filters={openSidebar ? DEFAULT_ORGANIZATION_FILTERS : { ...formValues, ...(getFiltersFromUrl()) }}
                        isShowReset={!isDefault({ ...formValues, ...(getFiltersFromUrl()) }) && !openSidebar}
                        onResetAll={handleResetAllFilter}
                        onResetFilter={handleResetSingleFilter}
                        show={showSummary}
                        MAX_BALANCE_CONVERTED={MAX_BALANCE_CONVERTED}
                        MAX_LIMIT_CONVERTED={MAX_LIMIT_CONVERTED}
                    />

                    <Divider />

                    <Box>
                        <DataGrid
                            rows={organizationList}
                            columns={COLUMNS}
                            pagination
                            paginationModel={{
                                page: page,
                                pageSize: rowsPerPage
                            }}
                            density={(dense && organizationList.length > 0) ? 'compact' : 'standard'}
                            sortingMode={"server"}
                            onSortModelChange={handleSort}
                            loading={brandLoading || isLoading}
                            slots={{
                                noRowsOverlay: noData,
                                footer: () => (
                                    <Box sx={{
                                        position: 'relative',
                                        width: { xs: "90vw", md: "auto" }
                                    }}>
                                        <TablePagination
                                            rowsPerPageOptions={[5, 10, 15, 30]}
                                            component="div"
                                            count={totalCount}
                                            rowsPerPage={rowsPerPage}
                                            page={page}
                                            onPageChange={onChangePage}
                                            onRowsPerPageChange={onChangeRowsPerPage}
                                            labelRowsPerPage={`${translate('commons.rowsPerPage')}`}
                                            sx={{
                                                overflow: "hidden",
                                                "& .MuiTablePagination-input": {
                                                    ml: { xs: 0.5, md: "default" },
                                                    mr: { xs: 3.5, md: "default" }
                                                }
                                            }}
                                        />
                                        <FormControlLabel
                                            control={<Switch checked={dense} onChange={onChangeDense} />}
                                            label={`${translate('commons.dense')}`}
                                            sx={{
                                                px: { xs: 0, sm: 3 },
                                                py: { xs: 0, sm: 1.5 },
                                                pb: { xs: 1.5, sm: 0 },
                                                mx: 0,
                                                top: 0,
                                                justifyContent: "center",
                                                width: { xs: "90vw", sm: "auto" },
                                                position: { sm: 'absolute' }
                                            }}
                                        />
                                    </Box>
                                )
                            }}
                            disableColumnMenu
                            pageSizeOptions={[5, 10, 15, 30]}
                            disableRowSelectionOnClick
                            disableColumnResize
                            onCellClick={(param) => {
                                if (!window.getSelection()?.toString())
                                    handleCellClick(param);
                            }}
                            sx={{
                                ...DataGridStyle,
                                cursor: 'pointer',
                                height: getHeight(),
                                maxHeight: getMaxHeight()
                            }}
                        />
                    </Box>

                </Card>
            </Container>

        </Page>
    );
}

type OptionsComponentProps = {
    openMenu: HTMLElement | null,
    handleOpenMenu: (event: React.MouseEvent<HTMLElement>) => void,
    handleCloseMenu: () => void,
    handleLogs: (id: string, type: string) => void,
    handleEdit: (id: string, type: string) => void,
    object: any,
    currentRow: any,
    isVendor: boolean
};

function OptionsComponent({ openMenu, handleOpenMenu, handleCloseMenu, handleLogs, handleEdit, object, currentRow, isVendor }: OptionsComponentProps) {

    const { translate } = useLocales();

    return (
        <TableMoreMenu
            showMenu={currentRow && object.id === currentRow.id}
            open={openMenu}
            onOpen={(event) => handleOpenMenu(event)}
            onClose={() => handleCloseMenu()}
            actions={
                <>
                    {isVendor && <PermissionBasedGuard permissions={[OrganizationPermissionTypes.OrganizationEdit]} >
                        <MenuItem
                            onClick={() => {
                                handleEdit(object.id, object.row.type);
                                handleCloseMenu();
                            }}
                        >
                            {`${translate("commons.edit")}`}
                        </MenuItem>
                    </PermissionBasedGuard>}

                    <PermissionBasedGuard permissions={[OrganizationPermissionTypes.OrganizationViewLogs]} >
                        <MenuItem
                            onClick={() => {
                                handleLogs(object.id, object.row.type);
                                handleCloseMenu();
                            }}
                        >
                            {`${translate("commons.logs")}`}
                        </MenuItem>
                    </PermissionBasedGuard>
                </>
            }
        />
    );
}
