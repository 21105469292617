import { useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Tab, Tabs, Card, Box, FormControlLabel, Switch, TablePagination, MenuItem, Divider, Container, Typography, Tooltip } from '@mui/material';
import { dispatch, RootState } from 'src/redux/store';
import { useSelector } from 'react-redux';
import useLocales from 'src/appHooks/useLocales';
import Label, { LabelColor } from 'src/components/label';
import useTable from 'src/appHooks/useTable';
import useTabs from 'src/appHooks/useTabs';
import { PATH_DASHBOARD } from 'src/routes/paths';
import { useForm } from 'react-hook-form';
import { DataGrid, GridCellParams, GridColDef, GridSortModel } from '@mui/x-data-grid';
import FormProvider from 'src/components/hook-form';
import { SparePartsFilters, SparePartSearchResult, DEFAULT_SPAREPARTS_FILTERS } from 'src/@types/spareParts';
import { sparePartsOperations } from 'src/redux/spareParts';
import { DataGridStyle } from 'src/utils/DataGridStyle';
import { noData } from 'src/components/empty-content/EmptyContent';
import TableMoreMenu from 'src/appComponents/TableMoreMenu';
import PermissionBasedGuard from 'src/guards/PermissionBasedGuard';
import Iconify from 'src/components/iconify';
import { useSnackbar } from 'notistack';
import { OrganizationPermissionTypes } from 'src/@types/permissions';
import Page from 'src/appComponents/Page';
import HeaderBreadcrumbs from 'src/components/custom-breadcrumbs';
import { useSettingsContext } from 'src/components/settings';
import SparePartsFilterToolbar from './SparePartsFilterToolbar';
import { isArray, isEqual, isObject, remove } from 'lodash';
import useResponsive from 'src/hooks/useResponsive';
import { setFiltersInUrl } from 'src/redux/spareParts/spareParts-slices';
import CurrencyAdapter from 'src/appComponents/CurrencyAdapter';
import { OrderAmount } from 'src/@types/orders';
import GenericFilterSidebar from 'src/utils/list/sidebar/GenericFilterSidebar';
import { FilterListType, QuickFilters } from 'src/@types/list';
import sparePartsServices from 'src/services/sparePartsServices';
import GenericFilterSummary from 'src/utils/list/summary/GenericFilterSummary';

const BASIC_FILTER_OPTIONS = {
    pageIndex: 0,
    pageSize: 10
};

interface SparePartsListProps {
    isVendor: boolean
}

export default function SparePartsList({ isVendor }: SparePartsListProps) {
    const {
        page,
        setPage,
        order,
        setOrder,
        orderBy,
        setOrderBy,
        rowsPerPage,
        setRowsPerPage,
        dense,
        onChangePage,
        onChangeDense,
        onChangeRowsPerPage,
    } = useTable();

    const navigate = useNavigate();

    const { themeStretch } = useSettingsContext();

    const isDesktop = useResponsive('up', 'md');

    const { translate, currentLang } = useLocales();

    const { enqueueSnackbar } = useSnackbar();

    const { isLoading, sparePartsList, totalCount, statistics, pageIndex, pageSize } = useSelector((state: RootState) => state.spareParts);

    const { currentTab: filterStatus, onChangeTab: onChangeFilterStatus } = useTabs('');

    const [filters, setFilters] = useState<SparePartsFilters>({ ...DEFAULT_SPAREPARTS_FILTERS });

    const [lastUsedLang, setLastUsedLang] = useState(currentLang.label);

    const [openSidebar, setOpenSidebar] = useState(false);

    const [resetForm, setResetForm] = useState(true);

    const [resetFormElement, setResetFormElement] = useState("");

    const [showSummary, setShowSummary] = useState(true);

    const methods = useForm({ defaultValues: DEFAULT_SPAREPARTS_FILTERS });

    const { reset, getValues, watch, setValue } = methods;

    var formValues = watch();

    //---- IS DEFAULT - START ----//
    // Checks if there are some filters selected 
    const statsKeysToRemove: string[] = useMemo(() => ["pageIndex", "pageSize", "sortField", "sortAscending", "onlyEnabled", "onlyDisabled", "onlyDeprecated"], []);

    const fullKeysToRemove: string[] = useMemo(() => ["all"], []);

    const statsKeyRemover = useCallback((key: string) => {
        return !(statsKeysToRemove.includes(key));
    }, [statsKeysToRemove]);

    const fullKeyRemover = useCallback((key: string) => {
        return !(statsKeysToRemove.includes(key) || fullKeysToRemove.includes(key));
    }, [statsKeysToRemove, fullKeysToRemove]);

    const isDefault = useCallback((filter: SparePartsFilters, controller?: SparePartsFilters, forStats?: boolean) => {

        const check = controller ? controller : DEFAULT_SPAREPARTS_FILTERS;

        const found = remove(Object.keys(check), forStats ? statsKeyRemover : fullKeyRemover)
            .find((element) => {
                if (isArray(filter[element]) && isArray(check[element])) {
                    return !isEqual(filter[element], check[element]);
                } else if (isObject(filter[element]) && isObject(check[element])) {

                    const strFilters = JSON.stringify(filter[element], Object.keys(filter[element]).sort());

                    const strDefaultFilters = JSON.stringify(check[element], Object.keys(check[element]).sort());

                    return strFilters !== strDefaultFilters;
                } else {
                    return filter[element] !== check[element];
                }
            });

        return (!found);
    }, [statsKeyRemover, fullKeyRemover]);
    //---- IS DEFAULT - END ----//

    //---- QUICKFILTERS START ----//
    const quickFilters: QuickFilters[] = useMemo(() => [
        {
            key: '',
            label: `${translate('commons.all')}`,
            color: 'primary',
        },
        {
            key: 'enabled',
            label: `${translate('statuses.enabled')}`,
            color: 'success',
        },
        {
            key: 'disabled',
            label: `${translate('statuses.disabled')}`,
            color: 'error',
        },
        {
            key: 'deprecated',
            label: `${translate('statuses.deprecated')}`,
            color: 'warning',
        }
    ], [translate]);

    const renderQuickFilters = (key: string) => {
        if (statistics) {
            switch (key) {
                case "":
                    return statistics.all;
                case "enabled":
                    return statistics.enabled;
                case "disabled":
                    return statistics.disable;
                case "deprecated":
                    return statistics.deprecated;
                default:
                    return 0;
            }
        } else return 0;
    };
    //---- QUICKFILTERS END ----//

    //---- SIDEBAR FILTERS ----// 
    const filtersInSidebar: FilterListType[] = useMemo(() => [
        {
            name: "stockQuantity",
            label: `${translate('spareParts.list.filters.stockQuantity')}`,
            type: "Slider",
            stepValue: 5,
            stepsNumber: 5
        },
        {
            name: "reservationQuantity",
            label: `${translate('spareParts.list.filters.reservationQuantity')}`,
            type: "Slider",
            stepValue: 5,
            stepsNumber: 5
        },
        {
            name: "availableQuantity",
            label: `${translate('spareParts.list.filters.availableQuantity')}`,
            type: "Slider",
            stepValue: 5,
            stepsNumber: 5
        },
        {
            name: "returnQuantity",
            label: `${translate('spareParts.list.filters.returnQuantity')}`,
            type: "Slider",
            stepValue: 5,
            stepsNumber: 5
        },
        {
            name: "pendingReception",
            label: `${translate('spareParts.list.tableHeaders.pendingReception')}`,
            type: "Slider",
            stepValue: 5,
            stepsNumber: 5
        },
        {
            name: "tagsSection",
            label: `${translate("commons.tags")}`,
            type: "Section"
        },
        {
            name: "tags",
            label: `${translate("commons.tags")}`,
            type: "Autocomplete",
            service: sparePartsServices.searchVendorTags
        }
    ], [translate]);

    //---- CLOSE AND OPEN SIDEBAR FUNC
    const handleOpenSidebar = () => {
        setOpenSidebar(true);
    };

    const handleCloseSidebar = () => {
        if (resetForm) {
            reset(DEFAULT_SPAREPARTS_FILTERS);
        };
        setOpenSidebar(false);
    };

    //---- CLEAR FROM SUMMARY FUNC
    const handleClearFromSummary = (section: string) => {
        setResetFormElement(section);
        if (isDefault(formValues)) {
            setResetForm(true);
        }
    };

    //---- FILTERS IN URL GET/SET START ----//
    const location = useLocation();

    const [firstRender, setFirstRender] = useState(true);

    const [lastStatsFilters, setLastStatsFilters] = useState<any>(BASIC_FILTER_OPTIONS);

    const check = (allFilters: any, filter: string) => {

        if (filter.includes("min") || filter.includes("max")) {
            return allFilters[filter] !== null;
        } else if (allFilters[filter]) {
            if (filter === "tags") {
                if (allFilters[filter].length > 0) {
                    return true;
                }
            } else if (allFilters[filter] !== DEFAULT_SPAREPARTS_FILTERS[filter]) {
                return true;
            }
        }

        return false;
    };

    const updateFiltersInUrl = useCallback((filters: any) => {

        let queryString = Object.keys(filters).filter(x => check(filters, x))
            .map((key) => {

                if (isArray(filters[key]) || isObject(filters[key])) {
                    return `${encodeURIComponent(key)}=${encodeURIComponent(JSON.stringify(filters[key]).replace(/stellantis-europe-/g, ""))}`;
                }

                return `${encodeURIComponent(key)}=${encodeURIComponent(filters[key])}`;
            })
            .join('&');

        if (queryString) queryString = "#" + queryString;

        dispatch(setFiltersInUrl(queryString));

        navigate(location.pathname + queryString, { replace: true });

    }, [location, navigate]);

    const getPageAndSize = useCallback(() => {
        if (firstRender) {
            return [pageSize, pageIndex];
        }
        else return [rowsPerPage, page];
    }, [firstRender, page, pageIndex, pageSize, rowsPerPage]);

    const getFiltersFromUrl = useCallback(() => {

        const { hash } = location;

        let searchFilters = {};

        const [pageSizeFunc, pageIndexFunc] = getPageAndSize();

        if (hash) {

            const cleanedHash = hash.slice(1);

            const decodedQuery = decodeURIComponent(cleanedHash);

            const searchParams = new URLSearchParams(decodedQuery);

            searchFilters = {
                pageIndex: pageIndexFunc,
                pageSize: pageSizeFunc,
                sortField: orderBy || null,
                sortAscending: orderBy ? (order === 'desc' ? true : false) : null,
                all: searchParams.get('all'),
                minStockQuantity: searchParams.get('minStockQuantity'),
                maxStockQuantity: searchParams.get('maxStockQuantity'),
                minReservationQuantity: searchParams.get('minReservationQuantity'),
                maxReservationQuantity: searchParams.get('maxReservationQuantity'),
                minAvailableQuantity: searchParams.get('minAvailableQuantity'),
                maxAvailableQuantity: searchParams.get('maxAvailableQuantity'),
                minReturnQuantity: searchParams.get('minReturnQuantity'),
                maxReturnQuantity: searchParams.get('maxReturnQuantity'),
                minPendingReception: searchParams.get('minPendingReception'),
                maxPendingReception: searchParams.get('maxPendingReception'),
                tags: searchParams.get('tags') ? JSON.parse(searchParams.get('tags')!) : null,
                onlyEnabled: searchParams.get('onlyEnabled'),
                onlyDisabled: searchParams.get('onlyDisabled'),
                onlyDeprecated: searchParams.get('onlyDeprecated'),
                customFields: searchParams.get('customFields') ? JSON.parse(searchParams.get('customFields')!.replace(/spareparts/g, "stellantis-europe-spareparts")) : null
            };

        } else {

            searchFilters = {
                pageIndex: pageIndexFunc,
                pageSize: pageSizeFunc,
                sortField: orderBy || null,
                sortAscending: orderBy ? (order === 'desc' ? true : false) : null
            };
        }

        searchFilters = Object.fromEntries(Object.entries(searchFilters).filter(([_, value]) => value !== null && value !== 'null'));

        return searchFilters as SparePartsFilters;

    }, [getPageAndSize, location, order, orderBy]);
    //---- FILTERS IN URL GET/SET END ----//

    //---- FETCH DATA FUNC ----//
    // Gets all filter values ​​other than the default ones and puts them in the url
    const getMinMaxValues = (values: SparePartsFilters, fieldName: string) => {

        if (values[fieldName] !== DEFAULT_SPAREPARTS_FILTERS[fieldName]) return values[fieldName];

        const counterpart = fieldName.includes('min') ? fieldName.replace('min', 'max') : fieldName.replace('max', 'min');

        if (values[counterpart] === DEFAULT_SPAREPARTS_FILTERS[counterpart]) return null;

        return DEFAULT_SPAREPARTS_FILTERS[fieldName];
    };

    const fetchData = useCallback(async (values: SparePartsFilters) => {

        var searchFilters: any = {};

        if (values === DEFAULT_SPAREPARTS_FILTERS) {
            searchFilters = BASIC_FILTER_OPTIONS;
        } else {

            searchFilters = {
                pageIndex: page,
                pageSize: rowsPerPage,
                sortField: orderBy,
                sortAscending: order === 'desc' ? false : true,
                all: values.all !== DEFAULT_SPAREPARTS_FILTERS.all ? values.all : null,
                minStockQuantity: getMinMaxValues(values, "minStockQuantity"),
                maxStockQuantity: getMinMaxValues(values, "maxStockQuantity"),
                minReservationQuantity: getMinMaxValues(values, 'minReservationQuantity'),
                maxReservationQuantity: getMinMaxValues(values, 'maxReservationQuantity'),
                minAvailableQuantity: getMinMaxValues(values, 'minAvailableQuantity'),
                maxAvailableQuantity: getMinMaxValues(values, 'maxAvailableQuantity'),
                minReturnQuantity: getMinMaxValues(values, 'minReturnQuantity'),
                maxReturnQuantity: getMinMaxValues(values, 'maxReturnQuantity'),
                minPendingReception: getMinMaxValues(values, 'minPendingReception'),
                maxPendingReception: getMinMaxValues(values, 'maxPendingReception'),
                tags: JSON.stringify(values.tags) !== JSON.stringify(DEFAULT_SPAREPARTS_FILTERS.tags) ? values.tags : null,
                onlyEnabled: values.onlyEnabled !== DEFAULT_SPAREPARTS_FILTERS.onlyEnabled ? values.onlyEnabled : null,
                onlyDisabled: values.onlyDisabled !== DEFAULT_SPAREPARTS_FILTERS.onlyDisabled ? values.onlyDisabled : null,
                onlyDeprecated: values.onlyDeprecated !== DEFAULT_SPAREPARTS_FILTERS.onlyDeprecated ? values.onlyDeprecated : null,
                customFields: JSON.stringify(values.customFields) !== JSON.stringify(DEFAULT_SPAREPARTS_FILTERS.customFields) ? values.customFields : null
            };
        }

        updateFiltersInUrl(searchFilters);
    }, [order, orderBy, page, rowsPerPage, updateFiltersInUrl]);

    //---- SEARCH FOR ITEMS AND STATISTICS FUNC ----//
    // This function is used to call APIs and get SpareParts' list and statistics using filters
    const adjustLastFiltered = useCallback((values: SparePartsFilters) => {

        return {
            pageIndex: 0,
            pageSize: 10,
            sortField: "",
            sortAscending: false,
            all: values.all !== DEFAULT_SPAREPARTS_FILTERS.all ? values.all : null,
            minStockQuantity: getMinMaxValues(values, "minStockQuantity"),
            maxStockQuantity: getMinMaxValues(values, "maxStockQuantity"),
            minReservationQuantity: getMinMaxValues(values, 'minReservationQuantity'),
            maxReservationQuantity: getMinMaxValues(values, 'maxReservationQuantity'),
            minAvailableQuantity: getMinMaxValues(values, 'minAvailableQuantity'),
            maxAvailableQuantity: getMinMaxValues(values, 'maxAvailableQuantity'),
            minReturnQuantity: getMinMaxValues(values, 'minReturnQuantity'),
            maxReturnQuantity: getMinMaxValues(values, 'maxReturnQuantity'),
            minPendingReception: getMinMaxValues(values, 'minPendingReception'),
            maxPendingReception: getMinMaxValues(values, 'maxPendingReception'),
            tags: JSON.stringify(values.tags) !== JSON.stringify(DEFAULT_SPAREPARTS_FILTERS.tags) ? values.tags : null,
            onlyEnabled: values.onlyEnabled !== DEFAULT_SPAREPARTS_FILTERS.onlyEnabled ? values.onlyEnabled : null,
            onlyDisabled: values.onlyDisabled !== DEFAULT_SPAREPARTS_FILTERS.onlyDisabled ? values.onlyDisabled : null,
            onlyDeprecated: values.onlyDeprecated !== DEFAULT_SPAREPARTS_FILTERS.onlyDeprecated ? values.onlyDeprecated : null,
            customFields: JSON.stringify(values.customFields) !== JSON.stringify(DEFAULT_SPAREPARTS_FILTERS.customFields) ? values.customFields : null,
        };
    }, []);

    const onSearch = useCallback((filtersFromUrl: SparePartsFilters) => {

        updateFiltersInUrl(filtersFromUrl);

        const customFieldsFromUrl: Record<string, string> = Object.entries(filtersFromUrl.customFields || {})
            .map(([k, val]) => ({
                key: "customFields." + k,
                value: val
            }))
            .reduce((obj, item) => Object.assign(obj, { [item.key]: item.value }), {});

        const updatedFiltersFromUrl = { ...filtersFromUrl, ...customFieldsFromUrl };

        delete updatedFiltersFromUrl.customFields;

        let searchStats = false;

        const strFiltersFromUrl = JSON.stringify(filtersFromUrl, Object.keys(filtersFromUrl).sort());

        const strBasicFilters = JSON.stringify(BASIC_FILTER_OPTIONS, Object.keys(BASIC_FILTER_OPTIONS).sort());

        if (firstRender || strFiltersFromUrl === strBasicFilters || !isDefault(filtersFromUrl, lastStatsFilters as SparePartsFilters, true)) {
            searchStats = true;
            setLastStatsFilters(adjustLastFiltered(filtersFromUrl));
        }

        if (isVendor) {
            dispatch(sparePartsOperations.searchVendorSpareParts(updatedFiltersFromUrl));
            if (searchStats) dispatch(sparePartsOperations.searchVendorStatistic(updatedFiltersFromUrl));
        }
        else {
            dispatch(sparePartsOperations.searchCustomerSpareParts(updatedFiltersFromUrl));
            if (searchStats) dispatch(sparePartsOperations.searchCustomerStatistic(updatedFiltersFromUrl));
        }

        if (firstRender) setFirstRender(false);

    }, [isVendor, updateFiltersInUrl, firstRender, adjustLastFiltered, isDefault, lastStatsFilters]);

    //---- SEARCH FOR ITEMS AND STATISTICS HOOK ----//
    // This hook is used to call onSearch when filters or language are changed
    const isOrderDiff = useCallback((filtersToCheck: SparePartsFilters, olderFilters: SparePartsFilters) => {

        const differencesToOld = Object.keys(filtersToCheck).filter((filter) => filtersToCheck[filter] !== olderFilters[filter]);

        if (differencesToOld.includes("sortField") || differencesToOld.includes("sortAscending")) return true;

        const differencesToFilters = Object.keys(olderFilters).filter((filter) => olderFilters[filter] !== filtersToCheck[filter]);

        return differencesToFilters.includes("sortField") || differencesToFilters.includes("sortAscending");

    }, []);

    useEffect(() => {

        let searchFilters = getFiltersFromUrl();

        switch (filterStatus) {
            case "enabled":
                searchFilters.onlyEnabled = true;
                searchFilters.onlyDisabled = undefined;
                searchFilters.onlyDeprecated = undefined;
                break;
            case "disabled":
                searchFilters.onlyEnabled = undefined;
                searchFilters.onlyDisabled = true;
                searchFilters.onlyDeprecated = undefined;
                break;
            case "deprecated":
                searchFilters.onlyEnabled = undefined;
                searchFilters.onlyDisabled = undefined;
                searchFilters.onlyDeprecated = true;
                break;
            default:
                searchFilters.onlyEnabled = undefined;
                searchFilters.onlyDisabled = undefined;
                searchFilters.onlyDeprecated = undefined;
        }

        const strSearchFilters = JSON.stringify(searchFilters, Object.keys(searchFilters).sort());

        const strFilters = JSON.stringify(filters, Object.keys(filters).sort());

        if (strSearchFilters !== strFilters || lastUsedLang !== currentLang.label) {

            if (!firstRender && isOrderDiff(searchFilters, filters)) searchFilters.pageIndex = 0;

            setFilters(searchFilters as SparePartsFilters);
            onSearch(searchFilters as SparePartsFilters);
            setLastUsedLang(currentLang.label);
            if (searchFilters.pageIndex !== page) setPage(searchFilters.pageIndex);
            if (searchFilters.pageSize !== rowsPerPage) setRowsPerPage(searchFilters.pageSize);
        }

    }, [location, page, rowsPerPage, orderBy, order, filterStatus, firstRender, isOrderDiff, currentLang, filters, getFiltersFromUrl, lastUsedLang, onSearch, setPage, setRowsPerPage]);

    //---- FILTERS SEARCH FUNC ----//
    // Used for search buttons in filters
    const handleSearchFilters = () => {
        fetchData(getValues());
        setResetForm(false);
        setOpenSidebar(false);
        setPage(0);
    };

    //---- FILTERS RESET - START ----//
    // Used for full clear buttons in filters
    const handleResetAllFilter = () => {
        if (openSidebar) {
            handleCloseSidebar();
        }
        setPage(0);
        setResetForm(true);
        setShowSummary(false);
        fetchData(DEFAULT_SPAREPARTS_FILTERS);
        reset(DEFAULT_SPAREPARTS_FILTERS);
    };

    const handleResetSingleFilter = (fieldName: string, value?: any) => {

        if (isArray(formValues[fieldName])) {
            const index = formValues[fieldName].indexOf(value);

            if (index > -1) {
                formValues[fieldName].splice(index, 1);
                setValue(fieldName, formValues[fieldName]);
                handleClearFromSummary(fieldName);
                fetchData(formValues);
            }
        } else if (isObject(formValues[fieldName])) {

            delete (formValues[fieldName] as Record<string, string>)[value];

            setValue(fieldName, formValues[fieldName]);
            handleClearFromSummary(fieldName);
            fetchData(formValues);

        } else {
            let capitalName = fieldName.charAt(0).toUpperCase() + fieldName.slice(1);
            let minName = "min" + capitalName;
            let maxName = "max" + capitalName;
            formValues[minName] = DEFAULT_SPAREPARTS_FILTERS[minName];
            formValues[maxName] = DEFAULT_SPAREPARTS_FILTERS[maxName];
            setValue(minName, DEFAULT_SPAREPARTS_FILTERS[minName]);
            setValue(maxName, DEFAULT_SPAREPARTS_FILTERS[maxName]);
            handleClearFromSummary(fieldName);
            fetchData(formValues);
        }
    };
    //---- FILTERS RESET - END ----//

    //---- HANDLE TABLE START ----//
    const [openMenu, setOpenMenuActions] = useState<HTMLElement | null>(null);

    const [actualRow, setActualRow] = useState<any>(null);

    const handleOpenMenu = (event: React.MouseEvent<HTMLElement>) => {
        setOpenMenuActions(event.currentTarget);
    };

    const handleCloseMenu = () => {
        setOpenMenuActions(null);
    };

    const getStatusColor = useCallback((status: boolean): LabelColor => {
        let statusColor = "default";

        const check = status ? "enabled" : "disabled";

        quickFilters.forEach(object => {
            if (object.key.toLowerCase() === check) {
                statusColor = object.color;
            }
        });

        return statusColor as LabelColor;
    }, [quickFilters]);

    const handleCellClick = (params: GridCellParams<any>) => {

        setActualRow(params);

        if (params.field !== "options") {
            navigate(PATH_DASHBOARD.spareParts.detail(params.id.toString()));
        }
    };

    const handleSort = (sortModel: GridSortModel) => {
        if (sortModel.length > 0) {
            setOrderBy(sortModel[0].field);
            setOrder(sortModel[0].sort!);
        } else {
            setOrderBy("");
            setOrder("asc");
        }
    };

    const getHeight = () => {
        let height: string | number = "auto";

        if (!dense || sparePartsList.length === 0) {
            if (isDesktop) height = rowsPerPage === 5 ? 380 : 650;
            else height = rowsPerPage === 5 ? 440 : 700;
        }

        return height;
    };

    const getMaxHeight = () => {
        return isDesktop ? 650 : 700;
    };

    const handleLogs = useCallback((id: string) => {
        navigate(PATH_DASHBOARD.spareParts.logs(id));
    }, [navigate]);

    const handleEnable = useCallback(async (id: string) => {
        try {
            await dispatch(sparePartsOperations.enableSparePart(id)).unwrap();

            onSearch(getFiltersFromUrl() as SparePartsFilters);

            enqueueSnackbar(`${translate('commons.enabled')}`, {
                variant: "success",
                autoHideDuration: 3000,
                anchorOrigin: { vertical: "top", horizontal: "right" }
            });

        } catch (e) {
            enqueueSnackbar(`${translate('commons.error')}`, {
                variant: "error",
                autoHideDuration: 3000,
                anchorOrigin: { vertical: "top", horizontal: "right" }
            });
        }
    }, [enqueueSnackbar, getFiltersFromUrl, onSearch, translate]);

    const handleDisable = useCallback(async (id: string) => {
        try {

            await dispatch(sparePartsOperations.disableSparePart(id)).unwrap();

            onSearch(getFiltersFromUrl() as SparePartsFilters);

            enqueueSnackbar(`${translate('commons.disabled')}`, {
                variant: "success",
                autoHideDuration: 3000,
                anchorOrigin: { vertical: "top", horizontal: "right" }
            });
        }
        catch (e) {
            console.error(e);
            enqueueSnackbar(`${translate('commons.error')}`, {
                variant: "error",
                autoHideDuration: 3000,
                anchorOrigin: { vertical: "top", horizontal: "right" }
            });
        }
    }, [enqueueSnackbar, getFiltersFromUrl, onSearch, translate]);

    const handleEnableDisable = useCallback((id: string, enabled: boolean) => {
        if (enabled) {
            handleDisable(id);
        } else {
            handleEnable(id);
        }
    }, [handleDisable, handleEnable]);

    const getPrice = (prices: OrderAmount) => {
        if (!prices)
            return 0;

        if (prices.withoutVat) {
            return prices.withoutVat;
        }

        if (prices.withVat) {
            return prices.withVat;
        }

        return '—';
    };

    const COLUMNS: GridColDef<SparePartSearchResult>[] = useMemo(() => [
        {
            field: 'externalId',
            headerName: `${translate('spareParts.list.tableHeaders.partNr')}`,
            flex: isDesktop ? 1.1 : undefined,
            minWidth: !isDesktop ? 250 : undefined
        },
        {
            field: 'name',
            headerName: `${translate('spareParts.list.tableHeaders.partDesc')}`,
            flex: isDesktop ? 1.25 : undefined,
            minWidth: !isDesktop ? 250 : undefined
        },
        {
            field: 'depositRef',
            headerName: `${translate('spareParts.list.tableHeaders.depositRef')}`,
            flex: isDesktop ? 0.75 : undefined,
            minWidth: !isDesktop ? 250 : undefined
        },
        {
            field: 'family',
            headerName: `${translate('spareParts.list.tableHeaders.supplierFamily')}`,
            flex: isDesktop ? 1.5 : undefined,
            minWidth: !isDesktop ? 250 : undefined,
            renderCell: (obj) =>
                <Box sx={{ display: 'flex', flexDirection: 'column', width: '80%' }}>
                    <Typography variant='subtitle2' >
                        {obj.row.family.code}
                    </Typography>
                    <Tooltip title={obj.row.family.name}>
                        <Typography variant='body2' textOverflow={'ellipsis'} overflow={'hidden'} >
                            {obj.row.family.name}
                        </Typography>
                    </Tooltip>
                </Box>
        },
        {
            field: 'sellAmount',
            headerName: `${translate('spareParts.list.tableHeaders.sellingPrice')}`,
            flex: isDesktop ? 1 : undefined,
            minWidth: !isDesktop ? 250 : undefined,
            renderCell: (obj) => {
                return (
                    <CurrencyAdapter
                        value={getPrice(obj.row.sellAmount)}
                        currency={obj.row.sellAmount.currentCurrency}
                        noVatAsterisk={!!obj.row.sellAmount.withoutVat}
                        fontSize={'0.875rem'}
                    />
                );
            }
        },
        {
            field: 'stockQuantity',
            headerName: `${translate('spareParts.list.tableHeaders.stockQuantity')}`,
            flex: isDesktop ? 0.7 : undefined,
            minWidth: !isDesktop ? 250 : undefined,
            headerAlign: 'center',
            align: "center"
        },
        {
            field: 'status',
            headerName: `${translate('commons.status')}`,
            flex: isDesktop ? 1 : undefined,
            minWidth: !isDesktop ? 250 : undefined,
            headerAlign: 'center',
            align: "center",
            renderCell: (obj) => {
                return (
                    <Label
                        color={getStatusColor(obj.row.enabled)}
                        sx={{ textTransform: 'uppercase' }}
                    >
                        {`${translate(`statuses.${obj.row.enabled ? "enabled" : "disabled"}`)}`}
                    </Label>
                );
            }
        },
        {
            field: 'deprecated',
            headerName: `${translate('spareParts.list.tableHeaders.deprecated')}`,
            flex: isDesktop ? 1 : undefined,
            minWidth: !isDesktop ? 250 : undefined,
            headerAlign: 'center',
            align: 'center',
            renderCell: (obj) => {
                return (
                    <Iconify
                        icon={obj.row.deprecated ? 'eva:checkmark-circle-fill' : 'eva:close-circle-outline'}
                        sx={{
                            width: 20,
                            height: 20,
                            color: 'success.main',
                            ...(!obj.row.deprecated && { color: 'error.main' }),
                        }}
                    />
                );
            }
        },
        {
            field: 'options',
            headerName: ``,
            flex: isDesktop ? 0.3 : undefined,
            maxWidth: !isDesktop ? 70 : undefined,
            headerAlign: 'center',
            align: "center",
            sortable: false,
            renderCell: (obj) => {
                return (
                    <OptionsComponent
                        openMenu={openMenu}
                        handleOpenMenu={handleOpenMenu}
                        handleCloseMenu={handleCloseMenu}
                        handleLogs={handleLogs}
                        handleEnableDisable={handleEnableDisable}
                        object={obj}
                        currentRow={actualRow}
                    />
                );
            }
        }
    ], [actualRow, getStatusColor, handleEnableDisable, handleLogs, isDesktop, openMenu, translate]);
    //---- HANDLE TABLE END ----//

    useEffect(() => {
        if (totalCount <= rowsPerPage * page) onChangePage(null, 0);
    }, [onChangePage, page, rowsPerPage, totalCount]);

    return (
        <Page title={`${translate('menu.management.spareParts.title')}`}>
            <Container maxWidth={themeStretch ? false : 'lg'}>

                <HeaderBreadcrumbs
                    heading={`${translate('menu.management.spareParts.title')}`}
                    links={[
                        { name: `${translate('commons.home')}`, href: PATH_DASHBOARD.root },
                        { name: `${translate('menu.management.spareParts.list')}` }
                    ]} />

                <Card>

                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            px: { xs: 2, md: 3.5 }, pb: 2
                        }}
                    >
                        <Box>
                            <Typography variant="body2" sx={{ mt: 2 }}>
                                {`${translate('spareParts.detail.description')}`}
                            </Typography>
                        </Box>

                        <Box sx={{ mt: 2, display: 'flex' }}>
                            <FormProvider methods={methods}>
                                <GenericFilterSidebar
                                    isOpen={openSidebar}
                                    onOpen={handleOpenSidebar}
                                    onClose={handleCloseSidebar}
                                    onFilter={handleSearchFilters}
                                    onResetAll={handleResetAllFilter}
                                    defaultFilters={DEFAULT_SPAREPARTS_FILTERS}
                                    resetTrigger={resetForm}
                                    filterValues={{ ...formValues, ...(getFiltersFromUrl()) }}
                                    filterList={filtersInSidebar}
                                    isDefault={isDefault}
                                    setShowSummary={setShowSummary}
                                    customfieldContext={["SpareParts"]}
                                    resetFormElement={resetFormElement}
                                    setResetFormElement={setResetFormElement}
                                />
                            </FormProvider>
                        </Box>
                    </Box>

                    <Tabs
                        allowScrollButtonsMobile
                        variant="scrollable"
                        scrollButtons={!isDesktop}
                        value={filterStatus}
                        onChange={onChangeFilterStatus}
                        sx={{
                            px: { xs: 0, md: 2 },
                            bgcolor: 'background.neutral'
                        }}
                    >
                        {quickFilters.map((tab) => (
                            <Tab
                                disableRipple
                                key={tab.key}
                                label={tab.label}
                                value={tab.key}
                                icon={
                                    <Label color={tab?.color} sx={{ mr: 1 }}>
                                        {statistics ? renderQuickFilters(tab.key) : ""}
                                    </Label>
                                }
                            />
                        ))}
                    </Tabs>

                    <FormProvider methods={methods}>
                        <SparePartsFilterToolbar
                            filters={filters}
                            onFilter={handleSearchFilters}
                            resetAll={handleResetAllFilter}
                            setShowSummary={setShowSummary}
                            showSummary={showSummary}
                        />
                    </FormProvider>

                    <GenericFilterSummary
                        showSummary={showSummary && !openSidebar && !isDefault({ ...formValues, ...(getFiltersFromUrl()) })}
                        defaultFilters={DEFAULT_SPAREPARTS_FILTERS}
                        filterValues={{ ...formValues, ...getFiltersFromUrl() }}
                        filterList={filtersInSidebar}
                        onResetFilter={handleResetSingleFilter}
                        onResetAll={handleResetAllFilter}
                        customfieldContext={["SpareParts"]}
                    />

                    <Divider />

                    <Box>
                        <DataGrid
                            rows={sparePartsList}
                            columns={COLUMNS}
                            pagination
                            paginationModel={{
                                page: page,
                                pageSize: rowsPerPage
                            }}
                            density={(dense && sparePartsList.length > 0) ? 'compact' : 'standard'}
                            sortingMode={"server"}
                            onSortModelChange={handleSort}
                            loading={isLoading}
                            slots={{
                                noRowsOverlay: noData,
                                footer: () => (
                                    <Box
                                        sx={{
                                            position: 'relative',
                                            width: { xs: "90vw", md: "auto" }
                                        }}
                                    >
                                        <TablePagination
                                            rowsPerPageOptions={[5, 10, 15, 30]}
                                            component="div"
                                            count={totalCount}
                                            rowsPerPage={rowsPerPage}
                                            page={page}
                                            onPageChange={onChangePage}
                                            onRowsPerPageChange={onChangeRowsPerPage}
                                            labelRowsPerPage={`${translate('commons.rowsPerPage')}`}
                                            sx={{
                                                overflow: "hidden",
                                                "& .MuiTablePagination-input": {
                                                    ml: { xs: 0.5, md: "default" },
                                                    mr: { xs: 3.5, md: "default" }
                                                }
                                            }}
                                        />
                                        <FormControlLabel
                                            control={<Switch checked={dense} onChange={onChangeDense} />}
                                            label={`${translate('commons.dense')}`}
                                            sx={{
                                                px: { xs: 0, sm: 3 },
                                                py: { xs: 0, sm: 1.5 },
                                                pb: { xs: 1.5, sm: 0 },
                                                mx: 0,
                                                top: 0,
                                                justifyContent: "center",
                                                width: { xs: "90vw", sm: "auto" },
                                                position: { sm: 'absolute' }
                                            }}
                                        />
                                    </Box>
                                )
                            }}
                            disableColumnMenu
                            pageSizeOptions={[5, 10, 15, 30]}
                            disableRowSelectionOnClick
                            disableColumnResize
                            onCellClick={(param) => {
                                if (!window.getSelection()?.toString())
                                    handleCellClick(param);
                            }}
                            sx={{
                                ...DataGridStyle,
                                cursor: 'pointer',
                                height: getHeight(),
                                maxHeight: getMaxHeight()
                            }}
                        />
                    </Box>

                </Card>

                <Typography sx={{ textAlign: 'right', mt: 3, mr: 1 }} fontSize={'13px'}>
                    {`${translate('orders.messages.vatAdvice')}`}
                </Typography>

            </Container>
        </Page>
    );
}

type OptionsComponentProps = {
    openMenu: HTMLElement | null,
    handleOpenMenu: (event: React.MouseEvent<HTMLElement>) => void,
    handleCloseMenu: () => void,
    handleLogs: (id: string) => void,
    handleEnableDisable: (id: string, enabled: boolean) => void,
    object: any,
    currentRow: any
};

function OptionsComponent({ openMenu, handleOpenMenu, handleCloseMenu, handleLogs, handleEnableDisable, object, currentRow }: OptionsComponentProps) {

    const { translate } = useLocales();

    return (
        <TableMoreMenu
            showMenu={currentRow && object.id === currentRow.id}
            open={openMenu}
            onOpen={(event) => handleOpenMenu(event)}
            onClose={() => handleCloseMenu()}
            actions={
                <>
                    <PermissionBasedGuard permissions={[OrganizationPermissionTypes.Product_SparePartsView]}>
                        <MenuItem
                            onClick={() => {
                                handleLogs(object.id);
                                handleCloseMenu();
                            }}
                        >
                            {`${translate("commons.logs")}`}
                        </MenuItem>
                    </PermissionBasedGuard>

                    <PermissionBasedGuard permissions={[OrganizationPermissionTypes.Product_SparePartsEdit]}>
                        <MenuItem
                            onClick={() => {
                                //handleLogs(object.id);
                                handleCloseMenu();
                            }}
                        >
                            {`${translate("commons.edit")}`}
                        </MenuItem>
                    </PermissionBasedGuard>

                    <PermissionBasedGuard permissions={[OrganizationPermissionTypes.Product_SparePartsEnableDisable]}>
                        <MenuItem
                            onClick={() => {
                                handleEnableDisable(object.id, object.enabled);
                                handleCloseMenu();
                            }}
                            sx={!object.enabled ? { color: 'success.main' } : { color: 'error.main' }}
                        >
                            {object.enabled ? `${translate('commons.disable')}` : `${translate('commons.enabled')}`}
                        </MenuItem>
                    </PermissionBasedGuard>
                </>
            }
        />
    );
}