import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { ErrorResponse, PagedResponseType } from "src/@types/commons";
import { EnableDisableGroupSliceProps, GroupMemberSearchResult, GroupSearchResult, GroupType } from "src/@types/group";
import groupOperations from "./group-operations";

interface GroupReducer {
    error: ErrorResponse,
    isLoading: boolean,
    isLogsLoading: boolean,
    isMembersLoading: boolean,
    filtersInUrl: string,
    group: GroupType | null,
    totalCount: number,
    memberTotalCount: number,
    groupsList: GroupSearchResult[],
    membersList: GroupMemberSearchResult[],
    //statistics: ContainerStatistics,
    groupLogs: PagedResponseType<any>,
    pageIndex: number,
    pageSize: number
}

const initialState: GroupReducer = {
    error: null,
    isLoading: false,
    isLogsLoading: false,
    isMembersLoading: false,
    filtersInUrl: "",
    group: null,
    totalCount: 0,
    memberTotalCount: 0,
    groupsList: [],
    membersList: [],
    //statistics: ContainerStatistics,
    groupLogs: {
        pageIndex: 0,
        pageSize: 0,
        items: [],
        totalCount: 0
    },
    pageIndex: 0,
    pageSize: 10
};

const GroupSlice = createSlice({
    name: 'group',
    initialState,
    extraReducers: (builder) => {
        builder
            //--------------------------------------
            .addCase(groupOperations.createGroup.rejected, (state, action) => {
                state.error = action.error.message as string;
                state.isLoading = false;
            })
            .addCase(groupOperations.createGroup.fulfilled, (state, action) => {
                state.group = action.payload;
                state.isLoading = false;
            })
            //--------------------------------------
            .addCase(groupOperations.updateGroup.rejected, (state, action) => {
                state.error = action.error.message as string;
                state.isLoading = false;
            })
            .addCase(groupOperations.updateGroup.fulfilled, (state, action) => {
                state.group = action.payload;
                state.isLoading = false;
            })
            //--------------------------------------
            .addCase(groupOperations.getGroup.rejected, (state, action) => {
                state.group = initialState.group;
                state.error = action.error.message as string;
                state.isLoading = false;
            })
            .addCase(groupOperations.getGroup.fulfilled, (state, action) => {
                state.group = action.payload;
                state.isLoading = false;
            })
            //--------------------------------------
            .addCase(groupOperations.searchGroups.rejected, (state, action) => {
                state.groupsList = initialState.groupsList;
                state.error = action.error.message as string;
                state.isLoading = false;
            })
            .addCase(groupOperations.searchGroups.fulfilled, (state, action) => {
                state.groupsList = action.payload.items;
                state.totalCount = action.payload.totalCount;
                state.pageIndex = action.payload.pageIndex;
                state.pageSize = action.payload.pageSize;
                state.isLoading = false;
            })
            //--------------------------------------
            .addCase(groupOperations.searchGroupMembers.rejected, (state, action) => {
                state.membersList = initialState.membersList;
                state.error = action.error.message as string;
                state.isMembersLoading = false;
            })
            .addCase(groupOperations.searchGroupMembers.fulfilled, (state, action) => {
                state.membersList = action.payload.items;
                state.memberTotalCount = action.payload.totalCount;
                state.isMembersLoading = false;
            })
            //--------------------------------------
            .addCase(groupOperations.enableDisableGroup.rejected, (state, action) => {
                state.error = action.error.message as string;
            })
            //--------------------------------------
            .addCase(groupOperations.getGroupLogs.rejected, (state, action) => {
                state.isLogsLoading = false;
                state.error = action.error.message as string;
                state.groupLogs = initialState.groupLogs;
            })
            .addCase(groupOperations.getGroupLogs.fulfilled, (state, action) => {
                state.isLogsLoading = false;
                state.groupLogs = action.payload;
            })
            //--------------------------------------
            ;
    },
    reducers: {
        startLoading(state) {
            state.isLoading = true;
        },
        startMembersLoading(state) {
            state.isMembersLoading = true;
        },
        startLogsLoading(state) {
            state.isLogsLoading = true;
        },
        resetGroup(state) {
            state.group = null;
        },
        setFiltersInUrl(state, action: PayloadAction<string>) {
            state.filtersInUrl = action.payload;
        },
        //RESET GROUP LIST
        resetPageIndexSize(state) {
            state.pageIndex = 0;
            state.pageSize = 10;
        },
        //DISABLE/ENABLE IN LIST AFTER SERVICE
        enableDisableTemplate(state, action: PayloadAction<EnableDisableGroupSliceProps>) {
            const { id, action: payloadAction } = action.payload;

            const index = state.groupsList.findIndex((group) => group.groupId === id);

            state.groupsList[index].enabled = payloadAction === "Enable";
        },
    }

});

export const {
    startLoading,
    startMembersLoading,
    startLogsLoading,
    resetGroup,
    setFiltersInUrl,
    resetPageIndexSize,
    enableDisableTemplate
} = GroupSlice.actions;

export default GroupSlice.reducer;