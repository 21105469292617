import { Typography, Box, Accordion, AccordionDetails, AccordionSummary, Checkbox, List, ListItem, alpha, Card } from "@mui/material";
import { Stack } from "@mui/system";
import PublishedWithChangesIcon from '@mui/icons-material/PublishedWithChanges';
import { useEffect, useMemo, useState } from "react";
import CancelIcon from '@mui/icons-material/Cancel';
import KeyboardReturnIcon from '@mui/icons-material/KeyboardReturn';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import FactCheckIcon from '@mui/icons-material/FactCheck';
import BrokenImageIcon from '@mui/icons-material/BrokenImage';
import HeightIcon from '@mui/icons-material/Height';
import { Alert, DEFAULT_ALERT, ItemSearchResult, RequestItem, RequestNew, RequestType } from "src/@types/request";
import WidgetsIcon from '@mui/icons-material/Widgets';
import { useLocales } from "src/locales";
import CompleteDamaged from "./complete/CompleteDamaged";
import CompleteNewParts from "./complete/CompleteNewParts";
import CompleteWarranty from "./complete/CompleteWarranty";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CompleteQuality from "./complete/CompleteQuality";
import CompleteWrong from "./complete/CompleteWrong";
import { DeliveryNote, Invoice, OrderDetailItem } from "src/@types/orders";
import useResponsive from "src/hooks/useResponsive";
import { getAlert } from "src/services/requestsServices";
import InfoIcon from '@mui/icons-material/Info';
import { WarningRounded } from '@mui/icons-material';
import { useSelector } from "react-redux";
import { RootState } from "src/redux/store";
import { SparePartSearchResult } from "src/@types/spareParts";
import PartsDetail, { getExceededItems } from "src/components/request-utils/PartsDetail";
import RequestDocumentsDetail from "src/components/request-utils/RequestDocumentsDetail";

interface CompleteProps {
    request: RequestNew,
    onChange: (name: string, value: any) => void,
    deliveryNotes: DeliveryNote[],
    invoices: Invoice[]
    files: File[],
    warrantyParts: ItemSearchResult[],
    ar: boolean,
    exceededParts: (SparePartSearchResult & { quantity: number })[],
    customerId: string,
    vendorId: string
}

export default function Complete({ request, onChange, deliveryNotes, invoices, files, warrantyParts, ar, exceededParts, customerId, vendorId }: CompleteProps) {

    const isDesktop = useResponsive('up', 'md');

    const [expand, setExpand] = useState(false);

    const { translate } = useLocales();

    const [alerts, setAlerts] = useState<Alert>(DEFAULT_ALERT);

    const { order } = useSelector((state: RootState) => state.orders);

    const requestItems = request.items.map(i => i.productId);

    const parts = useMemo(() => request.items.length > 0 ? (order && order.items.filter(i => requestItems.includes(i.id))) : [], [order, request, requestItems]);

    useEffect(() => {
        if (request.requestType !== "Warranty" && request.requestType !== "WrongShipment")
            if (order && request)
                getAlert({ orderId: order.id, productId: request.items[0].productId })
                    .then((val) => setAlerts(val.data));

    }, [order, request]);

    const handleCheck = (e: any) => {
        e.stopPropagation();
        onChange(e.target.name, e.target.checked);
    };

    const getIcon = () => {
        switch (request.requestType) {
            case 'NewPart':
                return <PublishedWithChangesIcon />;
            case 'DamagedPart':
                return <CancelIcon />;
            case 'WrongShipment':
                return <LocalShippingIcon />;
            case 'Core':
                return <KeyboardReturnIcon />;
            case 'Quality':
                return <FactCheckIcon />;
            case 'Warranty':
                return <BrokenImageIcon />;
            default:
                return <></>;
        };
    };

    const complete: Record<RequestType, JSX.Element> = {
        "NewPart": <CompleteNewParts order={order!} />,
        "WrongShipment": <CompleteWrong order={order!} request={request} customerId={customerId} vendorId={vendorId} />,
        "Core": <CompleteNewParts order={order!} />,
        "DamagedPart": <CompleteDamaged files={files} order={order!} request={request} />,
        "Warranty": <CompleteWarranty ar={ar} request={request} order={order!} files={files} />,
        "Quality": <CompleteQuality files={files} order={order!} request={request} />,
        "": <></>
    };

    const isMissingParts = useMemo(() => request.requestType === "WrongShipment" && request.requestReason === "SurplusDelivered", [request]);

    const partsDetailItems: RequestItem[] = useMemo(() => {
        if (request.requestType !== "Warranty") {
            if (parts && parts.length > 0) {
                return parts.map((part) => {
                    return {
                        orderId: order?.id,
                        itemIndex: part.itemIndex,
                        product: part,
                        bigAndHeavy: false,
                        requiredQuantity: part.quantity
                    } as RequestItem;
                });
            }
        }
        else if (warrantyParts && warrantyParts.length > 0) {
            return warrantyParts.map((part) => {
                return {
                    orderId: order?.id,
                    itemIndex: part.itemIndex,
                    product: part as unknown as OrderDetailItem,
                    bigAndHeavy: false,
                    requiredQuantity: part.quantity
                } as RequestItem;
            });
        }

        return [];
    }, [request.requestType, warrantyParts, parts, order?.id]);

    return (
        <Box sx={{ p: 2 }}>

            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', p: 2 }}>

                <Stack>
                    <Typography variant="h4">
                        {`${translate('request.form.title')}`}
                    </Typography>

                    <Typography
                        variant="h6"
                        sx={{
                            mb: isDesktop ? 1 : 0,
                            mt: isDesktop ? 1 : 3,
                            display: 'flex',
                            alignContent: 'center',
                            gap: 2
                        }}
                    >
                        {getIcon()}
                        {`${translate(`request.${request.requestType}`)}`}
                        {request.requestReason && ` - ${translate(`request.selectReason.${request.requestReason[0].toLowerCase() + request.requestReason.slice(1)}`)}`}
                    </Typography>
                </Stack>

                <Box sx={{ display: 'flex', gap: 2, alignContent: 'center' }}>
                    {request.items[0].bigAndHeavy && <Typography variant="subtitle2" sx={{ display: 'flex', border: '1px solid', borderRadius: '8px', borderColor: '#919eab3d', py: 1, px: 2, alignContent: 'center', gap: 1 }}><HeightIcon />{`${translate('request.big')}`}</Typography>}
                    <Typography variant="subtitle2" sx={{ display: 'flex', border: '1px solid', borderRadius: '8px', borderColor: '#919eab3d', py: 1, px: 2, alignContent: 'center', gap: 1 }}><WidgetsIcon />X{request.items.reduce((sum, current) => sum + current.quantity, 0)}</Typography>
                </Box>

            </Box>

            {partsDetailItems.length > 0 &&
                <Card>
                    <PartsDetail
                        items={partsDetailItems}
                        clickToDetail
                        missingChanges={isMissingParts}
                        exceededItems={getExceededItems(exceededParts)}
                        showDepositRef={request.requestType === "Warranty" || request.requestType === "Core"}
                    />
                </Card>
            }

            {invoices.length > 0 &&
                <RequestDocumentsDetail isInvoices documents={invoices} sx={{ my: 2 }} />
            }

            {deliveryNotes.length > 0 &&
                <RequestDocumentsDetail documents={deliveryNotes} sx={{ my: 2 }} />
            }

            {alerts.frequencyFee > 0 &&
                <Box sx={{ bgcolor: (theme) => theme.palette.warning.lighter, borderRadius: '8px', p: 1, my: 1 }}>
                    <Typography sx={{ color: '#7A0916', display: 'flex', justifyContent: 'flex-start', gap: 1 }}>
                        <WarningRounded color="warning" />
                        {`${translate('request.messages.feeFrequencyWarning')}`}
                        <b>-{alerts.frequencyFee}%</b>{`${translate('request.messages.disc')}`}
                    </Typography>
                </Box>
            }

            {alerts.invoiceFee > 0 &&
                <Box sx={{ bgcolor: (theme) => theme.palette.warning.lighter, borderRadius: '8px', p: 1, my: 1 }}>
                    <Typography sx={{ color: '#7A0916', display: 'flex', justifyContent: 'flex-start', gap: 1 }}>
                        <WarningRounded color="warning" />

                        {`${translate('request.messages.feeInvoiceWarning')}`}<b>-{alerts.invoiceFee}%</b>{`${translate('request.messages.disc')}`}.

                    </Typography>
                </Box>
            }

            {alerts.hasSurveillance &&
                <Box sx={{ bgcolor: (theme) => theme.palette.error.lighter, borderRadius: '8px', p: 1, my: 1 }}>
                    <Typography sx={{ color: '#7A0916', display: 'flex', justifyContent: 'flex-start', gap: 1 }}>
                        <InfoIcon color="error" />

                        {`${translate('request.messages.underSurvelliance')}`}.
                    </Typography>
                </Box>
            }

            {complete[request.requestType]}

            <Accordion
                expanded={expand}
                onChange={() => setExpand(!expand)}
                sx={{ backgroundColor: (theme) => theme.palette.mode === "light" ? '#F9FAFB' : alpha('#F9FAFB', 0.25), borderRadius: '8px' }}>
                <AccordionSummary
                    sx={{ backgroundColor: (theme) => theme.palette.mode === "light" ? '#F9FAFB' : alpha('#F9FAFB', 0.25), borderRadius: '8px', color: 'black' }}
                    expandIcon={<ExpandMoreIcon />}>

                    <Box sx={{ alignItems: 'center', display: 'flex', gap: 2, width: '100%', justifyContent: 'space-between' }}>
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <Checkbox name="terms" onClick={(e) => handleCheck(e)} />

                            <Typography variant="subtitle2">{`${translate('commons.termCondition')}`}</Typography>
                        </Box>
                        <Typography>{!expand ? `${translate('commons.readMore')}` : `${translate('commons.readLess')}`}</Typography>

                    </Box>
                </AccordionSummary>
                <AccordionDetails sx={{ m: 2 }}>
                    <List sx={{ listStyleType: 'disc', pl: 4 }}>

                        <ListItem sx={{ display: 'list-item' }}>{`${translate('request.termConditions.firstLine')}`}</ListItem>
                        <ListItem sx={{ display: 'list-item' }}>{`${translate('request.termConditions.secondLine')}`}</ListItem>
                        <ListItem sx={{ display: 'list-item' }}>{`${translate('request.termConditions.thirdLine')}`}
                            <List sx={{ listStyleType: 'disc', pl: 4 }}>
                                <ListItem sx={{ display: 'list-item' }}>{`${translate('request.termConditions.fourthLine')}`}</ListItem>
                                <ListItem sx={{ display: 'list-item' }}>{`${translate('request.termConditions.fifthLine')}`}</ListItem>
                                <ListItem sx={{ display: 'list-item' }}>{`${translate('request.termConditions.sixthLine')}`}</ListItem>
                                <ListItem sx={{ display: 'list-item' }}>{`${translate('request.termConditions.seventhLine')}`}</ListItem>
                                <ListItem sx={{ display: 'list-item' }}>{`${translate('request.termConditions.eighthLine')}`}</ListItem>
                                <ListItem sx={{ display: 'list-item' }}>{`${translate('request.termConditions.ninthLine')}`}</ListItem>
                            </List>
                        </ListItem>

                    </List>
                </AccordionDetails>
            </Accordion>
        </Box >

    );
}