import { forwardRef } from 'react';
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';
import { StyledLabel } from './styles';
import { LabelProps } from './types';
import { useSettingsContext } from '../settings';

// ---------------------------------------------------------------------- smallertext

const Label = forwardRef<HTMLSpanElement, LabelProps>(
  ({ children, color = 'default', variant = 'soft', startIcon, endIcon, smallerText, sx, ...other }, ref) => {

    const theme = useTheme();

    const { themeStretch } = useSettingsContext();

    const iconStyle = {
      width: 16,
      height: 16,
      '& svg, img': { width: 1, height: 1, objectFit: 'cover' },
    };

    return (
      <StyledLabel
        ref={ref}
        component="span"
        ownerState={{ color, variant }}
        fontSize={smallerText ? (!themeStretch && children && children?.toString().length > 25 ? 8 : 10) : 12}
        sx={{
          ...(startIcon && { pl: 0.75 }),
          ...(endIcon && { pr: 0.75 }),
          ...sx
        }}
        theme={theme}
        {...other}
      >
        {startIcon && <Box sx={{ mr: 0.75, ...iconStyle }}> {startIcon} </Box>}

        {children}

        {endIcon && <Box sx={{ ml: 0.75, ...iconStyle }}> {endIcon} </Box>}
      </StyledLabel>
    );
  }
);

export default Label;
