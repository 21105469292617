import { Typography, Box, FormControlLabel, Switch, TablePagination, Stack, TextField, MenuItem, Radio, Button, Tooltip, Checkbox } from "@mui/material";
import { useCallback, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { Item, ItemFilters, ItemSearchResult, RequestNew } from "src/@types/request";
import Iconify from "src/components/iconify";
import { useTable } from "src/components/table";
import { useLocales } from "src/locales";
import { RootState, dispatch } from "src/redux/store";
import { useAuth } from "src/appHooks/useAuth";
import DeleteIcon from '@mui/icons-material/Delete';
import { requestOperations } from "src/redux/request";
import { DataGrid, GridColDef, GridRenderCellParams, GridRowParams, GridSortModel } from "@mui/x-data-grid";
import { noData } from "src/components/empty-content/EmptyContent";
import { DataGridStyle } from "src/utils/DataGridStyle";
import ReceiptIcon from "@mui/icons-material/Receipt";
import { Newspaper } from "@mui/icons-material";
import DateZone from "src/appComponents/DateZone";
import { convert } from "src/utils/currency";
import useCurrency from "src/appHooks/useCurrency";
import { useSettingsContext } from "src/components/settings";
import { SparePartsFilters, SparePartSearchResult } from "src/@types/spareParts";
import { sparePartsOperations } from "src/redux/spareParts";
import { useUserOrganizationContext } from "src/contexts/UserOrganizationContext";
import { debounce } from "lodash";
import PermissionBasedGuard from "src/guards/PermissionBasedGuard";
import { OrganizationPermissionTypes } from "src/@types/permissions";
import SearchOffIcon from '@mui/icons-material/SearchOff';
import useTenant from "src/appHooks/useTenant";
import { getCustomFieldLabel } from "src/utils/CustomFieldManagment";

type SparePartSearchResultQty = SparePartSearchResult & { quantity: number }

interface SearchProductProps {
    onChange: (name: string, value: any, items?: boolean) => void,
    request: RequestNew,
    isVendor: boolean,
    organization: string,
    ar: boolean,
    arParts: ItemSearchResult[],
    changeArParts: (parts: ItemSearchResult[]) => void,
    exceededItems: SparePartSearchResultQty[]
    changeExceedItems: (parts: SparePartSearchResultQty[]) => void
}

export default function SearchProduct({ onChange, request, isVendor, organization, ar, changeArParts, arParts, exceededItems, changeExceedItems }: SearchProductProps) {

    const { requestItems, isItemsLoading } = useSelector((state: RootState) => state.request);

    const { sparePartsList, isLoading, totalCount } = useSelector((state: RootState) => state.spareParts);

    const { organizationId } = useUserOrganizationContext();

    const [searchBy, setSearchBy] = useState('part');

    const [search, setSearch] = useState('');

    const [isFiltered, setIsFiltered] = useState(false);

    const { translate, currentLang } = useLocales();

    const [selectionModel, setSelectionModel] = useState<string | null>(request.items[0]?.id || null);

    const [WrongShipmentSelection, setWrongShipmentSelection] = useState<Item[]>(request.items);

    const [warrantyArItems, setWarrantyArItems] = useState<ItemSearchResult[]>(arParts);

    const [surplusExceededItems, setSurplusExceededItems] = useState<SparePartSearchResultQty[]>(exceededItems);

    const { user } = useAuth();

    const { rates } = useCurrency();

    const [orderId, setOrderId] = useState(request.items[0]?.orderId || "");

    const [skipRules, setSkipRules] = useState(false);

    const { currency: currentCurrency } = useSettingsContext();

    const { customFields } = useTenant();

    const customFieldName = useCallback((id: string) => {

        return getCustomFieldLabel(customFields, id, currentLang);

    }, [currentLang, customFields]);

    const {
        page,
        rowsPerPage,
        onChangeDense,
        dense,
        onChangePage,
        onChangeRowsPerPage,
        order,
        setOrder,
        setOrderBy,
        orderBy,
        setPage
    } = useTable();

    const searchItems = (options: ItemFilters, clear?: boolean, value?: string) => {

        if (clear) {
            isVendor ?
                dispatch(requestOperations.searchVendorReturnItems({ ...options, documentExternalId: undefined, itemName: undefined })) :
                dispatch(requestOperations.searchCustomerReturnItems({ ...options, documentExternalId: undefined, itemName: undefined }));
            setSearch("");

            return;
        }

        isVendor ?
            dispatch(requestOperations.searchVendorReturnItems(options)) :
            dispatch(requestOperations.searchCustomerReturnItems(options));

        if (search || value)
            setIsFiltered(true);
    };

    const searchSpareParts = (options: SparePartsFilters, clear?: boolean, value?: string) => {

        if (clear) {

            dispatch(sparePartsOperations.searchVendorSpareParts(options));
            setSearch("");

            return;
        }

        dispatch(sparePartsOperations.searchVendorSpareParts({ ...options, all: value ? value : search }));

        if (search || value)
            setIsFiltered(true);
    };

    const debouncedSparePartsSearch = useRef(debounce(searchSpareParts, 500));

    const debouncedSearch = useRef(debounce(searchItems, 500));

    const handleSearch = (clear?: boolean, value?: string) => {

        let val = value !== undefined ? value : search;

        if (request.requestReason === "SurplusDelivered") {
            let options: SparePartsFilters = {
                pageIndex: page,
                pageSize: rowsPerPage,
                sortAscending: order === "asc" ? true : false,
                sortField: orderBy || undefined,
                organizationId: isVendor ? organizationId : organization
            };

            if (clear || val.length > 3 || val.length === 0)
                debouncedSparePartsSearch.current(options, clear, val);
        }
        else {

            let options: ItemFilters = {
                orderId: (request.requestType === "WrongShipment" && orderId) ? orderId : undefined,
                pageIndex: page,
                pageSize: rowsPerPage,
                sortAscending: order === "asc" ? true : false,
                requestType: request.requestType,
                customerId: isVendor ? organization : undefined,
                vendorId: !isVendor ? organization : undefined,
                itemName: searchBy === "part" ? val : undefined,
                documentExternalId: searchBy === "document" ? val : undefined,
                "customFields.stellantis-europe-spareparts-deposit-number": searchBy === "deposit" ? val : undefined,
                sortField: orderBy ? orderBy : undefined,
                skipRules: skipRules
            };

            if (clear || val.length > 3 || val.length === 0)
                debouncedSearch.current(options, clear, val);
        }

    };

    useEffect(() => {

        if (request.requestReason !== "SurplusDelivered") {

            let val = search || undefined;

            let options: ItemFilters = {
                orderId: (request.requestType === "WrongShipment" && orderId) ? orderId : undefined,
                pageIndex: page,
                pageSize: rowsPerPage,
                sortAscending: order === "asc" ? true : false,
                requestType: request.requestType,
                customerId: isVendor ? organization : undefined,
                vendorId: !isVendor ? organization : undefined,
                itemName: searchBy === "part" ? val : undefined,
                documentExternalId: searchBy === "document" ? val : undefined,
                "customFields.stellantis-europe-spareparts-deposit-number": searchBy === "deposit" ? val : undefined,
                sortField: orderBy ? orderBy : undefined,
                skipRules: skipRules
            };
            searchItems(options);
        }
        else {
            let options: SparePartsFilters = {
                pageIndex: page,
                pageSize: rowsPerPage,
                sortAscending: order === "asc" ? true : false,
                sortField: orderBy || undefined,
                organizationId: isVendor ? organizationId : organization
            };
            searchSpareParts(options);
        }

    }, [page, rowsPerPage, user, order, orderBy, isVendor, orderId, skipRules]);

    useEffect(() => {
        if (requestItems.totalCount <= rowsPerPage * page) {
            setPage(0);
        }
    }, [page, rowsPerPage, setPage, requestItems.totalCount]);

    const handleSort = (sortModel: GridSortModel) => {
        if (sortModel.length > 0) {
            setOrderBy(sortModel[0].field);
            setOrder(sortModel[0].sort!);
        } else {
            setOrderBy("");
            setOrder("asc");
        }
    };

    const handleChange = (value: GridRenderCellParams<ItemSearchResult> | GridRowParams<ItemSearchResult>) => {

        setSelectionModel(value.id.toString());
        onChange('document', [value.row.orderId, value.row.itemId, value.row.id, value.row.itemIndex]);
    };

    const handleMissing = (value: GridRowParams<ItemSearchResult>, e: boolean) => {

        if (e) {
            if (WrongShipmentSelection.length === 0 && request.requestReason === "MissingParts")
                setOrderId(value.row.orderId);

            let item: Item = {
                id: value.id.toString(),
                productId: value.row.itemId,
                orderItemIndex: value.row.itemIndex,
                orderId: value.row.orderId,
                quantity: 0,
                bigAndHeavy: false
            };

            if (WrongShipmentSelection.find(v => v.id === value.row.id))
                return;

            setWrongShipmentSelection(prev => prev.concat([item]));

            if (request.requestType === "Warranty" && ar) {
                setWarrantyArItems(prev => prev.concat([value.row]));
            }

        }
        else {
            if (WrongShipmentSelection.length === 1 && request.requestReason === "MissingParts")
                setOrderId("");
            setWrongShipmentSelection(prev => prev.filter(item => item.id !== value.row.id));

            if (request.requestType === "Warranty" && ar)
                setWarrantyArItems(prev => prev.filter(i => i.id !== value.row.id));
        }
    };

    const handleSurplus = (value: GridRowParams<SparePartSearchResult>, e: boolean) => {
        if (e) {

            let item: Item = {
                id: value.row.id,
                productId: value.row.id,
                orderItemIndex: null,
                orderId: null,
                quantity: 0,
                bigAndHeavy: false
            };

            if (WrongShipmentSelection.find(v => v.id === value.row.id))
                return;

            setWrongShipmentSelection(prev => prev.concat([item]));

            setSurplusExceededItems(prev => prev.concat([{ ...value.row, quantity: 0 }]));
        }
        else {

            setWrongShipmentSelection(prev => prev.filter(item => item.id !== value.row.id));

            setSurplusExceededItems(prev => prev.filter(i => i.id !== value.row.id));
        }
    };

    useEffect(() => {

        if (request.requestReason === "MissingParts" || (request.requestType === "Warranty" && ar)) {

            let items: Item[] = WrongShipmentSelection.map(item => ({
                id: item.id,
                bigAndHeavy: false,
                productId: item.productId,
                orderItemIndex: item.orderItemIndex,
                quantity: 0,
                orderId: item.orderId
            }));

            //onChange("order", orderId);
            if (request.requestType === "Warranty" && ar)
                changeArParts(warrantyArItems);

            onChange("", items, true);

        }

        if (request.requestReason === "SurplusDelivered") {

            let items: Item[] = WrongShipmentSelection.map(item => ({
                id: item.id,
                bigAndHeavy: false,
                productId: item.productId,
                orderItemIndex: null,
                quantity: 0,
                orderId: null
            }));

            changeExceedItems(surplusExceededItems);

            onChange("", items, true);
        }
    }, [WrongShipmentSelection]);

    const getHeight = () => {
        return rowsPerPage === 5 ? 380 : 650;
    };

    const columns: GridColDef<ItemSearchResult>[] = [
        {
            field: '',
            headerName: ``,
            flex: 0.20,
            sortable: false,
            align: 'left',
            renderCell: (v) => {
                if (request.requestType === "WrongShipment" || (request.requestType === "Warranty" && ar))
                    return <Checkbox
                        checked={!!WrongShipmentSelection.find(item => item.id === v.row.id)}
                    />;

                else
                    return <Radio
                        checked={selectionModel === v.id.toString()}
                        onClick={(e) => {
                            e.stopPropagation();
                            handleChange(v);
                        }}
                    />;
            }
        },
        ...(skipRules ? [{
            field: 'skipped',
            headerName: '',
            align: 'center',
            flex: 0.10,
            renderCell: (params) => {
                if (params.row.skippedRule)
                    return <Tooltip title={`${translate('request.form.skippedRules')}`} >
                        <SearchOffIcon />
                    </Tooltip>;
            }
        }] : []
        ) as GridColDef[],

        {
            field: 'itemExternalId',

            headerName: `${translate('request.form.partN')}`,
            flex: 0.5,
            valueGetter: (params: string) => {
                if (!params) {
                    return '—';
                }

                return params;
            },
        },
        {
            field: 'name',
            headerName: `${translate('commons.name')}`,
            flex: 0.6,
            valueGetter: (params: string) => {
                if (!params) {
                    return '—';
                }

                return params;
            },
        },
        ...(request.requestType !== "WrongShipment" ? [{
            field: 'quantity',
            headerName: `${translate('request.form.avQuantity')}`,
            flex: 0.6,
            valueGetter: (params: string) => {
                if (!params) {
                    return '—';
                }

                return params;
            },
        }] : []),
        {
            field: 'family',
            headerName: `${translate('spareParts.detail.family')}`,
            flex: 0.9,
            renderCell: (params) =>
            (params.row.family ?
                <Box sx={{ display: 'flex', flexDirection: 'column', width: '90%' }}>
                    <Typography variant="subtitle2"><b>{params.row.family ? params.row.family.code : "—"}</b></Typography>
                    <Tooltip title={params.row.family.name}>
                        <Typography variant='body2' textOverflow={'ellipsis'} overflow={'hidden'}>
                            {params.row.family ? params.row.family.name : "—"}
                        </Typography>
                    </Tooltip>

                </Box>
                :
                <Typography variant="body2">{"—"}</Typography>
            )
        },
        {
            field: 'Invoice',
            headerName: `${translate('orders.tableHeaders.invoice')}`,
            flex: 0.5,
            renderCell: (p) => {
                if (p.row.invoiceExternalId)
                    return <Typography variant="body2">{p.row.invoiceExternalId}</Typography>;
                else if (p.row.invoiceId)
                    return <ReceiptIcon />;

                return <Typography variant="body2">{"—"}</Typography>;
            }
        },
        {
            field: 'DeliveryNote',
            headerName: `${translate('orders.tableHeaders.deliveryNote')}`,
            flex: 0.5,
            renderCell: (p) => {
                if (p.row.deliveryNoteExternalId)
                    return <Typography variant="body2">{p.row.deliveryNoteExternalId}</Typography>;
                else if (p.row.deliveryNoteId)
                    return <Newspaper />;

                return <Typography variant="body2">{"—"}</Typography>;
            }
        },
        {
            field: 'createdOn',
            headerName: `${translate('orders.tableHeaders.createdOn')}`,
            flex: 0.5,
            renderCell: (params: any) => {
                if (!params.value) {
                    return '—';
                }

                return <DateZone variant="body2" date={params.value} noSeconds shortMonth />;
            },
        },
        {
            field: 'unitGrossAmount',
            headerName: `${translate('orders.tableHeaders.grossAmount')}`,
            flex: 0.5,
            align: 'center',
            renderCell: (params) => {
                return <Typography variant="body2">
                    {params.row.unitGrossAmount.withoutVat ?
                        Math.floor(
                            convert(
                                params.row.unitGrossAmount.withoutVat,
                                params.row.unitGrossAmount.currentCurrency,
                                currentCurrency.label,
                                rates
                            )) + " " + currentCurrency.symbol
                        : ""}
                </Typography>;
            },
        },
    ];

    const sparePartsColumns: GridColDef<SparePartSearchResult>[] = [
        {
            field: '',
            headerName: ``,
            flex: 0.25,
            sortable: false,
            align: 'left',
            renderCell: (v) => {
                return <Checkbox
                    checked={!!WrongShipmentSelection.find(item => item.id === v.row.id)}
                />;

            }
        },
        {
            field: 'externalId',

            headerName: `${translate('request.form.partN')}`,
            flex: 0.5,
            valueGetter: (params: string) => {
                if (!params) {
                    return '—';
                }

                return params;
            },
        },
        {
            field: 'name',
            headerName: `${translate('commons.name')}`,
            flex: 0.6,
            valueGetter: (params: string) => {
                if (!params) {
                    return '—';
                }

                return params;
            },
        },
        ...(request.requestType !== "WrongShipment" ? [{
            field: 'quantity',
            headerName: `${translate('request.form.avQuantity')}`,
            flex: 0.6,
            valueGetter: (params: string) => {
                if (!params) {
                    return '—';
                }

                return params;
            },
        }] : []),
        {
            field: 'family',
            headerName: `${translate('spareParts.detail.family')}`,
            flex: 0.9,
            renderCell: (params) =>
                <Box sx={{ display: 'flex', flexDirection: 'column', width: '90%' }}>
                    <Typography variant="subtitle2"><b>{params.row.family ? params.row.family.code : "—"}</b></Typography>
                    <Tooltip title={params.row.family.name}>
                        <Typography variant='body2' textOverflow={'ellipsis'} overflow={'hidden'}>
                            {params.row.family ? params.row.family.name : "—"}
                        </Typography>
                    </Tooltip>

                </Box>

        },
        {
            field: 'createdOn',
            headerName: `${translate('orders.tableHeaders.createdOn')}`,
            flex: 0.5,
            renderCell: (params: any) => {
                if (!params.value) {
                    return '—';
                }

                return <DateZone variant="body2" date={params.value} noSeconds shortMonth />;
            },
        },
        {
            field: 'netAmount',
            headerName: `${translate('orders.tableHeaders.netAmount')}`,
            flex: 0.5,
            renderCell: (params) => {
                return <Typography variant="body2">
                    {params.row.sellAmount?.withoutVat ?
                        Math.floor(
                            convert(
                                params.row.sellAmount.withoutVat,
                                params.row.sellAmount.currentCurrency,
                                currentCurrency.label,
                                rates
                            )) + " " + currentCurrency.symbol
                        : ""}
                </Typography>;
            },
        },
    ];

    return (
        <Box>
            <Typography variant="h6" sx={{ m: 3 }}>
                {`${translate('request.research')}`}
            </Typography>

            <Stack spacing={2} direction={{ xs: 'column', sm: 'row' }} sx={{ py: 2.5, px: 3 }}>
                <TextField
                    fullWidth
                    label={`${translate("returns.searchType")}`}
                    value={searchBy}
                    onChange={(e) => { setSearchBy(e.target.value); }}
                    select
                    SelectProps={{
                        MenuProps: {
                            sx: { '& .MuiPaper-root': { maxHeight: 260 } },
                        },
                    }}
                    sx={{
                        maxWidth: { sm: 240 },
                        textTransform: 'capitalize',
                    }}
                >
                    <MenuItem
                        sx={{
                            mx: 1,
                            my: 0.5,
                            borderRadius: 0.75,
                            typography: 'body2',
                            textTransform: 'capitalize',
                        }}
                        value={'part'} >
                        {`${translate('request.partReference')}`}
                    </MenuItem>
                    <MenuItem
                        sx={{
                            mx: 1,
                            my: 0.5,
                            borderRadius: 0.75,
                            typography: 'body2',
                            textTransform: 'capitalize',
                        }}
                        value={'document'} >
                        {`${translate('request.documentReference')}`}
                    </MenuItem>
                    {request.requestType === "Core" &&
                        <MenuItem
                            sx={{
                                mx: 1,
                                my: 0.5,
                                borderRadius: 0.75,
                                typography: 'body2',
                                textTransform: 'capitalize',
                            }}
                            value={'deposit'} >
                            {customFieldName("'stellantis-europe-spareparts-deposit-number'")}
                        </MenuItem>
                    }
                </TextField>

                <TextField
                    fullWidth
                    sx={{ m: 2 }}
                    id="orderSelect"
                    label={`${translate('commons.search')}`}
                    value={search}
                    onChange={(e) => {
                        setSearch(e.target.value);
                        handleSearch(false, e.target.value);
                    }}
                />

                <Button
                    variant="text"
                    sx={{ borderRadius: '8px' }}
                    onClick={() => handleSearch()}
                >
                    <Iconify
                        icon={'eva:search-fill'}
                        sx={{ width: 20, height: 20 }}
                    />
                </Button>
                {
                    isFiltered &&
                    <Button
                        variant="text"
                        onClick={() => {
                            setSearch('');
                            setIsFiltered(false);
                            handleSearch(true);
                        }}
                    >
                        <DeleteIcon />
                    </Button>
                }
                <PermissionBasedGuard permissions={isVendor ? [OrganizationPermissionTypes.WebShop_ReturnRequests_Requests_VendorSkipRules] : [OrganizationPermissionTypes.WebShop_ReturnRequests_Requests_CustomerSkipRules]}>
                    <FormControlLabel
                        sx={{ width: '35%' }}
                        onChange={(_, checked) => setSkipRules(checked)}
                        label={`${translate('request.form.disableRules')}`}
                        labelPlacement="start"
                        control={<Switch checked={skipRules} />}
                    />
                </PermissionBasedGuard>
            </Stack>
            {request.requestReason === "SurplusDelivered" &&
                <DataGrid
                    columns={sparePartsColumns}
                    rows={sparePartsList}
                    loading={isLoading}
                    disableColumnMenu
                    disableColumnResize
                    disableRowSelectionOnClick
                    pagination
                    paginationModel={{
                        page: page,
                        pageSize: rowsPerPage
                    }}
                    density={dense ? 'compact' : 'standard'}
                    sortingMode={"server"}
                    onSortModelChange={handleSort}
                    onRowClick={(p) => {
                        let found = WrongShipmentSelection.find(item => item.id === p.row.id.toString());

                        handleSurplus(p, !found);

                    }}
                    sx={{
                        ...DataGridStyle,
                        //cursor: 'pointer',
                        height: getHeight(),
                        maxHeight: 650,
                        '& .MuiDataGrid-columnHeaderTitle': {
                            whiteSpace: 'break-spaces',
                            lineHeight: 1,
                        }
                    }}
                    pageSizeOptions={[5, 10, 15, 30]}
                    slots={{
                        noRowsOverlay: noData,
                        footer: () => (
                            <Box sx={{ position: 'relative' }} >
                                <TablePagination
                                    rowsPerPageOptions={[5, 10, 15, 30]}
                                    component="div"
                                    count={totalCount}
                                    rowsPerPage={rowsPerPage}
                                    page={page}
                                    onPageChange={onChangePage}
                                    onRowsPerPageChange={onChangeRowsPerPage}
                                    labelRowsPerPage={`${translate('commons.rowsPerPage')}`} />

                                <FormControlLabel
                                    control={<Switch checked={dense} onChange={onChangeDense} />}
                                    label={`${translate('commons.dense')}`}
                                    sx={{ px: 3, py: 1.5, top: 0, position: { md: 'absolute' } }} />
                            </Box>
                        )
                    }}
                />}
            {request.requestReason !== "SurplusDelivered" && <DataGrid
                columns={columns}
                rows={requestItems.items}
                loading={isItemsLoading}
                disableColumnMenu
                disableRowSelectionOnClick
                pagination
                getRowClassName={(params) => `skippedRules--${params.row.skippedRule}`}
                paginationModel={{
                    page: page,
                    pageSize: rowsPerPage
                }}
                density={dense ? 'compact' : 'standard'}
                sortingMode={"server"}
                onSortModelChange={handleSort}
                onRowClick={(p) => {
                    if (request.requestType === "WrongShipment" || (request.requestType === "Warranty" && ar)) {
                        let found = WrongShipmentSelection.find(item => item.id === p.row.id.toString());

                        handleMissing(p, !found);
                    }
                    else {
                        handleChange(p);
                    }

                }}
                sx={{
                    ...DataGridStyle,
                    //cursor: 'pointer',
                    height: getHeight(),
                    maxHeight: 650,
                    '& .MuiDataGrid-columnHeaderTitle': {
                        whiteSpace: 'break-spaces',
                        lineHeight: 1,
                    },
                    '& .skippedRules--true': {
                        backgroundColor: (theme) => theme.palette.action.selected,
                        '&:hover': {
                            backgroundColor: (theme) => theme.palette.action.disabledBackground
                        }
                    }
                }}
                pageSizeOptions={[5, 10, 15, 30]}
                slots={{
                    noRowsOverlay: noData,
                    footer: () => (
                        <Box sx={{ position: 'relative' }} >
                            <TablePagination
                                rowsPerPageOptions={[5, 10, 15, 30]}
                                component="div"
                                count={requestItems.totalCount}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                onPageChange={onChangePage}
                                onRowsPerPageChange={onChangeRowsPerPage}
                                labelRowsPerPage={`${translate('commons.rowsPerPage')}`} />

                            <FormControlLabel
                                control={<Switch checked={dense} onChange={onChangeDense} />}
                                label={`${translate('commons.dense')}`}
                                sx={{ px: 3, py: 1.5, top: 0, position: { md: 'absolute' } }} />
                        </Box>
                    )
                }}
            />}
        </Box >
    );
}
