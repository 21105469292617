// ----------------------------------------------------------------------

import { ReportDetailTabs, ReportTypeData } from "src/@types/report";
import { RuleTabs } from "src/@types/rules";

function path(root: string, sublink: string): string {
  return `${root}${sublink}`;
}

const ROOTS_AUTH = '/auth';

const ROOTS_DASHBOARD = '/dashboard';

// ----------------------------------------------------------------------

export const PATH_AUTH = {
  root: ROOTS_AUTH,
  login: path(ROOTS_AUTH, '/login'),
  register: path(ROOTS_AUTH, '/register'),
  loginUnprotected: path(ROOTS_AUTH, '/login-unprotected'),
  registerUnprotected: path(ROOTS_AUTH, '/register-unprotected'),
  verify: path(ROOTS_AUTH, '/verify'),
  resetPassword: path(ROOTS_AUTH, '/reset-password'),
  newPassword: path(ROOTS_AUTH, '/new-password'),
};

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  general: {
    organizationSelector: path('', '/selector'),
    login: path('', '/login'),
    pendingActivation: path('', '/pendingActivation'),
    maintenance: path('', '/maintenance'),
    contactAdmin: path('', '/contactAdmin')
  },
  user: {
    user: path(ROOTS_DASHBOARD, '/user'),
    new: path(ROOTS_DASHBOARD, '/user/new'),
    list: path(ROOTS_DASHBOARD, '/user/list'),
    cards: path(ROOTS_DASHBOARD, '/user/cards'),
    profile: path(ROOTS_DASHBOARD, '/user/profile'),
    account: path(ROOTS_DASHBOARD, '/user/account'),
    edit: (id: string, tab: string = 'general') => path(ROOTS_DASHBOARD, `/user/${id}/edit/${tab}`),
    editAdd: (id: string, addType: string) => path(ROOTS_DASHBOARD, `/user/${id}/add/${addType}`),
    selfEdit: (id: string) => path(ROOTS_DASHBOARD, `/user/${id}/selfedit`),
    demoEdit: path(ROOTS_DASHBOARD, `/user/reece-chung/edit`),
    detailsTab: (id: string, tab: string) => path(ROOTS_DASHBOARD, `/user/${id}/detail/${tab}`),
    roleAssignment: (id: string) => path(ROOTS_DASHBOARD, `/user/${id}/roleAssignment`)
  },
  vehicle: {
    vehicle: path(ROOTS_DASHBOARD, '/vehicle'),
    new: path(ROOTS_DASHBOARD, '/vehicle/new'),
    list: path(ROOTS_DASHBOARD, '/vehicle/list'),
    edit: (id: string) => path(ROOTS_DASHBOARD, `/vehicle/${id}/edit`),
    detail: (id: string) => path(ROOTS_DASHBOARD, `/vehicle/${id}/detail`),
    detailLogs: (id: string, page: string) => path(ROOTS_DASHBOARD, `/vehicle/${id}/detail/${page}`),
    contractDataEdit: (id: string) => path(ROOTS_DASHBOARD, `/vehicle/${id}/contractualEdit`),
    contractDataView: (id: string) => path(ROOTS_DASHBOARD, `/vehicle/${id}/contractualView`),
    contractDataLogs: (id: string) => path(ROOTS_DASHBOARD, `/vehicle/${id}/contractualLogs`),
    logisticEdit: (id: string) => path(ROOTS_DASHBOARD, `/vehicle/${id}/logisticsEdit`),
    logisticView: (id: string) => path(ROOTS_DASHBOARD, `/vehicle/${id}/logisticsView`),
    logisticLogs: (id: string) => path(ROOTS_DASHBOARD, `/vehicle/${id}/logisticsLogs`),
    commercialDataEdit: (id: string) => path(ROOTS_DASHBOARD, `/vehicle/${id}/commercialEdit`),
    commercialDataView: (id: string) => path(ROOTS_DASHBOARD, `/vehicle/${id}/commercialView`),
    commercialDataLogs: (id: string) => path(ROOTS_DASHBOARD, `/vehicle/${id}/commercialLogs`)
  },
  matrix: {
    managment: path(ROOTS_DASHBOARD, '/rvMatrix/managment'),
    upload: path(ROOTS_DASHBOARD, '/matrix/upload'),
    templates: path(ROOTS_DASHBOARD, '/matrix/templates'),
    create: path(ROOTS_DASHBOARD, '/matrix/creation'),
    createVoid: path(ROOTS_DASHBOARD, '/matrix/creationVoid'),
    edit: (id: string) => path(ROOTS_DASHBOARD, `/matrix/${id}/edit`),
    dashboard: path(ROOTS_DASHBOARD, ''),
    requests: path(ROOTS_DASHBOARD, '/matrix/matrixRequests'),
    rvMatrixEdit: (id: string) => path(ROOTS_DASHBOARD, `/rvMatrix/${id}/edit`),
    rvMatrixView: (id: string) => path(ROOTS_DASHBOARD, `/rvMatrix/${id}/view`),
    rvMatrixViewLogs: (id: string, page: string) => path(ROOTS_DASHBOARD, `/rvMatrix/${id}/view/${page}`),
    summary: (id: string) => path(ROOTS_DASHBOARD, `/rvMatrix/${id}/summary`),
    MatrixList: (id: string) => path(ROOTS_DASHBOARD, `/matrix/${id}/list`),
    MatrixListMain: path(ROOTS_DASHBOARD, '/matrix/listMain')
  },
  tenant: {
    tenant: path(ROOTS_DASHBOARD, '/tenant'),
    newCustomField: path(ROOTS_DASHBOARD, '/tenant/customField/new'),
    CustomFieldList: path(ROOTS_DASHBOARD, '/tenant/customField/list'),
    CustomFieldDetail: (id: string) => path(ROOTS_DASHBOARD, `/tenant/customField/${id}/detail`),
    CustomFieldEdit: (id: string) => path(ROOTS_DASHBOARD, `/tenant/customField/${id}/edit`),
    systemEmail: path(ROOTS_DASHBOARD, '/tenant/systemEmail'),
    systemEmailDetails: (id: string) => path(ROOTS_DASHBOARD, `/tenant/systemEmail/${id}/detail`),
    role: {
      admin: {
        list: path(ROOTS_DASHBOARD, '/tenant/role/admin/list'),
        new: path(ROOTS_DASHBOARD, '/tenant/role/admin/new'),
        detail: (id: string) => path(ROOTS_DASHBOARD, `/tenant/role/admin/${id}/detail`),
        edit: (id: string) => path(ROOTS_DASHBOARD, `/tenant/role/admin/${id}/edit`),
        logs: (id: string) => path(ROOTS_DASHBOARD, `/tenant/role/admin/${id}/logs`)
      },
      organization: {
        list: path(ROOTS_DASHBOARD, '/tenant/role/organization/list'),
        new: path(ROOTS_DASHBOARD, '/tenant/role/organization/new'),
        detail: (id: string) => path(ROOTS_DASHBOARD, `/tenant/role/organization/${id}/detail`),
        edit: (id: string) => path(ROOTS_DASHBOARD, `/tenant/role/organization/${id}/edit`),
        logs: (id: string) => path(ROOTS_DASHBOARD, `/tenant/role/organization/${id}/logs`)
      }
    },
    notificationTemplate: {
      list: path(ROOTS_DASHBOARD, '/notificationTemplate/list'),
      new: path(ROOTS_DASHBOARD, '/notificationTemplate/new'),
      edit: (id: string) => path(ROOTS_DASHBOARD, `/notificationTemplate/${id}/edit`)
    }
  },
  organizations: {
    organizations: path(ROOTS_DASHBOARD, '/vendorOrganizations'),
    vendor: {
      list: path(ROOTS_DASHBOARD, '/vendorOrganizations/list'),
      new: path(ROOTS_DASHBOARD, '/vendorOrganizations/new'),
      detail: (id: string, type: string) => path(ROOTS_DASHBOARD, `/vendorOrganizations/${type}/${id}/detail`),
      detailsTab: (id: string, tab: string, type: string) => path(ROOTS_DASHBOARD, `/vendorOrganizations/${type}/${id}/detail/${tab}`),
      edit: (id: string, type: string) => path(ROOTS_DASHBOARD, `/vendorOrganizations/${type}/${id}/edit`),
      logs: (id: string, type: string) => path(ROOTS_DASHBOARD, `/vendorOrganizations/${type}/${id}/logs`),
    },
    customer: {
      list: path(ROOTS_DASHBOARD, '/organizations/list'),
      new: path(ROOTS_DASHBOARD, '/organizations/new'),
      detail: (id: string, type: string) => path(ROOTS_DASHBOARD, `/organizations/${type}/${id}/detail`),
      detailsTab: (id: string, tab: string, type: string) => path(ROOTS_DASHBOARD, `/organizations/${type}/${id}/detail/${tab}`),
      edit: (id: string, type: string) => path(ROOTS_DASHBOARD, `/organizations/${type}/${id}/edit`),
      logs: (id: string, type: string) => path(ROOTS_DASHBOARD, `/organizations/${type}/${id}/logs`),
      rules: {
        listTab: (id: string, tab: string, type: string, rulesTab: RuleTabs) => path(ROOTS_DASHBOARD, `/organizations/${type}/${id}/detail/${tab}/${rulesTab}`),
        edit: (id: string, tab: string, type: string, rulesTab: RuleTabs, ruleType: string) => path(ROOTS_DASHBOARD, `/organizations/${type}/${id}/detail/${tab}/${rulesTab}/${ruleType}/edit`)
      }
    }
  },
  spareParts: {
    spareParts: path(ROOTS_DASHBOARD, '/spareParts'),
    list: path(ROOTS_DASHBOARD, '/spareParts/list'),
    detail: (id: string) => path(ROOTS_DASHBOARD, `/spareParts/${id}/detail`),
    detailsTab: (id: string, tab: string) => path(ROOTS_DASHBOARD, `/spareParts/${id}/detail/${tab}`),
    logs: (id: string) => path(ROOTS_DASHBOARD, `/spareParts/${id}/logs`)
  },
  brands: {
    brands: path(ROOTS_DASHBOARD, '/brands'),
    new: path(ROOTS_DASHBOARD, '/brands/new'),
    list: path(ROOTS_DASHBOARD, '/brands/list'),
    detail: (id: string) => path(ROOTS_DASHBOARD, `/brands/${id}/detail`),
    detailsTab: (id: string, tab: string) => path(ROOTS_DASHBOARD, `/brands/${id}/detail/${tab}`),
    edit: (id: string) => path(ROOTS_DASHBOARD, `/brands/${id}/edit`)
  },
  orders: {
    vendor: {
      orders: path(ROOTS_DASHBOARD, '/orders/vendorOrders'),
      list: path(ROOTS_DASHBOARD, '/orders/vendorOrders/list'),
      detail: (id: string) => path(ROOTS_DASHBOARD, `/orders/vendorOrders/${id}/detail`),
      logs: (id: string) => path(ROOTS_DASHBOARD, `/orders/vendorOrders/${id}/logs`),
      documents: {
        list: path(ROOTS_DASHBOARD, '/orders/documents/vendorDocuments/list'),
        detail: (id: string, type: string) => path(ROOTS_DASHBOARD, `/orders/documents/vendorDocuments/${type}/${id}/detail`),
        logs: (id: string, type: string) => path(ROOTS_DASHBOARD, `/orders/documents/vendorDocuments/${type}/${id}/logs`)
      }
    },
    customer: {
      orders: path(ROOTS_DASHBOARD, '/orders/customerOrders'),
      list: path(ROOTS_DASHBOARD, '/orders/customerOrders/list'),
      detail: (id: string) => path(ROOTS_DASHBOARD, `/orders/customerOrders/${id}/detail`),
      logs: (id: string) => path(ROOTS_DASHBOARD, `/orders/customerOrders/${id}/logs`),
      documents: {
        list: path(ROOTS_DASHBOARD, '/orders/documents/customerDocuments/list'),
        detail: (id: string, type: string) => path(ROOTS_DASHBOARD, `/orders/documents/customerDocuments/${type}/${id}/detail`),
        logs: (id: string, type: string) => path(ROOTS_DASHBOARD, `/orders/documents/customerDocuments/${type}/${id}/logs`)
      },
    }
  },
  request: {
    vendor: {
      request: path(ROOTS_DASHBOARD, '/vendorRequests'),
      list: path(ROOTS_DASHBOARD, '/vendorRequests/list'),
      new: path(ROOTS_DASHBOARD, '/vendorRequests/new'),
      logs: (id: string) => path(ROOTS_DASHBOARD, `/vendorRequests/${id}/logs`),
      detail: (id: string) => path(ROOTS_DASHBOARD, `/vendorRequests/${id}/detail`),
      backlogDetail: (id: string) => path(ROOTS_DASHBOARD, `/vendorRequests/${id}/backlogDetail`),
      backlogDetailsTab: (id: string, tab: string) => path(ROOTS_DASHBOARD, `/vendorRequests/${id}/backlogDetail/${tab}`),
      organizationBacklogDetailsTab: (id: string, tab: string, type: string, orgId: string, orgTab: string) => path(ROOTS_DASHBOARD, `/vendorRequests/${id}/backlogDetail/${tab}/${type}/${orgId}/detail/${orgTab}`),
      warehouse: path(ROOTS_DASHBOARD, '/vendorRequests/confirm'),
      warehouseRequest: (id: string) => path(ROOTS_DASHBOARD, `/vendorRequests/${id}/confirm`),
      anomalies: {
        list: path(ROOTS_DASHBOARD, '/vendorRequests/anomalies/list'),
        detail: (id: string, backTo: string) => path(ROOTS_DASHBOARD, `/vendorRequests/anomalies/${id}/${backTo}/detail`),
        detailsTab: (id: string, tab: string, backTo: string) => path(ROOTS_DASHBOARD, `/vendorRequests/anomalies/${id}/${backTo}/detail/${tab}`),
        organizationDetailsTab: (id: string, tab: string, type: string, orgId: string, orgTab: string, backTo: string) => path(ROOTS_DASHBOARD, `/vendorRequests/anomalies/${id}/${backTo}/detail/${tab}/${type}/${orgId}/detail/${orgTab}`),
        logs: (id: string, backTo: string) => path(ROOTS_DASHBOARD, `/vendorRequests/anomalies/${id}/${backTo}/logs`)
      }
    },
    customer: {
      request: path(ROOTS_DASHBOARD, '/customerRequests'),
      new: path(ROOTS_DASHBOARD, '/customerRequests/new'),
      list: path(ROOTS_DASHBOARD, '/customerRequests/list'),
      detail: (id: string) => path(ROOTS_DASHBOARD, `/customerRequests/${id}/detail`),
      logs: (id: string) => path(ROOTS_DASHBOARD, `/customerRequests/${id}/logs`),
      scan: path(ROOTS_DASHBOARD, '/customerRequests/scan')
    },
    carrier: {
      request: path(ROOTS_DASHBOARD, '/carrierRequests'),
      list: path(ROOTS_DASHBOARD, '/carrierRequests/list'),
      backlogDetail: (id: string) => path(ROOTS_DASHBOARD, `/carrierRequests/${id}/backlogDetail`),
      logs: (id: string) => path(ROOTS_DASHBOARD, `/carrierRequests/${id}/logs`),
      driver: path(ROOTS_DASHBOARD, '/carrierRequests/driver'),
      driverRequest: (id: string) => path(ROOTS_DASHBOARD, `/carrierRequests/${id}/driver`),
    }
  },
  containers: {
    containers: path(ROOTS_DASHBOARD, '/containers'),
    list: path(ROOTS_DASHBOARD, '/containers/list'),
    detail: (id: string) => path(ROOTS_DASHBOARD, `/containers/${id}/detail`),
    logs: (id: string) => path(ROOTS_DASHBOARD, `/containers/${id}/logs`),
    templates: {
      templates: path(ROOTS_DASHBOARD, '/containers/templates'),
      list: path(ROOTS_DASHBOARD, '/containers/templates/list'),
      new: path(ROOTS_DASHBOARD, '/containers/templates/new'),
      detail: (id: string) => path(ROOTS_DASHBOARD, `/containers/templates/${id}/detail`),
      logs: (id: string) => path(ROOTS_DASHBOARD, `/containers/templates/${id}/logs`)
    }
  },
  reports: {
    reports: path(ROOTS_DASHBOARD, '/reports'),
    recurring: {
      recurring: path(ROOTS_DASHBOARD, '/reports/recurring'),
      list: path(ROOTS_DASHBOARD, '/reports/recurring/list'),
      detail: (id: string, tab: ReportDetailTabs, type: ReportTypeData) => path(ROOTS_DASHBOARD, `/reports/recurring/${type}/${id}/detail/${tab}`),
      edit: (id: string, type: ReportTypeData) => path(ROOTS_DASHBOARD, `/reports/recurring/${type}/${id}/edit`)
    },
    scheduled: {
      scheduled: path(ROOTS_DASHBOARD, '/reports/scheduled'),
      list: path(ROOTS_DASHBOARD, '/reports/scheduled/list'),
      detail: (id: string, tab: ReportDetailTabs, type: ReportTypeData) => path(ROOTS_DASHBOARD, `/reports/scheduled/${type}/${id}/detail/${tab}`),
      edit: (id: string, type: ReportTypeData) => path(ROOTS_DASHBOARD, `/reports/scheduled/${type}/${id}/edit`)
    }
  },
  inbox: {
    inbox: path(ROOTS_DASHBOARD, '/inbox'),
    list: path(ROOTS_DASHBOARD, '/inbox/list'),
    section: (section: string) => path(ROOTS_DASHBOARD, `/inbox/list/${section}`),
    detail: (section: string, messageId: string) => path(ROOTS_DASHBOARD, `/inbox/list/${section}/${messageId}`)
  },
  personalArea: {
    personalArea: path(ROOTS_DASHBOARD, '/personalArea')
  },
  rules: {
    listTab: (rulesTab: RuleTabs) => path(ROOTS_DASHBOARD, `/rules/${rulesTab}/list`),
    editHard: (rulesTab: RuleTabs, ruleType: string) => path(ROOTS_DASHBOARD, `/rules/${rulesTab}/${ruleType}/edit`),
    editSoft: (rulesTab: RuleTabs) => path(ROOTS_DASHBOARD, `/rules/${rulesTab}/edit`)
  },
  groups: {
    groups: path(ROOTS_DASHBOARD, '/groups'),
    new: path(ROOTS_DASHBOARD, '/groups/new'),
    list: path(ROOTS_DASHBOARD, '/groups/list'),
    detail: (id: string) => path(ROOTS_DASHBOARD, `/groups/${id}/detail`),
    edit: (id: string) => path(ROOTS_DASHBOARD, `/groups/${id}/edit`),
    logs: (id: string) => path(ROOTS_DASHBOARD, `/containers/${id}/logs`)
  }
};
