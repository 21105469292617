import { useNavigate, useParams } from "react-router";
import { useLocales } from "src/locales";
import HeaderBreadcrumbs from 'src/components/custom-breadcrumbs';
import { useSelector } from "react-redux";
import { dispatch, RootState } from "src/redux/store";
import { Accordion, AccordionDetails, AccordionSummary, Button, Card, Chip, Divider, Typography, alpha } from "@mui/material";
import { Box, Container, Stack } from "@mui/system";
import LoadingScreen from "src/appComponents/loading-screen";
import Page from "src/appComponents/Page";
import { PATH_DASHBOARD } from "src/routes/paths";
import { useSettingsContext } from "src/components/settings";
import PermissionBasedGuard from "src/guards/PermissionBasedGuard";
import { OrganizationPermissionTypes } from "src/@types/permissions";
import dayjs from "dayjs";
import MembersList from "src/sections/@dashboard/group/MembersList";
import Label from "src/components/label";
import { ExpandMore } from "@mui/icons-material";
import { lokalizableRoleStr } from "../role/utils";
import { useEffect } from "react";
import { groupOperations } from "src/redux/group";

export default function GroupDetail() {

    const { id: groupId } = useParams();

    const { translate } = useLocales();

    const { isLoading, group } = useSelector((state: RootState) => state.group);

    const { themeStretch } = useSettingsContext();

    const navigate = useNavigate();

    useEffect(() => {
        if (groupId && (!group || (group.groupId !== groupId))) {
            dispatch(groupOperations.getGroup(groupId));
        }

    }, [groupId, group]);

    return (
        <Page title={`${translate('groups.detail.title')}`}>
            {isLoading ?
                <LoadingScreen />
                :
                <Container maxWidth={themeStretch ? false : 'lg'}>

                    <HeaderBreadcrumbs
                        heading={`${translate('groups.detail.title')}`}
                        links={[
                            { name: `${translate('commons.home')}`, href: PATH_DASHBOARD.root },
                            { name: `${translate(`role.type.groups`)}`, href: PATH_DASHBOARD.groups.list },
                            { name: group?.displayName }
                        ]}
                        action={
                            <PermissionBasedGuard permissions={[OrganizationPermissionTypes.Group_Edit]}>
                                <Button
                                    variant="contained"
                                    onClick={() => navigate(PATH_DASHBOARD.groups.edit(groupId || ""))}>
                                    {`${translate('commons.edit')}`}
                                </Button>
                            </PermissionBasedGuard>
                        }
                    />

                    <Card sx={{ p: 3 }}>

                        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', mb: 4 }}>
                            <Stack>
                                <Box sx={{ display: 'flex', alignItems: "center", gap: 3 }}>
                                    <Typography variant="h4">
                                        {`${group?.displayName}`}
                                    </Typography>
                                    <Label
                                        color={group?.enabled ? 'success' : 'error'}
                                        sx={{ textTransform: 'uppercase' }}
                                    >
                                        {group?.enabled ? `${translate("commons.enabled")}` : `${translate("commons.disabled")}`}
                                    </Label>
                                </Box>
                                <Typography variant="body2">
                                    {`${translate(`commons.createdOn`)} `}
                                    <b>{dayjs(group?.createdOn).format('DD MMM YYYY, HH:mm')}</b>
                                </Typography>
                            </Stack>
                        </Box>

                        <Box sx={{ mt: 2, mb: 3, "& .MuiPaper-root": { borderRadius: "0px !important" } }}>
                            <Divider />
                            <Accordion sx={{ overflowY: 'auto' }}>
                                <AccordionSummary
                                    sx={{
                                        backgroundColor: (theme) => theme.palette.mode === "light" ? '#F4F6F8' : '#29313a',
                                        maxHeight: '40vh', overflowY: 'auto'
                                    }}
                                    expandIcon={<ExpandMore />}
                                >
                                    <Box sx={{
                                        display: 'flex',
                                        maxHeight: '40vh',
                                        overflowY: 'auto',
                                        justifyContent: 'space-between',
                                        alignItems: 'center',
                                        width: '100%'
                                    }}>
                                        <Typography variant="h5">
                                            {`${translate('groups.form.property_plural')}`}
                                        </Typography>
                                    </Box>
                                </AccordionSummary>
                                <AccordionDetails>
                                    {Object.keys(group?.propertyDependency?.properties || {}).map((field: string, index) =>
                                        <Chip
                                            sx={{ m: '5px', "& .MuiChip-label": { fontSize: 14 }, bgcolor: (theme) => alpha(theme.palette.primary.light, 0.6) }}
                                            key={"filter.object.key." + index + "." + field}
                                            label={field + ': "' + group?.propertyDependency?.properties[field] + '"'}
                                        />
                                    )}
                                </AccordionDetails>
                            </Accordion>
                            <Divider />
                        </Box>

                        <Box sx={{ mt: 2, mb: 3, "& .MuiPaper-root": { borderRadius: "0px !important" } }}>
                            <Divider />
                            <Accordion sx={{ overflowY: 'auto' }}>
                                <AccordionSummary
                                    sx={{
                                        backgroundColor: (theme) => theme.palette.mode === "light" ? '#F4F6F8' : '#29313a',
                                        maxHeight: '40vh', overflowY: 'auto'
                                    }}
                                    expandIcon={<ExpandMore />}
                                >
                                    <Box sx={{
                                        display: 'flex',
                                        maxHeight: '40vh',
                                        overflowY: 'auto',
                                        justifyContent: 'space-between',
                                        alignItems: 'center',
                                        width: '100%'
                                    }}>
                                        <Typography variant="h5">
                                            {`${translate('notificationTemplate.form.permission_plural')}`}
                                        </Typography>

                                        {group?.permissionDependency.atLeastOne &&
                                            <Label color="success" sx={{ mr: 3 }}>
                                                {`${translate('notificationTemplate.form.atLeastOne')}`}
                                            </Label>
                                        }
                                    </Box>
                                </AccordionSummary>
                                <AccordionDetails>
                                    {group?.permissionDependency.permissions.map((v, index) => (
                                        <Chip key={index} sx={{ m: 0.5 }} label={
                                            <Typography
                                                key={index}
                                                variant="body2">
                                                {`${translate(`role.permission.${lokalizableRoleStr(v)}.title`)}`}
                                            </Typography>
                                        } />
                                    ))}
                                </AccordionDetails>
                            </Accordion>
                            <Divider />
                        </Box>

                        <Typography variant="h5" sx={{ pl: 2, mb: 2 }}>
                            {`${translate('groups.detail.membersList')}`}
                        </Typography>

                        <Divider />

                        <MembersList />

                    </Card>
                </Container>
            }
        </Page >
    );
}