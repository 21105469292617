import { createAsyncThunk } from '@reduxjs/toolkit';
import { CloseShipSliceProps, ContainerFilters, ContainerTemplateSearchResult, EditSliceProps, EnableDisableSliceProps, NewContainerTemplate, NewExtraItem, TemplateStatusType } from 'src/@types/container';
import { startTemplateLoading, startLoading, startLogsLoading, enableDisableTemplate, newTemplateInList, editTemplateInList, openTemplate, closeShip } from './container-slices';
import containerService from '../../services/containerService';
import removeEmptyKeys from 'src/utils/removeEmptyKeys';

const createContainerTemplate = createAsyncThunk(
    'container/createContainerTemplate',
    async (options: { container: NewContainerTemplate, tab: TemplateStatusType }, { dispatch, rejectWithValue }) => {
        dispatch(startTemplateLoading());
        try {
            const { data, status } = await containerService.createContainerTemplate(options.container);

            if (status >= 400) {
                throw new Error('Something went wrong');
            }

            dispatch(newTemplateInList({ item: data, tab: options.tab }));

            return data;
        } catch (err) {
            if (err?.response?.data?.errors) {
                return rejectWithValue(err.response.data.errors);
            }

            return rejectWithValue(err?.response?.statusText);
        }
    }
);

const updateContainerTemplate = createAsyncThunk(
    'container/updateContainerTemplate',
    async (params: { container: NewContainerTemplate, id: string, options: EditSliceProps }, { dispatch, rejectWithValue }) => {
        dispatch(startTemplateLoading());
        try {
            const { container, id, options } = params;

            let newItem: ContainerTemplateSearchResult | undefined = undefined;

            if (options.totalCount > options.listLen && options.listLen === options.pageSize && options.tab !== "") {
                const { data } = await containerService.searchContainerTemplate({ ...options.filters, pageIndex: options.filters.pageIndex + 1 });

                newItem = data.items[0];

                if (!data) {
                    throw new Error('Something went wrong');
                }

            }

            const { data, status } = await containerService.updateContainerTemplate(container, id);

            if (status >= 400) {
                throw new Error('Something went wrong');
            }

            dispatch(editTemplateInList({ ...options, item: data, newItem: newItem }));

            return data;
        } catch (err) {
            if (err?.response?.data?.errors) {
                return rejectWithValue(err.response.data.errors);
            }

            return rejectWithValue(err?.response?.statusText);
        }
    }
);

const getContainerTemplate = createAsyncThunk(
    'container/getContainerTemplate',
    async (id: string, { dispatch, rejectWithValue }) => {
        dispatch(startTemplateLoading());
        try {
            const { data } = await containerService.detailContainerTemplate(id);

            if (!data) {
                throw new Error('Something went wrong');
            }

            return data;
        } catch (err) {
            if (err?.response?.data?.errors) {
                return rejectWithValue(err.response.data.errors);
            }

            return rejectWithValue(err?.response?.statusText);
        }
    }
);

const getContainer = createAsyncThunk(
    'container/getContainer',
    async (id: string, { dispatch, rejectWithValue }) => {
        dispatch(startLoading());
        try {
            const { data, status } = await containerService.detailContainer(id);

            if (status >= 400) {
                throw new Error('Something went wrong');
            }

            return data;
        } catch (err) {
            if (err?.response?.data?.errors) {
                return rejectWithValue(err.response.data.errors);
            }

            return rejectWithValue(err?.response?.statusText);
        }
    }
);

const searchContainerTemplate = createAsyncThunk(
    'container/searchContainerTemplate',
    async (params: ContainerFilters, { dispatch, rejectWithValue }) => {
        dispatch(startLoading());
        try {
            const { data } = await containerService.searchContainerTemplate(params);

            if (!data) {
                throw new Error('Something went wrong');
            }

            return data;
        } catch (err) {
            if (err?.response?.data?.errors) {
                return rejectWithValue(err.response.data.errors);
            }

            return rejectWithValue(err?.response?.statusText);
        }
    }
);

const searchContainer = createAsyncThunk(
    'container/searchContainer',
    async (params: { filters: ContainerFilters, isIstance: boolean }, { dispatch, rejectWithValue }) => {
        dispatch(startLoading());
        try {
            const { data } = await containerService.searchContainer(params.filters);

            if (!data) {
                throw new Error('Something went wrong');
            }

            return { ...data, isIstance: params.isIstance };
        } catch (err) {
            if (err?.response?.data?.errors) {
                return rejectWithValue(err.response.data.errors);
            }

            return rejectWithValue(err?.response?.statusText);
        }
    }
);

const enableDisableContainerTemplate = createAsyncThunk(
    'container/enableDisableContainerTemplate',
    async (options: EnableDisableSliceProps, { dispatch, rejectWithValue }) => {

        try {

            let newItem: ContainerTemplateSearchResult | undefined = undefined;

            if (options.totalCount > options.listLen && options.listLen === options.pageSize && options.tab !== "") {
                const { data } = await containerService.searchContainerTemplate({ ...options.filters, pageIndex: options.filters.pageIndex + 1 });

                newItem = data.items[0];

                if (!data) {
                    throw new Error('Something went wrong');
                }

            }

            const { data, status } = options.action === 'Enable' ?
                await containerService.enableContainerTemplate(options.id) :
                await containerService.disableContainerTemplate(options.id);

            if (status >= 400) {
                throw new Error('Something went wrong');
            }

            dispatch(enableDisableTemplate({ ...options, newItem: newItem }));

            return data;
        } catch (err) {
            if (err?.response?.data?.errors) {
                return rejectWithValue(err.response.data.errors);
            }

            return rejectWithValue(err?.response?.statusText);
        }
    }
);

const openContainer = createAsyncThunk(
    'container/openContainer',
    async (id: string, { rejectWithValue, dispatch }) => {
        try {
            const { data, status } = await containerService.openContainer(id);

            if (status >= 400) {
                throw new Error('Something went wrong');
            }

            dispatch(openTemplate({ id: id }));

            return data;
        } catch (err) {
            if (err?.response?.data?.errors) {
                return rejectWithValue(err.response.data.errors);
            }

            return rejectWithValue(err?.response?.statusText);
        }
    }
);

const closeShipContainer = createAsyncThunk(
    'container/closeShipContainer',
    async (options: CloseShipSliceProps, { dispatch, rejectWithValue }) => {

        try {

            let newItem: ContainerTemplateSearchResult | undefined = undefined;

            if (options.totalCount > options.listLen && options.listLen === options.pageSize && options.tab !== "") {
                const { data } = await containerService.searchContainer({ ...options.filters, pageIndex: options.filters.pageIndex + 1 });

                newItem = data.items[0];

                if (!data) {
                    throw new Error('Something went wrong');
                }

            }

            const { data, status } = options.action === 'Close' ?
                await containerService.closeContainer(options.id) :
                await containerService.shipContainer(options.id);

            if (status >= 400) {
                throw new Error('Something went wrong');
            }

            dispatch(closeShip({ ...options, newItem: newItem }));

            return data;
        } catch (err) {
            if (err?.response?.data?.errors) {
                return rejectWithValue(err.response.data.errors);
            }

            return rejectWithValue(err?.response?.statusText);
        }
    }
);

const closeContainer = createAsyncThunk(
    'container/closeContainer',
    async (id: string, { rejectWithValue }) => {
        try {
            const { status } = await containerService.closeContainer(id);

            if (status >= 400) {
                throw new Error('Something went wrong');
            }
        }
        catch (err) {
            if (err?.response?.data?.errors) {
                return rejectWithValue(err.response.data.errors);
            }

            return rejectWithValue(err?.response?.statusText);
        }
    }
);

const shipContainer = createAsyncThunk(
    'container/shipContainer',
    async (id: string, { rejectWithValue }) => {
        try {
            const { status } = await containerService.shipContainer(id);

            if (status >= 400) {
                throw new Error('Something went wrong');
            }
        }
        catch (err) {
            if (err?.response?.data?.errors) {
                return rejectWithValue(err.response.data.errors);
            }

            return rejectWithValue(err?.response?.statusText);
        }
    }
);

const searchStatisticsContainerTemplate = createAsyncThunk(
    'container/searchStatisticsContainerTemplate',
    async (params: ContainerFilters, { rejectWithValue, dispatch }) => {
        dispatch(startLoading());
        try {
            const { data } = await containerService.searchStatisticsContainerTemplate(params);

            if (!data) {
                throw new Error('Something went wrong');
            }

            return data;
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
);

const searchStatisticsContainer = createAsyncThunk(
    'container/searchStatisticsContainer',
    async (params: ContainerFilters, { rejectWithValue, dispatch }) => {
        dispatch(startLoading());
        try {
            const { data } = await containerService.searchStatisticsContainer(params);

            if (!data) {
                throw new Error('Something went wrong');
            }

            return data;
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
);

const getContainerLogs = createAsyncThunk(
    'container/getContainerLogs',
    async (options: { id: string, filters: any }, { rejectWithValue, dispatch }) => {
        dispatch(startLogsLoading());
        try {

            const { data } = await containerService.searchLogsContainer(options.id, removeEmptyKeys(options.filters));

            return data;
        } catch (err) {
            return rejectWithValue(err.message);
        }
    }
);

const addRequest = createAsyncThunk(
    'container/addRequest',
    async (params: { containerId: string, requestId: string }, { rejectWithValue, dispatch }) => {
        try {

            const { status, data } = await containerService.addRequest(params);

            if (status >= 400) {
                throw new Error('Something went wrong');
            }

            return data;
        } catch (err) {
            return rejectWithValue(err.message);
        }
    }
);

const addExtraItems = createAsyncThunk(
    'container/addExtraItems',
    async (params: { containerId: string, newExtraItem: NewExtraItem }, { rejectWithValue }) => {
        try {

            const { status } = await containerService.addExtraItems(params.containerId, params.newExtraItem);

            if (status >= 400) {
                throw new Error('Something went wrong');
            }

            return params.newExtraItem;
        } catch (err) {
            return rejectWithValue(err.message);
        }
    }
);

const getContainerByTemplate = createAsyncThunk(
    'container/getContainerByTemplate',
    async (id: string, { rejectWithValue, dispatch }) => {
        dispatch(startLoading());
        try {
            const { data, status } = await containerService.detailByTemplate(id);

            if (status >= 400) {
                throw new Error('Something went wrong');
            }

            return data;
        } catch (err) {
            if (err?.response?.data?.errors) {
                return rejectWithValue(err.response.data.errors);
            }

            return rejectWithValue(err?.response?.statusText);
        }
    }
);

const containerOperations = {
    createContainerTemplate,
    updateContainerTemplate,
    getContainerTemplate,
    searchContainerTemplate,
    enableDisableContainerTemplate,
    searchStatisticsContainerTemplate,
    openContainer,
    getContainerLogs,
    getContainer,
    searchContainer,
    searchStatisticsContainer,
    addRequest,
    closeShipContainer,
    getContainerByTemplate,
    addExtraItems,
    shipContainer,
    closeContainer
};

export default containerOperations;