import React, { useState } from 'react';
import { TableRow, TableCell, MenuItem, Typography } from '@mui/material';
import useLocales from 'src/appHooks/useLocales';
import Label from 'src/components/label';
import TableMoreMenu from 'src/appComponents/TableMoreMenu';
import DateZone from 'src/appComponents/DateZone';
import { Role } from 'src/@types/role';

type RoleTableRowProps = {
  row: Role;
  onEditRow: VoidFunction;
  onDetailsRow: VoidFunction;
  onLogsRow: VoidFunction;
  onEnableRow: VoidFunction;
  onDisableRow: VoidFunction;
};

export default function OrganizationRoleTableRow({
  row,
  onEditRow,
  onDetailsRow,
  onLogsRow,
  onEnableRow,
  onDisableRow
}: RoleTableRowProps) {

  const { createdOn, name, userInRole, enabled } = row;

  const { translate } = useLocales();

  const [openMenu, setOpenMenuActions] = useState<HTMLElement | null>(null);

  const handleOpenMenu = (event: React.MouseEvent<HTMLElement>) => {
    setOpenMenuActions(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setOpenMenuActions(null);
  };

  return (
    <TableRow hover sx={{ cursor: 'pointer' }}>

      <TableCell onClick={onDetailsRow}>
        <Typography>{name}</Typography>
      </TableCell>

      <TableCell align='left' onClick={onDetailsRow}><DateZone date={createdOn} /></TableCell>

      <TableCell align='left' onClick={onDetailsRow}><Typography>{userInRole}</Typography></TableCell>

      <TableCell align='center' onClick={onDetailsRow}>
        <Label
          color={!enabled ? 'error' : 'success'}
          sx={{ textTransform: 'uppercase' }}
        >
          {enabled ? `${translate("commons.enabled")}` : `${translate("commons.disabled")}`}
        </Label>
      </TableCell>

      <TableCell align='center'>
        <TableMoreMenu
          open={openMenu}
          onOpen={handleOpenMenu}
          onClose={handleCloseMenu}
          actions={
            <>
              <MenuItem
                onClick={() => {
                  if (!enabled) {
                    onEnableRow();
                  }
                  else {
                    onDisableRow();
                  }
                  handleCloseMenu();
                }}
                sx={!enabled ? { color: 'success.main' } : { color: 'error.main' }}
              >
                {enabled ? `${translate("commons.disable")}` : `${translate("commons.enabled")}`}
              </MenuItem>

              <MenuItem
                onClick={() => {
                  onEditRow();
                  handleCloseMenu();
                }}>
                {`${translate("commons.edit")}`}
              </MenuItem>

              <MenuItem
                onClick={() => {
                  onLogsRow();
                  handleCloseMenu();
                }}>
                {`${translate("commons.logs")}`}
              </MenuItem>

            </>
          }
        />
      </TableCell>

    </TableRow>
  );
}
