import { useCallback, useEffect, useMemo, useState } from 'react';
import { Card, Box, Typography, Grid, Divider, Button, TextField, FormControlLabel, Switch } from '@mui/material';
import { RootState, dispatch } from 'src/redux/store';
import { useSelector } from 'react-redux';
import useLocales from 'src/appHooks/useLocales';
import { defaultRates } from 'src/sections/@dashboard/rules/Constants';
import { useForm } from 'react-hook-form';
import { SurveillanceRulesFormResult } from 'src/@types/rules';
import FormProvider from "src/components/hook-form/FormProvider";
import useResponsive from 'src/hooks/useResponsive';
import RHFNumericFormat from 'src/components/hook-form/RHFNumericFormat';
import { NumericFormat } from 'react-number-format';
import { Theme, alpha } from '@mui/material/styles';
import { rulesOperations } from 'src/redux/rules';
import ErrorModal from 'src/components/modals/ErrorModal';
import SuccessModal from 'src/components/modals/SuccessModal';
import SaveModal from 'src/components/modals/SaveModal';
import { hasPermissions } from 'src/utils/user';
import { OrganizationPermissionTypes } from 'src/@types/permissions';
import { useUserOrganizationContext } from 'src/contexts/UserOrganizationContext';

export default function SurveillanceTab() {

    const { translate, currentLang } = useLocales();

    const isDesktop = useResponsive('up', 'md');

    const { organization } = useUserOrganizationContext();

    const { rule } = useSelector((state: RootState) => state.rules);

    const defaultValues = useMemo(() =>
        defaultRates(rule?.surveillance as SurveillanceRulesFormResult), [rule]);

    const formMethods = useForm<SurveillanceRulesFormResult>({
        mode: "onChange",
        defaultValues
    });

    const { reset, watch, getValues, setValue } = formMethods;

    useEffect(() => {
        reset(defaultValues);
    }, [defaultValues, reset]);

    const { newPart, damagePackaging, damageUnpackaging, quality, enabled } = watch();

    const getDamagedValue = useCallback(() => {
        return damagePackaging + damageUnpackaging;
    }, [damagePackaging, damageUnpackaging]);

    const damagedValue = useMemo(() => getDamagedValue(), [getDamagedValue]);

    const getTotalValue = useCallback(() => {
        return newPart + quality + damagedValue;
    }, [damagedValue, newPart, quality]);

    const totalValue = useMemo(() => getTotalValue(), [getTotalValue]);

    const decimalSeparator = useMemo(() => {

        const fixedTestValue = 10.15;

        return fixedTestValue.toLocaleString(currentLang.locale.code, { style: "decimal" })[2];

    }, [currentLang]);

    const [isOpenError, setIsOpenError] = useState(false);

    const [isOpenSuccess, setIsOpenSuccess] = useState(false);

    const [isOpenSave, setIsOpenSave] = useState(false);

    const handleSubmit = useCallback(async () => {
        setIsOpenSave(false);

        try {
            await dispatch(rulesOperations.updateRuleSurveillance({ ...(getValues()) })).unwrap();

            setIsOpenSuccess(true);
        } catch {

            setIsOpenError(true);
        }
    }, [getValues]);

    return (
        <Card>

            <SuccessModal
                isOpen={isOpenSuccess}
                toggle={() => setIsOpenSuccess(prev => !prev)}
                successText={`${translate('rules.save')}`}
                returnPage={""} //PATH_DASHBOARD
            />

            <ErrorModal
                isOpen={isOpenError}
                toggle={() => setIsOpenError(prev => !prev)}
                errorText={`${translate('commons.error')}`}
            />

            <SaveModal
                toggle={() => setIsOpenSave(prev => !prev)}
                isOpen={isOpenSave}
                handleSave={handleSubmit}
                saveText={`${translate('rules.messages.saveConfirm')}`}
            />

            <FormProvider methods={formMethods}>

                <Box>

                    <Box sx={{ px: (isDesktop ? 3 : 1.5), py: 2 }}>

                        <LabeledRate
                            name={"newPart"}
                            text={`${translate('rules.form.survForm.newPart')}`}
                            strong={true}
                            decimalSeparator={decimalSeparator}
                        />

                        <LabeledRate
                            name={"damagedReturn"}
                            text={`${translate('rules.form.survForm.damagedReturn')}`}
                            strong={true}
                            value={damagedValue}
                            decimalSeparator={decimalSeparator}
                        />

                        <LabeledRate
                            name={"damagePackaging"}
                            text={`${translate('rules.form.survForm.damagePackaging')}`}
                            strong={false}
                            pl={(isDesktop ? 4 : 2)}
                            decimalSeparator={decimalSeparator}
                        />

                        <LabeledRate
                            name={"damageUnpackaging"}
                            text={`${translate('rules.form.survForm.damageUnpackaging')}`}
                            strong={false}
                            pl={(isDesktop ? 4 : 2)}
                            decimalSeparator={decimalSeparator}
                        />

                        <LabeledRate
                            name={"quality"}
                            text={`${translate('rules.form.survForm.quality')}`}
                            strong={true}
                            decimalSeparator={decimalSeparator}
                        />

                        <Divider />

                        <LabeledRate
                            name={"totalThreshold"}
                            text={`${translate('rules.form.survForm.totalThreshold')}`}
                            strong={true}
                            value={totalValue}
                            pt={2}
                            decimalSeparator={decimalSeparator}
                        />

                        <Divider sx={{ mt: 2 }} />

                        <LabeledRate
                            name={"fee"}
                            text={`${translate('rules.form.survForm.surveillanceFee')}`}
                            strong={true}
                            pt={2}
                            decimalSeparator={decimalSeparator}
                        />

                    </Box>

                    <Divider />

                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: "space-between",
                            px: isDesktop ? 5 : 2,
                            py: isDesktop ? 3 : 1,
                            gap: 1,
                            flexDirection: isDesktop ? 'row' : 'column-reverse'
                        }}
                    >

                        <FormControlLabel
                            disabled={!hasPermissions([OrganizationPermissionTypes.WebShop_ReturnRequests_Rules_VendorEnableDisable], organization.roles)}
                            label={`${translate(`commons.${enabled ? "enabled" : "disabled"}`)}`}
                            control={<Switch checked={enabled} onChange={(_, check) => setValue("enabled", check)} />}
                        />

                        <Button
                            variant="contained"
                            size={'medium'}
                            disabled={!hasPermissions([OrganizationPermissionTypes.WebShop_ReturnRequests_Rules_VendorEdit, OrganizationPermissionTypes.WebShop_ReturnRequests_Rules_VendorEnableDisable], organization.roles)}
                            onClick={() => setIsOpenSave(true)}
                            sx={{
                                ml: isDesktop ? 1 : 0,
                                mx: isDesktop ? 0 : 1.5,
                                my: isDesktop ? 0 : 1.5,
                                borderRadius: "100px"
                            }}
                        >
                            {`${translate("commons.justSave")}`}
                        </Button>
                    </Box>

                </Box>

            </FormProvider>
        </Card>
    );
}

interface LabeledRateProps {
    name: string,
    text: string,
    strong: boolean,
    decimalSeparator: string,
    value?: number,
    pt?: number,
    pl?: number
}

const LabeledRate = ({ name, text, strong, value, pt, pl, decimalSeparator }: LabeledRateProps) => {

    const { translate } = useLocales();

    const isDesktop = useResponsive('up', 'md');

    const isFee = useMemo(() => name === "fee", [name]);

    const { organization } = useUserOrganizationContext();

    return (
        <Grid
            container
            sx={{
                pb: pt ? 0 : (isDesktop ? 2 : 3),
                pt: pt || 0
            }}
        >
            <Grid item xs={8} md={10.5}>
                <Typography
                    display="inline"
                    sx={{
                        fontWeight: strong ? 'bold' : "normal",
                        pl: pl || 0
                    }}
                    variant={"subtitle1"}
                >
                    {`${text}: `}
                </Typography>
            </Grid>

            <Grid item xs={4} md={1.5}>
                {typeof value === "number" ? (
                    <NumericFormat
                        customInput={TextField}
                        name={name}
                        label={`${translate(isFee ? 'rules.form.fee' : 'rules.list.tableHeaders.rate')}`}
                        value={value}
                        decimalSeparator={decimalSeparator}
                        disabled
                        suffix={'%'}
                        sx={{ '& .MuiOutlinedInput-root': { '& fieldset': { background: (theme: Theme) => alpha(theme.palette.grey[300], 0.20) } } }}

                    />
                ) : (
                    <RHFNumericFormat
                        name={name}
                        disabled={!hasPermissions([OrganizationPermissionTypes.WebShop_ReturnRequests_Rules_VendorEdit], organization.roles)}
                        label={`${translate(isFee ? 'rules.form.fee' : 'rules.list.tableHeaders.rate')}`}
                        decimalSeparator={decimalSeparator}
                        suffix={'%'}
                    />
                )}

            </Grid>
        </Grid>
    );
};