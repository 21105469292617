import { Box, Button, Card, Divider, Modal, Typography } from "@mui/material";
import { useCallback, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router";
import { Address } from "src/@types/organizations";
import { DEFAULT_WAREHOUSE_ACCEPT, Request } from "src/@types/request";
import LoadingScreen from "src/appComponents/loading-screen/LoadingScreen";
import useTenant from "src/appHooks/useTenant";
import MediaSlider from "src/components/mediaSlider/MediaSlider";
import ErrorModal from "src/components/modals/ErrorModal";
import useResponsive from "src/hooks/useResponsive";
import { useLocales } from "src/locales";
import { requestOperations } from "src/redux/request";
import { RootState, dispatch } from "src/redux/store";
import { PATH_DASHBOARD } from "src/routes/paths";
import { rejectByWarehouse, warehouseAccept } from "src/services/requestsServices";
import { getCustomFieldLabel } from "src/utils/CustomFieldManagment";

interface DriverDetailsProps {
    handlePreviousStep: VoidFunction,
    handleNextStep?: VoidFunction,
    request?: Request | null,
    carrier: boolean,
    customer: boolean
}

export default function DriverDetails({ handlePreviousStep, handleNextStep, request, carrier, customer }: DriverDetailsProps) {

    const { translate, currentLang } = useLocales();

    const { isLoading } = useSelector((state: RootState) => state.request);

    const navigate = useNavigate();

    const isDesktop = useResponsive('up', 'sm');

    const [isOpen, setIsOpen] = useState(false);

    const [isRefused, setIsRefused] = useState(false);

    const [err, setErr] = useState(false);

    const { id } = useParams();

    const { customFields } = useTenant();

    const customFieldName = useCallback((id: string) => {

        return getCustomFieldLabel(customFields, id, currentLang);

    }, [currentLang, customFields]);

    const detailTypoSX = {
        color: '#6D6D6D',
        fontSize: 14,
        fontFamily: 'Public Sans',
        fontWeight: '400',
        wordWrap: 'break-word'
    };

    const getAddress = (address: Address): string[] => {
        let addr = "";
        let city = "";
        let country = "";

        addr += address.address ? address.address + ", " : "";
        addr += address.district ? address.district + "" : "";
        city += address.zipCode ? address.zipCode + ", " : "";
        city += address.city ? address.city + "" : "";
        country += address.country ? address.country : "";

        return [addr, city, country];
    };

    const handleConfirm = async () => {
        if (request)
            try {
                if (carrier) {
                    if (request.status === "RefusedReadyForPickUp")
                        await dispatch(requestOperations.refusedPickedUpOp({ id: request.id, note: request.refusedNotes?.note || "", customFields: {} })).unwrap();
                    else
                        await dispatch(requestOperations.pickedUpRequest({ id: request.id, note: "", customFields: {} })).unwrap();
                }
                else if (customer) {
                    await dispatch(requestOperations.returnToCustomerOp({ id: request.id, note: request.refusedNotes?.note || "", customFields: {} })).unwrap();
                }
                else
                    await warehouseAccept({ ...DEFAULT_WAREHOUSE_ACCEPT, id: request.id, containerCode: null });

                setIsOpen(true);
            }
            catch {
                setErr(true);
            }

    };

    const handleRefuse = async () => {
        if (request)
            try {
                await rejectByWarehouse({ customFields: {}, id: request.id, note: "" });

                setIsRefused(true);
                setIsOpen(true);
            } catch {
                setErr(true);
            }
    };

    const allQuantities = useMemo(() => {

        let counter = 0;

        if (request) {
            if (request.items.length > 0) request.items.forEach((item) => counter += item.requiredQuantity || item.approvedQuantity);
            if (request.missingItems.length > 0) request.missingItems.forEach((item) => counter += item.requiredQuantity || item.approvedQuantity);
            if (request.exceededItems.length > 0) request.exceededItems.forEach((item) => counter += item.quantity);
        }

        return counter;

    }, [request]);

    const isMediaImage = useMemo(() => request && request.hasMedia && request.requestType !== "Warranty", [request]);

    return (
        <>
            {isLoading ? <LoadingScreen /> :
                <Card sx={{ my: 2, p: 3 }}>

                    <ErrorModal isOpen={err} errorText={`${translate('commons.error')}`} toggle={() => setErr(prev => !prev)} />

                    <Modal
                        open={isOpen}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                    >
                        <Card sx={{
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            transform: 'translate(-50%, -50%)',
                            width: isDesktop ? '23vw' : '63vw',
                            p: isDesktop ? 5 : 2,
                        }}>
                            <Box sx={{ textAlign: "center" }}>

                                <Box component="img" src="/assets/illustrations/illustration_docs.svg" sx={{ width: "45%", m: "auto" }} />

                                <Typography variant="h6" sx={{ mt: 3 }}>
                                    {`${translate('request.confirmed')}`}
                                </Typography>

                                {request?.requestType === "WrongShipment" && !isRefused &&
                                    <Typography variant='body1'>
                                        {`${translate('request.messages.requestSentSuccess')}`}
                                    </Typography>
                                }
                                {request?.requestType === "WrongShipment" && isRefused &&
                                    <Typography variant='body1'>
                                        {`${translate("request.anomalies.detail.successMessage")}`}
                                    </Typography>
                                }

                                {request?.requestType !== "WrongShipment" && <Typography variant='body1'>
                                    {`${translate('request.pickupConfirm')}`}
                                </Typography>}

                                <Button
                                    variant="contained"
                                    size={isDesktop ? "small" : 'large'}

                                    onClick={() => navigate(carrier ? PATH_DASHBOARD.request.carrier.list :
                                        customer ?
                                            PATH_DASHBOARD.request.customer.list :
                                            PATH_DASHBOARD.request.vendor.list)
                                    }
                                    sx={{ mt: 3, ml: 2, borderRadius: "50px !important" }}
                                >
                                    {`${translate('commons.toList')}`}
                                </Button>

                            </Box>
                        </Card>
                    </Modal>

                    <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                        <Typography variant="h5">
                            {`${translate('request.detail.found')}`}
                        </Typography>
                    </Box>

                    {(request && (request.requestType === "Warranty" || request?.requestType === "WrongShipment")) ? (
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                gap: 1,
                                width: '100%',
                                backgroundColor: '#F9FAFB',
                                borderRadius: '8px',
                                p: 5,
                                mt: 2,

                            }}>
                            {request.items.map((item) =>
                                <Typography variant="subtitle1" key={item.product.id}>{item.product.externalId} - {item.product.name}</Typography>
                            )}
                            {request.exceededItems.map((item) =>
                                <Typography variant="subtitle1" key={item.product.id}>{item.product.externalId} - {item.product.name}</Typography>
                            )}
                            {request.missingItems.map((item) =>
                                <Typography variant="subtitle1" key={item.product.id}>{item.product.externalId} - {item.product.name}</Typography>
                            )}
                            <Typography variant="body2" textTransform={"uppercase"}>{`${translate('request.detail.barCode')}`}: {request?.barcode}</Typography>
                        </Box>
                    ) : (
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                gap: 1,
                                width: '100%',
                                backgroundColor: '#F9FAFB',
                                borderRadius: '8px',
                                p: 5,
                                mt: 2,

                            }}>
                            <Typography variant="subtitle1">{request ? request.prettyName : 1234} {`${translate('request.detail.returnRef')}`}</Typography>
                            <Typography variant="body2" textTransform={"uppercase"}>{`${translate('request.detail.barCode')}`}: {request?.barcode}</Typography>
                            {request && <Divider />}
                            {request && <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    gap: 1,
                                    width: '100%',
                                    backgroundColor: '#F9FAFB',
                                    borderRadius: '8px',
                                    mt: 2,

                                }}>
                                {request.items.map((item) =>
                                    <Typography variant="subtitle1" key={item.product.id}>{item.product.externalId} - {item.product.name}</Typography>
                                )}
                                {request.exceededItems.map((item) =>
                                    <Typography variant="subtitle1" key={item.product.id}>{item.product.externalId} - {item.product.name}</Typography>
                                )}
                            </Box>}
                        </Box>
                    )}

                    <Box sx={{
                        display: 'grid',
                        gridTemplateColumns: '0.75fr 1fr',
                        width: '100%',
                        gap: 2, my: 2
                    }}>
                        <Typography sx={{
                            color: '#6D6D6D',
                            fontSize: 12,
                            fontFamily: 'Public Sans',
                            fontWeight: '700',
                            textTransform: 'uppercase',
                            wordWrap: 'break-word'
                        }}>
                            {`${translate('commons.details')}`}
                        </Typography>

                        <Typography />

                        <Typography sx={detailTypoSX}>
                            {`${translate('commons.status')}`}
                        </Typography>

                        <Typography sx={detailTypoSX}>
                            {request ? `${translate(`commons.order.returnRequest.${request.status}`)}` : "—"}
                        </Typography>

                        <Typography sx={detailTypoSX}>
                            {customFieldName("stellantis-europe-returnrequestapprover-icar-request")}
                        </Typography>

                        <Typography sx={{ ...detailTypoSX }} color='#303030'>
                            {request && request.approverNotes && request.approverNotes.customFields["stellantis-europe-returnrequestapprover-icar-request"] || "—"}
                        </Typography>

                        {(request && request.requestType !== "Warranty") &&
                            <Typography sx={detailTypoSX}>
                                {customFieldName('stellantis-europe-spareparts-deposit-number')}
                            </Typography>
                        }

                        {(request && request.requestType !== "Warranty") &&
                            <Typography sx={{ ...detailTypoSX }} color='#303030'>
                                {request && request.items.map((item, index) => {
                                    return ((item.product.customFields && item.product.customFields['stellantis-europe-spareparts-deposit-number']) || "—") + (index === request.items.length - 1 ? "" : ", ");
                                })}
                            </Typography>
                        }

                        <Typography sx={detailTypoSX}>
                            {`${translate('request.big')}`}
                        </Typography>

                        <Typography sx={{ ...detailTypoSX }} color='#303030'>
                            {request && (request.items[0]?.bigAndHeavy || request.exceededItems[0]?.bigAndHeavy || request.missingItems[0]?.bigAndHeavy) ? `${translate('commons.yes')}` : `${translate('commons.no')}`}
                        </Typography>

                        <Typography sx={detailTypoSX}>
                            {`${translate('commons.quantity')}`}
                        </Typography>

                        <Typography sx={{ ...detailTypoSX }} color='#303030'>
                            {allQuantities}
                        </Typography>

                        <Typography sx={{ ...detailTypoSX }} color='#303030'>
                            {`${translate('commons.action')}`}
                        </Typography>

                        <Typography sx={{ ...detailTypoSX }} color='#303030'>
                            {request && request.approverNotes && request.approverNotes.approvedType ?
                                `${translate(`request.${request.approverNotes.approvedType[0].toLowerCase() + request.approverNotes.approvedType.substring(1)}`)}` : "—"}
                        </Typography>

                    </Box>

                    <Divider sx={{ my: 3 }} />

                    <Box sx={{ display: isDesktop ? 'grid' : 'flex', flexDirection: 'column', gridTemplateColumns: '0.75fr 1fr', gap: 2 }}>

                        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                            <Typography
                                sx={{
                                    color: '#6D6D6D',
                                    fontSize: 12,
                                    fontFamily: 'Public Sans',
                                    fontWeight: '700',
                                    textTransform: 'uppercase',
                                    wordWrap: 'break-word',
                                    mb: 1
                                }}
                            >
                                {`${translate('vehicle.form.model.origin')}`}
                            </Typography>

                            <Typography variant="body1">
                                {request ? request.createdBy.firstName + " " + request.createdBy.lastName : "—"}
                            </Typography>

                            <Typography variant="body1">
                                {request ? (request.deliveryOrganization.externalId + " " + request.deliveryOrganization.name) : "—"}
                            </Typography>

                            <Typography variant="body1">
                                {request ? getAddress(request.deliveryOrganization.address).join("") : "—"}
                            </Typography>

                            <Typography variant="body1">
                                {`${translate('commons.phone')}`}: {(request && request.deliveryOrganization.contact) ? request.deliveryOrganization.contact.businessPhone : "—"}
                            </Typography>
                        </Box>

                        <Box sx={{ display: 'flex', flexDirection: 'column', mr: isDesktop ? 30 : 0 }}>
                            <Typography
                                sx={{
                                    color: '#6D6D6D',
                                    fontSize: 12,
                                    fontFamily: 'Public Sans',
                                    fontWeight: '700',
                                    textTransform: 'uppercase',
                                    wordWrap: 'break-word',
                                    mb: 1
                                }}
                            >
                                {`${translate('request.hubRet')}`}
                            </Typography>

                            <Typography variant="body1">
                                {request ? getAddress(request.vendor.address).join("") : "—"}
                            </Typography>

                            <Typography variant="body1">
                                {`${translate('commons.phone')}`}: {request ? request.vendor.contact?.businessPhone : "—"}
                            </Typography>
                        </Box>
                    </Box>

                    <Divider sx={{ my: 3 }} />

                    {(request?.hasMedia && isMediaImage) &&
                        <>
                            <Typography
                                sx={{
                                    color: '#6D6D6D',
                                    fontSize: 12,
                                    fontFamily: 'Public Sans',
                                    fontWeight: '700',
                                    textTransform: 'uppercase',
                                    wordWrap: 'break-word',
                                    mb: 1
                                }}
                            >
                                {`${translate('request.form.damageDocuments')}`}
                            </Typography>

                            <MediaSlider images={request.media} />

                            <Divider sx={{ mb: 3 }} />
                        </>
                    }

                    <Box
                        sx={{
                            display: 'flex',
                            px: 5,
                            py: 3,
                            gap: 1,
                            flexDirection: isDesktop ? 'row' : 'column',
                            justifyContent: 'space-between'
                        }}
                    >
                        <Button
                            variant="soft"
                            onClick={!id ? handlePreviousStep : () => navigate(PATH_DASHBOARD.request.carrier.backlogDetail(id))}
                        >
                            {`${translate('commons.goBack')}`}
                        </Button>

                        {(request && (request.status === "ReadyForPickUp" || request.status === "PickedUp")) && <Box
                            sx={{
                                ml: 'auto',
                                display: 'flex',
                                gap: 2,
                                mt: isDesktop ? 0 : 1,
                                flexDirection: isDesktop ? 'row' : 'column',
                                width: isDesktop ? 'auto' : '100%'
                            }}
                        >
                            {request?.requestType === "WrongShipment" && !carrier &&
                                <Button variant="contained" color="error" onClick={() => handleRefuse()}>
                                    {`${translate('commons.refuse')}`}
                                </Button>
                            }
                            {handleNextStep === undefined ?
                                <Button variant="contained" onClick={() => handleConfirm()}>{`${translate('commons.confirm')}`}</Button>
                                :
                                <Button variant="contained" onClick={handleNextStep}>{`${translate('commons.confirm')}`}</Button>
                            }
                        </Box>}

                    </Box>

                </Card >
            }
        </>
    );
}

