import {
    Box, Card, Container, MenuItem,

    TextField, Button, FormHelperText
} from "@mui/material";
import Page from "src/appComponents/Page";
import useLocales from "src/appHooks/useLocales";
import { useSettingsContext } from "src/components/settings";
import HeaderBreadcrumbs from '../../../../components/custom-breadcrumbs';
import { PATH_DASHBOARD } from '../../../../routes/paths';
import { useEffect, useState } from "react";
import { administrationRoleCreate } from "src/services/roleServices";
import { LoadingButton } from "@mui/lab";
import { useNavigate } from "react-router";
import { Stack } from "@mui/system";
import ErrorModal from "src/components/modals/ErrorModal";
import SuccessModal from "src/components/modals/SuccessModal";
import { ClusterPermissionTypes, ClusterPermissionsList, OrganizationPermissionsList } from "src/@types/permissions";
import PermissionTable from "src/components/table/PermissionTable";

function permissionCheck(permissionToCheck: string, type: string, family: "Cluster" | "Organization") {

    let result = false;

    const arrToCheck = family === "Cluster" ? ClusterPermissionsList : OrganizationPermissionsList;

    arrToCheck[type].some(permission => {

        if (permissionToCheck === permission) {
            result = true;

            return true;
        }

        return false;
    });

    return result;
}

export function permDependencies(name: string, father: string, type: string, family: "Cluster" | "Organization") {

    var permissions = [name];

    const newPermissionArr = [];

    switch (father) {
        case "Logs":
            newPermissionArr.push(name.replace("Logs", ""));
            break;
        case "Create":
            newPermissionArr.push(name.replace("Create", "View"));
            break;
        case "Edit":
            newPermissionArr.push(name.replace("Edit", "View"), name.replace("Edit", "Create"));
    }

    newPermissionArr.forEach(permission => {
        if (permissionCheck(permission, type, family)) {
            permissions.push(permission);
        }
    });

    return permissions;
}

// ------------------------------------ //

export default function NewAdminRole() {

    const { translate } = useLocales();

    const navigate = useNavigate();

    const { themeStretch } = useSettingsContext();

    const [newRole, setNewRole] = useState<string[]>([]);

    const [roleName, setRoleName] = useState<string>('');

    const [type, setType] = useState<any>('Users');

    const [isOpenErr, setIsOpenErr] = useState(false);

    const [isOpenSucc, setIsOpenSucc] = useState(false);

    const [nameErrorText, setNameErrorText] = useState('');

    const [generalErrorText, setgeneralErrorText] = useState('');

    const handleSubmit = async () => {

        const param = {
            name: roleName.trim(),
            permissions: newRole as ClusterPermissionTypes[]
        };

        try {
            await administrationRoleCreate(param);
            toggleSucc();
        } catch (e) {
            toggleErr();
        }
    };

    const toggleErr = () => {
        isOpenErr ? setIsOpenErr(false) : setIsOpenErr(true);
    };

    const toggleSucc = () => {
        isOpenSucc ? setIsOpenSucc(false) : setIsOpenSucc(true);
    };

    const handleBlur = (e: any) => {
        if (!e.target.value) {
            setNameErrorText(`${translate('commons.validation.requiredField')}`);
        } else if (e.target.value.length > 15) {
            setNameErrorText(`${translate('role.validation.nameLength')}`);
        } else {
            setNameErrorText('');
        }
    };

    useEffect(() => {
        if (newRole.length === 0 && !(roleName.trim()) && !nameErrorText) {
            setgeneralErrorText(`${translate('role.validation.roleAndName')}`);
        } else if (newRole.length === 0) {
            setgeneralErrorText(`${translate('role.validation.oneRole')}`);
        } else if (!(roleName.trim()) && !nameErrorText) {
            setgeneralErrorText(`${translate('role.validation.justName')}`);
        } else {
            setgeneralErrorText("");
        }
    }, [newRole, roleName, translate, nameErrorText]);

    return (
        <Page title={`${translate('role.form.new.admin')}`}>

            <ErrorModal
                isOpen={isOpenErr}
                errorText={`${translate('commons.error')}`}
                toggle={toggleErr}
            />
            <SuccessModal
                isOpen={isOpenSucc}
                successText={`${translate('role.messages.success')}`}
                toggle={toggleSucc}
                returnPage={PATH_DASHBOARD.tenant.role.admin.list}
            />

            <Container maxWidth={themeStretch ? false : 'lg'} >
                <HeaderBreadcrumbs
                    heading={`${translate('role.form.new.admin')}`}
                    links={[
                        { name: `${translate('commons.home')}`, href: PATH_DASHBOARD.root },
                        { name: `${translate('commons.list')}`, href: PATH_DASHBOARD.tenant.role.admin.list },
                        { name: `${translate('role.form.new.admin')}` }
                    ]}
                />

                <Card sx={{ p: 3 }}>

                    <Stack spacing={2} direction={{ xs: 'column', sm: 'row' }} sx={{ py: 2.5 }}>
                        <TextField
                            select
                            fullWidth
                            id='tipoRuolo'
                            SelectProps={{
                                MenuProps: {
                                    sx: { '& .MuiPaper-root': { maxHeight: 260 } },
                                },
                            }}
                            sx={{
                                maxWidth: { sm: 240 },
                                textTransform: 'capitalize'
                            }}
                            value={type}
                            onChange={e => setType(e.target.value)} >
                            {Object.keys(ClusterPermissionsList).map((type) => (
                                <MenuItem
                                    key={type}
                                    value={type}
                                    sx={{
                                        mx: 1,
                                        my: 0.5,
                                        borderRadius: 0.75,
                                        typography: 'body2',
                                        textTransform: 'capitalize',
                                    }}
                                >
                                    {`${translate(`role.type.${type[0].toLowerCase() + type.slice(1)}`)}`}
                                </MenuItem>
                            ))}
                        </TextField>

                        <TextField
                            label={`${translate('role.form.name')}`}
                            fullWidth
                            required
                            sx={{ textAlign: 'center', width: '50%', alignSelf: 'center', '& .MuiInputLabel-asterisk': { color: 'error.main' } }}
                            onChange={e => setRoleName(e.target.value)}
                            onBlur={e => handleBlur(e)}
                            error={!!nameErrorText}
                            helperText={nameErrorText}
                        />
                    </Stack>

                    <PermissionTable
                        PermissionT={ClusterPermissionsList}
                        onChange={(per) => setNewRole(old => ({ ...old, permissions: (per as ClusterPermissionTypes[]) }))}
                        type={type}
                        isCluster
                    />

                    <Box hidden={!generalErrorText}>
                        <FormHelperText sx={{ pb: 1.2, textAlign: "right !important" }} error>
                            {generalErrorText}
                        </FormHelperText>
                    </Box>

                    <Box sx={{ mt: 3, display: 'flex', justifyContent: 'flex-end', gap: 2 }}>
                        <Button variant="soft" color="inherit" onClick={() => navigate(PATH_DASHBOARD.tenant.role.admin.list)}>
                            {`${translate("commons.cancel")}`}
                        </Button>
                        <LoadingButton disabled={!!nameErrorText || !!generalErrorText} type="submit" variant="contained" onClick={handleSubmit}>
                            {`${translate("role.form.create")}`}
                        </LoadingButton>
                    </Box>

                </Card>
            </Container>

        </Page>
    );
}
